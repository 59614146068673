<template>
  <div class="all">
    <div class="head">
      <van-nav-bar  title="Paylaş Kazan Planı" color="#556fb5" left-arrow  @click-left="onClickLeft"></van-nav-bar>
      <!-- <label class="head-text" for=""> Paylaş Kazan Planı</label> -->
    </div>
    <div class="gift-box">
      <img src="@/assets/img/info-introduced/11_03.jpg" width="100%" alt="" />
    </div>
    <div class="rectangle">
      <div class="rectangle-frame">
        <div class="rectangle-frame-top">
          Arkadaşlarınızın bonus kazanıma davet edin
        </div>
        <div class="rectangle-frame-text1" for="">aktivite açıklaması</div>
        <ul>
          <li>1. A B'yi davet eder, B kaydı tamamlar, A XX a2. B'n</li>
          <li>1. A B'yi davet eder, B kaydı tamamlar, A XX a2. B'n</li>
          <li>1. A B'yi davet eder, B kaydı tamamlar, A XX a2. B'n</li>
        </ul>
        <div class="rectangle-frame-button">
          <img src="@/assets/img/info-introduced/48.png" width="40%" alt="" />
        </div>
      </div>
    </div>
    //第二个
    <div class="rectangle1">
      <div class="rectangle-frame1">
        <div class="rectangle-frame-top1">
          davet etiniz Arkadaşınız görev yapar, komisyonu siz kazanırsın
        </div>
        <div class="rectangle-frame-text1" for="">1. Takım komisyonu</div>
        <label for="" class="rectangle-frame-text2">
          Davet ettiğiniz arkadaşlar görevi tamamlar ve belirli bir oranDavet
          ettiğiniz arkadaşlar görevi tamamlar ve belirli bir oran</label
        >
        <img
          src="@/assets/img/info-introduced/people.png"
          width="100%"
          alt=""
        />
        <div class="rectangle-frame-text2">
          Örneğin: A aynı gün 3 görevi tamamlar toplam 21 olur yukarıdakÖrneğin:
          A aynı gün 3 görevi tamamlar toplam 21 olur yukarıdak
        </div>
        <table>
          <tr>
            <td>Toplam ekip görevleri</td>
            <td>Toplam ekip görevleriÖdül oranı</td>
          </tr>
          <tr>
            <td>1-10</td>
            <td>1%</td>
          </tr>
          <tr>
            <td>11-50</td>
            <td>2%</td>
          </tr>
          <tr>
            <td>51-100</td>
            <td>3%</td>
          </tr>
        </table>
        <div class="rectangle-frame-text2">
          Toplam ekip görevleri = A+B1+B2+B3+B4+B5
        </div>
        <div class="rectangle-frame-text1">2. Seviye farkı komisyonu</div>
        <div class="rectangle-frame-text2">
          Takım komisyonu aldığınızda fark komisyonunuz da olur. Fark kom
          komisyonunuz da olur. Fark
        </div>
        <img src="@/assets/img/info-introduced/tree2.png" width="100%" alt="" />
        <div class="rectangle-frame-text2">
          Şekilde gösterildiği gibi, kendi oranlarını elde etmek için takım
          görevlerini doğrudan bağlı olan B1 ve B2 takımlarıyla karşılaştıran A
          takımıdır. Aşağıdaki tabloda gösterildiği gibi
        </div>
        <table>
          <tr>
            <td>takım</td>
            <td>Toplam ekip görevleri</td>
            <td>komisyon oranı</td>
          </tr>
          <tr>
            <td>A</td>
            <td>A+B1+B2</td>
            <td>3%</td>
          </tr>
          <tr>
            <td>B1</td>
            <td>B1+C1+C2</td>
            <td>2%</td>
          </tr>
          <tr>
            <td>B2</td>
            <td>B2+D1+D2</td>
            <td>1%</td>
          </tr>
        </table>
        <div class="rectangle-frame-text2">
          A'nın B1'de alabileceği seviye farkı komisyonu= (3%-2%)*(B1+C1+C2)
        </div>
        <div class="rectangle-frame-text2">
          A'nın B2'de alabileceği seviye farkı komisyonu= (3%-1%)*(B2+D1+D2)
        </div>
      </div>
      <div class="rectangle-frame2">
        <div class="rectangle-frame-top1">
          davet etiniz Arkadaşınız görev yapar, komisyonu siz kazanırsın
        </div>
        <div class="rectangle-frame-text2">
          Sonsuz sürprizler var.Komisyon almanın yukarıdaki üç yoluna ek Sonsuz
          sürprizler var.Komisyon almanın yukarıdaki üç yoluna ek
        </div>
        <table>
          <tr>
            <td>Bireysel tamamlanmış toplam</td>
            <td>Ödül oranı</td>
          </tr>
          <tr>
            <td>1-10</td>
            <td>A+B1+B2</td>
          </tr>
          <tr>
            <td>11-50</td>
            <td>B1+C1+C2</td>
          </tr>
          <tr>
            <td>50-100</td>
            <td>B2+D1+D2</td>
          </tr>
        </table>
        <div class="rectangle-frame-text2">
          Örneğin: A aynı gün 3 görevi tamamlar toplam 21 olur yukarıdak
          Örneğin: A aynı gün 3 görevi tamamlar toplam 21 olur yukarıdak
        </div>
        <div class="rectangle-frame-text2">21*2%=0.42</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
     onClickLeft(){
       this.$router.push('/user')
    }
  }
};
</script>

<style scoped>
.all {
  width: 100%;
  /* height: 380vh; */
  background: linear-gradient(#9babf6, #94e6fb);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.head {
  width: 100%;
  /* height: 60px; */
  background-color: #556fb5;
  text-align: center;
  line-height: 60px;
  overflow: hidden;
}

.van-nav-bar{
  background-color:#556fb5;
   color:#fff;
  font-size: 24px;
}
.head-text {
  color: #f6f1ea;
  font-size: 24px;
}
.rectangle {
  width: 100%;
  height: 300px;
  padding: 0 20px 0 20px;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: -10px;
}
.rectangle-frame {
  width: 100%;
  /* height: 250px; */
  border: 1px solid #333333;
  border-radius: 5px;
  padding: 30px 10px 0 10px;
}
.rectangle-frame-top {
  background-color: #97c0ff;
  width: 80%;
  text-align: center;
  margin: 0 auto;
  margin-top: -40px;
  margin-bottom: 30px;
}
.rectangle-frame-text1 {
  font-size: 26px;
  margin-bottom: 20px;
}
ul {
  margin-top: 20px;
  line-height: 30px;
}
.rectangle-frame-button {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* position: absolute;
  bottom: 110px; */
  margin-top: 32px;
}

.rectangle1 {
  width: 100%;
  /* height: 500px; */
  padding: 0 20px 0 20px;
}
.rectangle-frame1 {
  width: 100%;
  /* height: 1500px; */
  border: 1px solid #333333;
  border-radius: 5px;
  padding: 30px 10px 0 10px;
}
.rectangle-frame-top1 {
  background-color: #94cefe;
  border: 1px solid #d9b789;
  padding: 10px;
  width: 70%;
  text-align: center;
  margin: 0 auto;
  margin-top: -60px;
  margin-bottom: 30px;
}
.rectangle-frame-text2 {
  line-height: 28px;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
}
table,
table tr td {
  border: 1px solid black;
  border-collapse: collapse;
  text-align: center;
  line-height: 25px;
}
td {
  width: 40%;
}
tr:first-child {
  background-color: #cfa972;
}

.rectangle-frame2 {
  width: 100%;
  /* height: 440px; */
  border: 1px solid #333333;
  border-radius: 5px;
  padding: 30px 10px 0 10px;
  margin-top: 60px;
  margin-bottom: 30px;
}
</style>