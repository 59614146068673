<template>
  <div class="recordBox">
    <div class="bgc">
      <div class="topTitle">{{ $t("commissionRecord.default[0]") }}</div>
    </div>
    <div class="height"></div>
    <div class="record_nav">
      <div class="record_nav_box">
        <div class="record_nav_title">
          {{ $t("commissionRecord.default[1]") }}
        </div>
        <div class="record_nav_content">
          <div class="record_nav_timeBox">
            <div class="record_nav_time start">
              <input
                type="text"
                readonly
                v-model="startDate"
                @click="
                  (showDate = true),
                    (currentDate = new Date(startDate)),
                    (pickType = 1)
                "
              />
              <img src="../../../public/static/images/riQiPan.png" />
            </div>
            <span>-</span>
            <div class="record_nav_time end">
              <input
                type="text"
                readonly
                v-model="endDate"
                @click="
                  (showDate = true),
                    (currentDate = new Date(endDate)),
                    (pickType = 2)
                "
              />
              <img src="../../../public/static/images/riQiPan.png" />
            </div>
          </div>
          <div class="record_nav_search" @click="getCommission(1)">
            {{ $t("commissionRecord.default[4]") }}
          </div>
        </div>
      </div>
    </div>
    <div class="record_content">
      <div
        class="record_content_item"
        v-for="(item, index) in commissionList"
        :key="index"
      >
        <!-- 日期 -->
        <h3>{{ item.cr_date.split(" ")[0] }}</h3>
        <div class="record_item_detail">
          <div class="record_detail_item">
            <div class="record_detail_item_money">
              {{ item.reward_amount }}
            </div>
            <div class="record_detail_item_title">
              {{ $t("commissionRecord.default[6]") }}
            </div>
          </div>
          <div class="record_detail_item">
            <div class="record_detail_item_money">
              {{ item.team_reward_amount }}
            </div>
            <div class="record_detail_item_title">
              {{ $t("commissionRecord.default[7]") }}
            </div>
          </div>
          <div class="record_detail_item">
            <div class="record_detail_item_money">
              {{ item.level_reward }}
            </div>
            <div class="record_detail_item_title">
              {{ $t("commissionRecord.default[8]") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 日期 -->
    <van-popup
      class="popup_date"
      v-model="showDate"
      position="bottom"
      close-on-popstate
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :title="$t('commissionRecord.date[0]')"
        :min-date="minDate"
        :confirm-button-text="$t('commissionRecord.date[1]')"
        :cancel-button-text="$t('commissionRecord.date[2]')"
        @confirm="confirmDate"
        @cancel="showDate = false"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "commissionRecord",
  components: {},
  data() {
    return {
      commissionList: "", //获取数据
      //
      showDate: false, //显示日期
      startDate: this.$Util.DateFormat("YY-MM-DD", new Date()),
      endDate: this.$Util.DateFormat("YY-MM-DD", new Date()),
      currentDate: "",
      minDate: new Date(2020, 0, 1),
    };
  },
  computed: {},
  watch: {
    commissionList() {
      console.log(this.commissionList);
      console.log(Object.keys(this.commissionList));
    },
  },
  created() {
    //标题
    this.$parent.navBarTitle = "";
    this.getCommission();
  },
  methods: {
    //选择日期
    confirmDate(date) {
      console.log(date);
      if (this.pickType == 1) {
        if (this.endDate) {
          //判断起始时间不能小于结束时间
          let end = parseInt(this.endDate.split("-").join("")),
            start = parseInt(
              this.$Util.DateFormat("YY-MM-DD", date).split("-").join("")
            );
          if (start > end) {
            return this.$toast(this.$t("resultsTheQuery.msg[1]"));
          }
        }
        this.startDate = this.$Util.DateFormat("YY-MM-DD", date);
      }
      if (this.pickType == 2) {
        if (this.startDate) {
          //判断起始时间不能小于结束时间
          let start = parseInt(this.startDate.split("-").join("")),
            end = parseInt(
              this.$Util.DateFormat("YY-MM-DD", date).split("-").join("")
            );
          if (start > end)
            return this.$toast(this.$t("resultsTheQuery.msg[1]"));
        }
        this.endDate = this.$Util.DateFormat("YY-MM-DD", date);
      }
      this.showDate = false;
    },
    //获取查询信息
    getCommission(chooseDate) {
      let param = {};
      if (chooseDate == 1) {
        if (!this.startDate || !this.endDate) {
          this.$toast(this.$t("commissionRecord.msg[0]"));
          return false;
        }
        param["startdate"] = this.startDate; //开始时间
        param["enddate"] = this.endDate;
      }

      param["type"] = 1; //type 1 我的业绩 2 团队业绩 3 下级团队业绩
      this.commissionList = {};
      this.$Model.getCommissionRecord(param, (data) => {
        var data = data.commisionData;
        data.forEach((item) => {
          this.$set(this.commissionList, item["report_date"], item);
          this.$set(
            this.commissionList[item["report_date"]],
            ["team_reward_amount"],
            0
          );
          /*  this.commissionList[item["report_date"]] = item;*/
          //this.commissionList[item["report_date"]]["team_reward_amount"] = 0;
        });
        param["type"] = 2;
        this.$Model.getCommissionRecord(param, (data) => {
          var teamdata = data.commisionData;
          teamdata.forEach((item) => {
            var report_date = item["report_date"];
            var curritem = null;
            if (this.commissionList.hasOwnProperty(report_date)) {
              curritem = this.commissionList[report_date];
              curritem["team_reward_amount"] = item["reward_amount"];
              curritem["level_reward"] = item["level_reward"];
            } else {
              curritem = item;
              curritem["reward_amount"] = 0;
              curritem["team_reward_amount"] = item["reward_amount"];
            }
            // this.commissionList[report_date] = curritem;
            this.$set(this.commissionList, report_date, curritem);
          });
        });
      });
    },
  },
};
</script>

<style>
.recordBox {
  overflow: auto;
  height: 100vh;
  background-color: #f5f5f5;
}

.recordBox .bgc {
  width: 100%;
  background-color: #4e51bf;
  height: 46px;
  position: fixed;
  top: 0;
  z-index: 200;
}

.topTitle {
  position: absolute;
  max-width: 60%;
  white-space: nowrap;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.height {
  height: 46px;
}

.record_nav {
  padding: 10px 20px 91px;
  background-color: #4e51bf;
  border-radius: 0px 0px 80px 80px;
}

.record_nav .record_nav_box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.record_nav .record_nav_title {
  color: #d0d1ee;
  font-size: 13px;
  /* font-weight: 600; */
  margin-right: 5px;
}

/* 日期 */
.record_nav .record_nav_timeBox {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.record_nav .record_nav_timeBox span {
  margin: 0 5px;
  color: #fff;
}

.record_nav .record_nav_time {
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.record_nav .record_nav_time input {
  height: 29px;
  border-radius: 6px;
  color: #d0d1ee;
  background-color: #4143ac;
  border: 1px solid #7c7cd8;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  padding: 4px 30px 4px 4px;
  text-align: center;
  box-sizing: border-box;
}

input::-webkit-input-placeholder {
  color: #fff;
  font-size: 14px;
}

.record_nav .record_nav_time img {
  position: absolute;
  top: 50%;
  transform: translate(-15%, -40%);
  right: 0px;
  width: 20px;
  margin-left: 9%;
}

.record_nav .record_nav_content {
  display: flex;
  flex-wrap: wrap;
}

/* .record_nav .record_nav_timeBox>>> */
.record_nav .record_nav_search {
  padding: 4.5px 17px;
  border: 1px solid #afaeff;
  border-radius: 5px;
  color: #403dec;
  font-size: 14px;
  background: linear-gradient(180deg, #b3b5fc, #f3e1d6);
  flex-shrink: 0;
  /* margin-left: auto; */
}

.record_content {
  padding: 0 20px;
  margin-top: -76px;
}

.record_content .record_content_item {
  padding: 12px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 10px;
}
/* 日期 */
.record_content .record_content_item h3 {
  color: #666;
  font-size: 16px;
}

.record_content .record_item_time {
  font-size: 14px;
  color: #282828;
  /* font-weight: 600; */
  display: flex;
  align-items: center;
}

.record_content .record_item_detail {
  margin-top: 8px;
  border-radius: 0px 40px 40px 40px;
  background-color: #ecebfd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px;
  flex-wrap: wrap;
}

.record_content .record_detail_item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 95px;
  margin: 5px auto;
}

.record_content .record_detail_item_money {
  font-size: 18px;
  color: #fa93a5;
  font-weight: bold;
}

.record_content .record_detail_item_title {
  font-size: 10px;
  font-family: Adobe 黑体 Std;
  transform: scale(0.9);
  text-align: center;
}

.ipt {
  font-size: 13px;
}
.popup_date {
  width: 100%;
  border-radius: 10px 10px 0 0;
}
</style>
