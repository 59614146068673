<template>
  <div class="Site PageBox">
    fdafdafdaf
    <van-cell is-link @click="showPopup">展示弹出层</van-cell>

    <van-popup v-model="show2">内容</van-popup>

    <van-dialog v-model="show" :show-cancel-button="true" :before-close="beforeClose">
      <div style="width: 100%; height: 200px; display: flex; flex-direction: column; align-items: center;">
        <span style="width: 100%; height: 30px; font-size: 20px; color: #ffffff; font-weight: bold; text-align: center; background-color: #37AAEA;">备注</span>
        <van-field
                v-model="remarkValue"
                placeholder="请输入备注内容"
                clearable
                autosize
                type="textarea"
                rows="1"
                maxlength="230"
                show-word-limit
        />
      </div>
    </van-dialog>
  </div>
</template>
<script>
//设置默认接口
import config from '@/config'

const ApiUrl = config.ApiUrl

import Ping from 'web-pingjs'
export default {
  name: 'Lines',
  components: {

  },
  props: {},
  data() {
    return {
      listData: '',
      timeList: [],
      currLine: '',
      show: false,
    }
  },
  computed: {

  },
  filters: {

  },
  watch: {

  },
  created() {

  },
  mounted() {

  },
  activated() {

  },
  destroyed() {
    
  },
  methods: {
    showPopup() {
      this.show = true
    },

  }

}
</script>
<style scoped>
.van-cell__value{
  flex: none;
}
.van-cell__title i{
  color: #4e51bf;
  margin-left: 15px;
}
.van-radio{
  justify-content: flex-end;
}
</style>