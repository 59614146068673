<template>
	<div class="newpeople">
		<!-- 标题 -->
		<div :class="[titlebg ? 'titlebg' : '']">
			<div class="title">{{ $t('newpeople.default[0]') }}</div>
			<div class="link">{{ $t('newpeople.default[1]') }}</div>
		</div>
		<!-- 最高免费赚盒子 -->
		<div class="head" :class="[Language == 'cn' ? 'head_cn' : '']" ref="hig"><img :src="`static/images/newpeopleTitle_${Language}.png`" /></div>
		<!-- 主题部分盒子 -->
		<div class="neck">
			<van-swipe class="lun" style="height: 30px" vertical loop :touchable="false" :show-indicators="false" :autoplay="3000">
				<van-swipe-item>{{ $t('newpeople.default[2]', { index: 1, index_item: 2, money: 0.3 }) }}</van-swipe-item>
			</van-swipe>
		</div>
		<div class="body">
			<div class="body_item" v-for="(item, index) in taskList">
				<div class="item_box" @click="turn(item.status, item.pac)">
					<!-- <p class="item_title" v-if="Language != 'cn'">{{ $t(`newpeople.ptb_item_title[${index}]`, { index: index + 1 }) }}</p> -->
					<p class="item_title" v-if="Language != 'cn'">{{ item.title }}</p>
					<div class="item_content">
						<div class="item_left">{{ index + 1 }}</div>
						<div class="item_right">
							<!-- <p class="item_title_cn" v-if="Language == 'cn'">{{ $t('newpeople.default[3]', { index: index + 1 }) }}</p> -->
							<p class="item_1" :class="Language == 'cn' ? 'item_1_noCn' : ''">{{ item.name }}</p>
							<div class="item_2">
								{{ $t('newpeople.default[5]') }}：
								<div>
									<van-progress :percentage="item.percentage ? item.percentage : ''" />
									<div style="padding-left: 10px;">{{ item.completed_task }}/{{ item.task_count }}</div>
								</div>
							</div>
							<p class="item_3">
								{{ $t('newpeople.default[6]') }}：
								<span>R${{ item.getamisstion }}</span>
							</p>
						</div>
					</div>
					<!-- 0未完成(不可看) 1可领取 2已完成 4未完成(可看) -->
					<div class="item_state" v-if="item.status == 0">{{ $t('newpeople.default[7]') }}</div>
					<div class="item_state" v-else-if="item.status == 1" @click="complete(item.pac)">{{ $t('newpeople.default[8]') }}</div>
					<div class="item_state" v-else-if="item.status == 2">{{ $t('newpeople.default[9]') }}</div>
					<div class="item_state" v-else-if="item.status == 4">{{ $t('newpeople.default[10]') }}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { Toast } from 'vant';
export default {
	data() {
		return {
			// 进度条
			titlebg: false,
			progress: 2,
			scuss: false,
			show: false,
			taskList: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
			Language: localStorage['Language'] ? localStorage['Language'] : 'en', //语言
			index: '' //判断做到了第几个任务
		};
	},
	created() {
		let uid = this.UserInfo.userid;
		// console.log('this.UserInfo.userid', id);
		this.getMyNoviceTask(uid); // 获取新手礼包列表
	},
	mounted() {
		this.pageScroll();
	},
	computed: {},
	methods: {
		getMyNoviceTask(uid, fn) {
			// 获取新手礼包列表
			let json = {
				uid
			};
			// console.log('json', json);
			this.$Model.getMyNoviceTask(json, data => {
				//把完成进度换算成百分比
				var t = 0;
				var that = this;
				data.novice_task.forEach(function(item, index) {
					// console.log('this.taskList.percentage',index,item.completed_task,item.task_count)
					data.novice_task[index].percentage = (item.completed_task / item.task_count) * 100;
					if (item.status == 0 && t == 0) {
						data.novice_task[index].status = 4;
						t = 1;
						that.index = index;
					}
				});
				this.taskList = data.novice_task;
				console.log('this.taskList', this.taskList);
			});
		},
		turn(status, index) {
			console.log('status', status);
			//通过status来判断，项目完成状态，0：未完成(不可看)、1：可以领取、2：已经领取、手动设置了4为未完成(可看)
			switch (status) {
				case 0:
					Toast(this.$t('newpeople.default[11]'));
					break;
				case 1:
					this.$router.push(`/user/extension/${index}`);
					break;
				case 2:
					Toast('completado!'); //提示已经完成
					break;
				case 4:
					this.$router.push(`/user/extension/${index}`);
					break;
				default:
			}

			// if(index == this.index+1){
			// 	this.$router.push(`/user/extension/${index}`);
			// }else{
			// 	Toast(this.$t('newpeople.default[11]'))
			// }
		},
		complete(pac) {
			event.stopPropagation(); //阻止冒泡
			this.$Model.receiveNoviceGift({ uid: this.UserInfo.userid, pac: pac }, data => {
				if (data.code == 1) {
					Toast('Recebido com sucesso');
					setTimeout(()=>{
						window.location.reload();
					},500)
				} else {
					Toast('Tarefa não completada');
				}
			});
		},
		pageScroll() {
			window.addEventListener('scroll', this.handleScroll, true);
		},
		handleScroll() {
			let top = this.$refs.hig.getBoundingClientRect().top;
			if (top > -46) {
				this.titlebg = false;
			} else {
				this.titlebg = true;
			}
		}
	},
	destroyed() {
		// 在组件生命周期结束的时候要销毁监听事件，否则其他页面也会使用这个监听事件
		window.removeEventListener('scroll', this.handleScroll, true);
	}
};
</script>
<style scoped>
.newpeople {
	background: url(/static/images/newpeople.jpg);
	background-size: 100%;
	height: 100%;
	overflow: auto;
}

.titlebg {
	background: #4f51bf;
	position: fixed;
	top: 0px;
	width: 100%;
	height: 46px;
	z-index: 99;
}

.title {
	position: fixed;
	top: 0px;
	width: 100%;
	text-align: center;
	font-size: 17px;
	color: #fff;
	line-height: 46px;
	z-index: 99;
}

.link {
	color: #fff;
	font-size: 12px;
	margin-left: 20%;
	width: 100px;
	position: fixed;
	right: -30px;
	top: 18px;
	z-index: 999;
}

.head {
	margin-top: 80px;
	width: 100%;
	text-align: center;
}

.head img {
	width: 50%;
	min-width: 100px;
	max-width: 300px;
}

.head_cn img {
	width: 70%;
}

.neck {
	background: url('../../../public/static/images/inp.png') no-repeat;
	background-size: 100% 35px;
	height: 35px;
	line-height: 40px;
	text-align: center;
	color: #fff156;
	margin: 25px 15px 0px 15px;
}

.newpeople >>> .lun .van-swipe-item {
	font-size: 12px;
	margin-left: -5px;
}

.body {
	margin-top: 30px;
	width: 100%;
	padding: 0 20px;
	margin-bottom: 60px;
}
.body .body_item {
	background-image: linear-gradient(to bottom, #ff93a4, #4f51bf);
	border-radius: 20px;
	padding: 3px;
	margin-bottom: 20px;
}
.body .item_box {
	padding: 10px;
	border-radius: 20px;
	background-color: #fff;
}
.body .item_title {
	font-size: 17px;
	font-weight: 600;
	color: #ab03fd;
	text-align: center;
}
.body .item_title_cn {
	font-size: 17px;
	font-weight: 600;
	color: #ab03fd;
	letter-spacing: 1px;
}
.body .item_title,
.body .item_title_cn,
.body .item_1 {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.body .item_content {
	display: flex;
	justify-content: space-around;
	align-items: flex-end;
}
.body .item_left {
	width: 65px;
	height: 72px;
	background: url('../../../public/static/images/box.png') no-repeat;
	background-size: 100%;
	display: flex;
	justify-content: center;
	line-height: 90px;
	font-size: 24px;
	color: #ab03fd;
	font-weight: 600;
	margin-right: 10px;
	flex-shrink: 0;
}
.body .item_right {
	overflow: hidden;
}
.body .item_1 {
	margin-top: 16px;
	font-size: 12px;
}
.body .item_1_noCn {
	margin-top: 6px;
}
.body .item_2,
.body .item_3 {
	/* display: flex; */
	font-size: 12px;
	margin-top: 5px;
}
/* .body .item_3{font-size: 12px;} */
.body .item_2 {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.body .item_2 > div {
	display: flex;
	align-items: center;
}
.body .item_2 >>> .van-progress {
	width: 100px;
}
.body .item_2 >>> .van-progress .van-progress__portion {
	background-color: #4f51bf;
}
.body .item_2 >>> .van-progress__pivot {
	display: none;
}
.body .item_3 > span {
	font-size: 15px;
	color: #fd4133;
}
.body .item_state {
	width: 90px;
	border-radius: 20px;
	height: 23px;
	text-align: center;
	line-height: 23px;
	font-size: 13px;
	background-color: #ab03fd;
	color: #fff;
	margin-left: auto;
	margin-top: 6px;
}
</style>
