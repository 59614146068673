<template>
  <div class="open_zd">
    <div class="success">
      <div class="successImg">
        <img src="../../../public/static/images/fail.png" />
      </div>
      <div class="successContent">
        <h2>{{ $t("automaticComplete[5]") }}</h2>
        <p>{{ $t("TaskPopup[15]") }}</p>
        <div>
          <!-- <div @click="setShow()">{{ $t("grabtask[6]") }}</div> -->
          <div @click="openzd()">{{ $t("TaskPopup[16]") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 手动抢单  当日次数已达上限 提示开通自动抢单 */
export default {
  name: "open_zd",
  data() {
    return {};
  },
  methods: {
    openzd() {
      this.$emit("openzd");
    },
  },
};
</script>

<style scoped>
/* 自动抢单 弹窗样式 */
.success {
  /* position: fixed;
		top:30%;
		left:35px; */
  width: 300px;
  height: 258px;
  background: linear-gradient(to bottom, rgb(254, 147, 164), rgb(79, 82, 191));
  border-radius: 20px;
  overflow: hidden;
}
.successImg {
  width: 70px;
  height: 70px;
  position: absolute;
  top: -35px;
  left: 115px;
}
.successImg > img {
  width: 100%;
  height: 100%;
}
.successContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 290px;
  height: 248px;
  background: #fff;
  border-radius: 16px;
  margin: 5px;
  overflow: hidden;
  text-align: center;
  padding: 0 20px;
}
.successContent > h2 {
  width: 100%;
  height: 25px;
  line-height: 25px;
  margin-top: 30px;
  font-size: 20px;
  color: rgb(254, 136, 157);
}
.successContent > p {
  width: 100%;
  max-height: 100px;
  text-align: center;
  margin-top: 5px;
  line-height: 20px;
}
.successContent > div {
  width: 100%;
  height: 40px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.successContent > div > div {
  width: 180px;
  height: 100%;
  text-align: center;
  line-height: 40px;
  color: #fff;
  background: rgb(78, 81, 191);
  border-radius: 15px;
}
</style>
