export default {
  mixRecharge: ["Täytä sähköpostiosoitteesi"],
  language: "Kieli",
  common: ["Verkkopalvelu", "Poista kohdistus", "Vahvista", "Peruuta"],
  upload: ["Ladataan...", "Väärä muoto", "Lataus onnistui", "Lataus epäonnistui"],
  vanPull: ["Ei enää tietoja", "Ei tietoja"],
  login: {
    text: ["Kieli", "Mlink", "Kirjaudu sisään"],
    label: ["Kirjaudutaan sisään...", "Kirjaudu heti"],
    placeholder: ["Anna sähköpostiosoitteesi", "Syötä salasanasi"],
    default: ["Ei tiliä?", "Rekisteröidy nyt", "Unohditko salasanasi?", "Unohditko salasanasi"],
    codes: ["tiliä ei löydy", "väärä salasana", "tili jäädytetty", "epäonnistui"],
    text2: ['Sähköposti', 'Vahvistus', 'Salasana', 'Kutsu', 'Puhelin'],
    text1: ['Kirjaudu sisään', 'Tervetuloa verkkosivustolle', "Sähköposti tai puhelin", 'Salasana', 'Kirjaudu sisään'],
  },
  register: {
    text: [
      "Mlink",
      "Vahvistuskoodi lähetetään...",
      "Klikkaa saadaksesi",
      "Rekisteröidään...",
      "Rekisteröidy nyt",
    ],
  },
 placeholder: [
  "Anna sähköpostiosoitteesi",
  "Syötä vahvistuskoodi",
  "Syötä salasanasi",
  "Vahvista salasanasi",
  "Syötä kutsukoodi",
  "Salasanat eivät täsmää",
  "Syötä vahvistuskoodi",
  "Virheellinen sähköposti"
],
label: ["Onko sinulla jo tili?{a} {line}", "Takaisin kirjautumiseen"],
codes: [
  "Tili on olemassa",
  "Tyhjä koodi",
  "Väärä koodi",
  "Salasanat eivät täsmää",
  "Väärä suosittelija",
  "Epäonnistui"
],
resetpwd: [
  "Unohtunut salasana",
  "Anna sähköpostiosoitteesi",
  "Syötä vahvistuskoodi",
  "Klikkaa saadaksesi",
  "Syötä salasana",
  "Nollaa salasana"
],
footer: ["Etusivu", "Hall", "Tilaus", "Oma"],
home: {
  WebMarket: "Verkkokauppa",
  special_attention: "Erityishuomio",
  spot_market: "Spottimarkkinat",
  label: ["Tervetuloa"],
  broadcast: "Onnittelut jäsenelle {member}, joka nousi tasolle {grade}",
  menu: ["Rahoituspäiväkirja", "Opas", "Kutsu", "Asiakaspalvelu"],
  noticeTitle: "Viimeisimmät ilmoitukset",
  msg: "Tehtävä ei ole avoin",
  video: "Ei vielä videon opetusohjelmia",
},
hall: {
  default: [
    "Kaupankäyntisali",
    "Saldo",
    "Haluan ostaa",
    "Haluan myydä",
    "Vahvista myynti",
    "Tilauslista",
    "Myyntitilaus",
    "Myy kauppiaalle",
    "Odotustilaus",
    "Osta",
    "Myy"
  ],
  list: [
    "Määrä",
    "Yksikköhinta",
    "Ei käyty kauppaa",
    "Myy USDT",
    "Anna USDT:n määrä",
    "Saatava määrä",
    "Määrä",
    "Osta USDT",
    "Käytetty määrä"
  ],
  kbip: ["Peruuta", "Ylimääräinen määrä", "On oltava määrän rajoissa"]
},
msglist: [
  "Viestilista"
],
sell: {
  placeholder: [
    "Syötä myyntihinta",
    "Syötä myytävä määrä"
  ],
  label: [
    "saldo (USDT)",
    "Nimi",
    "Pankkitili",
    "Nykyinen hinta (USDT)",
    "paras myyntihinta",
    "myyntihinta",
    "myyty määrä",
    "paras hinta",
    "kaikki",
    "varma myynti"
  ]
},
buy: {
  placeholder: [
    "Syötä ostohinta",
    "Syötä ostomäärä",
    "Vähimmäismäärä",
    "Enimmäismäärä"
  ],
  label: [
    "saldo (USDT)",
    "Nimi",
    "Pankkitili",
    "Nykyinen hinta (USDT)",
    "paras ostohinta",
    "ostohinta",
    "Ostomäärä",
    "paras hinta",
    "kaikki",
    "varma osto",
    "Määrä"
  ]
},
user: {
  default: [
    "Henkilökohtainen keskus",
    "Kirjautumistili",
    "Kutsukoodi",
    "Kirjaudu ulos",
    "Saldo",
    "Kulta",
    "Lompakko"
  ],
  menu: [
    "Omat varat",
    "Tiimilista",
    "Tilin yhdistäminen",
    "Tilin tiedot",
    "Alustan esittely",
    "Kirjaudu ulos"
  ]
},

 bindAccount: {
  default: ["Liitä tili", "Lähetä"],
  fields: ["Pankin nimi", "Tilin nimi", "Pankkitili", "Puhelinnumero", "Nimi", "Tyypit", "USDT-osoite"],
  placeholder: [
    "Anna pankin nimi",
    "Anna tilin nimi",
    "Anna pankkitilin numero",
    "Anna puhelinnumero",
    "Anna pankin nimi",
    "Valitse USDT-tyyppi",
    "Anna USDT-osoite"
  ],
  fields2: ["Tili", "Puhelin", "Kortin numero", "Pankin nimi", "Tyyppi", "Osoite"],
  placeholder2: [
    "Anna tili",
    "Anna puhelin",
    "Anna kortin numero",
    "Anna pankin nimi",
    "Valitse USDT-tyyppi",
    "Anna USDT-osoite"
  ],
},
wallet: {
  default: [
    "Omat varat",
    "Lataa saldoa",
    "Nosta varoja",
    "Lataushistoria",
    "Nostohistoria",
    "Ladattu määrä",
    "Myyty määrä",
    "Ansaittu",
    "Tilin saldo",
    "Saldo",
    "Latausmäärä (USDT)",
    "Myyty määrä (USDT)",
    "Kokonaisansio",
    "Päivitä kauppiaaksi",
    "Päivittääksesi kauppiaaksi, ota yhteyttä asiakaspalveluun",
    "Vahvista kauppiaan asettuminen",
    "Kauppiaan sertifiointi",
    "Sinun täytyy maksaa",
    "Maksutosite",
    "Kauppiaan talletus",
    "Todista nyt",
    "Tieto",
    "Sinun täytyy maksaa",
    "Onko saldo riittämätön lataamiseen"
  ],
  label: [
    "Nostotapa",
    "Nostettava määrä",
    "Varojen salasana",
    "Lähetä",
    "Puhelinnumero",
    "Sähköposti",
    "IFSC",
    "Varmista"
  ],
  placeholder: [
    "Valitse nostotapa",
    "Anna nostettava määrä",
    "Anna varojen salasana",
    "Valitse nostotapa",
    "Anna vastaanottajan puhelinnumero",
    "Anna vastaanottajan sähköposti",
    "Anna vastaanottajan IFSC"
  ],
},

msg: [
  "Et ole asettanut varojen salasanaa, aseta se ensin",
  "Et ole liittänyt PIX-tiliäsi, liitä se ensin",
  "Oletko varma, että haluat nostaa?"
],
recharge: {
  default: [
    "Lompakon lataus",
    "Lompakon ennakkolataus",
    "Lataustapa",
    "Lataa saldoa",
    "Vahvista"
  ],
  label: [
    "Kategoria",
    "Osoite",
    "Latausmäärä",
    "Syötä määrä",
    "Lataa tosite",
    "Kopiointi onnistui",
    "Ennakkolatausvaihto"
  ],
  placeholder: [
    "Tyypit",
    "Osoite"
  ],
  info: [
    "Latausmäärä",
    "Tilausnumero",
    "Hyötyjän pankki",
    "Vastaanottotili",
    "Saaja",
    "Kopioi"
  ]
},
task: {
  tabs: ["Kaikki", "Kesken", "Vanhentunut", "Valmistunut", "Odota maksua", "Odota vahvistusta"],
  default: ["Tilauslista"],
  msg: ["Lähetä tarkastettavaksi", "Lähetetty, odota tarkastusta", "Lähetys epäonnistui, lähetä uudelleen"]
},

userInfo: {
  default: [
    "Henkilötiedot",
    "Avatar",
    "Tili",
    "Sähköposti",
    "Luotto",
    "Yksityiskohdat",
    "Vaihda salasana",
    "Varojen salasana",
    "Klikkaa asetuksia",
    "Muokkaa avatar",
    "Muokkaa kirjautumissalasanaa",
    "Muokkaa varojen salasanaa",
    "Lähetä",
    "Tyhjennä välimuisti",
    "Näytä tiedot",
    "Lempinimi",
    "Suosittelukoodi"
  ],
  label: [
    "Alkuperäinen kirjautumissalasana",
    "Uusi kirjautumissalasana",
    "Vahvista salasana",
    "Alkuperäinen varojen salasana",
    "Uusi varojen salasana",
    "Vahvista salasana",
    "Tallenna"
  ],
  placeholder: [
    "Syötä alkuperäinen kirjautumissalasana",
    "Syötä uusi kirjautumissalasana",
    "Vahvista kirjautumissalasana",
    "Syötä alkuperäinen varojen salasana",
    "Syötä uusi varojen salasana",
    "Vahvista varojen salasana"
  ],
},
fundRecord: {
  default: ["Kulukirjaus", "Lataushistoria", "Varaloki", "Lataus", "Vastaanota", "Haara"],
  tabs: ["Tulot", "Menot", "Lataus"],
},
dialog: [
  "Vihje",
  "Vahvista",
  "Lähetetään...",
  "Kopiointi onnistui",
  "IOS-järjestelmäversiota ei tueta",
  "Rekisteröidään...",
  "Ladataan...",
  "Ladataan...",
],
serviceCenter: [
  "Asiakaspalvelu",
  "Hei, olen omistautunut asiakaspalvelija~",
  "Ilo palvella sinua",
  "Itsepalvelu",
  "Verkkopalvelu",
  "Latausasiakaspalvelu",
  "Riviasiakaspalvelu",
],
userTaskRecord: [
  "Oma tilaus",
  "Myyntitilaus",
  "Ostotilaus",
  "Nykyinen tila",
  "Hanki palkkio",
  "Valmistunut",
],
withdrawlist: [
  "Nostohistoria",
],
teamReport: {
  default: [
    "Joukkuelista",
  ],
},
common2: [
  "Aloittelijan opas",
  "Ota yhteyttä asiakaspalveluun",
  "Palveluehdot, katso tiedot",
  "Asiakaspalvelu 1",
  "Asiakaspalvelu 2"
],
common3: [
  "Onnistui",
  "Alustan esittely"
],
invite: [
  "Jaa kampanja",
  "Minun kutsukoodi",
  "Kopioi kutsukoodi kutsuaksesi lisää ystäviä mukaan",
  "Kopioi"
],
common4: [
  "Oikean nimen todentaminen",
  "Lähetetty onnistuneesti",
  "Huomioitavia asioita",
  "Asioiden sisältö",
  "Saadut palkkiot",
  "Veromäärä",
  "Rahoitustodistus",
  "Viimeistele kortin liittäminen",
  "Viimeistele henkilöllisyystodistus",
  "Viimeistele yrityksen todentaminen"
],
common5: [
  "Myyty",
  "Minuutit",
  "ennen",
  "Tallenna QR-koodi",
  "Avaa",
  "Tili on jäädytetty eikä sitä voi toistaiseksi käyttää kaupankäyntiin",
  "APP"
],
common6: [
  "Lataustilaus",
  "Tilausnumero",
  "Määrä",
  "Aika",
  "Tila"
],
hall2: ["min myyntimäärä on", "", "ylittää päivittäisen myyntimäärän"],
register2: ["Sähköposti", "Puhelin", "Syötä puhelinnumero"],
withdraw2: [
  "Nosto",
  "Myyjätili",
  "Saldo",
  "Tarvittava maksu",
  "Maksa",
  "On vielä tehtäviä, joita ei ole suoritettu. Käteisen nosto ei ole toistaiseksi sallittua",
  "Nostokertojen määrä ylittyi"
],
mytask2: [
  "Kauppiastili",
  "hinta",
  "määrä",
  "saldo",
  "maksun määrä",
  "tilauksen yksityiskohdat",
  "numero ei voi olla 0",
  "saldo ei riitä",
  "hinta ei voi olla 0",
  "väärä alue"
],
taskOrder2: [
  "Sinulla on vielä avoin tilaus, jota ei voi peruuttaa",
  "saldo ei riitä",
  "Vahvista",
  "epäonnistui",
  "Sinun täytyy suorittaa tilaus ennen kuin voit nostaa rahaa:"
],
busAuth2: [
  "Kauppiaan päivitys",
  "Hyväksyn",
  "Päivityksen vahvistus",
  "Päivitä kauppiaaksi",
  "Jos tarvitset muuttaa pankkikortin tietoja, ota yhteyttä asiakaspalveluun",
  "Nykyinen tili täytyy päivittää kauppiastiliksi"
],
recharge2: ["vaadittu määrä", "vaaditut kuvakaappaukset"],
buy3: [
  "laskuri",
  "tunti",
  "tyyppi",
  "Hyvinvointitilaus",
  "Ryhmätilaus",
  "määräraja",
  "määräraja",
  "määritä käyttäjätunnus",
  "syötä käyttäjätunnus"
],
hall3: [
  "päivä",
  "tunti",
  "minuutti",
  "sekunti",
  "Ryhmätilaus",
  "tili",
  "yliaika"
],
sell3: ["myyntihinta ei voi olla suurempi kuin paras hinta"],
busAuth3: ["Jäsen", "Kauppias", "Kruunukauppias"],
recharge3: ["Laskuri"],
home3: ["Kaivostyöläinen", "Arvaa", "Yllätyslaatikko", "Rahoitus", "Tulossa pian"],
home4: [
  "Nopea kauppa",
  "Osta USDT nopeasti",
  "C2C-kauppa",
  "Siirrä USDT",
  "Verkossa",
  "24H Määrä (USDT)"
],
common7: [
  "Ota yhteyttä asiakaspalveluun, jos sinulla on kysyttävää",
  "Ota yhteyttä palveluun",
  "Pyydä apua",
  "Asetukset",
  "Kaikki",
  "Kutsu",
  "Jäsenmäärä",
  "Kuukauden uusi",
  "Rekisteröintiaika",
  "Alatason henkilöstömäärä"
],
hall4: [
  "Tyyppi",
  "Jäsen",
  "Tilisi",
  "kauppa onnistui",
  "onnistui täydellisesti",
  "Kokonaismäärä",
  "Osta",
  "Myy"
],
task3: ["Kaikki", "Ei maksettu", "Maksettu"],
my: ["Oletko varma, että haluat kirjautua ulos?"],
bindAccount2: [
  "Varmista, että tiedot on täytetty oikein, muuten normaali kauppa saattaa häiriintyä",
  "Liitä nyt",
  "Asiakaspalvelu"
],
recharge4: [
  "Rahojesi turvallisuuden vuoksi, siirron onnistumisen jälkeen, lähetä kuvakaappaus onnistuneesta siirrosta tarkistettavaksi!"
],
resetpwd2: [
  "Sähköposti",
  "Puhelin",
  "Huomautus: salasana koostuu 6–16 merkistä eikä ole kirjainkoon tarkka"
],
home2: ["Erityishuomio", "Rahamarkkinat"],
login1: [
  "Seuraava",
  "Kirjaudu sisään nyt",
  "Näyttää siltä, että sinulla ei ole tiliä. Luodaan uusi tili:",
  "Hyväksy ja jatka"
],
login2: [
  "Lisää kirjautumistapoja",
  "Jatka ",
  "Facebook",
  "Google",
  "Apple"
],
service2: ["Kaupankäynti", "Muu"],
buy2: ["Tilauksen tyyppi", "Osto onnistui", "USDT ostettiin onnistuneesti"],
sell4: [
  "Vahvistatko tämän myynnin",
  "Vahvista",
  "Myynti onnistui",
  "Myit onnistuneesti",
  "Odota ostajan maksua",
  "Valmis"
],
hall5: ["Jäsen"],

busauth: [
  "Tunnista tiedot",
  "Anna oikea nimesi",
  "Anna henkilötunnuksesi",
  "Lataa valokuva",
  "Lataa henkilöllisyystodistuksen kuva",
  "Lataa pankkitiedot"
],
recharge5: [
  "Lataa valokuva",
  "Lataus onnistui",
  "USDT ladattiin onnistuneesti tilille",
  "Vahvista"
],
note: [
  "Huomautus",
  "vihjeitä",
  "Puhelinnumero",
  "Syötä puhelinnumerosi",
  "Jätä viesti",
  "Jätä viestisi tähän",
  "Lataa kuvia",
  "Lataa kuva",
  "Lähetetty onnistuneesti",
  "Olemme sinuun yhteydessä mahdollisimman pian",
  "Sulje"
],
notelist: [
  "Huomautuslista"
],
search: ["Haku", "ei löytynyt tietoja", "Syötä avainsana löytääksesi"],
searchs: {
  orders: "Tilaus",
  recharge: "Lataus",
  withdraw: "Nosto"
},
team2: ["Kutsutiedot", "Kutsu ystäviä"],
task4: ["Tehtäväni"],
lang3: ["Englanti", "Korea", "Taiwanilainen"],
login3: ["Tili- tai salasanavirhe, syötä uudelleen"],
shiming: ["Verotarkastus"],
};
