<template>
  <div class="commissionTasks">
    <!-- 文字部分 -->
    <div class="text">
      <div class="title">
        <p>Yüksek komi-</p>
        <p>syon gorevleri</p>
      </div>
      <div class="title2">Görev tanımı</div>
      <div class="desc">
        <p>{{ $t("TaskPopup[49]") }}</p>
        <p>{{ $t("TaskPopup[50]") }}</p>
      </div>
    </div>
    <!-- 按钮 -->
    <div class="btn">
      <div @click="accept">{{ $t("TaskPopup[54]") }}</div>
    </div>
    <!-- 底部关闭图案 -->
    <div class="close">
      <img
        @click="close"
        src="../../assets/img/close.png"
        alt=""
        width="36"
        height="36"
      />
    </div>
  </div>
</template>

<script>
/* 高额佣金的弹窗 */
export default {
  name: "commissionTasks",
  data() {
    return {};
  },
  components: {},
  methods: {
    accept() {
      this.$emit("accept");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.commissionTasks {
  position: relative;
  width: 100%;
  height: 510px;
  background: url("../../assets/img/gaoeyongjin.png") no-repeat center/cover;
}
.commissionTasks > .text {
  margin-top: 40%;
  text-align: center;
}
.commissionTasks > .text > .title {
  font-size: 20px;
  color: rgb(231, 191, 88);
}
.commissionTasks > .text > .title2 {
  margin-top: 20px;
  font-size: 18px;
  color: #fff;
}
.commissionTasks > .text > .desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.commissionTasks > .text > .desc > p {
  text-align: left;
  color: rgb(253, 248, 248);
  width: 200px;
  font-size: 12px;
}
.commissionTasks > .text > .desc > p:nth-child(2) {
  margin-top: 15px;
}
.commissionTasks > .btn {
  display: flex;
  justify-content: center;
}
.commissionTasks > .btn > div {
  margin-top: 50px;
  font-size: 20px;
  color: rgb(210, 54, 84);
  text-align: center;
  width: 220px;
  height: 50px;
  line-height: 50px;
  background: url("../../assets/img/btn_yellow.png") no-repeat center/cover;
}
.commissionTasks > .close {
  text-align: center;
}
.commissionTasks > .close > img {
  margin-top: 45px;
}
</style>
