<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('common2[4]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
<!--    <iframe :src="service_url2"-->
<!--            width="1200" height="800" frameborder="0" scrolling="auto"style="position:absolute;top: 40px;left: 0px;"></iframe>-->
  </div>
</template>
<script type='text/javascript'>
    (function(a, b, c, d, e, j, s) {
        a[d] = a[d] || function() {
            (a[d].a = a[d].a || []).push(arguments)
        };
        j = b.createElement(c),
            s = b.getElementsByTagName(c)[0];
        j.async = true;
        j.charset = 'UTF-8';
        j.src = 'https://static.meiqia.com/widget/loader.js';
        s.parentNode.insertBefore(j, s);
    })(window, document, 'script', '_MEIQIA');
    _MEIQIA('entId', 'd9b85f92369ddfd0e081d79dab3fb2de');
    _MEIQIA('manualInit');
    _MEIQIA('language','en');
    _MEIQIA('withoutBtn');
    _MEIQIA('init');
    console.log("ddd");

//设置默认接口
import config from '@/config'

const ApiUrl = config.ApiUrl

import Ping from 'web-pingjs'
export default {
  name: 'Lines',
  components: {

  },
  props: {},
  data() {
    return {
      listData: '',
      timeList: [],
      service_url2:'',
      currLine: '',
      show: false,
    }
  },
  computed: {

  },
  filters: {

  },
  watch: {

  },
  created() {

  },
  mounted() {
      this.service_url2 = this.InitData.setting.service_url2;
      // this.service_url2 = "https://chatlink-new.meiqia.cn/widget/standalone.html?eid=d9b85f92369ddfd0e081d79dab3fb2de";
  },
  activated() {

  },
  destroyed() {
    
  },
  methods: {
    showPopup() {
      this.show = true
    },

  }

}
</script>
<style scoped>
  .PageBox>>>.van-nav-bar{
    background: #252D30;
  }
  .van-nav-bar>>>.van-nav-bar__title{
    color:#fff;
  }
.van-cell__value{
  flex: none;
}
.van-cell__title i{
  color: #4e51bf;
  margin-left: 15px;
}
.van-radio{
  justify-content: flex-end;
}
</style>