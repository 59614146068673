<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('userInfo.default[0]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
    <div class="ScrollBox">
      <van-form>
        <div class="infoPanel">
          <van-cell
            size="large" is-link
            :title="$t('userInfo.default[1]')"
            center
            @click="showHeader = true"
          >
            <div class="userFace">
              <img :src="`./static/head/${UserInfo.header}`" />
            </div>
          </van-cell>
        </div>
        <div class="infoPanel" style="margin-top: 20px">
        <van-cell
                size="large"
                :title="$t('userInfo.default[15]')"
                center
                :is-link="UserInfo.nickname ? false : true"
        >
          <template #right-icon>
            <van-field input-align="right" style="width:180px;" v-model="UserInfo.nickname" :placeholder="$t('userInfo.default[8]')"/>
          </template>
        </van-cell>
      <van-cell
              size="large"
              :title="$t('userInfo.default[2]')"
              center
              :value="UserInfo.username ? UserInfo.username : $t('userInfo.default[8]')"
              :is-link="UserInfo.username ? false : true"
      />
          <van-cell
                size="large"
                :title="$t('userInfo.default[3]')"
                center
                :value="UserInfo.mail"
                :value-class="['']"
        />
<!--      <van-cell-->
<!--              size="large"-->
<!--              :title="$t('userInfo.default[3]')"-->
<!--              center-->
<!--              :value="UserInfo.username ? UserInfo.username : $t('userInfo.default[8]')"-->
<!--              :is-link="UserInfo.username ? false : true"-->
<!--      />-->
      <van-cell
              size="large"
              :title="$t('userInfo.default[16]')"
              center
              :value-class="['']"
      >
        <template>{{UserInfo.idcode}}
          <img :src="`./skinlscc/copy.png`" class="copy-qb" @click="copyCode()" width="16"></img>
        </template>
      </van-cell>
          <van-cell
              size="large"
              :title="$t('userInfo.default[6]')"
              center
              :value="$t('userInfo.default[8]')"
              is-link
              @click="showPassWord = true"
      />
          <van-cell
              size="large"
              :title="$t('userInfo.default[4]')"
              center
              :value="UserInfo.credit"
              :value-class="['']"
          />
        </div>
      </van-form>
      <div class="centerPanel" style="margin-top: 20px;padding: 10px 0px">
        <van-button @click="setUserInfo">{{ $t('userInfo.label[6]') }}</van-button>
      </div>
    </div>
    <van-action-sheet
      v-model="showHeader"
      :title="$t('userInfo.default[9]')"
      close-on-popstate
    >
      <van-radio-group v-model="radioHeader">
        <van-grid clickable icon-size="45" column-num="5">
          <van-grid-item
            :icon="`./static/head/head_${item}.png`"
            v-for="item in 40"
            :key="item"
            @click="selectHeader(item)"
          />
        </van-grid>
      </van-radio-group>
    </van-action-sheet>
    <van-action-sheet
      v-model="showPassWord"
      :title="$t('userInfo.default[10]')"
      close-on-popstate
    >
      <van-field
        v-model.trim="postData.o_password"
        type="password"
        :label="$t('userInfo.label[0]')"
        :placeholder="$t('userInfo.placeholder[0]')"
        clearable
        size="large"
      />
      <van-field
        v-model.trim="postData.n_password"
        type="password"
        :label="$t('userInfo.label[1]')"
        :placeholder="$t('userInfo.placeholder[1]')"
        clearable
        size="large"
      />
      <van-field
        v-model.trim="postData.r_password"
        type="password"
        :label="$t('userInfo.label[2]')"
        :placeholder="$t('userInfo.placeholder[2]')"
        clearable
        size="large"
      />
      <div style="text-align: left;padding: 5px">
        <div style="margin-left: 20px;color: var(--button_bg);font-size: 16px">{{$t('resetpwd2[2]')}}</div>
      </div>
      <textarea id="inputBox" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"></textarea>
      <div style="padding: 6px;padding-bottom: 5px">
        <van-button @click="setpwd">{{ $t("userInfo.default[12]") }}</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>

export default {
  name: "Info",
  components: {},
  props: [],
  data() {
    return {
      showHeader: false,
      showPassWord: false,
      showPayWord: false,
      radioHeader: "",
      postData: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.postData.header = this.UserInfo.header;
    this.$Model.GetUserInfo();
    console.log(this.UserInfo);
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    copyCode() {
      var that = this;
      var copyBox = document.getElementById("inputBox");
      copyBox.value = this.bankInfo.idcode;
      copyBox.select();
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$Dialog.Toast(this.$t('recharge.label[5]'));
    },
    selectHeader(index) {
      this.postData.header = `head_${index}.png`;
      this.UserInfo.header  = `head_${index}.png`;
      if (this.showHeader) {
        this.showHeader = false;
      }
    },
    setpwd(){
      if (this.showPassWord) {
        this.showPassWord = false;
      }
    },
    setUserInfo() {
      this.postData['nickname'] = this.UserInfo.nickname
      debugger
      this.$Model.SetUserInfo(this.postData, (data) => {
        if (data.code == 1) {
          if (this.showHeader) {
            this.showHeader = false;
          }
          if (this.showPassWord) {
            this.showPassWord = false;
          }
          if (this.showPayWord) {
            this.showPayWord = false;
          }
          this.postData = {};
        }
      });
    },
    clearCache() {
      // localStorage.clear();
      // this.$Model.GetBackData();
      // this.$router.push("/login");
      this.$Model.Logout()
    },
  },
};
</script>
<style scoped>
  .PageBox {

  }

.van-cell__left-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.van-icon__image {
  width: 100%;
  height: 100%;
}
.van-cell__title {
  font-size: 14px;
}

.infoPanel {
  margin-top: 10px;
  border-radius: 10px;
  background-color: var(--panel_color);
  padding-left: 10px;
}

.van-cell {
  background-color: transparent;
}
.ScrollBox {
  padding: 10px;
}

  .van-cell>>>.van-icon{
    font-size: 19px;
  }

.ScrollBox >>> .van-cell {
  order-radius: 10px;
  border-radius: 5px;

}
.ScrollBox >>> .van-cell::after {
  display: none;
}
.ScrollBox >>> .van-cell__title {
  font-size: 14px;
  font-family: Alibaba;
  font-weight: normal;
}

.ScrollBox >>> .van-cell__value {
  display: flex;
  color:var(--cell_placeholder);
  justify-content: flex-end;
  padding-right: 8px;
}
.ScrollBox >>> .userFace {
  width: 65px;
  height: 65px;
  background: #eff0f2;
  border-radius: 50%;
  padding: 0px;
  overflow: hidden;
}

  .van-cell >>> .van-field__control {
    color: var(--cell_title) !important;
    padding-left: 7px;
    height: 100%;
  }

  .van-cell >>> .van-field__control::-webkit-input-placeholder {
    color: var(--cell_placeholder) !important;
    font-family: Alibaba;
  }

  .van-cell >>> .van-field__control::-moz-placeholder {
    color: var(--cell_placeholder) !important;
    font-family: Alibaba;
  }



.ScrollBox >>> .userFace img {
  width: 100%;
}
.ScrollBox >>> .isTrue {
  color: #333;
  padding-right: 20px;
}
.van-cell:nth-child(4) >>> .van-cell__left-icon img {
  transform: scale(1.1);
}
.ScrollBox >>> .van-cell__value {
  font-size: 15px;
}

.btn {
  padding: 10px 10px;
  border-radius: 5px;
  background-color: var(--button_bg);
  color: #FFFEFF;
  font-size: 18px;
  text-align: center;
  margin: 15px 10px 10px;
}
</style>
