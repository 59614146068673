<template>
	<div class="finCenterBox">
		<div class="WalletBg">
			<div class="title">{{$t('fin[0]')}}</div>
		</div>
		<van-cell class="info" :border="false">
			<template #title>
				<div class="userName">{{$t('fin[24]')}}</div>
				<div class="userName2">{{Number(summData.totalfin).toFixed(2)}}</div>
			</template>
			<template #label>
			</template>
			<template #right-icon>
				<van-button class="MyWallet" :icon="`./static/icon2/icon_wallet01.png`" type="info" to="/user/finlist">
						<span>
							{{$t('fin[1]')}}
						</span>
				</van-button>
			</template>
		</van-cell>
		<van-row style="text-align: center;margin: 5px">
			<van-col span="12">
				<div  class="divCCC2">
					{{$t('fin[2]')}}
					<div>{{Number(summData.ydayearnings).toFixed(2)}}</div>
				</div>
			</van-col>
			<van-col span="12">
				<div  class="divCCC2">
					{{$t('fin[3]')}}
					<div>{{Number(summData.totalearnings).toFixed(2)}}</div>
				</div>
			</van-col>
		</van-row>
		<div class="tool">
			<van-row gutter="5px "style="text-align: center;margin: 10px">
				<van-col span="11" offset="5px">
					<van-button :class="[form.importChannel==1?'':'button3']" round :plain="form.importChannel==1" hairline type="info" @click="chooseChannel(0)">
						{{$t('fin[4]')}}
					</van-button>
				</van-col>
				<van-col span="2" offset="5px">
				</van-col>
				<van-col span="11" offset="5px">
					<van-button :class="[form.importChannel==0?'':'button3']" round :plain="form.importChannel==0" hairline type="info" @click="chooseChannel(1)">
						{{$t('fin[5]')}}
					</van-button>
				</van-col>
			</van-row>
			<van-row >
				<van-col span="24">
					<van-field class="text33_2" v-model="form.amount" :placeholder="Number(summData.balance).toFixed(2)"
							   @input="changeAmount"
					/>
				</van-col>
			</van-row>
		</div>

		<div class="tool">
			<van-row style="margin: 10px;padding-left: 20px">
				<van-col span="24">
					<div class="title0">{{$t('fin[6]')}}</div>
				</van-col>
			</van-row>
			<van-row style="text-align: center;margin: 10px">
				<van-col span="12">
					<van-button :class="[form.fin_type==1?'':'button3']" round :plain="form.fin_type==1" hairline type="info" style="width: 110px;height:35px" @click="chooseFinType(2)">
						{{$t('fin[11]')}}
					</van-button>
				</van-col>
				<van-col span="12">
					<van-button :class="[form.fin_type==2?'':'button3']" round :plain="form.fin_type==2" hairline type="info" style="width: 110px;height:35px" @click="chooseFinType(1)">
						{{$t('fin[10]')}}
					</van-button>
				</van-col>
			</van-row>
			<van-grid clickable :border="false" :column-num="2" v-if="form.fin_type==2">
				<van-grid-item icon="home-o" text="路由跳转"  :key="index" v-for="(item,index) in dingqiConfigs">
					<van-button  :class="[item.id==form.configId?'button4':'button5']" :plain="item.id!=form.configId" type="info" @click="chooseFinConfig(item)">
						<div class="title1"> {{item.name}}</div>
						<div>{{$t('fin[23]')}}: {{Number(item.rate).toFixed(2)}}%</div>
					</van-button>
				</van-grid-item>
			</van-grid>
			<van-grid :border="false" :column-num="1" v-if="form.fin_type==1">
				<van-grid-item icon="home-o" text="路由跳转" >
					<div class="text33" style="width: 90%">
						<div class="title0">{{huoqiConfig.name}}</div>
						<div>{{$t('fin[13]')}}</div>
						<div>{{$t('fin[12]')}}: {{Number(huoqiConfig.rate).toFixed(2)}}%</div>
					</div>
				</van-grid-item>
			</van-grid>
		</div>

		<div style="align-items: center;padding-left: 20px">
		</div>
		<div class="tool2">
			<van-row>
				<van-col span="24">
					<div class="title0">{{$t('fin[7]')}}</div>
					<div style="color: pink;font-weight: bold">{{form.preEarning}}</div>
				</van-col>
			</van-row>
			<van-row style="text-align: center;margin: 10px;margin-bottom: 20px">
				<van-col span="12">
					<van-button class='button3_2' @click="outFin" round plain hairline type="info">
						{{$t('fin[8]')}}
					</van-button>
				</van-col>
				<van-col span="12">
					<van-button class='button3_3' @click="addFin" round plain hairline type="info">
						{{$t('fin[9]')}}
					</van-button>
				</van-col>
			</van-row>
		</div>
	</div>
</template>

<script>
	import config from '@/config'

	const AppDown = config.AppDown
	export default {
		name: 'fin',
		components: {},
		props: [],
		data() {
			return {
				statisticalData: {},
				token:'',
				uid:'',//用户ID
				preEarning:0,
				summData:{
					balance:0,
					totalfin:0,
					ydayearnings:0,
					totalearnings:0
				},
				form:{
					id:0,
					amount:0,
					fin_type:2,
					importChannel:0,
					preEarning:0,
					configId:0,
				},
				chooseConfig:{},
				huoqiConfig:{},
				dingqiConfigs:[],
			}
		},
		computed: {

		},
		created() {
			this.token = localStorage.getItem('Token');
			this.loadFinData()
		},
		methods: {
			changeAmount(){
				// debugger
				// if (this.form.amount>=this.summData.balance){
				//   this.form.amount = this.summData.balance
				// }else{
				this.chooseFinConfig(this.chooseConfig)
				// }
			},
			chooseFinType(index) {
				this.form.fin_type = index
				if (index==1){
					this.chooseFinConfig(this.huoqiConfig)
				}else{
					this.chooseFinConfig(this.dingqiConfigs[0])
				}
			},
			chooseChannel(index) {
				this.form.importChannel = index
			},
			chooseFinConfig(item){
				this.form.configId = 0
				if (!item.id){
					return
				}
				this.form.configId = item.id
				//计算预计收益:
				let earnings = this.form.amount*item.rate/100
				this.chooseConfig = item
				this.form.preEarning = Number(earnings).toFixed(2)
			},
			loadFinData() {
				this.$Model.GetFinSumm({token:this.token},data => {
					this.summData = data.data;
					this.form.amount = Number(this.summData.balance).toFixed(2)

					this.$Model.GetFinConfigData({token:this.token,status:1},data => {
						for (var i=0;i<data.data.length;i++){
							let item = data.data[i]
							if (item.fin_type==1){
								this.huoqiConfig = item
							}else{
								this.dingqiConfigs.push(item)
							}
						}
						if (this.dingqiConfigs.length>0)
							this.chooseFinConfig(this.dingqiConfigs[0])
					})
				})

			},
			addFin(){
				console.log('addFinaddFinaddFin')
				if (this.form.configId==0){
					this.$Dialog.Toast('no product chosed','center')
					return
				}
				let amount = Number(this.form.amount)
				if (this.form.importChannel==0){
					if (amount>this.summData.balance){
						this.$Dialog.Toast(this.$t('fin[22]'),'center')
						return
					}
				}
				let minAmount = Number(this.chooseConfig.minAmount)
				if (amount<minAmount){
					this.$Dialog.Toast(this.$t('fin[22]'),'center')
					return
				}
				let maxAmount = Number(this.chooseConfig.maxAmount)
				if (amount>maxAmount){
					this.$Dialog.Toast(this.$t('fin[22]'),'center')
					return
				}
				//充值转入
				if (this.form.importChannel==1){
					let info = this.form.configId+"-"+this.form.importChannel
					this.$router.push(`/user/mixRecharge?target=2&amount=${Number(amount)}&targetInfo=${info}`)
				}else{
					this.form['token'] = this.token;
					this.form['amount'] = amount
					this.$Model.addFin(this.form,data=>{
						if(data.code==1){
							this.$Dialog.Toast(this.$t('shareface[8]'),'center')
						}
					})
				}
			},
			outFin(){
				console.log('outFinoutFinoutFin')
				this.$router.push("/user/finlist");
			}
		}
	}
</script>

<style scoped>
	.finCenterBox {
		overflow: scroll;
		height: 100vh;
		/*background-color: #fff;*/
		font-family: Alibaba;
		font-weight: normal;
	}

	.WalletBg {
		height: 160px;
		background-color: #4e51bf;
		border-bottom-left-radius: 50px;
		border-bottom-right-radius: 50px;
	}

	.button3{
		background-color: #7c7cd8;
		color: #ffffff;
	}

	.button3_2{
		background-color: #7c7cd8;
		color: #ffffff;
		width: 130px;
	}

	.button3_3{
		width: 130px;
	}

	.button4{
		background-color: #d9d8fb;
		color: #000000;
		border-radius: 12px;
		line-height: 22px;
		height: 110px;
		width: 150px;
	}

	.button4_2{
		background-color: #d9d8fb;
		color: #000000;
		border-radius: 12px;
		height: 30px;
		width: 120px;
	}

	.button5{
		border-radius: 12px;
		line-height: 22px;
		height: 110px;
		width: 150px;
	}


	.finCenterBox .info {
		margin-top: -105px;
		z-index: 10;
		/*background-color: #151d31;*/
		/*margin-top: 10px;*/
		color: #fff;
		position: relative;
		z-index: 3;
		background: transparent none repeat scroll 0% 0%;
		position: relative;
		padding: 10px 16px 6px 20px;
	}

	.title0{
		font-size: 18px;
		font-weight: bold;
	}

	.title1{
		font-size: 16px;
		font-weight: bold;
	}

	.text33{
		text-align: center;
		margin: 10px;
		background-color: #d9d8fb;
		border-radius: 12px;
		padding-top: 10px;
		height: 120px;
		line-height: 25px;
	}

	.text33_2{
		text-align: center;
		margin: 10px;
		width: 95%;
		background-color: #d9d8fb;
		-webkit-text-fill-color:#000000;
		border-radius: 12px;
		line-height: 17px;
		align-items: center;
	}

	.userName {
		width: 220px;
		margin: 10px auto;
		font-size: 19px;
		font-family: emoji;
		text-align: center;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.userName2 {
		width: 220px;
		margin: 0px auto;
		font-size: 23px;
		font-family: emoji;
		text-align: center;
		color: orange;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		margin-bottom: 30px;
	}

	.MyWallet {
		/*margin-right: -30px;*/
		background-color: #fff;
		color: #000;
		border-radius: 20px;
		height: 30px;
		position: absolute;
		top: 20px;
		right: -20px;
		padding: 0 33px 0 15px;
		box-shadow: 0px 4px 7px 0px rgb(0 19 218 / 29%);
	}

	.divCCC {
		border-radius: 12px;
		box-shadow: 0 2px 10px #dbdbdbdb;
		line-height: 25px;
	}

	.divCCC2 {
		margin: 5px;
		border-radius: 12px;
		background-color: #b3b4f8;
		line-height: 25px;
	}

	.finCenterBox .tool {
		width: 95%;
		border-radius: 12px;
		background-color: #fff;
		box-shadow: 0 2px 10px #dbdbdbdb;
		overflow: hidden;
		margin: 0px auto;
		margin-top: 10px;
		z-index: 99;
		padding: 0 5px;
		text-align: center;
		align-items: center;
	}
	.finCenterBox .tool2 {
		width: 90%;
		margin: 0px auto;
		margin-top: 30px;
		z-index: 99;
		padding: 0 5px;
		text-align: center;
		line-height: 25px;
		align-items: center;
	}

	.title {
		color: #fff;
		background-color: #4e51bf;
		width: 100%;
		height: 45px;
		font-size: 18px;
		padding-top: 10px;
		text-align: center;
		position: fixed;
		z-index: 10;
	}

	.finCenterBox .bgc {
		width: 100%;
		background-color: #4e51bf;
		height: 46px;
		position: fixed;
		top: 0;
		z-index: 200;
	}

	.topTitle {
		position: absolute;
		max-width: 60%;
		white-space: nowrap;
		font-size: 16px;
		color: #fff;
		background-color: transparent;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
	}

</style>
