<template>
	<div class="shareface">
		<div class="title">{{ $t("shareface[0]") }}</div>
		<!-- 提现分享 -->
		<div class="one_box" v-if="$route.query.urlCode==1">
			<div class="head">
				<div class="head_son">
					<p>
						{{ $t("shareface[1]") }} <span>STAR MAKING</span>
						{{ $t("shareface[2]") }}
					</p>
					<p>{{ $t("shareface[3]") }}</p>
					<!-- <p>{{ $t("shareface[4]") }}{{ $t("shareface[5]") }} ！</p> -->
				</div>
			</div>
			<div class="end">
				<div class="body">
					<div class="body_box">
						<p>{{ $t("shareface[6]") }}<span>{{withdrawalInfo.money}} R$</span></p>
						<p>
							{{ $t("shareface[7]") }}<span>{{ $t("shareface[8]") }}</span>
						</p>
						<p>{{ $t("shareface[9]") }}{{getNowFormatDate2()}}</p>
					</div>
				</div>
			</div>
		</div>
		<!-- 签到分享 -->
		<div class="one_box" v-else>
			<div class="head head1">
				<div class="head_son">
					<p style="font-size: 16px;">
						{{ $t("shareface[1]") }} <span style="font-size: 16px;">STAR MAKING</span>
					</p>
					<p v-html="$t('shareface[12]',{money:$route.query.money})"></p>
					<!-- <p>{{ $t("shareface[4]") }}{{ $t("shareface[5]") }} ！</p> -->
				</div>
			</div>
		</div>
		<div class="two_box">
			<div class="one">
				<!-- <div class="ytb" @click="ytb()"></div> -->
				<!-- <div class="wa" @click="wa()"></div> -->
				<div class="fb" @click="share('facebook')"></div>
				<div class="tl" @click="share('twitter')"></div>
				<!-- <div class="wx" @click="wx()"></div> -->
			</div>
			<div class="two">
				<!-- <div class="qq" @click="qq()"></div> -->
				<!-- <div class="null"></div> -->
				<!-- <div class="more" @click="more()">{{ $t("shareface[10]") }}</div> -->
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				withdrawalInfo: {}, //分享信息
				idcode: '', //邀请码

			};
		},
		created() {
			// 保存传过来的提现信息
			this.withdrawalInfo = this.$route.query
			// 邀请码
			this.idcode = this.UserInfo ? this.UserInfo.idcode : ''
			console.log(this.idcode)
			// this.share()
		},
		mounted() {},
		methods: {
			// 分享到。。。
			share(type) {
				let url_myPage = localStorage['CurrLine'] +`/#/user/promotekid/${this.idcode}?idcode=${this.idcode}&urlCode=${this.withdrawalInfo.urlCode}&money=${this.withdrawalInfo.money}`
				console.log(url_myPage)
				let url=''
				switch (type) {
					case "facebook":
						url =
							`https://www.facebook.com/sharer.php?u=${encodeURIComponent(url_myPage)}`;
						break
					case "twitter":
						url = `http://twitter.com/share?url=${encodeURIComponent(url_myPage)}`;
						break
					case "linkin":
						url =
							`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=Hello%20World&summary=12312`;
						break
						// case "instagram":
						//     url = `https://www.instagram.com/aiaindonesia/?hl=en`;
						//     break
					case "whatsapp":
						url = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}&url=${encodeURIComponent(shareUrl)}`
						break
					case "email":
						url = `mailto:?subject=Life%20Insurance%20Advisor%20-%20AIA%20Indonesia&body=${encodeURIComponent(shareUrl)}`
						break
					case "messenger":
						url = `fb-messenger://share/?link=${encodeURIComponent(shareUrl)}`
						break
					case "viber":
						url = `viber://forward?text=${encodeURIComponent(shareUrl)}`
						break
					case "message":
						var u = navigator.userAgent;
						//判断是否为android终端
						var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
						//判断是否为ios终端
						var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
						//获取要分享的端口号
						var tel = ''
						//这里填写要分享的内容
						var des = encodeURIComponent(shareUrl);
						//ios终端
						if (isiOS) {
							url = "sms:" + tel + "&body=" + des;
							//安卓终端
						} else {
							url = "sms:" + tel + "?body=" + des;
						}
						break
				}
				console.log(url)
				window.open(url,'_blank')
				this.more()
			},
			//获取当前时间 yyyy-MM-dd
			getNowFormatDate2() {
				var date = new Date();
				var seperator1 = "-";
				//外国的月份都是从0开始的，所以+1
				var month = date.getMonth() + 1;
				var strDate = date.getDate();
				//1-9月用0补位
				if (month >= 1 && month <= 9) {
					month = "0" + month;
				}
				//1-9日用0补位
				if (strDate >= 0 && strDate <= 9) {
					strDate = "0" + strDate;
				}
				//获取当前时间 yyyy-MM-dd HH:mm:ss
				var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate
				// 切割变数字 
				return currentdate;
			},
			more() {
				this.insertUserWriteShareLog()
				// this.$router.push("/user/shareface2");
			},
			//写入分享日志
			insertUserWriteShareLog() {
				console.log(11)
				let {
					category
				} = this.ActivityGetData.item
				let json={}
				// 判断是签到还是提现
				if(this.withdrawalInfo.urlCode==0){
					json = {
						category,
						sgin_id: this.$route.query.id
					}
				}else{
					json = {
						category:"cash"
					}
				}
				this.$Model.UserWriteShareLog(json, (data) => {
					console.log(data)
				})
			},
		},
	};
</script>
<style scoped>
	.shareface {
		background: url("../../../public/static/images/promote_bg.png") no-repeat;
		width: 100%;
		height: 100%;
		background-size: cover;
	}

	.title {
		height: 50px;
		font-size: 16px;
		color: #fff;
		width: 100%;
		text-align: center;
		line-height: 50px;
	}

	span {
		color: #fe889d;
		font-size: 22px;
		font-weight: 600;
	}

	.head {
		text-align: center;
		width: 330px;
		height: 180px;
		margin: auto;
		position: relative;
		/* top: 40px; */
		z-index: 9;
		background: url("../../../public/static/images/title.png") no-repeat;
		background-size: 100% 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.head1 {
		position: relative;
		top: 120px;
		background: url("../../../public/static/images/title1.png") no-repeat;
		background-size: 100% 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.head_son {padding: 0 30px;
		letter-spacing: 1px;
		color: #4e51bf;
		line-height: 25px;
		font-size: 16px;
		font-weight: 600;
	}

	.head_son p:nth-of-type(2) {
		margin-top: 5px;
	}

	.head_son p:nth-of-type(1) {
		letter-spacing: 1px;
		color: #4e51bf;
		line-height: 20px;
		font-size: 20px;
		font-weight: 600;
	}

	.end {
		position: fixed;
		top: 200px;
		width: 100%;
	}

	.body {
		background-image: linear-gradient(to bottom, #6b5cbb, #f890a5);
		width: 265px;
		height: 250px;
		padding: 3px;
		margin: auto;
		border-radius: 5px;
	}

	.body_box {
		background: #fff;
		height: 100%;
		line-height: 40px;
		padding: 45px 40px;
		border-radius: 3px;
		font-size: 16px;
		font-family: SimHei;
	}

	.body_box p:nth-of-type(2) span {
		font-size: 14px !important;
	}

	.two_box {
		margin: 0 20px 0 20px;
		margin-top: 290px;
	}

	.one {
		display: flex;
		justify-content: center;
	}

	.two {
		display: flex;
		justify-content: center;
	}

	.one div {
		width: 48px;
		height: 48px;
		border-radius: 50px;
		background-color: #fff;
		margin: 2%;
	}

	.two div {
		width: 48px;
		height: 48px;
		border-radius: 50px;
		text-align: center;
		line-height: 50px;
		background-color: #fff;
		margin: 2%;
	}

	.ytb {
		background: url("../../../public/static/icon2/youtube.png") no-repeat;
		background-size: 30px;
		background-position: center;
	}

	.wa {
		background: url("../../../public/static/icon2/wa.png") no-repeat;
		background-size: 30px;
		background-position: center;
	}

	.fb {
		background: url("../../../public/static/icon2/facebook.png") no-repeat;
		background-size: 30px;
		background-position: center;
	}

	.wx {
		background: url("../../../public/static/icon2/wx.png") no-repeat;
		background-size: 30px;
		background-position: center;
	}

	.qq {
		background: url("../../../public/static/icon2/qq.png") no-repeat;
		background-size: 30px;
		background-position: center;
	}

	.tl {
		background: url("../../../public/static/icon2/telegram.png") no-repeat;
		background-size: 30px;
		background-position: center;
	}

	.null {}

	.more {
		color: #4e51bf;
		font-family: SimHei !important;
		font-size: 14px !important;
	}
</style>
