<template>
  <div class="PageBox" style="margin-top: 20px">
    <div class="bgc">
      <div class="topTitle">{{ $t("topNav[0]") }}</div>
      <div class="topTitle">{{ $t("card[0]") }}</div>
    </div>
    <div>
      <!-- <div style="overflow: hidden; margin-top: 15px" v-if="userInfo.is_realname != 1">
				<van-divider :hairline="false">{{ $t('bankCard.tips[0]') }}</van-divider>
				<div style="padding: 15px">
					<van-button block type="danger" style="font-size: 16px" @click="$router.push('/user/set/realname')">{{ $t('bankCard.default[1]') }}</van-button>
				</div>
			</div> -->
      <div v-if="showAdd == false">
        <van-cell
          class="list"
          :border="false"
          :title="`${item.bank_name} ${item.bank_code}`"
          :label="item.card_no.replace(/^(.{4}).*(.{4})$/, '$1 **** **** $2')"
          icon="card"
          v-for="item in cardList"
          :key="item.id"
        />
        <div style="padding: 10px 10px 0">
          <van-button
            block
            type="info"
            style="font-size: 16px"
            @click="showAdd = true"
            >{{ $t("bankCard.default[4]") }}</van-button
          >
        </div>
      </div>
      <div v-if="showAdd">
        <div style="text-align: center; margin: 0 20px; display: flex">
          <van-button
            :plain="isCoinCard"
            type="info"
            style="margin-right: 5px; flex: 1"
            @click="changeCardType(false)"
            >{{ $t("bankCard.default[5]") }}</van-button
          >
          <van-button
            :plain="!isCoinCard"
            type="info"
            style="margin-left: 5px; flex: 1"
            @click="changeCardType(true)"
            >{{ $t("bankCard.default[6]") }}</van-button
          >
        </div>
        <div v-if="!isCoinCard">
          <van-divider
            :hairline="false"
            style="text-align: center; color: red"
            >{{ $t("card[1]") }}</van-divider
          >
          <van-form @submit="onSubmit" ref="form">
            <van-field
              readonly
              :value="postData.bank_name"
              :label="$t('bankCard.label[1]')"
              :placeholder="`--${$t('bankCard.placeholder[0]')}--`"
              @click="showPicker = true"
              v-if="
                bankcardInfo.bank_name == 1 &&
                bankcardInfo.bank_name_dropdown == 1
              "
            />
            <van-field
              v-model.trim="postData.bank_name"
              :label="$t('bankCard.label[1]')"
              :placeholder="$t('bankCard.placeholder[0]')"
              v-if="
                bankcardInfo.bank_name == 1 &&
                bankcardInfo.bank_name_dropdown == 0
              "
            />
            <van-field
              v-model.trim="postData.name"
              :label="$t('bankCard.label[0]')"
              v-if="bankcardInfo.real_name == 1"
            />
            <van-field
              v-model.trim="postData.bank_name"
              :label="$t('bankCard.label[1]')"
              v-if="bankcardInfo.card_no == 1"
            />
            <van-field
              v-model.trim="postData.card_no"
              type="text"
              maxlength="26"
              :label="$t('bankCard.label[2]')"
              :formatter="formatter"
              v-if="bankcardInfo.card_no == 1"
            />
            <!-- <div style="padding: 20px 15px"><van-button style="width: 80px" block size="mini" type="info" round>Paste</van-button></div> -->
            <van-field
              v-model.trim="postData.bank_code"
              :label="$t('bankCard.label[3]')"
              :placeholder="$t('bankCard.placeholder[2]')"
              v-if="bankcardInfo.bank_code == 1"
            />
            <!-- 隐藏CPF -->
            <!-- <van-field v-model.trim="postData.upi" label="CPF" /> -->
            <!-- <van-field v-model.trim="postData.upi" :label="$t('bankCard.label[4]')" :placeholder="$t('bankCard.placeholder[3]')" v-if="bankcardInfo.upi == 1" /> -->
            <div style="padding: 35px">
              <van-button
                block
                type="danger"
                :loading="isSubmit"
                :loading-text="$t('bankCard.default[2]')"
                style="font-size: 16px"
              >
                {{ $t("bankCard.default[3]") }}
              </van-button>
            </div>
          </van-form>
        </div>
        <div v-if="isCoinCard">
          <van-form @submit="onSubmit" v-if="showAdd">
            <van-field
              v-model.trim="postData.card_no"
              type="text"
              :label="$t('bankCard.label[5]')"
            />
            <div style="padding: 35px">
              <van-button
                block
                type="danger"
                :loading="isSubmit"
                :loading-text="$t('bankCard.default[2]')"
                style="font-size: 16px"
              >
                {{ $t("bankCard.default[3]") }}
              </van-button>
            </div>
            <div style="padding-left: 10px">
              {{ $t("CoinRecharge[30]") }}
            </div>
            <div class="tips">
              {{ $t("CoinRecharge[31]") }}
            </div>
            <div class="tips">
              {{ $t("CoinRecharge[32]") }}
            </div>
            <div class="tips">
              {{ $t("CoinRecharge[33]") }}
            </div>
            <div class="tips">
              {{ $t("CoinRecharge[34]") }}
            </div>
            <div class="tips">
              {{ $t("CoinRecharge[35]") }}
            </div>
            <div class="tips">
              {{ $t("CoinRecharge[36]") }}
            </div>
          </van-form>
        </div>
      </div>
    </div>
    <van-popup v-model="showPicker" position="bottom"
      ><van-picker
        show-toolbar
        :columns="bankList"
        @confirm="onConfirm"
        @cancel="showPicker = false"
    /></van-popup>
    <!-- 示例 -->
    <div class="example" v-if="showAdd">
      <h3 class="title">Tarzi doldurun</h3>
      <van-field
        readonly
        :label="$t('bankCard.label[0]')"
        placeholder="Dudukaradana"
      />
      <van-field
        readonly
        :label="$t('bankCard.label[1]')"
        placeholder="Ziratbankasl"
      />
      <van-field
        readonly
        type="text"
        :label="$t('bankCard.label[2]')"
        placeholder="TR200001000394140572765011"
      />
      <img class="exampleLogo" src="../../assets/img/example.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BankCard",
  components: {},
  props: [],
  data() {
    return {
      postData: {
        name: "",
        bank_id: "",
        bank_name: "",
        card_no: "",
        bank_code: "",
        currency: "",
        upi: "",
      },
      isCoinCard: false, //币卡
      showPicker: false,
      bankList: "",
      bankcardInfo: {},
      lang: "",
      showAdd: false,
      isSubmit: false,
      is_realname: "",
      bankCardNumber: "", //银行卡号
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.UserInfo;
    },
    cardList() {
      return this.$store.state.BankCardList;
    },
  },
  watch: {},
  created() {
    // this.$Model.GetUserInfo();
    this.$parent.navBarTitle = "";
    if (this.cardList.length) {
      this.showAdd = false;
    } else {
      this.showAdd = true;
    }
    this.postData.name = "";
    this.lang = localStorage["Language"] || "en";
    this.bankcardInfo = this.InitData.BankcardInfo;
    this.bankList = this.InitData.BanksList.map((item) => item.bank);
    this.$Model.GetBankCardList();
    this.$Model.GetUserInfo((data) => {
      this.is_realname = data.info.is_realname;
      // console.log(data.info.realname)
      if (this.is_realname == 1) {
        this.postData.name = data.info.realname;
      }
    });
    // if(this.is_realname==0){
    // 	this.postData.name = this.postData.realname;
    // 	// console.log(this.postData.realname)
    // 	// console.log(1)
    // }else{
    // 	console.log(this.postData.realname)
    // }
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    changeCardType(isCoin) {
      this.postData.card_no = "";
      this.isCoinCard = isCoin;
      this.postData.currency = "";
      if (isCoin) {
        this.postData.currency = "TRX";
      }
    },
    onConfirm(value, index) {
      this.postData.bank_name = value;
      this.postData.bank_id = this.InitData.BanksList[index].bank_id;
      this.postData.bank_code = this.InitData.BanksList[index].bank_code;
      this.showPicker = false;
    },
    onSubmit() {
      // 银行卡位数判断
      if (this.bankCardNumber.length != 26) {
        this.$Dialog.Toast(this.$t("card[2]"));
        return;
      }
      if (!this.postData.card_no) {
        this.$Dialog.Toast(this.$t("bankCard.placeholder[1]"));
        return;
      }
      if (!this.isCoinCard) {
        this.postData.currency = ""; //不传币种
        if (!this.postData.name) {
          this.$Dialog.Toast(this.$t("bankCard.placeholder[4]"));
          return;
        }
        if (!this.postData.bank_name) {
          this.$Dialog.Toast("Nome do banco está vazio");
          return;
        }
        // if (!this.postData.upi) {
        //   this.$Dialog.Toast(this.$t("tips0805[2]"));
        //   return;
        // }
      }
      // if(!this.postData.bank_branch_name){
      //   this.$Dialog.Toast(this.$t('bankCard.placeholder[2]'));
      //   return;
      // }
      //银行卡号是否有空格
      if (this.postData.card_no.indexOf(" ") != -1) {
        console.log(111);
        this.$Dialog.Toast(this.$t("bankCard.placeholder[5]"));
        return false;
      }
      if (this.is_realname == 1) {
        this.$Model.SetUserInfo(this.postData, (data) => {
          if (data.code == 1) {
            this.$Model.AddBankCard(this.postData, (data) => {
              this.isSubmit = false;
              this.isCoinCard = false;
              if (data.code == 1) {
                this.showAdd = false;
                //清除表单 部分内容
                this.postData.bank_name = "";
                this.postData.card_no = "";
              } else if (data.code > 0) {
                this.$Dialog.Confirm(
                  this.$t(`tips0805[${data.code}]`),
                  () => {},
                  "OK"
                );
              }
            });
          }
        });
      } else {
        this.postData.realname = this.postData.name;
        // console.log(this.postData)
        this.isSubmit = true;
        this.$Model.SetUserInfo(this.postData, (data) => {
          if (data.code == 1) {
            this.$Model.AddBankCard(this.postData, (data) => {
              this.isSubmit = false;
              this.isCoinCard = false;
              if (data.code == 1) {
                this.showAdd = false;
              }
            });
          }
        });
      }

      // this.$Model.AddBankCard(this.postData, data => {
      // 	this.isSubmit = false;
      // 	if (data.code == 1) {
      // 		this.showAdd = false;
      // 	}
      // });
    },
    // 输入限制
    formatter(value) {
      if (value)
        // 限制不能输入特殊符号，如空格，逗号，横线等
        this.bankCardNumber = value.replace(/[\W+]/g, "");
      return value.replace(/[\W+|\_]/g, "");
    },
  },
};
</script>
<style scoped>
.PageBox .bgc {
  width: 100%;
  background-color: #4e51bf;
  height: 46px;
  position: fixed;
  top: 0;
  z-index: 200;
}

.topTitle {
  position: absolute;
  max-width: 60%;
  white-space: nowrap;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.list {
  margin: 10px 0;
}
.list .van-cell__left-icon {
  font-size: 50px;
  color: #4087f1;
  margin-right: 15px;
}
.list .van-cell__label {
  font-size: 16px;
  color: #888;
}
.ScrollBox >>> .van-button,
.ScrollBox >>> .van-form .van-button {
  /*background: none;*/
  /*background-color: #4e51bf;*/
  border: none;
}
.ScrollBox >>> .list .van-icon {
  color: #4e51bf;
}
.tips {
  padding-left: 15px;
  padding-top: 5px;
}
/* example */
.example {
  position: relative;
  overflow: hidden;
  height: 195px;
  box-shadow: 0px 0px 15px #eee;
  margin: 0 20px;
  border-radius: 10px;
}
.example .title {
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  background: #ffac00;
}
.example .exampleLogo {
  position: absolute;
  top: 55px;
  right: 20px;
  width: 116px;
  height: 80px;
}
</style>
