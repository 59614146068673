<template>
  <div class="Site PageBox">
    <van-nav-bar
      fixed
      :border="false"
      :title="$t('appDown[0]')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div id="QRCode"></div>
    <p>{{$t('appDown[1]')}}</p>
    <div style="padding: 20px 16px" v-if="isPlus">
      <van-button block type="info" style="font-size: 16px" @click="saveQRCode">{{$t('appDown[2]')}}</van-button>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2"
export default {
  name: 'AppDown',
  components: {

  },
  props: {},
  data() {
    return {
      saveN: 0,
      isPlus: window.plus,
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    
  },
  mounted() {
    const lang = localStorage['Language'] || Language
    new QRCode(document.getElementById("QRCode"), {
      text: AppDown+lang.toUpperCase(),
      width: 160,
      height: 160,
      correctLevel : QRCode.CorrectLevel.H
    });
  },
  activated() {

  },
  destroyed() {
    
  },
  methods: {
    saveQRCode() {
      if(window.plus){
        this.saveN+=1;
        var img = $('#QRCode').find('img').attr('src').replace('data:image/png;base64,','');
        var bm = new plus.nativeObj.Bitmap();
        bm.loadBase64Data(img, ()=>{
          bm.save( '_doc/appdown'+this.saveN+'.jpg', {'overwrite':true,'format':'jpg'}, (e)=>{
            plus.gallery.save( e.target, (e)=>{  
              this.$Dialog.Toast(this.$t('appDown[3]'))
            },(err)=>{  
              this.$Dialog.Toast(this.$t('appDown[4]'))
            }); 
          }, (error)=>{  
            this.$Dialog.Toast(this.$t('appDown[4]'))
          });
          setTimeout(function(){
            bm.recycle();
          },1000);
        }, (err)=>{  
          this.$Dialog.Toast(this.$t('appDown[4]'))
        });
      }else{
        this.$Dialog.Toast(this.$t('promote[9]'))
      }
    },
  }
}
</script>
<style scoped>
.PageBox{
  text-align: center;
  background: url('../../public/static/images/down1.png') no-repeat right 0,
  url('../../public/static/images/down2.png') no-repeat left 0;
  background-size: auto 45%,auto 45%;
}
#QRCode{
  display: inline-block;
  margin: 25% 0 10px;
}
#QRCode>>>img{
  border: 10px #fff solid;
}
</style>