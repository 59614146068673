<template>
  <div class="PageBox">
    <div class="ScrollBox">
      <div class="Robot">
        <h2>服务说明</h2>
        <p>1.开通服务费用为99/月,次日生效</p>
        <p>2.生效以后将自动替客户完成每日任务,每天早上8点之前自动完成当天任务并结算收益</p>
        <van-button class="mt15" type="danger" block style="font-size: 16px;" :disabled="UserInfo.is_housekeeper==1?true:false" @click="onSubmit">开通云管家</van-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Robot',
  components: {
  },
  props: [],
  data() {
    return {
 
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.$parent.navBarTitle = '云管家'
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {
    
  },
  methods: {
    onSubmit() {
      this.$Model.SetUserInfo({is_housekeeper:1})
    }
  }
}
</script>
<style scoped>
.Robot{
  padding: 16px;
}
.Robot h2{
  text-align: center;
  margin-bottom: 10px;
}
.Robot p{
  margin-top: 5px;
}
</style>