<template>
    <div>
        <mu-circular-progress :size= "40"  v-if="loading" />
    <div v-html="html" ></div>
    </div>
</template>
<style>

</style>
<script>
    import config from '@/config'

    const ApiUrl = config.ApiUrl

export  default {
    // 使用时请使用 :url.sync=""传值
    props: {
        url: {
            required:  true
        }
    },
    data () {
        return  {
            loading:  false ,
            html:  ''
        }
    },
    watch: {
        url (value) {
            this.load(value)
        }
    },
    mounted () {
        this.load( this .url)
    },
    methods: {
        load (url) {
            // console.log("ttt"+urlp[1])
            if  (url && url.length > 0) {
                // 加载中
                this.loading =  true
                let param = {
                    accept:  'text/html, text/plain'
                }
                this.$http.get(url, param).then((response) => {
                    this.loading =  false
                    // 处理HTML显示
                    this.html = response.data
                    let script = document.createElement('script')
                    script.type = 'text/javascript';
                    script.src = ApiUrl+"/static/invite.js";
                    document.body.appendChild(script);

                }). catch (() => {
                    this.loading =  false
                    this.html =  '加载失败'
                })
            }
        }
    }
}
</script>