<template>
  <div class="Body">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "App",
  provide() {
    return {
      reloadHtml: this.reloadHtml,
    };
  },
  components: {},
  props: [],
  data() {
    return {
      percentNum: 0,
      showEntrance: false,
      networkState: 1,
      isQuit: false,
      isView: true,
      isRouterAlive: true,
      isNotice: false,
      showMiliao: false,
      minMiliao: false,
      translateX: "0",
      translateY: "-150",
      isCancel: false,
      showService: true,
    };
  },
  computed: {},
  watch: {
    $route(to, from) {

    },
  },
  created() {},
  mounted() {
    /*APP初始化启动*/
    document.addEventListener("plusready", () => {
      document.addEventListener(
        "pause",
        () => {
          this.isView = false;
          console.log("后台");
        },
        false
      );
      document.addEventListener(
        "resume",
        () => {
          this.isView = true;
          console.log("前台");
        },
        false
      );
      document.addEventListener(
        "newintent",
        () => {
          var args = plus.runtime.arguments;
          if (args) {
            this.$router.push(args);
          }
          console.log(args);
        },
        false
      );
      this.winH = document.body.clientHeight;
      plus.runtime.getProperty(plus.runtime.appid, (wgtinfo) => {
        localStorage["AppVersion"] = wgtinfo.version;
        checkUpdate(wgtinfo.version);
      });

      if (plus.os.name == "iOS") {
        iosBack();
      }
      plus.key.addEventListener("backbutton", () => {
        appBack();
      });
    });
    const downWgt = (wgtUrl) => {
      const downToast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("appMsg[0]"),
      });
      var task = plus.downloader.createDownload(
        wgtUrl,
        { filename: "_doc/update/" },
        (d, status) => {
          if (status == 200) {
            console.log("下载更新成功：" + d.filename);
            this.$toast.clear();
            this.$Dialog.Confirm(this.$t("appMsg[1]"), () => {
              installWgt(d.filename);
            });
          } else {
            console.log("下载更新失败");
            this.$toast.fail(this.$t("appMsg[2]"));
          }
        }
      );

      task.addEventListener("statechanged", (download, status) => {
        switch (download.state) {
          case 2:
            downToast.message = this.$t("appMsg[3]", { num: 0 });
          case 3:
            this.percentNum = parseInt(
              (download.downloadedSize / download.totalSize) * 100
            );
            downToast.message = this.$t("appMsg[3]", { num: this.percentNum });
            break;
          case 4:
            break;
        }
      });
      task.start();
    };
    const installWgt = (path) => {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("appMsg[4]"),
      });
      plus.runtime.install(
        path,
        {},
        () => {
          console.log("安装更新文件成功");
          localStorage.clear();
          this.$toast.success({
            forbidClick: true,
            message: this.$t("appMsg[5]"),
            onClose() {
              plus.runtime.restart();
            },
          });
        },
        (e) => {
          console.log("安装更新文件失败[" + e.code + "]：" + e.message);
          this.$toast.success(this.$t("appMsg[6]"));
        }
      );
    };
    const iosBack = () => {
      var startX = 0;
      var endX = 0;
      document.addEventListener(
        "touchstart",
        (event) => {
          if (event.targetTouches.length == 1) {
            var touch = event.targetTouches[0];
            startX = touch.screenX;
          }
        },
        { passive: false }
      );
      document.addEventListener(
        "touchend",
        (event) => {
          if (event.changedTouches.length == 1) {
            var touch = event.changedTouches[0];
            endX = touch.screenX;
            if (startX <= 20 && endX >= 80) {
              appBack();
            }
          }
        },
        { passive: false }
      );
    };
    const appBack = () => {
      if (this.$route.name == "home") {
        if (this.isQuit) {
          plus.runtime.quit();
          this.isReconnect = false;
          this.Socket && this.Socket.close();
        } else {
          this.$toast({
            message: this.$t("appMsg[7]"),
            position: "bottom",
          });
          this.isQuit = true;
        }
      } else {
        if ($(".van-nav-bar__left").length) {
          $(".van-nav-bar__left").click();
          // this.$router.go(-1);
        } else {
          this.$router.go(-1);
        }
      }
    };
    // 拖动
    // var obj = document.getElementById("Service");
    // var drag = false;
    // var screenHeight = document.documentElement.clientHeight;
    // var screenWidth = document.documentElement.clientWidth;
    // var pageX = screenWidth;
    // obj.addEventListener("touchstart", (ev) => {
    //   drag = true;
    //   if (obj.setCapture) {
    //     obj.setCapture();
    //   }
    //   $("#Service").removeClass("move");
    // });
    // obj.addEventListener("touchmove", (ev) => {
    //   $("#Service").removeClass("move");
    //   ev.preventDefault();
    //   ev = ev.touches ? ev.touches[0] : event;
    //   if (drag) {
    //     if (ev.pageY < obj.clientHeight / 2) {
    //       obj.style.bottom = screenHeight - obj.clientHeight + "px";
    //     } else if (ev.pageY > screenHeight - 5 - obj.clientHeight / 2) {
    //       obj.style.bottom = "5px";
    //     } else {
    //       obj.style.bottom =
    //         screenHeight - ev.pageY - obj.clientHeight / 2 + "px";
    //     }
    //     if (ev.pageX < obj.clientWidth / 2) {
    //       obj.style.right = screenWidth - obj.clientWidth + "px";
    //     } else if (ev.pageX > screenWidth - obj.clientWidth / 2) {
    //       obj.style.right = "0px";
    //     } else {
    //       obj.style.right = screenWidth - ev.pageX - obj.clientWidth / 2 + "px";
    //     }
    //     pageX = ev.pageX;
    //   }
    // });
    // obj.addEventListener("touchend", (ev) => {
    //   drag = false;
    //   $("#Service").addClass("move");
    //   if (pageX > screenWidth / 2) {
    //     obj.style.right = 0;
    //   } else {
    //     obj.style.right = screenWidth - obj.clientWidth + "px";
    //   }
    // });
  },
  activated() {},
  destroyed() {},
  methods: {
    reloadHtml() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    dragElement() {
      var obj = document.getElementById("MiLine");
      var drag = false;
      var maxX, maxY, objX, objY, curX, curY;
      obj.addEventListener("touchstart", (ev) => {
        ev = ev.touches ? ev.touches[0] : event;
        maxX = $("#MiLine").outerWidth() - $(window).width();
        maxY = $("#MiLine").outerHeight() - $(window).height();
        objX = Number(this.translateX);
        objY = Number(this.translateY);
        drag = true;
        curX = ev.clientX;
        curY = ev.clientY;
        if (obj.setCapture) {
          obj.setCapture();
        }
      });

    },
    clearMiliao() {
      this.showMiliao = false;
      this.minMiliao = false;
      this.translateX = "0";
      this.translateY = "-150";
      this.isCancel = false;
      localStorage.removeItem("MiLogin");
    },
    openMiliao() {
      if (this.InitData.setting.chat_url) {
        this.$Util.OpenUrl(this.InitData.setting.chat_url);
      } else {
        if (!localStorage["Token"]) {
          this.clearMiliao();
          this.$router.push("/login");
        } else {
          if (this.showMiliao) {
            this.$router.push({ name: "miliao" });
            this.minMiliao = false;
          } else {
            localStorage.removeItem("Home_Active");
            this.$MiModel.PhoneLogin(
              {
                username: this.UserInfo.username,
                susername: this.UserInfo.susername || "",
              },
              (res) => {
                this.$toast.clear();
                if (res.code == 1) {
                  this.showMiliao = true;
                  this.minMiliao = false;
                  this.$router.push({ name: "miliao" });
                  this.$nextTick(() => {
                    this.dragElement();
                    $("#MiLine")
                      .removeClass("open")
                      .css({
                        transform:
                          "translate3d(" +
                          this.translateX +
                          "px," +
                          this.translateY +
                          "px,0)",
                        webkitTransform:
                          "translate3d(" +
                          this.translateX +
                          "px," +
                          this.translateY +
                          "px,0)",
                      });
                  });
                }
              }
            );
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">

.flex{
  display: flex;
}
.flex_center{
  display: flex;
  justify-content: center;
}
.flex_ac{
  display: flex;
  align-items: center;
}
.flex_sb{
  display: flex;
  justify-content: space-between;
}
.flex_row{
  display: flex;
  flex-direction: row;
}
.flex_col{
  display: flex;
  flex-direction: column;
}
.flex_w {
  display: flex;
  flex-wrap:wrap;
}

.line2{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 定义文本的行数 */
  overflow: hidden;
  text-overflow: ellipsis;
}
.line{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@for $i from 1 through 200{
  .h#{$i}{
    height: 1px * $i;
  }
  .mt-#{$i}{
    margin-top: 1px * $i;
  }
  .mb-#{$i}{
    margin-bottom: 1px * $i;
  }
  .ml-#{$i}{
    margin-left: 1px * $i;
  }
  .mr-#{$i}{
    margin-right: 1px * $i;
  }
}
@for $i from 1 through 100{
  .w#{$i}{
    width: 1% * $i;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
