<template>
  <div class="Site">
    <!-- <div style="padding-left: 15px;padding-top: 10px">
      <img :src="`./yfc/logo.png`" width="100"></img>
    </div> -->

    <div class="flex_sb flex_ac top_div">
      <div class="top_text">{{$t('home.WebMarket')}}</div>
      <img class="top_img" src="~@/assets/img/new/kefu.png" alt="" @click="$router.push('serviceCenter')">
    </div>

    <div class="Home">
      <div class="header">
        <van-swipe :autoplay="3000"
                   :show-indicators="false"
                   class="homeswipe">
          <van-swipe-item v-for="(item, index) in bannerList" :key="index">
            <img ref="img" :src="item" class="BroadBarItem" width="100%" height="100%"/>
          </van-swipe-item>
        </van-swipe>

        <div class="menuPanel">
          <div style="width: 25%" @click="$router.push('/user/fundRecord')">
            <div>
              <img src="~@/assets/img/new/icon1.png" style="height: 3.6rem" />
            </div>
            <div>{{ $t('home.menu[0]') }}</div>
          </div>
          <div @click="$router.push('/user/invite/'+UserInfo.idcode+'/'+UserInfo.idcode)" style="width: 25%">
            <div>
              <img src="~@/assets/img/new/icon3.png" style="height: 3.6rem" />
            </div>
            <div>{{ $t('home.menu[2]') }}</div>
          </div>
          <div style="width: 25%" @click="$router.push('/serviceCenter')">
            <div class="four">
              <img src="~@/assets/img/new/icon5.png" style="height: 3.6rem" />
            </div>
            <div>{{ $t('home.menu[3]') }}</div>
          </div>
          <div style="width: 25%" @click="$router.push('/user/bindAccount')">
            <div class="four">
              <img src="~@/assets/img/new/icon4.png" style="height: 3.6rem" />
            </div>
            <div>{{ $t('user.menu[2]') }}</div>
          </div>
        </div>
      </div>

      <div class="header_link flex_ac flex_sb">
        <img class="tz_img1" src="~@/assets/img/new/tz_img.png" alt="">
        <van-notice-bar color="#888"
                        background="none"
                        :text="noticeBarContent"
        />
        <img class="tz_img2" src="~@/assets/img/new/tz_right.png" alt="">
      </div>
      <div style="font-size: 14px;display: flex">
        <div class="Menu2item2" style="background-color: var(--panel_color)" @click="gotoBuy">
          <div style="margin-left: 5px;width: 80%;">
            <div style="color:#5AA4EF;font-size: 14px;font-weight: bold;line-height: 30px;margin-bottom: 5px">{{ $t('home4[0]') }}</div>
            <!--            <div style="font-size: 13px;color:var(&#45;&#45;font_subtitle);margin-bottom: 10px">{{ $t('home4[1]') }}</div>-->
            {{$t('hall.default[9]')}}
          </div>
          <div style="padding-left: 0px;margin-right: 5px;margin-top: 10px">
            <van-icon name="./skin/arrow2.png" size="40"/>
          </div>
        </div>
        <div class="Menu2item2 Menu2item3" style="background-color: var(--panel_color)" @click="$router.push('/sell')">
          <div style="margin-left: 5px;width: 70%;">
            <div style="color:#F79D2F;font-size: 14px;font-weight: bold;line-height: 30px;margin-bottom: 5px">{{ $t('home4[2]') }}</div>
            <!--            <div style="font-size: 13px;color:var(&#45;&#45;font_subtitle);margin-bottom: 10px">{{ $t('home4[3]') }}</div>-->
            {{$t('hall.default[10]')}}
          </div>
          <div style="padding-left: 0px;margin-right: 5px;margin-top: 10px">
            <van-icon name="./skin/arrow1.png" size="40"/>
          </div>
        </div>
      </div>
      <div class="title">{{$t('home.special_attention')}}</div>

      <div class="tool">
        <div class="tool_item" v-for="(item, index) in currency0" :key="index">
          <div class="flex_ac">
            <img class="t_img" :src="`./static/currency/`+item.name.toLowerCase()+`.png`" alt="">
            <div class="t_name"> {{item.name}}<span class="t_text"> / {{InitData.usdtinfo.sign}}</span></div>
          </div>
          <div style="color: #333;font-size: 18px;font-weight: bold;margin:10px 0"> {{parseFloat(item.price).toFixed(2)}}</div>
          <div class="flex_ac" v-if="item.rate>0" style="color: #55D3E4;font-size: 13px;font-weight: bold">
            <img style="width:10px;margin-right:6px" src="~@/assets/img/new/shang.png" alt="">
            {{'+' + parseFloat(item.rate).toFixed(2)}}%
          </div>
          <div v-else style="color: #FF6270;font-size: 13px;font-weight: bold">
            <img style="width:10px;margin-right:6px" src="~@/assets/img/new/xia.png" alt="">
            {{'-' + parseFloat(item.rate).toFixed(2)}}%
          </div>
        </div>
      </div>

      <div class="title">{{$t('home.spot_market')}}</div>

      <div class="homelist">
        <div v-for="(item, index) in currency2" class="coinitem">
          <table style="font-size:13px;width: 100%;padding:8px 5px 7px 0px">
            <tr>
              <td style="width: 8%">
                <img :src="`./static/icon/`+item.star.toLowerCase()+`.png`" style="height: 1.2rem" />
              </td>
              <td style="width: 10%">
                <img :src="`./static/currency/`+item.name.toLowerCase()+`.png`" style="height: 2.5rem;margin-right:10px" />
              </td>
              <td style="font-size: 16px;width: 20%;">
                <div style="font-weight:bold">{{item.name}}</div>
                <div style="font-size: 12px" v-if="item.rate>0" class="rate1">+{{parseFloat(item.rate).toFixed(2)}}%</div>
                <div style="font-size: 12px" v-else class="rate2">{{parseFloat(item.rate).toFixed(2)}}%</div>
              </td>
              <td style="font-size: 16px;text-align: right">
                <div style="line-height: 20px">$ {{$Util.formatNum(parseFloat(item.price0).toFixed(2))}}
                </div>
                <div style="font-size: 11px" v-if="item.volume">Vol : {{$Util.formatNum(parseFloat(item.volume/1000).toFixed(2))}}k
                </div>
              </td>

            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="h80"></div>
    <Footer />
    <van-popup
        v-model="showNotice"
        style="background: transparent; width: 90%; text-align: center"
        @closed="$parent.isNotice = true"
    >
      <div class="popupBg">
        <dl
            class="ScrollBox NoticePopup"
            v-if="InitData.noticelist && InitData.noticelist.length"
            @click="$router.push(`/article/notice/${InitData.noticelist[0].id}`)"
        >
          <dt class="popup_title">
            <div style="margin: 0 auto">
              <span :class="{ letter_s: language1 }">{{
                  $t("home.noticeTitle")
                }}</span
              ><span>NOTICE</span>
            </div>
          </dt>
          <dd v-html="InitData.noticelist[0].content" style="color: #000"></dd>
        </dl>
      </div>
      <a class="close" href="javascript:;" @click="showNotice = false">
        <img src="../../public/static/images/pupup_false.png" />
      </a>
    </van-popup>
  </div>
</template>


<script>
import $ from "jquery";
// import {ref} from 'vue'
export default {
  name: "Home",
  inject: ["reloadHtml"],
  components: {},
  props: ["user_id"],
  data() {
    return {
      currentRate: 0,
      showNotice: false,
      tabActive: 0, //任务大厅
      tabActive1: 0, //榜单
      currency0:[],
      currency2:[],
      noticeBarContent:"",
      language1: localStorage.getItem("Language") == "cn",
      bannerList: [
          "../static/images/bg_banner.png"
      ],
      moeny: "",
      currHeader:'head_6.png',
      timer: null, //定时器
      time1:0,
    };
  },

  computed: {
    text() {
      // return this.currentRate.toFixed(0) + '%'
      return "loading";
    },
  },
  watch: {
    "InitData.noticelist"(data) {
      if (!this.$parent.isNotice && data.length) this.showNotice = true;
    },
  },
  created() {
    if (!localStorage["Token"]) {
      this.$router.push("/login");
      return;
    }
    var a = this.InitData;
    window.callReadFunction = this.callReadFunction;
    if (localStorage["Token"]) {
      // 查询绑定银行卡
      this.$Model.GetBankCardList();
    }
    var ret = this.$Util.readAppStorage();
    console.log("欢迎");
    this.noticeBarContent = "";
    this.$Model.GetBackData((data) => {
      // this.bannerList = data.bannerList;
      this.usdtinfo = data.usdtinfo;
      for (let i=0;i<data.drawNotice.length;i++){
        this.noticeBarContent += data.drawNotice[i]['content']+"; "
      }
      this.noticeBarContent = this.noticeBarContent.replace("<p>","").replace("</p>","")
    });
    this.getPrices();
    // alert("xxxxxxxxxxxxx:"+ret)
  },
  mounted() {
    if (this.UserInfo.header){
      this.currHeader = this.UserInfo.header;
    }
    //获取浏览器地址
    let getHref = window.location.href;
    // console.log(this.$refs)
    // for(let i=0;i<)
  },
  activated() {},
  destroyed() {},
  // 离开页面清除倒计时
  beforeDestroy() {
    clearInterval(this.timer);
    console.log("clear timer done:"+this.timer);
  },
  methods: {
    gotoBuy(){
      let usertype = this.UserInfo.user_type;
      if (!usertype||usertype==0){
        this.$Dialog.Toast(this.$t("busAuth2[5]"));
        return;
      }else{
        this.$router.push("/buy");
      }
    },
    callReadFunction(data) {
      localStorage["AppData"] = data;
      // this.$toast(data)
    },
    getPrices() {
      var that = this;
      that.setCoinData(that.InitData);
      setTimeout(function() {
        that.timer = setInterval(() => {
          // console.log("get prices");
          that.getCommonData();
        }, 4000);
      }, 5000);
    },
    getCommonData(){
      var that = this;
      that.time1 = new Date().getTime();
      this.$Model.GetCommonData(data=>{
        that.setCoinData(data)
      });
    },
    setCoinData(data){
      var that = this;
      let time2 = new Date().getTime();
      if (!data.currencylist){
        return;
      }
      console.log("time cost00: "+(time2-that.time1))
      let coins = data.currencylist;
      if (coins.length<3){
        this.$Dialog.Toast("no currency found");
        return;
      }
      console.log("get prices done:")
      let coins0 = [];
      for (var i=0;i<3;i++){
        coins0.push(coins[i]);
      }
      this.currency0 = coins0;
      coins.forEach(coin=>{
        var ii = "star1";
        var kk = Math.round(Math.random()*10);
        if (kk>5){
          ii = "star2"
        }
        coin['star'] = ii;
      })
      this.currency2 = coins;
      time2 = new Date().getTime();
      console.log("time cost: "+(time2-that.time1))
    },
    goTask(index, state) {
      if (state == 3) {
        this.$toast.fail(this.$t("home.msg"));
      } else {
        let grade = this.UserInfo.vip_level;
        let taskType = this.InitData.taskclasslist.filter(
            (obj) => obj.state == 1
        )[index].group_id;
        /* this.$router.push(`/taskList/${taskType}/${grade}`); */
        this.$router.push("/GrabOrders");
      }
    },

    // 打招呼
    goChatPage(id) {
      this.$router.push(`/user/chatPage/${id}`);
    },
    toGrabtask() {
      console.log(1);
      this.$router.push("/user/Grabtask");
    },
  },
};
</script>
<style scoped lang="scss">
.Site {
  background-image: url(~@/assets/img/new/index_top.png);
  background-repeat: no-repeat;
  background-size: 100% auto ;
  background-color: #F5F8FF;
  font-family: Alibaba;
  &::v-deep .van-swipe__indicators{
    left: auto;
    right:0 !important;
  }
  &::v-deep .van-swipe__indicator--active{
    width: 14px;
    border-radius: 3px;
    background: #fff !important;
  }
}
.top_div{
  width: 96%;
  margin-left: 2%;
  padding: 12px 0;
  .top_text{
    font-size: 22px;
    font-weight: bold;
    color: #000;
  }
  .top_img{
    height: 28px;
    width: 28px;
  }
}

.ScrollBox{
  // background-color: #F5F8FF;
}
.BroadBarItem {
  width: 100%;
}


.header_link {
  padding: 0 15px;
  margin: 0 2% 20px;
  border-radius: 30px;
  background: #FCEBDB;
  .van-notice-bar{
    width: calc(100% - 50px);
  }
  .tz_img1{
    width: 20px;
  }
  .tz_img2{
    width: 10px;
  }
}

.header_link ::v-deep .van-cell {
  border-radius: 30px;
  padding: 0px 10px;
  margin-top: 10px;
}

.header_link ::v-deep .van-cell__value span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Alibaba;
  color: #fff;
  font-size: 13px;
}

.header ::v-deep .Menu {
  justify-content: space-between;
  background-color: #0F1A29;
  padding: 10px 20px 10px 10px;
  width: 100%;
  border-radius: 5px;
  max-width: 450px;
  font-size: 11px;
  margin: 20px auto 0px;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.header ::v-deep .Menu2 {
  justify-content: space-between;
  width: 100%;
  max-width: 450px;
  font-size: 11px;
  border-radius: 10px;
  flex-wrap: wrap;
  text-align: left;
}

.menuPanel {
  display: flex;
  margin: 5px auto;
  margin-top: 15px;
  margin-bottom: 0px;
  text-align: center;
  padding: 10px;
  color: #333;
}
.Menu2 ::v-deep .van-grid-item__content {
  /*background-color: #151d31;*/
  border-radius: 5px;
  color:#cccccc;
  background: transparent none repeat scroll 0% 0%;
}

.tool ::v-deep .van-grid-item__content {
  padding: 3px !important;
  display: flex;
  color: #D7D7D7;
  background-color:#24303C;
}

.homeswipe {
  border-radius: 10px;
  width: 96%;
  margin-left: 2%;
  height: 13rem; z-index: 50;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #000;
}
.homelist {
  // background-color: #0F1A29;
  background: #fff;
  border-radius: 10px;
  margin: 10px 2%;
  padding: 10px 0;
}
.header ::v-deep .Menu .van-grid-item__content {
  padding: 5px;
  display: flex;
  color: #d7d7d7;
}

.header ::v-deep .Menu .van-grid-item__content div:first-child {
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header ::v-deep .Menu .van-grid-item__content div:last-child {
  margin-top: 10px;
  text-align: center;
}

.content_footer ::v-deep .van-cell::after {
  display: none;
}

.Menu2item2 {
  display: flex;
  height: 105px;
  width: 50%;
  padding: 5px;
  padding-top: 15px;
  text-align: left;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  line-height: 14px;
  border-radius: 10px;
  border:1px solid #A6C4F6;
}

.Menu2item3 {
  border:1px solid #EBCEA6;
}
.tool::before {
  content: "";
  display: block;
}

.tool {
  border-radius: 10px;
  color: #000;
  overflow-x: auto;
  z-index: 199;
  padding: 0 5px;
  line-height: 22px;
  white-space: nowrap;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 0;
    height: 0;
    border-radius: 0px;
    background-color: #282932;
    border: 0px solid transparent;
    background-clip: padding-box;
  }
  .tool_item{
    display: inline-block;
    background: #fff;
    border-radius: 16px;
    width: 220px;
    padding: 15px;
    margin: 15px 10px 15px 0;
    .t_img{
      height: 55px;
      width: 55px;
      margin-right: 8px;
    }
    .t_name{
      font-size: 18px;
      color: #000;
      font-weight: 600;
    }
    .t_text{
      font-size: 16px;
      color: #999;
      font-weight: 400;
    }
  }
}

.zh-CN ::v-deep .van-grid-item__icon-wrapper,
.zh-HK ::v-deep .van-grid-item__icon-wrapper {
  word-spacing: 5px;
}

.MemberList ::v-deep .van-tabs__wrap {
  height: 38px;
}

.MemberList ::v-deep .van-tabs__nav--card {
  margin: 0;
}

.MemberList ::v-deep .swipe-item_box {
  display: flex;
  align-items: center;
  padding: 10px 10px !important;
}

.swipe-item_box ::v-deep .swipe-item_info_title {
  margin-left: 15px;
  margin-top: 6px;
  height: 18px;
  font-size: 13px !important;
  font-family: Alibaba;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swipe-item_box ::v-deep .swipe-item_info_title span {
  color: #ff93a4;
}

.swipe-item_box ::v-deep .swipe-item_info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 10px;
  overflow: hidden;
}

.swipe-item_box ::v-deep .swipe-item_info_details {
  display: flex;
  margin-top: 4px;
  align-items: flex-start;
  border-bottom: 1px solid #d0d0d0;
}

.swipe-item_box ::v-deep .swipe-item_info_details .item_state {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 12px;
  transform: scale(0.8);
  white-space: nowrap;
}
.swipe-item_box ::v-deep .swipe-item_info_details .item_state > p {
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.swipe-item_box ::v-deep .swipe-item_info_details .isState {
  color: #4e51bf !important;
}

.swipe-item_box ::v-deep .swipe-item_info_details .isState span {
  background-color: #ff93a4 !important;
}

.swipe-item_box ::v-deep .swipe-item_info_details .item_state span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #a0a0a0;
  margin-right: 3px;
  flex-shrink: 0;
  margin-top: 2px;
}

.swipe-item_box ::v-deep .swipe-item_info_details .item_site {
  color: #666;
  font-size: 12px;
  transform: scale(0.8);
  white-space: nowrap;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swipe-item_box ::v-deep .swipe-item_info_details .profit {
  display: flex;
  margin-left: auto;
  align-items: center;
  color: #ff93a4;
}

.swipe-item_box ::v-deep .swipe-item_info_details .profit img {
  margin-right: 2px;
}

.swipe-item_box ::v-deep .swipe-item_info_details .item_massage {
  margin-left: auto;
  width: 80px;
  padding: 3px 0;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ------------- */
.MemberList ::v-deep .swipe-item_img_box {
  background: linear-gradient(0deg, #4e51bf, #ff93a4);
  padding: 2px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}

.MemberList ::v-deep .swipe-item_img_box1 {
  background: gray;
  padding: 2px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}

.MemberList ::v-deep .swipe-item_img {
  width: 100%;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ffe9ce;
}

.MemberList ::v-deep .swipe-item_img img {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.MemberList ::v-deep .van-cell__title {
  display: flex;
}

.MemberList ::v-deep .van-tab__text {
  /*font-size: 18px;*/
  font-weight: bold;
  display: flex;
  align-items: center;
}

.MemberList .van-swipe-item {
  padding: 1px 0;
}

.topItem {
  line-height: 1;
  /*background-color: #151d31;*/
  color:#fff;
}

.topItem .van-cell__left-icon {
  height: 46px;
  width: 46px;
  /*background-color: #fff;*/
  border-radius: 100%;
  overflow: hidden;
  padding: 2px;
}

.TaskHall_info ::v-deep .van-cell__left-icon {
  font-size: 14px;
}

.topItem .van-icon__image {
  width: 100%;
  height: 100%;
}

.topItem .van-cell__label {
  line-height: 1;
  margin-top: 0;
  color:#fff;
  font-size: 14px;
}

.topItem .profit {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 3px 8px;
  font-size: 13px;
  color: #0e1526;
}
.coinitem {
  margin-left: 10px;
  border-radius: 10px;
  padding-left: 5px;
  margin-right: 10px;
  margin-top: 5px;
  color: #333;
  .rate1{
    color: #55D3E4;
  }
  .rate2{
    color: #FF6270;
  }
}
.topItem .profit img {
  margin-right: 5px;
}
.MyHeader {
  font-size: 19px;
  border-radius: 50%;
  background: pink;
  overflow: hidden;
  width: 72px;
  height: 72px;
}
.MyHeader_box {
  padding: 2px;
  overflow: hidden;
  margin-left: 10px;
  flex-shrink: 0;
}

.header ::v-deep .Menu {
  justify-content: space-between;
  background-color: #0F1A29;
  padding: 10px 20px 10px 10px;
  width: 100%;
  border-radius: 5px;
  max-width: 450px;
  font-size: 11px;
  margin: 20px auto 0px;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.Menu ::v-deep .van-grid-item__content {
  /*background-color: #151d31;*/
  border-radius: 5px;
  color:#cccccc;
  background: transparent none repeat scroll 0% 0%;
}

.Menu ::v-deep .van-grid-item__icon-wrapper {
  margin-right: 15px;
  overflow: hidden;
  flex: 1;
}

.Title {
  /*background-color: #151d31;*/
  /*color: #fff;*/
  margin: 4px 0;
  padding: 7px 25px;
}

.popupBg {
  background: #fff;
  border-radius: 0.8rem;
}

.NoticePopup {
  background-size: contain;
  background-color: #fff;
  height: 450px;
}

.NoticePopup dd {
  font-size: 13.5px;
  overflow: inherit;
  color: #666666;
}

.popup_title {
  display: flex;
  line-height: normal;
  height: 170px;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 40px 0px 0 0;
}

.popup_title div {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 26px;
}

.popup_title div span:first-child {
  font-weight: bold;
  color: #e7e7e7;
}

.popup_title div span:last-child {
  color: #e7e7e7;
  letter-spacing: 0.34rem;
  font-size: 22px;
}

.popup_title ::v-deep .letter_s {
  letter-spacing: 0.2rem;
  font-size: 25px !important;
}

.close img {
  width: 36px;
  margin-top: 20px;
}

.van-popup {
  overflow: inherit;
  /* height: 300px; */
}

.content_footer_justify ::v-deep .van-cell__left-icon {
  margin-right: 0;
}

.content_footer_justify ::v-deep .van-cell__left-icon img {
  width: 1.1em;
  height: 1.1em;
}

.content_footer_justify_itemBgc {
  background: linear-gradient(180deg, #4e51bf, #ff93a4);
  padding: 3px;
  border-radius: 16px;
  margin-top: 15px;
}

.van-grid-item {
  padding: 0 !important;
}

.Site ::v-deep .van-nav-bar__text {
  color: #fff;
  font-size: 15px;
  position: relative;
}

.van-nav-bar__text1 ::v-deep .van-nav-bar__text {
  letter-spacing: 0.09rem;
}

.Site ::v-deep .van-nav-bar__text::after {
  content: "";
  position: absolute;
  background: url(../../public/static/images/language_after.png) no-repeat;
  background-size: 100% 100%;
  width: 9px;
  height: 5px;
  top: 9px;
  right: -14px;
}

.Site ::v-deep .van-nav-bar__right {
  padding: 0;
  padding-right: 35px;
}

.TaskHall_info ::v-deep .van-cell__left-icon img {
  width: 11px;
  height: 11px;
}

.two img {
  width: 30px;
  height: 20px !important;
}
.title{
  margin-left: 2%;
  font-size: 20px;
  color: #000;
}
</style>
