<template>
  <!-- <div class="PageBox" :style="infoData.o_status == 1 || infoData.o_status == 2 ? 'padding-bottom: 64px' : ''"> -->
  <div class="PageBox">
	  <!-- 顶部 -->
    <div class="bgc">
      <div class="topTitle">{{ $t("task.info[0]") }}</div>
    </div>
    <div class="ScrollBox" v-if="infoData">		
      <div class="task_title"
       style="margin-bottom: 10px">     
      </div>
	  
      <div class="Details task_content">        
        <dl
          style="padding: 6px 0;">
          <dt style="justify-content: flex-start;margin:55px 0 13px 0;" class="Details userinfo">
            <label>
              <img
                :src="`./static/head/${infoData.f_header}`"
                height="40"
                style="
                  border-radius: 100%;
                  vertical-align: middle;
                  margin-right: 10px;
                "
              />
            </label>
            <span>
              <p class="partedelad">
                {{ $t("task.info[9]") }}
                <span>{{ infoData.f_username }}</span>
              </p>
              <div class="lanzar">
                {{ infoData.add_time }} {{ $t("task.info[10]") }}
              </div>
            </span>
          </dt>
          <!-- 任务描述 -->
          <dd
            style="display: flex;flex-direction: column;"
            v-show="!infoData.commission_ratio" 
          >
            <p
              style="font-weight: 600;line-height: 30px;min-width:100% ;text-align: left;"
            >
              {{ $t("task.comment[0]") }}
            </p>
            <div
              style="display: flex;justify-content: space-between;width: 100%;min-height:30px;margin-bottom: 15px;flex-wrap:wrap ;"
            >
              <button
                style="min-width:70px;height:30px;background:#fff;color:#333;border:1px solid #666;outline:0;border-radius:5px;margin-top:10px;padding: 0 10px;"
                v-for="(item,index) in infoData.finish_condition" :key="index"
              >
                {{ $t("task.attestation[" + item + "]") }}
              </button>
              <!-- <button style="width:70px;height:30px;background:#4B4B4B;color:#fff;border:0;outline:0;border-radius:5px;">5</button>
							<button style="width:70px;height:30px;background:#4B4B4B;color:#fff;border:0;outline:0;border-radius:5px;">6</button> -->
            </div>
            <div
              style="display: flex;justify-content: space-between;width: 100%;min-height:30px;flex-wrap:wrap ;"
            >
              <h5
                style="font-weight: 600;line-height: 30px;min-width:60px;font-size: 14px;"
              >
                {{ $t("task.comment[1]") }}
              </h5>
              <p
                style="text-align: left;line-height: 25px;font-size: 14px;"
                id="Content_description"
              >
                {{ infoData.comment_content }}
              </p>
              <div
                style="width:100%;height:30px;display: flex;flex-direction: row-reverse;"
              >
                <van-button
                  style="width:50px;background-color:#4e51bf;border:none"
                  block
                  size="mini"
                  type="info"
                  round
                  @click="
                    $Util.CopyTexts(
                      'Content_description',
                      'Content_description'
                    )
                  "
                >
                  {{ $t("task.show[7]") }}
                </van-button>
              </div>
            </div>
          </dd>
          <dd  class="Details userinfo1"  v-if="infoData.is_fx == 1">
            
			<div class="xz">
				<label>{{ $t("task.info[20]") }}：</label>
				<a href="">https：//www.yamaxunzzz.com</a>
			</div>
            <!-- <input
              style="
                width: 100%;
                flex: 1;
                margin-right: 5px;
                border-radius: 10px;
                padding: 5px 8px;
                border: 0;
                background-color: #f5f5f5;
                color: #888;
                position: relative;
                z-index: 9;
              "
              disabled="disabled"
              :value="infoData.link_info"
            /> -->
           <!-- <input
              class="link"
              id="AppLink"
              type="text"
              readonly
              :value="infoData.link_info"
              style="position: absolute; opacity: 0"
            /> -->
            <span id="IosLink" style="position: absolute; opacity: 0">{{
              infoData.link_info
            }}</span>
            <div>
              <van-button
                style="border:1px solid #1296ff;background: none;color: #1296ff;width: 50px;
					height: 22px;
					border-radius: 7px;float: right;margin-right: 14px;"
                block
                size="mini"
                type="info"
                round
                @click="$Util.CopyText('IosLink', 'AppLink')"
              >
                {{ $t("task.show[7]") }}
              </van-button>
            </div>
          </dd>
          <dd
            style="padding-top: 0px"
            v-else
            v-show="!infoData.commission_ratio"
          >
            <input
              class="link"
              id="AppLink"
              type="text"
              readonly
              :value="infoData.link_info"
              style="position: relative; z-index: 9"
            />
            <span id="IosLink" style="position: absolute; opacity: 0">{{
              infoData.link_info
            }}</span>
            <div class="copyUrl">
              <van-button
                block
                size="mini"
                plain
                round
                @click="$Util.CopyText('IosLink', 'AppLink')"
                >{{ $t("task.info[11]") }}</van-button
              >
              <!-- <van-button block size="mini" type="info" round style="margin: 5px 0 0;" @click="$Util.OpenUrl(infoData.link_info)">{{$t('task.info[12]')}}</van-button> -->
            </div>
          </dd>
          <!-- <dd v-if="infoData.is_fx == 1">
            <div id="Promote" class="PromoteInfo">
              <img
                width="100%"
                :src="`./static/images/share-${$i18n.locale}.png`"
              />
              <div>
                <h4>{{ $t("promote[0]") }}{{ UserInfo.username }}</h4>
                <h4>{{ $t("promote[1]", { title: docTitle }) }}</h4>
                <div id="QRCode"></div>
                <h4 class="m0">
                  {{ $t("promote[2]") }}：{{ UserInfo.idcode }}
                </h4>
              </div>
            </div>
          </dd>
          <dd style="padding-top: 0" v-if="infoData.is_fx == 1">
            <van-button
              block
              type="info"
              style="font-size: 16px"
              @click="saveQRCode"
              >{{ $t("promote[10]") }}</van-button
            >
          </dd> -->
        </dl>
		
		<dl  class="Details userinfo2">
		  <dd class="nomedoPrid" v-if="Boolean(Number(infoData.commission_ratio))">
		    <label>Nome do Produto:</label>
		    <!-- <label>商品名称:</label> -->
		    <span>{{ infoData.title }}</span>
		  </dd>	
		  
		  <dd class="youfu1" v-if="Boolean(Number(infoData.commission_ratio))">
		    <label>Preço de Produto:</label>
		    <!-- <label>商品价格:</label> -->
		    <span 
		      >{{ parseFloat(infoData.reward_price).toFixed(1)
		      }}{{ currency_name }}</span
		    >
		  </dd>
		  
		  <dd class="youfu1" v-if="Boolean(Number(infoData.commission_ratio))">
		    <label>Obtenha Comissões:</label>
		    <!-- <label>获得佣金:</label> -->
		    <span>{{ infoData.commission_ratio }}</span>
		  </dd>
		  
		  <dd class="descripcio">
		    <label>{{ $t("task.info[3]") }}：{{ infoData.content }}</label>
		    
		  </dd>
		  
		  <dd v-if="infoData.o_status == 1" v-show="!infoData.commission_ratio">
		    <label>{{ $t("task.info[5]") }}：</label>
		    <van-uploader
		      v-model="exampleImg"
		      :after-read="afterRead"
		      multiple
		      :upload-icon="'photo'"
		    />
		  </dd>
		  
		  <dd v-else>
		    <label>{{ $t("task.info[5]") }}：</label>
		    <div v-if="infoData.o_examine_demo.length">
		      <van-image
		        fit="cover"
		        width="49"
		        height="49"
		        :src="`${ApiUrl}${item}`"
		        v-for="(item, index) in infoData.o_examine_demo"
		        :key="index"
		        @click="$ImagePreview([`${ApiUrl}${item}`])"
		      />
		    </div>
		    <span v-else>{{ $t("task.info[6]") }}</span>
		  </dd>
		  <!-- <dd class="add">
						<label>{{ $t('task.info[7]') }}：</label>
						<span>{{ infoData.handle_remarks }}</span>
					</dd> -->
		  <!-- 	<dd v-if="infoData.o_status != 6">
						<label>{{ $t('task.info[8]') }}：</label>
						<span>{{ infoData.handle_time }}</span>
					</dd> -->
		  
		  
		</dl>
      </div>
      <van-tabs
        class="TaskHall"
        :border="false"
        color="#0000CD"
        v-model="index_select"
        title-inactive-color="#ccc"
        line-width="60"
        style="font-weight: bold"
        v-if="!infoData.commission_ratio"
      >
        <van-tab class="active" style="background: #fff; font-weight: bold">
          <template #title>
            <div class="t1">
              <div>{{ $t("task.info[13]") }}</div>
            </div>
          </template>
          <div class="mygrid">
            <dl v-for="(item, index) in infoData.task_step" :key="index">
              <dt>
                <label>{{ $t("task.info[14]", { index: index + 1 }) }}：</label>
                <span>{{ item.describe }}</span>
              </dt>
              <dd>
                <van-image
                  fit="cover"
                  :src="`${ApiUrl}${item.img}`"
                  @click="$ImagePreview([`${ApiUrl}${item.img}`])"
                />
              </dd>
            </dl>
          </div>
        </van-tab>
        <van-tab style="background: #fff; font-weight: bold">
          <template #title>
            <div class="t2">
              <div>{{ $t("task.info[15]") }}</div>
            </div>
          </template>
          <div class="mygrid" v-if="infoData.examine_demo">
            <van-image
              fit="cover"
              :src="`${ApiUrl}${item}`"
              v-for="(item, index) in infoData.examine_demo"
              :key="index"
              @click="$ImagePreview([`${ApiUrl}${item}`])"
            />
          </div>
          <van-empty image="error" :description="$t('task.info[16]')" v-else />
        </van-tab>
      </van-tabs>
    </div>
    <van-loading class="DataLoad" size="60px" vertical v-if="isLoad">{{
      $t("task.info[17]")
    }}</van-loading>
    <!-- <div
			class="mygrid"
			style="
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 5px;
      "
			v-if="infoData.o_status == 1 || infoData.o_status == 2"
		> -->
    <!--van-button block color="#aaa" style="font-size: 16px;margin: 0 5px" @click="cancelTask">{{$t('task.info[18]')}}</van-button-->
    <!-- <van-button block type="danger" style="font-size: 16px; margin: 0 5px" @click="submitTask" v-if="infoData.o_status == 1">{{ $t('task.info[19]') }}</van-button>
		</div> -->
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import Html2Canvas from "html2canvas";
export default {
  name: "Show",
  components: {},
  props: ["taskId"],
  data() {
    return {
      isLoad: true,
      infoData: "",
      exampleImg: [],
      docTitle: document.title,
      promoteUrl: "",
      index_select: 0,
      currency_name: JSON.parse(localStorage.getItem("InitData")).setting
        .currency_name, //货币符号
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getTaskinfo();
    this.promoteUrl = `${this.InitData.setting.reg_url}/#/register/${this.UserInfo.idcode}`;
  },
  mounted() {
    // console.log(this.infoData)
    // console.log(this.taskId)
  },
  activated() {},
  destroyed() {},
  methods: {
    getTaskinfo() {
      this.$Model.TaskOrderInfo(this.taskId, (data) => {
        this.isLoad = false;
        if (data.code == 1) {
          this.infoData = data.info;
          console.log(this.infoData);
        }
        this.$nextTick(() => {
          if (data.info.is_fx == 1) {
            // console.log(this.promoteUrl)
            // 暂时不用
            // new QRCode(document.getElementById("QRCode"), {
            // 	text: this.promoteUrl,
            // 	width: 110,
            // 	height: 110,
            // 	correctLevel: QRCode.CorrectLevel.H,
            // });
          }
        });
      });
    },
    submitTask() {
      if (this.exampleImg.length) {
        const examine_demo = this.exampleImg.map((item) => item.url);
        this.$Model.SubmitTask(
          {
            order_id: this.taskId,
            examine_demo: examine_demo,
          },
          (data) => {
            if (data.code == 1) {
              this.getTaskinfo();
            }
          }
        );
      } else {
        this.$Dialog.Toast(this.$t("task.msg"));
      }
    },
    cancelTask() {
      this.$Model.SubmitTask(
        {
          order_id: this.taskId,
          status: 6,
        },
        (data) => {
          if (data.code == 1) {
            this.getTaskinfo();
          }
        }
      );
    },
    afterRead(file) {
      file.status = "uploading";
      file.message = this.$t("upload[0]");
      this.uploadImgs(file);
    },
    compressImg(file) {
      this.$Util.PushLog("clientclient ddd");
      this.$Util.CompressImg(file.file.type, file.content, 750, (image) => {
        let param = new FormData();
        param.append("token", localStorage["Token"]);
        param.append("type", 3);
        param.append("image", image, file.file.name);
        this.$Model.UploadImg(param, (data) => {
          if (data.code == 1) {
            file.message = this.$t("upload[2]");
            file.status = "success";
            file.url = data.url;
          } else {
            console.log("failedfailedfailed");
            file.status = "failed";
            file.message = this.$t("upload[3]");
          }
        });
      });
    },
    uploadImgs(file) {
      if (file.length) {
        file.forEach((item) => {
          if (!item.file.type.match(/image/)) {
            item.status = "failed";
            item.message = this.$t("upload[1]");
            return;
          }
          console.log("2");
          this.compressImg(item);
        });
      } else {
        if (!file.file.type.match(/image/)) {
          file.status = "failed";
          file.message = this.$t("upload[1]");
          return;
        }
        this.compressImg(file);
      }
    },
    saveQRCode() {
      Html2Canvas(document.getElementById("Promote")).then((canvas) => {
        if (window.plus) {
          var saveN = 0;
          var saveImg = (img) => {
            saveN += 1;
            var bm = new plus.nativeObj.Bitmap();
            bm.loadBase64Data(
              img,
              () => {
                bm.save(
                  "_doc/promote" + saveN + ".jpg",
                  {
                    overwrite: true,
                    format: "jpg",
                  },
                  (e) => {
                    plus.gallery.save(
                      e.target,
                      (e) => {
                        this.$Dialog.Toast(this.$t("promote[7]"));
                      },
                      (err) => {
                        this.$Dialog.Toast(this.$t("promote[8]"));
                      }
                    );
                  },
                  (error) => {
                    this.$Dialog.Toast(this.$t("promote[8]"));
                  }
                );
                setTimeout(function() {
                  bm.recycle();
                }, 1000);
              },
              (err) => {
                this.$Dialog.Toast(this.$t("promote[8]"));
              }
            );
          };
          this.$Dialog.Alert(this.$t("promote[11]"), () => {
            saveImg(canvas.toDataURL().replace("data:image/png;base64,", ""));
          });
        } else {
          this.downCanvas(canvas.toDataURL());
        }
      });
    },
    downCanvas(url) {
      var a = document.createElement("a");
      var event = new MouseEvent("click");
      a.download = "promote";
      a.href = url;
      a.dispatchEvent(event);
    },
  },
};
</script>
<style scoped>
.PageBox {
  font-family: Alibaba;
  background: url(../../assets/img/detail_bg.jpg) no-repeat;
}

.bgc {
  width: 100%;
  height: 46px;
  position: fixed;
  top: 0;
  z-index: 200;
}

/* 标题 */
.topTitle {
  position: absolute;
  max-width: 60%;
  white-space: normal;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; 
  width: 188px;
  height: 24px;
  color: #ffffff;
  font-family: Arial;
  font-size: 21px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

.Details {
  /* padding: 10px 10px; */
  position: relative;
  /* background: #fff; */
  z-index: 2;
  margin: auto;
  width: 327px;
  border-radius: 14px;
  /* border: solid 1px #979797; */
}




.Details > div {
/*  border-radius: 15px;
  box-shadow: 0 2px 5px #888; */
  background-color: #fff;
  /* overflow: hidden; */
  width: 100%;
  position: relative;
  z-index: 2;
}

.Details dl {  
  overflow: hidden;
}

.Details dl dt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  margin: 13px 0;
  
}

.Details dl dd {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 13px 0;
}

.Details dl dd .van-image {
  margin: 3px;
}

.task_title dl label {
  color: #fff !important;
}

.Details dl label {
  /* white-space: nowrap; */
  color: #333;
  font-family: emoji;
  font-size: 15px;
}

.task_title dl label {
  font-size: 20px;
}

.task_content dl label {
  font-size: 13.5px;
}

.task_content dl span {
  font-size: 13.5px;
}


.Details dl dd i {
  color: #4087f1;
}

.Details dl dd em {
  color: #999;
}

.Details .link {
  background-color: #f5f5f5;
  border: 0;
  flex: auto;
  margin-right: 10px;
  border-radius: 10px;
  padding: 6px 10px;
  color: #888;
  text-align: center;
  font-size: 12px;
}

.van-tabs >>> .van-tabs__content {
  overflow: hidden;
  min-height: 150px;
}

.van-tabs >>> .van-tabs__content dl {
  padding: 0 10px;
}

.van-tabs >>> .van-tabs__content dl dt {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.van-tabs >>> .van-tabs__content dl dt label {
  font-weight: 600;
}

.van-tabs >>> .van-tabs__content dl dd {
  padding: 0 0px;
  margin-bottom: 15px;
}

.task_content >>> dd span {
  padding-left: 5px;
}

.van-tabs >>> .van-tabs__content img {
  max-width: 100%;
}

.state1 {
  color: #f1c70d;
}

.state2 {
  color: #dd6161;
}

.state3,
.state4,
.state6 {
  color: #bbb;
}

.StateIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 99;
}

.van-uploader >>> .van-uploader__upload,
.van-uploader >>> .van-uploader__preview-image {
  margin: 0;
}

.van-uploader >>> .van-uploader__preview {
  margin: 0;
}

.PromoteInfo {
  position: relative;
  max-height: 410px;
  overflow: hidden;
}

.PromoteInfo > div {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  text-align: center;
  color: #fff;
}

.PromoteInfo > div h4 {
  margin-bottom: 10px;
}

#QRCode >>> img {
  margin: 0 auto 10px;
  display: block;
  border: 8px #fff solid;
}

.task_content >>> .van-uploader__upload {
  background: #e1e1f9;
  border-radius: 6px;
  overflow: hidden;
  width: 48px;
  height: 50px;
}

.task_content >>> .van-uploader__upload-icon {
  color: #5777db;
  font-size: 25px;
}
/* 白框1 */
.userinfo{
	width: 100%;
	height: 85px;	
	background: #fff;
	padding-left: 14px;
}
/* 白框1下文字 */
.partedelad{
	display: inline-block;
	width: 200px;
	height: 17px;
	-webkit-text-stroke: dashed 1px #979797;
	color: #333333;
	font-family: "PingFangSC-Regular";
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	/* text-align: right; */
	/* margin-left: 6px; */
}
.lanzar {
	display: block;
  /* width: 156px; */
  height: 15px;
  -webkit-text-stroke: dashed 1px #979797;
  color: #999999;
  font-family: "PingFangSC-Regular";
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  
}
/* 白框2 */
.userinfo1{
	width: 100%;
	height: 50px;
	border-radius: 14px;
	border: solid 1px #979797;
	background: #fff;
	padding-left: 14px;
}
/* 白框3 */
.userinfo2{
	width: 100%;
	height: 163px;
	border-radius: 14px;
	border: solid 1px #979797;
	background: #fff;
	padding-left: 14px;	
	color: #333333;
	margin-top: -5px;
;
}
.userinfo2 .youfu1{
	justify-content: space-between;
	padding-right: 14px;
}
.nomedoPrid {
  width: 209px;
  height: 19px;
  color: #333333;
  font-family: "PingFangSC-Medium";
  font-size: 19px;
  font-weight: 400;
  line-height: 19px;
}
.descripcio {
  width: 299px;
  height: 40px;
  color: #333333;
  font-family: "PingFangSC-Regular";
  font-size: 14px;
  font-weight: 400;
  /* line-height: 40px; */
 /* padding-right: 10px; */
  white-space: normal;
}

.userinfo >>> label {
  width: 55px;
  height: 55px;
  background: #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
}

.userinfo >>> label img {
  margin-right: 0 !important;
}

.copyUrl button {
  width: 60px;
  padding: 14px 0;
  color: #fff;
  background: #4e51bf;
  border-radius: 12px;
}

.copyUrl >>> .van-button__text {
  font-size: 13px;
  margin-left: -5px;
}

.TaskHall {
  padding: 0px 15px;
}

.TaskHall >>> .van-tabs__wrap {
  display: flex;
  justify-content: center;
  height: 40px;
}

.TaskHall >>> .van-tabs__nav {
  border-radius: 10px;
  padding: 0 !important;
  box-sizing: border-box;
  height: 28px;
  width: 220px;
  background-color: #4e51bf;
}

.TaskHall >>> .van-tab {
  width: 130px;
  box-sizing: border-box;
  padding: 0 !important;
  color: #fff !important;
}

.TaskHall >>> .van-tab .van-tab__text {
  width: 100%;
  height: 100%;
}

.TaskHall >>> .van-tab .van-tab__text div {
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 28px;
}

.TaskHall >>> .van-tab--active {
  border-radius: 12px;
  color: #000 !important;
}

.TaskHall >>> .van-tabs__line {
  display: none;
}

.TaskHall >>> .van-tab__text {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
}

.mygrid dl {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.mygrid {
  padding: 0px 10px;
}

.mygrid div {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  box-shadow: none;
}

.mygrid dt {
  font-size: 12px;
  font-weight: 500;
}

.TaskHall >>> .van-tab--active .t1 {
  background-image: linear-gradient(to right, #ff93a4, #4e51bf);
  border-radius: 10px;
  padding: 2px;
  font-size: 12px;
  font-weight: 600;
}

.TaskHall >>> .van-tab--active .t1 div {
  background: #fff;
  border-radius: 8px;
  line-height: 24px;
}

.TaskHall >>> .van-tab--active .t2 {
  background-image: linear-gradient(to right, #4e51bf, #ff93a4);
  border-radius: 10px;
  padding: 2px;
  font-weight: 600;
  font-size: 12px;
}

.TaskHall >>> .van-tab--active .t2 div {
  background: #fff;
  border-radius: 8px;
}
</style>
