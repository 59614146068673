<template>
  <div class="PageBox">
    <div class="bgc">
      <div class="topTitle" v-if="rechargeList[0]">
        {{ rechargeList[0].currencies[0] }}{{ $t("CoinRecharge[0]") }}
      </div>
    </div>
    <div class="box">
      <p class="balance">
        {{ $t("CoinRecharge[1]") }}:<span>{{ price }}</span>
      </p>
      <p class="platform">{{ $t("CoinRecharge[2]") }}</p>
      <a class="toRegister" @click="$router.push('/register')">{{
        $t("CoinRecharge[3]")
      }}</a>
      <div class="selectBox">
        <div class="select" v-if="rechargeList[active]">
          <p
            :class="{ active: active == index }"
            v-for="(item, index) in rechargeList"
            :key="index"
            @click="upActive(index)"
          >
            {{ item.name }}
          </p>
        </div>
      </div>
      <div class="selectAmount">
        <p class="amount">{{ $t("CoinRecharge[7]") }}</p>
        <div class="select" v-if="rechargeList[active]">
          <p
            :class="{ active: priceActive == index }"
            v-for="(item, index) in rechargeList[active].fixed"
            :key="index"
            @click="upPriceActive(index)"
          >
            {{ item }}
          </p>
        </div>
      </div>
      <div class="QRBox">
        <p class="QR-title">{{ $t("CoinRecharge[8]") }}</p>
        <div class="upload">
          <!--					<div class="QRCode">-->
          <!--						<img v-if="rechargeList[active]" :src="rechargeList[active].accounts[0].qrcode+''" />-->
          <!--					</div>-->
          <div class="infoBox">
            <!-- 我绑定的充值渠道 -->
            <!-- <p class="address">{{$t('CoinRecharge.default[9]')}}</p>
						<textarea id="inputBox" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"></textarea>
						<p class="code" v-if="rechargeList[active]"><span>{{rechargeList[active].accounts[0].account}}</span><span class="copy" @click="copyCode(0)">{{$t('CoinRecharge.default[11]')}}</span></p> -->
            <!-- <p class="recharge">
							<span>{{$t('CoinRecharge.default[23]')}}：{{upiData.pay_name}}</span>
							<span>{{$t('CoinRecharge.default[24]')}}：{{upiData.pay_mobile}}</span>
							<span>{{$t('CoinRecharge.default[25]')}}：{{upiData.pay_email}}</span>
							<span>{{$t('CoinRecharge.default[12]')}}<span class="price" v-if="rechargeList[active]">{{rechargeList[active].fixed[priceActive]}}.00</span></span>
						</p> -->
            <van-cell-group>
              <van-field
                :disabled="myUserInputClick.name ? true : false"
                v-model="myUser.name"
                :label="$t('CoinRecharge[23]')"
                :placeholder="$t('placeholder[0]')"
              />
            </van-cell-group>
            <van-cell-group>
              <van-field
                :disabled="myUserInputClick.phone ? true : false"
                v-model="myUser.phone"
                :label="$t('CoinRecharge[24]')"
                :placeholder="$t('placeholder[1]')"
              />
            </van-cell-group>
            <van-cell-group>
              <van-field
                :disabled="myUserInputClick.email ? true : false"
                v-model="myUser.email"
                :label="$t('CoinRecharge[25]')"
                :placeholder="$t('placeholder[2]')"
              />
            </van-cell-group>
            <van-cell-group>
              <van-field
                :disabled="myUserInputClick.money ? true : false"
                v-model="myUser.money"
                :label="$t('CoinRecharge[12]')"
                :placeholder="$t('placeholder[3]')"
              />
            </van-cell-group>
            <!-- <van-cell-group>
						  <van-field v-model="myUser.email" label="文本" placeholder="请输入邮箱" />
						</van-cell-group> -->
            <!--						<p class="order">{{$t('CoinRecharge.default[13]')}}<span>2021020914521635113</span></p>-->
            <!--						<p class="account">{{$t('CoinRecharge.default[14]')}}-->
            <!--							<input class="serialNumberIpt" v-model="serialNumber">-->
            <!--						</p>-->
          </div>
          <p class="btn" @click="submitUrl()">{{ $t("CoinRecharge[15]") }}</p>
        </div>
      </div>
    </div>
    <div class="description">
      <p class="title">{{ $t("CoinRecharge[16]") }}</p>
      <p class="p">1.{{ $t("CoinRecharge[17]") }}</p>
      <p class="p">2.{{ $t("CoinRecharge[18]") }}</p>
      <p class="p">3.{{ $t("CoinRecharge[19]") }}</p>
      <p class="p">4.{{ $t("CoinRecharge[20]") }}</p>
      <p class="p">5.{{ $t("CoinRecharge[21]") }}</p>
    </div>
    <div class="popUpWindows" v-show="popUpWindows">
      <div class="title">{{ $t("wallet.default[8]") }}</div>
      <div class="content">{{ $t("wallet.default[9]") }}</div>
      <div class="btn">
        <p class="cancel" @click="(popUpWindows = false), $router.go(-1)">
          {{ $t("wallet.default[11]") }}
        </p>
        <p class="go" @click="$router.push('/user/bindcoin')">
          {{ $t("wallet.default[10]") }}
        </p>
      </div>
    </div>
    <div
      class="masking"
      @click="(popUpWindows = false), $router.go(-1)"
      v-show="popUpWindows"
    ></div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import axios from "axios";
import { Toast } from "vant";
export default {
  name: "Wallet",
  components: {},
  props: ["walletType"],
  data() {
    return {
      listData: "",
      isFinished: false,
      isRefresh: false,
      pageNo: 1,
      tabsState: 1,
      tabsIndex: 0,
      taskTabs: [
        {
          type: 1,
          text: this.$t("wallet.default[3]"),
        },
        // {type: 2, text: '转账记录'},
        {
          type: 3,
          text: this.$t("wallet.default[4]"),
        },
      ],
      showTurn: false,
      showDraw: false,
      showPicker: false,
      selectBank: "",
      isSubmit: false,
      turnData: {
        username: "",
        turn_money: "",
        drawword: "",
      },
      drawData: {
        draw_type: "bank",
        user_bank_id: "",
        draw_money: 100,
        // phone: '',
        email: "",
        // ifsc: '',
        drawword: "",
      },
      popUpWindows: false,
      price: 0, //价格，金额
      rechargeList: [], //交易信息
      upiData: {}, //我的信息
      active: 0, //点击的哪个充值平台
      priceActive: 0, //点击的哪个充值金额
      serialNumber: null, //流水号
      accounts: {}, //信息
      myUser: {
        //我的信息
        name: "",
        phone: "",
        email: "",
        money: "0.00",
      },
      myUserInputClick: {}, //判断我的信息的输入框是否禁用
      flag: true, //判断充值只能点一次
    };
  },
  computed: {
    cardList() {
      let data = this.$store.state.BankCardList.map(
        (item) =>
          item.bank_name +
          " " +
          item.card_no.replace(/^(.{4}).*(.{4})$/, "$1 **** $2")
      );
      // if(this.UserInfo.alipay){
      //   data.unshift(this.$t('wallet.default[7]')+this.UserInfo.alipay.replace(/^(.{4}).*(.{4})$/, '$1 **** $2'))
      // }
      return data;
    },
  },
  watch: {},
  created() {
    this.price = this.$route.query.amount;
    // this.$Model.GetBankCardList()
    this.$parent.navBarTitle = "";
    this.GetRechargeType();
    // console.log(this.myUser)
  },
  mounted() {
    // this.getQRUrl();
  },
  activated() {},
  destroyed() {},
  methods: {
    //复制码和金额
    copyCode(i) {
      var copyBox = document.getElementById("inputBox");
      if (i == 0) {
        copyBox.value = this.rechargeList[this.active].accounts[0].account;
      } else {
        copyBox.value = this.rechargeList[this.active].fixed[this.priceActive];
      }
      copyBox.select();
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$Dialog.Toast(this.$t("Copy success"));
    },
    //  //二维码
    //  getQRUrl(qrCode){
    // new QRCode(document.getElementById("QRCode"), {
    //   text: this.accounts.promoteUrl,
    //   width: 130,
    //   height: 130,
    //   correctLevel : QRCode.CorrectLevel.H
    // });
    //  },
    //支付信息
    GetRechargeType() {
      this.$Model.GetRechargeType((data) => {
        console.log(data);
        //字符串转数组
        data.info.forEach((item) => {
          item.fixed = item.fixed.split(",");
        });
        var infos = [];
        var usertypeids = this.$store.state.BankCardList.map(
          (item) => item.typeid
        );
        var eee = this.$store.state.BankCardList.map((item) => {
          a: 33;
        });
        if (data.info) {
          for (var i = 0; i < data.info.length; i++) {
            if (
              data.info[i]["state"] == 1 ||
              (this.UserInfo.is_inner && data.info[i]["state"] == 3)
            ) {
              if (usertypeids.includes(data.info[i].id)) {
                infos.push(data.info[i]);
              }
            }
          }
        }
        // 提示是否绑定银行卡
        // if(infos.length==0){
        // 	this.popUpWindows=true
        // 	return
        // }
        // 我绑定的充值渠道
        // this.rechargeList = infos
        // 所有充值渠道
        this.rechargeList = data.info;
        // 我绑定的充值渠道下的我的银行卡信息
        // this.accounts = infos[0].accounts[0]
        // console.log(this.accounts.account)
        // this.getQRUrl(this.accounts.qrcode)
        // 我的信息
        this.upiData = data.user;
        this.myUser.name = this.upiData.pay_name;
        this.myUser.email = this.upiData.pay_email;
        this.myUser.phone = this.upiData.pay_mobile;
        this.myUser.money = `${
          this.rechargeList[this.active].fixed[this.priceActive]
        }.00`;
        this.myUserInputClick.name = this.upiData.pay_name;
        this.myUserInputClick.email = this.upiData.pay_email;
        this.myUserInputClick.phone = this.upiData.pay_mobile;
        this.myUserInputClick.money = true;
      });
    },
    //修改充值平台active
    upActive(index) {
      this.active = index;
      this.priceActive = 0;
    },
    //修改充值金额active
    upPriceActive(index) {
      this.priceActive = index;
      this.myUser.money = `${
        this.rechargeList[this.active].fixed[this.priceActive]
      }.00`;
    },
    //提交
    submitUrl() {
      // 我的银行卡信息
      // let account = this.rechargeList[this.active].accounts[0].account;
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      if (this.flag) {
        // console.log(1)
        this.flag = false;
        let json = {
          typeid: this.rechargeList[this.active].id,
          pay_name: this.upiData.pay_name, //姓名
          pay_mobile: this.upiData.pay_mobile, //电话
          pay_email: this.upiData.pay_email, //邮箱
          busi_code: 100601, //只针对巴西
          currency: this.rechargeList[this.active].currencies[0],
          uid: this.UserInfo.userid,
        };
        //判断是否输入用户名
        if (!this.myUser.name) {
          this.$Dialog.Toast(this.$t("CoinRecharge.placeholder[0]"), "center");
          return false;
        }
        //判断是否输入手机号
        if (!this.myUser.phone) {
          this.$Dialog.Toast(this.$t("CoinRecharge.placeholder[1]"), "center");
          return false;
        }
        //判断是否输入邮箱
        if (!this.myUser.email) {
          this.$Dialog.Toast(this.$t("CoinRecharge.placeholder[2]"), "center");
          return false;
        }
        // 直接跳转页面充值
        let goUrl = `https://happyjob.online/pay/gateway/index?uid=${
          this.UserInfo.userid
        }
							&price=${this.myUser.money}&typeid=${
          this.rechargeList[this.active].id
        }&pay_email=${this.myUser.email}
							&pay_mobile=${this.myUser.phone}&pay_name=${this.myUser.name}&busi_code=100601`;
        window.location.href = goUrl;
        // 充值的请求接口
        // let url = this.rechargeList[this.active].submitUrl
        // this.$Model.SubmitUrl(url, json, (data) => {
        // 	console.log(data)
        // 	this.$Dialog.Toast(this.$t('success'));
        // })
      }
    },
  },
};
</script>

<style scoped>
/* .van-nav-bar--fixed,.Site .van-nav-bar{
	background-color: #4e51bf;
} */
.PageBox {
  overflow: auto;
}

.PageBox .bgc {
  width: 100%;
  background-color: #4e51bf;
  height: 46px;
  position: fixed;
  top: 0;
  z-index: 200;
}

.topTitle {
  position: absolute;
  max-width: 60%;
  white-space: nowrap;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.PageBox .box {
  padding: 0 19px;
  font-size: 19px;
  margin-top: 15px;
  color: #555;
}

.box .balance {
  color: #000;
  font-size: 20px;
  margin-bottom: 15px;
}

.box .balance span {
  color: #4e51bf;
  padding-left: 5px;
}

.box .toRegister {
  font-size: 14px;
  color: #a0a0a0;
  text-transform: uppercase;
  border-bottom: 1px dashed #4e51bf;
}

.box .selectBox {
  border-bottom: 1px dashed #4e51bf;
}

.selectBox .select {
  /* width:; */
  max-width: 365px;
  display: flex;
  margin: 8px auto;
  flex-wrap: wrap;
}

.selectBox .select p {
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 16px;
  background-color: #dcf8ff;
  border: 1px solid #4e51bf;
  color: #4e51bf;
  margin: 8px 25px 12px 0;
}
.selectBox .select p:last-child {
  margin-right: 0px;
}
p.active {
  background-color: #4e51bf !important;
  color: #fff !important;
}

.selectAmount .amount {
  margin-top: 20px;
}

.selectAmount .select {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 8px auto;
  flex-wrap: wrap;
}

.selectAmount .select p {
  padding: 8px 13px;
  border-radius: 4px;
  font-size: 16px;
  background-color: #dcf8ff;
  border: 1px solid #4e51bf;
  color: #4e51bf;
  margin-top: 10px;
}

.informationBox {
  border-bottom: 1px dashed #c9c9c9;
}

.information-title {
  margin: 40px 0 34px 0;
}

.information {
  display: flex;
  font-size: 18px;
  align-items: center;
  margin-bottom: 26px;
}

.information input {
  flex: 1;
  height: 40px;
}

.QRBox .QR-title {
  margin-top: 20px;
}

.QRBox .upload {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin: 15px 0 0px 0;
}

.QRBox .upload .QRCode {
  width: 130px;
  height: 130px;
}

.QRBox .upload .QRCode img {
  width: 100%;
  height: 100%;
}

.QRBox .upload .imgBox {
  border: 1px solid #c9c9c9;
  padding: 30px;
}

.QRBox .infoBox {
  font-size: 18px;
}

.QRBox .infoBox .address {
  margin-top: 30px;
}

.QRBox .infoBox .code {
  margin-top: 2px;
  color: #4e51bf;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.QRBox .infoBox .code > span:first-child {
  flex: 1;
  word-break: break-all;
}

.QRBox .infoBox .copy {
  display: inline-block;
  padding: 8px 10px;
  border-radius: 6px;
  margin-left: 20px;
  color: #fff;
  background-color: #4e51bf;
}

.QRBox .infoBox .recharge {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.QRBox .infoBox .recharge .price {
  padding-left: 10px;
}

.QRBox .infoBox .recharge .copy {
  display: inline-block;
  padding: 8px 10px;
  border-radius: 6px;
  margin-left: 20px;
  color: #fff;
  background-color: #4e51bf;
}

.QRBox .infoBox .order {
  margin: 20px 0 30px 0;
}

.QRBox .infoBox .account {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.QRBox .infoBox .account input {
  width: 150px;
  height: 40px;
  border-radius: 4px;
  background-color: #dcf8ff;
  border: 1px solid #4e51bf;
  padding-left: 10px;
  font-size: 20px;
}

.QRBox .upload .btn {
  padding: 10px 30px;
  border-radius: 6px;
  background-color: #4e51bf;
  color: #fff;
  text-align: center;
  margin: 41px 0 70px 0;
}

.description {
  font-size: 20px;
  color: #555;
  background-color: #f3f3f3;
  padding: 40px 19px;
}

.description p {
  color: #666;
  font-size: 18px;
  margin-bottom: 8px;
}

.description .title {
  color: #555;
  font-size: 20px;
  margin-bottom: 30px;
}

.van-loading {
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popUpWindows {
  width: 50%;
  min-width: 280px;
  max-width: 400px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  border-radius: 4px;
  background-color: #fff;
}
.popUpWindows .warmHint {
  display: block;
  width: 50px;
  height: 50px;
  margin: 30px auto 0 auto;
}
.popUpWindows .title {
  text-align: center;
  color: #4e51bf;
  font-size: 22px;
  line-height: 60px;
}
.popUpWindows .content {
  width: 100%;
  padding: 15px 20px 30px 20px;
  color: #000;
  text-align: center;
}
.popUpWindows .btn {
  display: flex;
  padding: 30px 20% 30px 20%;
  justify-content: space-between;
  color: #fff;
}
.popUpWindows .btn > p {
  line-height: 30px;
  text-align: center;
  background-color: rgb(211, 211, 211);
  width: 44%;
  border-radius: 4px;
}
.popUpWindows .btn > p:nth-child(2) {
  background-color: rgb(128, 128, 255);
}
.masking {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(90, 90, 90, 0.6);
  z-index: 3;
}
.infoBox >>> .van-cell-group::after {
  content: none;
}
.infoBox >>> .van-cell-group .van-field {
  border-bottom: 1px solid #4e51bf;
}
</style>
