(function(a, b, c, d, e, j, s) {
    a[d] = a[d] || function() {
        (a[d].a = a[d].a || []).push(arguments)
    };
    j = b.createElement(c),
        s = b.getElementsByTagName(c)[0];
    j.async = true;
    j.charset = 'UTF-8';
    j.src = 'https://static.meiqia.com/dist/meiqia.js';
    s.parentNode.insertBefore(j, s);
})(window, document, 'script', '_MEIQIA');
_MEIQIA('entId', '6d93b8a46e3beb18911c95f4b4fb7c1d');
_MEIQIA('manualInit');
_MEIQIA('withoutBtn');
_MEIQIA('init');
_MEIQIA('allSet', function () {
    var lang = localStorage['Language'] || 'vi';
    if (lang=='vn'){
        lang = 'vi';
    }else {
        lang = 'en';
    }
    _MEIQIA('language',lang)
    console.log("all done");
});
