<template>
	<div class="Site IndexBox">
		<div class="ScrollBox">
			<div class="bgimg">
				<div class="bgc" :class="[bgColor ? 'bgColor' : '']">
					<div class="topTitle">{{ $t("vip.user.vip_title") }}</div>
				</div>
				<!-- <van-swipe :autoplay="3000" indicator-color="#888" style="height: 10rem">
        <van-swipe-item v-for="(item,index) in InitData.bannerList" :key="index">
          <img :src="item" width="100%">
        </van-swipe-item>
      </van-swipe> -->
				<van-cell-group ref="content" :border="false" style="margin-top: 280px;padding: 20px 30px 10px;">
					<!-- <van-cell class="my myUserInfo" v-if="UserInfo">
						<template #title>
							<div class="top" style="font-size: 16px;text-align: start;margin-left: 6%;margin-top: -4px;">
								{{$t('vip.user.title[0]')}}:{{UserInfo.useridentity}}
							</div>
							<div class="bottom">
								<span v-html="$t('vip.list.label',{number: UserInfo.number})"></span>
								<span style="font-size: 14px">
								</span>
							</div>
						</template>
					</van-cell> -->
					<!-- 暂不显示VIP相关
					<van-cell class="my">
						<template #title>
							<div class="top">
								{{$t('vip.user.title[0]')}}:{{UserInfo?UserInfo.useridentity:$t('vip.user.title[1]')}}
							</div>
							<div class="bottom">
								<span style="color: #E4C7A1; font-size: 16px;">{{$t('vip.user.label')}}：{{UserInfo?UserInfo.number:'--'}}</span>
								<span>
									<router-link style="font-size: 12px;width: 76px;padding: 5px 0;border: 2px solid #F9E9AA;display: block;text-align: center;border-radius: 10px;line-height: 10px;"
												 to="/user/memberQuestions">{{$t('vip.user.title[2]')}}</router-link>
								</span>
							</div>
							<div v-if="!UserInfo">
								<router-link style="font-size: 17px;font-family: Alibaba;width: 130px;margin: 13px auto 0;padding: 7px 0;background: linear-gradient(45deg, #D0BD8C, #FEE6B6, #E1C67F, #ECCB7C);display: block;text-align: center;border-radius: 20px;color: #000;"
											 to="/login">{{$t('vip.user.title[3]')}}</router-link>
							</div>
						</template>
					</van-cell> -->
				</van-cell-group>
				<!-- 暂不显示VIP相关
				<div class="swiper_box">
					<div class="swiper">
						<van-swipe style="height: 87px" :show-indicators="false" vertical :autoplay="3000" v-if="flowList.length>1">
							<van-swipe-item v-for="(item,index) in flowList" style="height: 87px;">
								<div class="swiper_item_head">
									<img :src="ApiUrl+item.image" />
								</div>
								<p>{{$t('vip.user.swiper',{username:item.username,level_name:item.level_name,money:item.money})}}</p>
							</van-swipe-item>
						</van-swipe>

					</div>
				</div> -->
				</van-grid>
				<div class="tree_box">
					<div class="tree" v-if="ruleList.length>1"  v-for="item,index in ruleList">
						<!-- <div class="vip0" style="padding-top:0px;" v-if="ruleList[0]">
							<div class="vip0_left_box" @click="openTaskList(ruleList[0].amount,ruleList[0].grade,ruleList[0].name)">
								<div class="vip0_left">LV0</div>
							</div>
							<div class="vip0_right_box">
								<div class="vip0_title">
									<p>{{$t('vip.vipList.vip0[0]')}}</p>
									<p>{{$t('vip.vipList.vip0[1]',{money:(ruleList[0].experience*1).toFixed(1)})}}</p>
								</div>
								<div class="vip0_money">{{$t('vip.vipList.cashPledge',{money:ruleList[1].amount})}}</div>
							</div>
						</div> -->
						<!-- <div class="vip0" v-for="(item,index) in ruleList" :class="[UserInfo.vip_level==2?'clearAfter':'']" v-if="ruleList.length>1||UserInfo.vip_level-1>0">
							<div :class="index%2==0?'vip1':'vip5'" @click="openTaskList(ruleList[index].amount,ruleList[index].grade,ruleList[index].name)">
								<div :class="index%2==0?'vip0_left vip2_left':'vip0_left vip3_left'"><img src="../../public/static/images/vip_vip2Img.png" />{{item.name}}</div>
							</div>
							<div class="vip0_right_box">
								<div class="vip0_title" v-if="index==0" :class="index%2==0?'right':'left'">
									<p>{{$t('vip.vipList.vip0[0]')}}</p>
									<p>{{$t('vip.vipList.vip0[1]',{money:(ruleList[0].experience*1).toFixed(1)})}}</p>
								</div>
								<div class="vip0_title" v-else :class="index%2==0?'right':'left'">
									<p>{{$t('vip.vipList.vipCount[0]')}}{{ruleList[index].number}}</p>
									<p>{{$t('vip.vipList.vipCount[1]')}}{{ruleList[index].price}}</p>
									<p>{{$t('vip.vipList.vipCount[2]')}}{{ruleList[index].money}}</p>
									<p>{{$t('vip.vipList.vipCount[3]')}}{{ruleList[index].month}}</p>
									<p>{{$t('vip.vipList.vipCount[4]')}}{{ruleList[index].year}}</p>
								</div>
								<div class="vip0_money" :class="index%2==0?'right':'left'">{{$t('vip.vipList.cashPledge',{money:ruleList[index].amount})}}</div>
							</div>
						</div> -->
						<!-- 左边 &&item.grade<11 -->
						<div v-if="index==1" style="display:flex;width: 60%;margin-left: 150px;margin-top: -50px;margin-bottom: 15px">
							<van-field v-model="reAmount"></van-field>
							<van-button plain square type="info" style="width: 70px" @click="balanceRecharge">
								{{$t('wallet.default[1]')}}
							</van-button>
						</div>
						<div :class="index%2==0?'vip0':'vip1 vip3'" v-if="index%2==0&&item.grade<5">
							<div :class="index%2==0?(index==0?'vip0_left_box vip00':'vip0_left_box'):'vip1_left_box'" @click="openTaskList(ruleList[index].amount,ruleList[index].grade,ruleList[index].name)">
								<div :class="index%2==0?'vip0_left vip2_left':'vip1_left vip3_left'" style="width:100%;text-align: center;"><img :src="imgList[index]" v-if="index!=0"/>{{item.name}}</div>
							</div>
							<div class="vip0_titles" v-if="index==0">
								<p>{{$t('vip.vipList.vip0[0]')}}</p>
								<p>{{$t('vip.vipList.vip0[1]',{money:(ruleList[0].experience*1).toFixed(1)})}}</p>
							</div>
							<div :class="index%2==0?'vip0_right_box':'vip1_right_box'" v-else>
								<div :class="index%2==0?'vip0_money':'vip1_money'" >{{$t('vip.vipList.cashPledge',{money:ruleList[index].amount})}}</div>
								<div :class="index%2==0?'vip0_title':'vip1_title'">
									<p>{{$t('vip.vipList.vipCount[0]')}}{{ruleList[index].number}}</p>
									<p>{{$t('vip.vipList.vipCount[1]')}}{{ruleList[index].price}}</p>
									<p>{{$t('vip.vipList.vipCount[2]')}}{{ruleList[index].money}}</p>
									<p>{{$t('vip.vipList.vipCount[3]')}}{{ruleList[index].month}}</p>
									<p>{{$t('vip.vipList.vipCount[4]')}}{{ruleList[index].year}}</p>
								</div>
							</div>
						</div>
						<!--右边 -->
						<div :class="index%2==0?'vip0':'vip1 vip3'" v-else-if="index%2==1&&item.grade<6">
							<div class="vip0_titles" v-if="index==0">
								<p>{{$t('vip.vipList.vip0[0]')}}</p>
								<p>{{$t('vip.vipList.vip0[1]',{money:(ruleList[0].experience*1).toFixed(1)})}}</p>
							</div>
							<div :class="index%2==0?'vip0_right_box':'vip1_right_box'" v-else>
								<div :class="index%2==0?'vip0_money':'vip1_money'" >{{$t('vip.vipList.cashPledge',{money:ruleList[index].amount})}}</div>
								<div :class="index%2==0?'vip0_title':'vip1_title'">
									<p>{{$t('vip.vipList.vipCount[0]')}}{{ruleList[index].number}}</p>
									<p>{{$t('vip.vipList.vipCount[1]')}}{{ruleList[index].price}}</p>
									<p>{{$t('vip.vipList.vipCount[2]')}}{{ruleList[index].money}}</p>
									<p>{{$t('vip.vipList.vipCount[3]')}}{{ruleList[index].month}}</p>
									<p>{{$t('vip.vipList.vipCount[4]')}}{{ruleList[index].year}}</p>
								</div>
							</div>
							<div :class="index%2==0?'vip0_left_box':'vip1_left_box'" @click="openTaskList(ruleList[index].amount,ruleList[index].grade,ruleList[index].name)">
								<div :class="index%2==0?'vip0_left vip2_left':'vip1_left vip3_left'">{{item.name}}<img :src="imgList[index]" /></div>
							</div>
						</div>

						<!-- <div class="vip1" :class="[UserInfo.vip_level==2?'clearAfter':'']" v-if="ruleList.length>1||UserInfo.vip_level-1>0">
							<div class="vip1_right_box">
								<div class="vip1_title">
									<p>{{$t('vip.vipList.vipCount[0]')}}{{ruleList[0].number}}</p>
									<p>{{$t('vip.vipList.vipCount[1]')}}{{ruleList[0].price}}</p>
									<p>{{$t('vip.vipList.vipCount[2]')}}{{ruleList[0].money}}</p>
									<p>{{$t('vip.vipList.vipCount[3]')}}{{ruleList[0].month}}</p>
									<p>{{$t('vip.vipList.vipCount[4]')}}{{ruleList[0].year}}</p>
								</div>
								<div class="vip1_money" v-if="UserInfo.vip_level-1==2">{{$t('vip.vipList.cashPledge',{money:ruleList[2].amount})}}</div> -->
						<!-- <div class="vip1_money">{{$t('vip.vipList.cashPledge',{money:ruleList[2].amount})}}</div> -->
						<!-- </div> -->
						<!-- <div class="vip1_left_box" @click="openTaskList(ruleList[1].amount,ruleList[1].grade,ruleList[1].name)"> -->
						<!-- <div class="vip1_left">LV1<img class="lv1" src="../../public/static/images/vip_vip1Img.png" /></div> -->
						<!-- </div> -->
						<!-- </div> -->
						<!-- <div class="vip0" :class="[UserInfo.vip_level==3?'clearAfter':'']" v-if="ruleList.length>2&&UserInfo.vip_level-1>1">
							<div class="vip0_left_box" @click="openTaskList(ruleList[2].amount,ruleList[2].grade,ruleList[2].name)">
								<div class="vip0_left vip2_left"><img src="../../public/static/images/vip_vip2Img.png" />LV2</div>
							</div>
							<div class="vip0_right_box">
								<div class="vip0_title">
									<p>{{$t('vip.vipList.vipCount[0]')}}{{ruleList[1].number}}</p>
									<p>{{$t('vip.vipList.vipCount[1]')}}{{ruleList[1].price}}</p>
									<p>{{$t('vip.vipList.vipCount[2]')}}{{ruleList[1].money}}</p>
									<p>{{$t('vip.vipList.vipCount[3]')}}{{ruleList[1].month}}</p>
									<p>{{$t('vip.vipList.vipCount[4]')}}{{ruleList[1].year}}</p>
								</div>
								<div class="vip0_money" v-if="UserInfo.vip_level-1==3">{{$t('vip.vipList.cashPledge',{money:ruleList[3].amount})}}</div>
							</div>
						</div> -->

						<!-- <div class="vip1 vip3" :class="[UserInfo.vip_level==4?'clearAfter':'']" v-if="ruleList.length>2"> -->
						<!-- <div class="vip1_right_box"> -->
						<!-- <div class="vip1_title">
                            <p>{{$t('vip.vipList.vipCount[0]')}}{{ruleList[2].number}}</p>
                            <p>{{$t('vip.vipList.vipCount[1]')}}{{ruleList[2].price}}</p>
                            <p>{{$t('vip.vipList.vipCount[2]')}}{{ruleList[2].money}}</p>
                            <p>{{$t('vip.vipList.vipCount[3]')}}{{ruleList[2].month}}</p>
                            <p>{{$t('vip.vipList.vipCount[4]')}}{{ruleList[2].year}}</p>
                        </div> -->
						<!-- <div class="vip1_money" v-if="UserInfo.vip_level-1==4">{{$t('vip.vipList.cashPledge',{money:ruleList[4].amount})}}</div> -->
						<!-- <div class="vip1_money">{{$t('vip.vipList.cashPledge',{money:ruleList[3].amount})}}</div> -->
						<!-- </div> -->
						<!-- <div class="vip1_left_box" @click="openTaskList(ruleList[2].amount,ruleList[2].grade,ruleList[2].name)">
                            <div class="vip1_left vip3_left">LV2<img src="../../public/static/images/vip_vip3Img.png" /></div>
                        </div> -->
						<!-- </div> -->


						<!-- 	<div class="vip1 vip3" :class="[UserInfo.vip_level==4?'clearAfter':'']" v-if="ruleList.length>3">
                                <div class="vip1_right_box">
                                    <div class="vip1_title">
                                        <p>{{$t('vip.vipList.vipCount[0]')}}{{ruleList[3].number}}</p>
                                        <p>{{$t('vip.vipList.vipCount[1]')}}{{ruleList[3].price}}</p>
                                        <p>{{$t('vip.vipList.vipCount[2]')}}{{ruleList[3].money}}</p>
                                        <p>{{$t('vip.vipList.vipCount[3]')}}{{ruleList[3].month}}</p>
                                        <p>{{$t('vip.vipList.vipCount[4]')}}{{ruleList[3].year}}</p>
                                    </div>
                                    <div :class="index%2==0?'vip1_money':''" v-if="UserInfo.vip_level-1==4">{{$t('vip.vipList.cashPledge',{money:ruleList[4].amount})}}</div>
                                </div>
                                <div class="vip1_left_box" @click="openTaskList(ruleList[3].amount,ruleList[3].grade,ruleList[3].name)">
                                    <div class="vip1_left vip3_left">LV3<img src="../../public/static/images/vip_vip3Img.png" /></div>
                                </div>
                            </div> -->
						<!-- <div class="vip0 vip4" :class="[UserInfo.vip_level==5?'clearAfter':'']" v-if="ruleList.length>4">
							<div class="vip0_left_box" @click="openTaskList(ruleList[4].amount,ruleList[4].grade,ruleList[4].name)">
								<div class="vip0_left vip4_left"><img src="../../public/static/images/vip_vip4Img.png" />LV4</div>
							</div>
							<div class="vip0_right_box">
								<div class="vip0_title">
									<p>{{$t('vip.vipList.vipCount[0]')}}{{ruleList[4].number}}</p>
									<p>{{$t('vip.vipList.vipCount[1]')}}{{ruleList[4].price}}</p>
									<p>{{$t('vip.vipList.vipCount[2]')}}{{ruleList[4].money}}</p>
									<p>{{$t('vip.vipList.vipCount[3]')}}{{ruleList[4].month}}</p>
									<p>{{$t('vip.vipList.vipCount[4]')}}{{ruleList[4].year}}</p>
								</div>
								<div class="vip0_money" v-if="UserInfo.vip_level-1==5">{{$t('vip.vipList.cashPledge',{money:ruleList[5].amount})}}</div>
							</div>
						</div> -->
						<!-- <div class="vip1 vip5" :class="[UserInfo.vip_level==6?'clearAfter':'']" v-if="ruleList.length>5">
							<div class="vip1_right_box">
								<div class="vip1_title">
									<p>{{$t('vip.vipList.vipCount[0]')}}{{ruleList[5].number}}</p>
									<p>{{$t('vip.vipList.vipCount[1]')}}{{ruleList[5].price}}</p>
									<p>{{$t('vip.vipList.vipCount[2]')}}{{ruleList[5].money}}</p>
									<p>{{$t('vip.vipList.vipCount[3]')}}{{ruleList[5].month}}</p>
									<p>{{$t('vip.vipList.vipCount[4]')}}{{ruleList[5].year}}</p>
								</div>
							</div>
							<div class="vip1_left_box" @click="openTaskList(ruleList[5].amount,ruleList[5].grade,ruleList[5].name)">
							</div>
						</div> -->
					</div>
				</div>
				<div class="query_box_padding">
					<div class="query_box">
						<div class="query_box_title">{{$t('vip.user.title[4]')}}</div>

						<div class="query_content">
							<template v-for="(item,index) in $t('vip.dialogue.user')">
								<!-- 用户对话 -->
								<div class="left_item">
									<div class="img_box">
										<!-- <img /> -->
									</div>
									<div class="left_item_content">{{$t(`vip.dialogue.user[${index}]`)}}</div>
								</div>
								<!-- 管理员对话 -->
								<div class="right_item">
									<div class="right_item_content">{{$t(`vip.dialogue.admin[${index}]`)}}</div>
									<div class="img_box">
										<!-- <img /> -->
									</div>
								</div>
							</template>
						</div>
						<div class="more" @click="goMemberQuestions()">{{$t('vip.user.title[5]')}}</div>
					</div>
				</div>
			</div>

		</div>
		<Footer />
	</div>
</template>

<script>
	export default {
		name: "Vip",
		components: {},
		props: [],
		data() {
			return {
				reAmount:0,
				ruleList: [], //规则List
				flowList: [], //轮播list
				bgColor: false,
				imgList:[
					'',

				],//vip图片list
			};
		},
		computed: {},
		watch: {},
		created() {
			// console.log(this.{{InitData.currency}})
			this.InitData.currency='R$';
			this.$Model.GetUserInfo();
			if (!this.VipList) {
				this.$Model.GetBackData();
			}else{
				this.VipList.pop();
			}

			this.ruleList = this.VipRuleList ? this.VipRuleList : [], //规则List
					this.flowList = this.VipFlowList ? this.VipFlowList : [], //轮播list
					this.getFlowList()
			this.getRuleList()
			console.log(this.UserInfo)
		},
		mounted() {
			// 监听滚动
			this.pageScroll();
		},
		activated() {},
		destroyed() {
			this.$toast.clear();
			// 在组件生命周期结束的时候要销毁监听事件，否则其他页面也会使用这个监听事件
			window.removeEventListener("scroll", this.handleScroll, true);
		},
		methods: {
			pageScroll() {
				// 监听滚动事件，用handleScroll这个方法进行相应的处理
				window.addEventListener("scroll", this.handleScroll, true);
			},
			handleScroll() {
				let top = this.$refs.content.getBoundingClientRect().top;
				// 监听到页面滚动后的处理事件
				if (top > 235) {
					this.bgColor = false;
				} else {
					this.bgColor = true;
				}
			},
			// 更多疑问解答
			goMemberQuestions() {
				this.$router.push('user/memberQuestions')
			},
			//获取轮播list
			getFlowList() {
				//轮播list
				this.$Model.VipFlow((data) => {
					console.log(data)
					this.flowList = data.data
				})
			},
			//获取规则list
			getRuleList() {
				//轮播list
				this.$Model.VipRule((data) => {
					console.log(data)
					this.ruleList = data.data
				})
			},
			balanceRecharge(){
				if (Number(this.reAmount)==0){
					this.$toast(this.$t("recharge.placeholder[0]"));
					return;
				}
				this.$router.push(`/user/mixRecharge?amount=${Number(this.reAmount)}`)
			},
			// 跳转充值
			openTaskList(amount, grade, name) {
				console.log(amount, grade, name)
				if (grade > 0) {
					if (!this.UserInfo) {
						this.$router.push('/login')
						return
					}
					var html = this.$t('vip.dialog[0]', {
						currency: this.InitData.currency,
						amount: amount,
						name: name
					})
					if (this.UserInfo.vip_level-1 > grade) {
						this.$Toast.fail(this.$t('vip.dialog[1]', {
							currname: this.UserInfo.useridentity,
							name: name
						}))
					} else {
						if (this.UserInfo.vip_level-1 == grade) {
							html = this.$t('vip.dialog[2]', {
								currency: this.InitData.currency,
								amount: amount,
								name: name
							})
						}
						this.$Dialog.Confirm(html, () => {
							this.$Model.BuyVip({
								grade: grade
							}, data => {
								if (data.code == 1) {
									this.$Model.GetUserInfo()
								}
								if (data.code == 2) {
									this.$router.push(`/user/mixRecharge?target=1&amount=${Number(amount)}`)
								}
							})
						})
					}
				}
			},
		},
	};
</script>
<style scoped>
	.left{
		position: relative;
		right:0;
	}
	.right{
		position: relative;
		left:50%
	}
	.bgimg {
		background: url(../../public/static/images/vipCenter_bg.png) no-repeat;
		background-size: cover;
		overflow: hidden;
		/* padding-bottom: 60px; */
		background-position: 0 0;
	}

	.van-cell-group {
		background: transparent;
		padding: 15px;
	}

	.van-cell.my {
		height: 170px;
		padding-top: 0px;
		font-size: 16px;
		background: url("../../public/static/images/vipCenter_login.png") no-repeat;
		background-size: 100% 100%;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		align-items: end !important;
	}

	.van-cell {
		font-size: 16px;
		background: url("../../public/static/images/vip_new_bg1.png") no-repeat 0px,
		linear-gradient(60deg, #ccd7e9, #7085a5);
		background-size: 100%;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		padding: 25px 15px;
		margin-top: -20px;
	}

	.van-cell+.van-cell {
		margin-top: 12px;
	}

	.van-cell:nth-of-type(2) {
		background: url("../../public/static/images/vip_new_bg2.png") no-repeat 0px,
		linear-gradient(60deg, #fd9e02, #d86a25);
		background-size: 100%;
	}

	.van-cell:nth-of-type(3) {
		background: url("../../public/static/images/vip_new_bg3.png") no-repeat 0px,
		linear-gradient(60deg, #3044b3, #273580);
		background-size: 100%;
	}

	.van-cell:nth-of-type(4) {
		background: url("../../public/static/images/vip_new_bg4.png") no-repeat 0px,
		linear-gradient(60deg, #fd9e02, #d86a25);
		background-size: 100%;
	}

	.van-cell:nth-of-type(5) {
		background: url("../../public/static/images/vip_new_bg5.png") no-repeat 0px,
		linear-gradient(60deg, #31b489, #3e91b8);
		background-size: 100%;
	}

	.van-cell:nth-of-type(6) {
		background: url("../../public/static/images/vip_new_bg6.png") no-repeat 0px,
		linear-gradient(60deg, #47484c, #1f2025);
		background-size: 100%;
	}

	.van-cell:nth-of-type(7) {
		background: url("../../public/static/images/vip_new_bg1.png") no-repeat 0px,
		linear-gradient(60deg, #ccd7e9, #7085a5);
		background-size: 100%;
	}

	.van-cell .van-cell__title {
		color: #fff;
		text-align: center;
		line-height: 1;
	}

	.van-cell .van-cell__title>p:nth-child(1) {
		left: 10px !important;
	}

	.van-cell .van-cell__title>p {
		margin-top: 10px;
		font-size: 16px;
	}

	.van-cell .van-cell__title .top {
		font-size: 16px;
		text-align: start;
		margin-left: 6%;
		/*white-space: nowrap;*/
		text-overflow: ellipsis;
		overflow: hidden;
		margin-top: 14px;
	}

	.van-cell .van-cell__title .top p {
		margin-top: 15px;
		opacity: 0.5;
	}

	.van-cell .van-cell__title small {
		font-size: 16px;
		color: #ff0;
	}

	.van-cell .van-cell__title .bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: right;
		margin-top: 40px;
		padding-left: 5px;
		font-size: 16px;
		color: #e4c7a1;
	}

	.van-cell .van-cell__title .bottom a {
		color: #fff;
		font-size: 18px;
	}

	.van-field {
		font-size: 16px;
		height: 20px;
		background: none;
		background-color: #fde4a2;
		-webkit-text-fill-color:#0e1526;
		font-weight: bold;
		background-size: 100%;
		border-radius: 5px;
		margin-top: -2px;
		width: 110px;
	}

	.van-cell .van-cell__title .bottom div {
		font-size: 12px;
		margin-top: 8px;
	}

	.van-cell .van-cell__value {
		color: #333;
		/*w
  hite-space: nowrap;*/
	}

	.swiper {
		height: 100px;
		border-radius: 8px;
		border: 2px solid #f9e9aa;
		background: #292929;
		margin-top: 10px;
		display: flex;
		align-items: center;
		padding: 5px 0;
		box-sizing: border-box;
	}

	.swiper_box {
		padding: 0 20px;

	}

	.swiper_item_head {
		width: 25px;
		height: 25px;
		border: 2px solid #f9e9aa;
		box-sizing: border-box;
		border-radius: 50%;
		margin: 0 10px 0 25px;
		overflow: hidden;
		flex-shrink: 0;
	}

	.swiper_item_head img {
		width: 100%;
		height: auto;
		max-width: 100%;
		max-height: 100%;
	}

	.swiper>>>.van-swipe-item {
		/* height: 100% !important; */
		color: #fff;
		display: flex;
		align-items: center;
	}

	.swiper>>>.van-swipe-item p {
		font-size: 13px;
		overflow: hidden;
	}

	/* van-swipe */

	.tree {
		/* background: url(../../public/static/images/vipCenterArrows.png) no-repeat; */
		background-size: contain;
		background-position: center;
		color: #efcf7c;
		font-size: 15px;
		position: relative;
		padding: 0 10px;
	}

	.tree_box {
		padding: 40px 15px 0;
		font-family: Alibaba;
	}
	.tree_box .tree:first-child{
		display: none;
	}
/* 	.tree_box .tree:nth-of-type(11),
	.tree_box .tree:nth-of-type(12),
	.tree_box .tree:nth-of-type(13),
	.tree_box .tree:nth-of-type(14),
	.tree_box .tree:nth-of-type(15),
	.tree_box .tree:nth-of-type(16),
	.tree_box .tree:nth-of-type(17),
	.tree_box .tree:nth-of-type(18),
	.tree_box .tree:nth-of-type(19),
	.tree_box .tree:nth-of-type(20),{
		display: none;
	} */
	.vip0 {
		display: flex;
		position: relative;
		top: -8px;
		padding-bottom: 60px;
		box-sizing: content-box;
	}

	.vip0 .vip0_left {
		background: linear-gradient(11deg, #efcf7c, #ffe7a9, #f3d381);
		box-shadow: -2px 1px 0px 0px #c57e57;
		width: 70%;
		margin-left: 15.6%;
		height: 55px;
		border-radius: 10px;
		max-width: 150px;
		font-size: 25px;
		font-weight: bold;
		color: #000;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 120px;
		text-align: right;
	}
	.vip00{
		width:90% !important;
	}

	.vip0 .vip0_title {
		margin-left: 18px;
		height: 100px;
		padding-top: 2px;
		position: absolute;
		bottom: 0;
	}

	.vip0 .vip0_titles {
		margin-left: 18px;
		height: 100px;
		padding-top: 2px;
	}

	.vip0_left_box {
		width: 50%;
		display: flex;
		justify-content: flex-end;
		margin-left: 2.5px;
	}

	.vip0_right_box {display: none;
		width: 49%;
		font-size: 13px;
		position: relative;
		min-height:185px;
	}

	.vip0_money {
		width: 100%;
		border: 2px solid #f9e9aa;
		border-radius: 10px;
		margin-top: 24px;
		text-align: center;
		padding: 2px 0;
		color: #fff;
		font-size: 12px;
		position: absolute;
		top: 0;
	}

	.vip1 {
		display: flex;
		position: relative;
		top: -8px;
		padding-bottom: 60px;
		box-sizing: content-box;
	}

	.vip1 .vip1_left {
		background: linear-gradient(11deg, #efcf7c, #ffe7a9, #f3d381);
		box-shadow: -2px 1px 0px 0px #c57e57;
		width: 70%;
		height: 55px;
		border-radius: 10px;
		max-width: 150px;
		font-size: 25px;
		font-weight: bold;
		color: #000;
		display: flex;
		justify-content:center;
		align-items: center;
		min-width: 120px;
	}

	.vip1_left img {display: none;
		width: 58%;
		max-width: 64px;
	}

	.vip1 .vip1_title {display: none;
		margin-right: 18px;
		height: 100px;
		padding-top: 2px;
		position: absolute;
		bottom: 0;
	}

	.vip1_title p {
		text-align: start;
	}

	.vip1_left_box {
		width: 50%;
		display: flex;
		justify-content: flex-start;
		margin-left: 2.5px;
	}

	.vip1_right_box {
		width: 49%;
		font-size: 13px;
		position: relative;
		min-height:185px;
	}

	.vip1_money {
		width: 100%;
		border: 2px solid #f9e9aa;
		border-radius: 10px;
		margin-top: 24px;
		text-align: center;
		padding: 2px 0;
		color: #fff;
		font-size: 12px;
		position: absolute;
		top: 0;
		right: 0;
	}

	.vip3 .vip1_money {display: none;
		/* margin-top: 26px; */
	}

	.vip4 .vip0_money {
		/* margin-top: 20px; */
		position: absolute;
		top: 0;
	}

	.vip2_left {
		justify-content: flex-start ;
	}

	.vip2_left img { display: none;
		max-width: 100%;
		width: 53%;
		margin-left: -10%;
		max-width: 94px;
	}

	.vip3_left img {
		margin-right: -14%;
		max-width: 94px;
		margin-top: -20px;
		width: 62%;
	}

	.vip4_left {
		justify-content: flex-start !important;
	}

	.vip4_left img {
		margin-left: -30%;
		max-width: 94px;
	}

	.vip5 {
		padding-top: 15px;
	}

	.vip5_left img {
		margin-right: -30%;
		max-width: 110px;
		margin-top: -20px;
		width: 78%;
	}

	.vip4 .vip0_title {
		height: 107px;
	}

	.vip4 {
		margin-bottom: 85px;
	}

	.vip5 .vip1_left_box {
		margin-left: 1.5px !important;
	}

	.vip0,
	.vip1 {
		height: 50px;
		padding-top: 10px;
	}

	.myUserInfo>>>.van-cell__title {
		margin-top: -66px;
	}

	.query_box {
		border-radius: 12px;
		background-color: #fff;
		padding: 11px 10px 15px;
		border: 3px solid #f9e9aa;
		box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
	}

	.query_box_padding {display: none;
		padding: 95px 20px 0;
	}

	.query_box_title {
		width: 170px;
		min-width: 150px;
		padding: 3px 0;
		border-radius: 10px;
		text-align: center;
		border: 2px solid #f9e9aa;
		background: linear-gradient(11deg, #efcf7c, #ffe7a9, #f3d381);
		box-shadow: -2px 1px 0px 0px #c57e57;
		font-family: Alibaba;
		font-weight: normal;
		color: #292929;
		font-size: 15px;
		font-weight: bold;
		margin: 5px auto 34px;
	}

	.query_content {
		display: flex;
		flex-direction: column;
	}

	.query_content .left_item {
		display: flex;
		width: 100%;
		margin-bottom: 16px;
	}

	.query_content .left_item .img_box,
	.query_content .right_item .img_box {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: #000000;
		overflow: hidden;
		border: 2px solid #f9e9aa;
	}

	.query_content .left_item .img_box img,
	.query_content .right_item .img_box img {
		width: 100%;
	}

	.query_content .left_item .left_item_content {
		max-width: 260px;
		width: 65%;
		min-width: 150px;
		padding: 10px 15px;
		border-radius: 6px;
		background: #f1dcc1;
		font-family: Alibaba;
		color: #313131;
		font-size: 13px;
		display: flex;
		align-items: center;
		letter-spacing: 0.04rem;
		position: relative;
		margin-left: 13px;
	}

	.query_content .left_item .left_item_content::after {
		content: "";
		display: block;
		position: absolute;
		left: -8px;
		top: 20px;
		width: 10px;
		height: 10px;
		background: url(../../public/static/images/vip_content_item_angle.png) no-repeat;
		background-size: contain;
	}

	.query_content .right_item {
		display: flex;
		width: 100%;
		margin-bottom: 16px;
		justify-content: flex-end;
	}

	.query_content .right_item .right_item_content {
		max-width: 260px;
		width: 65%;
		min-width: 150px;
		padding: 10px 15px;
		border-radius: 6px;
		background: #dcdcdc;
		font-family: Alibaba;
		color: #313131;
		font-size: 13px;
		display: flex;
		align-items: center;
		letter-spacing: 0.04rem;
		position: relative;
		margin-right: 13px;
	}

	.query_content .right_item .right_item_content::after {
		content: "";
		display: block;
		position: absolute;
		right: -9px;
		top: 20px;
		width: 10px;
		height: 10px;
		background: url(../../public/static/images/vip_content_item_angle_right.png) no-repeat;
		background-size: contain;
	}

	.query_box .more {
		padding: 5px 20px;
		border-radius: 15px;
		background: #f9e9aa;
		font-size: 13px;
		color: #2f2f2f;
		margin: 20px auto 100px;
		width: max-content;
		font-family: Alibaba;
	}

	.lv1 {
		width: 41% !important;
		margin-right: 5px;
	}

	.ScrollBox .bgc {
		width: 100%;
		background-color: transparent;
		height: 46px;
		position: fixed;
		top: 0;
		z-index: 200;
	}

	.topTitle {
		position: absolute;
		max-width: 60%;
		white-space: nowrap;
		font-size: 18px;
		color: #fff;
		background-color: transparent;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
	}

	.height {
		height: 46px;
	}

	.bgColor {
		background-color: #222 !important;
	}

	.tree>.vip0,
	.tree>.vip1 {
		background: url(../../public/static/images/vipRule_item.png) no-repeat;
		background-position: center;
		background-size: 10px 100%;
		position: relative;
	}

	.tree>.vip0::after,
	.tree>.vip1::after {
		content: "";
		display: block;
		width: 30px;
		height: 32px;
		background: url(../../public/static/images/vipRule_jian.png) no-repeat;
		background-position: center;
		background-size: 100% 100%;
		position: absolute;
		bottom: 0;
		right: 50%;
		transform: translate(50%, 0%);
	}

	.clearAfter::after {
		content: '';
		background: none !important;
	}

	.clearAfter {
		padding-bottom: 0;
	}
</style>
