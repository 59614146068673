<template>
  <div class="Site IndexBox">
	  <div style="font-size:10px;color:#000">
	  	<van-nav-bar fixed :border="false" left-arrow @click-left="$router.go(-1)" />
	  </div>
    <div class="ScrollBox">
      <div class="header_bg">
      	<img src="../../../public/static/images/home_Title_Bgc.png"></img>
      </div>
      <van-swipe :autoplay="3000" indicator-color="#888" style="height: 10rem;z-index: 50">
      	<van-swipe-item v-for="(item,index) in InitData.bannerList" :key="index">
      		<img ref="img" :src="`https://happyjob.online/upload/resource/202012021510121274465498.png`" class="BroadBarItem">
      	</van-swipe-item>
      </van-swipe>
      </van-swipe>
      <van-tabs color="#0000CD" title-inactive-color="#ccc" :ellipsis="false" :border="false" line-width="60" v-model="tabsIndex" @change="changeTabs">
        <van-tab v-for="(item,index) in InitData.taskclasslist.filter(obj=>obj.state=1)" :key="item.group_id" style="padding: 0 20px;">
			 <template #title>
				 <div :class="{'activeTab':tabsIndex==index}">
					 <div class="imgBox">
						 <img v-if="tabsIndex!=index" :src="taskListImg[index]" />
						 <img v-else :src="taskListImg_select[index]" />
					 </div>
					 <p>{{item.group_name}}</p>
				 </div>
				 
			 </template>
          <van-cell v-for="item in InitData.UserGradeList" :key="item.grade" @click="openTaskList(item.amount,item.grade,item.name)">
            <template #title>
              <b>{{item.name}}</b>
              <div v-html="$t('vip.list.label',{number: item.number})"></div>
            </template>
            <span class="tag">{{UserInfo.vip_level==item.grade?$t('task.index[0]'):''}}</span>
          </van-cell>
          <van-cell v-if="UserInfo&&UserInfo.vip_level>InitData.gradeLimit" v-for="item in InitData.UserGradeList2" :key="item.grade" @click="openTaskList(item.amount,item.grade,item.name)">
            <template #title>
              <b>{{item.name}}</b>
              <div v-html="$t('vip.list.label',{number: item.number})"></div>
            </template>
            <span class="tag" v-if="UserInfo.vip_level==item.grade">{{$t('task.index[0]')}}</span>
          </van-cell>
        </van-tab>
      </van-tabs>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  name: "Task",
  components: {},
  props: ["tabsActive"],
  data() {
    return {
      taskType: "",
      tabsIndex: 0, //tab选中第几项
      isStore: false,
      taskListImg: [
        "./static/images/task_tab_item_img01.png",
        "./static/images/task_tab_item_img02.png",
        "./static/images/task_tab_item_img04.png",
        "./static/images/task_tab_item_img05.png",
        "./static/images/task_tab_item_img03.png",
      ],
      taskListImg_select: [
        "./static/images/task_tab_item_img01_select.png",
        "./static/images/task_tab_item_img02_select.png",
        "./static/images/task_tab_item_img04_select.png",
        "./static/images/task_tab_item_img05_select.png",
        "./static/images/task_tab_item_img03_select.png",
      ],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.tabsIndex = this.tabsActive ? Number(this.tabsActive) : 0;
    if (this.InitData.taskclasslist.length) {
      this.taskType = this.InitData.taskclasslist.filter(
        (obj) => obj.state == 1
      )[0].group_id;
      if (this.tabsActive) {
        this.taskType = this.InitData.taskclasslist.filter(
          (obj) => obj.state == 1
        )[this.tabsActive].group_id;
      }
    }
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    changeTabs(index) {
      this.taskType = this.InitData.taskclasslist.filter(
        (obj) => obj.state == 1
      )[index].group_id;
    },
    openTaskList(amount, grade, name) {
      if (this.UserInfo.vip_level < grade) {
        const html = this.$t("task.index[1]", {
          currVip: this.UserInfo.useridentity,
          vip: name,
        });
        this.$Dialog.Confirm(
          html,
          () => {
            //问题12，直接跳转到充值页面，已改
            this.$router.push(`/vip`);
          },
          this.$t("task.index[2]")
        );
      } else {
        if (this.taskType) {
          this.$router.push(`/taskList/${this.taskType}/${grade}`);
        } else {
          this.$Dialog.Toast(this.$t("task.index[3]"));
        }
      }
    },
  },
};
</script>
<style scoped>
.ScrollBox {
  font-family: Alibaba;
  padding-bottom: 50px;
}
.van-tab__pane {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.van-cell {
  background-size: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-top: 20px;
  height: 70px;
  display: flex;
  padding: 0;
}
.van-cell::after {
  border: 0;
}
.van-cell__title {
  background: linear-gradient(90deg, #b3b5fc 0%, #ffe9ce 100%);
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #4e51bf !important;
  font-family: Alibaba;
}
.van-cell__value {
  width: 32px;
  height: 100%;
  border-radius: 0 !important;
  top: 0px !important;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #4e51bf !important;
}
.van-cell__value span {
  transform: scale(0.8);
  text-align: center;
  color: #e1e1f9;
}
.van-cell:nth-of-type(2) {
  background-size: 100%;
}
.van-cell:nth-of-type(3) {
  background-size: 100%;
}
.van-cell:nth-of-type(4) {
  background-size: 100%;
}
.van-cell:nth-of-type(5) {
  background-size: 100%;
}
.van-cell:nth-of-type(6) {
  background-size: 100%;
}
.van-cell:nth-of-type(7) {
  background-size: 100%;
}
.van-cell .van-cell__title {
  flex: auto;
  color: #fff;
  text-align: center;
  font-size: 14px;
}
.van-cell .van-cell__title b {
  font-size: 18px;
  font-weight: 400;
  display: block;
}
.van-cell .van-cell__value {
  position: absolute;
  top: 10px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 12px;
  padding: 0 5px 0 5px;
  border-radius: 0 50px 50px 0;
}
.header_bg {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  z-index: 0;
}
.header_bg img {
  width: 100%;
  height: auto;
}
.BroadBarItem {
  width: 100%;
  border-radius: 10%;
  padding: 0 20px;
}
.ScrollBox >>> .van-swipe {
  height: auto !important;
  margin-top: 21%;
}
.van-tabs >>> .van-tabs__nav {
  padding: 0 30px 12px;
  margin-top: 15px;
}
.ScrollBox >>> .van-tabs__wrap--scrollable {
  height: auto;
  display: flex;
}
.ScrollBox >>> .van-tabs__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.ScrollBox >>> .van-tab {
  width: 75px;
  height: 75px;
  border-radius: 10px;
  background: #eff0f2;
  padding: 0;
  box-sizing: border-box;
  margin: 8px 1px 0;
  flex: inherit;
}
.ScrollBox >>> .van-tabs__line {
  display: none;
}
.van-tabs >>> .van-tab .van-tab__text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #535353;
  font-size: 13px;
}
.van-tabs >>> .van-tab .van-tab__text .imgBox {
  width: 100%;
  height: 47px;
  padding: 5px 12px 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.van-tabs >>> .van-tab .van-tab__text p {
  text-align: center;
}
.van-tabs >>> .van-tab .van-tab__text .imgBox img {
  max-height: 100%;
  max-width: 100%;
}
.activeTab p {
  color: #000;
  transform: scale(1.1);
}
</style>