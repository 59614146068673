import Vue from "vue";
import { Locale } from "vant";
import VueI18n from "vue-i18n";

import Chinese from "./cn";
import zhHK from "./hk";
import tc from "./tc";
import kr from "./kr";
import jp from "./jp";
import fi from "./fi";
import English from "./en";

import enUS from "vant/lib/locale/lang/en-US";
import zhCN from "vant/lib/locale/lang/zh-CN";
import vn from "./vn";
import config from "@/config";

//设置默认语言
var Language = config.Language;

/*初始化*/
const lang = localStorage["Language"] || Language;

let locale = "id-ID";
switch (lang) {
  case "en":
    locale = "en-US";
    Locale.use("en-US", enUS);
    break;
  case "vn":
    locale = "vn";
    Locale.use("vn", vn);
    break;
  case "jp":
    locale = "jp";
    Locale.use("jp", jp);
    break;
  case "fi":
    locale = "fi";
    Locale.use("fi", fi);
    break;
  case "cn":
    locale = "zh-CN";
    Locale.use("zh-CN", zhCN);
    break;
  case "tc":
    locale = "zh-HK";
    Locale.use("zh-HK", tc);
    break;
  case "kr":
    locale = "ko-KR";
    Locale.use("ko-KR", kr);
    break;
}

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: locale,
  messages: {
    "zh-CN": Chinese,
    "en-US": English,
    "vn": vn,
    "zh-HK": tc,
    "ko-KR": kr,
    "jp": jp,
    "fi":fi,
  },
});

/*切換*/
export function SetLanguage(lang) {
  i18n.locale = lang;
  switch (lang) {
    case "en-US":
      localStorage["Language"] = "en";
      Locale.use("en-US", enUS);
      break;
    case "vn":
      localStorage["Language"] = "vn";
      Locale.use("vn", vn);
      break;
    case "jp":
      localStorage["Language"] = "jp";
      Locale.use("jp", jp);
      break;
    case "fi":
      localStorage["Language"] = "fi";
      Locale.use("fi", fi);
      break;
    case "zh-CN":
      localStorage["Language"] = "cn";
      Locale.use("zh-CN", zhCN);
      break;
    case "zh-HK":
      localStorage["Language"] = "tc";
      Locale.use("zh-HK", tc);
      break;
    case "ko-KR":
      localStorage["Language"] = "kr";
      Locale.use("ko-KR", kr);
      break;
  }
}
export default i18n;
