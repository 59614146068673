<template>
  <div class="GrabOrders">
    <van-nav-bar
      fixed
      :border="false"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <!-- 顶部标题 -->
    <div class="title">{{ $t("TaskPopup[7]") }}</div>
    <!-- 抽奖机 -->
    <div class="mach">
      <div class="machine">
        <div class="slot-machine">
          <div
            class="slot"
            v-for="(slot, index) in slots"
            ref="slots"
            :key="index"
          >
            <div class="slot__window">
              <div class="slot__wrap">
                <div
                  class="slot__item"
                  v-for="(opt, idx) in slot.items"
                  :key="`${index}_${idx}`"
                >
                  <div class="slot__item_content">
                    <img :src="opt" alt="" width="100%" />
                  </div>
                </div>
                <template v-for="prize in prizeNum">
                  <div class="slot__item slot__item--copy" :key="prize">
                    <div class="slot__item_content">
                      <img :src="slot.items[prize]" alt="" width="100%" />
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 正在抢单中 -->
    <div class="zz">
      <div style="display: flex; align-items: center">
        <div
          style="
            width: 94px;
            height: 3px;
            background: linear-gradient(270deg, #fd9941, #fa6b00, #b8ff9507);
          "
        ></div>
        <span>{{ text }}</span>
        <div
          style="
            width: 94px;
            height: 3px;
            background: linear-gradient(270deg, #fd9941, #fa6b00, #b8ff9507);
          "
        ></div>
      </div>
    </div>
    <!-- 两个按钮 -->
    <div style="display: flex; justify-content: center">
      <div
        class="zidong"
        style="width: 227px; height: 62px; margin-top: 20px"
        @click="start(0)"
      >
        {{ $t("TaskPopup[3]") }}
      </div>
    </div>
    <div style="display: flex; justify-content: center">
      <div
        class="shoudong"
        style="width: 155px; height: 55px; margin-top: 20px"
        @click="start(1)"
      >
        {{ $t("TaskPopup[2]") }}
      </div>
    </div>
    <!-- 抢单记录 -->
    <div class="qdjl">
      <div
        style="
          width: 61px;
          height: 3px;
          border-radius: 333px;
          background: #b99af7;
          margin: 0 15px;
        "
      ></div>
      <div
        style="
          width: 148px;
          height: 31px;
          border-radius: 21px;
          background: #a981ff;
        "
      >
        {{ $t("görev[32]") }}
      </div>
      <div
        style="
          width: 61px;
          height: 3px;
          border-radius: 333px;
          background: #b99af7;
          margin: 0 15px;
        "
      ></div>
    </div>
    <div class="OrderList">
      <div class="item" v-for="(item, index) in list">
        <div class="top">
          <div
            style="
              width: 70px;
              height: 70px;
              border-radius: 5px;
              background: #101010;
            "
          >
            <!-- 获取logo -->
            <img style="width: 70px; height: 70px" :src="item.icon" alt="" />
          </div>
          <div class="text" style="flex: 1; margin-left: 10px">
            <p>Requisitos de tarfe:</p>
            <div style="display: flex; justify-content: space-between">
              <span>{{ item.content }}</span>
              <span>
                {{ currency_name }}
                {{ Number(item.actual_amount).toFixed(0) }}</span
              >
            </div>
            <p>Criado：{{ gettime(item.add_time) }}</p>
            <p v-if="item.complete_time != 0">
              Criado：{{ gettime(item.complete_time) }}
            </p>
          </div>
        </div>
        <div class="border"></div>
        <div class="bottom">
          <div class="left">
            <span>Abrir link</span>
            <span>Copiar link</span>
          </div>
          <div
            class="right"
            v-if="item.status == 1"
            @click="autoComplete(item.id)"
          >
            {{ $t("görev[30]") }}
          </div>
          <div class="right" v-if="item.status == 3">
            {{ $t("shopTask[14]") }}
          </div>
          <div class="right" v-if="item.status == 5">失败</div>
          <div class="right" v-if="item.status == 2">
            {{ $t("shopTask[15]") }}
          </div>
          <van-uploader
            v-model="fileList[index]"
            :preview-image="false"
            :after-read="(event) => afterRead(event, item.id, index)"
            v-if="item.status == 1"
          >
            <div class="right">{{ $t("görev[31]") }}</div>
          </van-uploader>
        </div>
      </div>
    </div>
    <div
      v-if="list.length == 0"
      style="margin-top: 40px; font-size: 20px; text-align: center"
    >
      {{ $t("automaticComplete[6]") }}!
    </div>
    <!-- 手动抢单成功的 弹窗 -->
    <orderSuccess :show="show_success" @toMytask="toMytask()"></orderSuccess>
    <!-- 手动完成 提现弹窗 -->
    <withDrawal :show="show_withDrawal" @withdrawal="withdrawal()"></withDrawal>
    <van-popup
      v-model="show"
      round
      style="overflow: visible; background: transparent"
    >
      <zidong @openzd="openzd()" v-if="tc_type == '自动'"></zidong>
      <!-- 手动抢单 当日次数已达上限 弹窗 -->
      <openzd @openzd="openzd()" v-if="tc_type == 'sFail'"></openzd>
      <!-- 已开通自动抢单弹窗   v-if="tc_type == 'autoEnabled'"-->
      <autoEnabled
        @close="close()"
        v-if="tc_type == 'autoEnabled'"
      ></autoEnabled>
      <!-- 自动抢单成功 -->
      <autoSuccess
        @toMytask="toMytask"
        v-if="tc_type == 'autoSuccess'"
        :count="count"
      ></autoSuccess>
      <!--自动抢单  当日无抢单次数  -->
      <autoNo @invitation="invitation" v-if="tc_type == 'autoNo'"></autoNo>
      <!-- 自动完成 试用次数已完 弹窗 -->
      <on-trial
        v-if="tc_type == '自动完成试用次数已完'"
        @determine="determine"
      ></on-trial>
      <!-- 自动完成 成功弹窗 -->
      <auto-complete
        v-if="tc_type == '自动完成任务成功'"
        @determine="determine()"
      ></auto-complete>
    </van-popup>
  </div>
</template>

<script>
import orderSuccess from "../../components/tanchuang/orderSuccess.vue";
import withDrawal from "../../components/tanchuang/withdrawal.vue";
import zidong from "../../components/tanchuang/zidong.vue";
import openzd from "../../components/tanchuang/openZD.vue";
import autoEnabled from "../../components/tanchuang/autoEnabled.vue";
import autoSuccess from "../../components/tanchuang/autoSuccess.vue";
import autoNo from "../../components/tanchuang/autoNo.vue";
import onTrial from "../../components/tanchuang/onTrial.vue";
import autoComplete from "../../components/tanchuang/autoComplete.vue";
const next =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  function (cb) {
    window.setTimeout(cb, 1000 / 60);
  };
export default {
  name: "GrabOrders",
  data() {
    return {
      slots: [
        {
          title: "When",
          items: [
            require("../../assets/img/zhekou.png"),
            require("../../assets/img/liwu.png"),
            require("../../assets/img/hongbao.png"),
          ],
        },
        {
          title: "Where",
          items: [
            require("../../assets/img/zhekou.png"),
            require("../../assets/img/liwu.png"),
            require("../../assets/img/hongbao.png"),
          ],
        },
        {
          title: "How",
          items: [
            require("../../assets/img/zhekou.png"),
            require("../../assets/img/liwu.png"),
            require("../../assets/img/hongbao.png"),
          ],
        },
      ],
      prizeNum: 3, // 可视区域每列展示的奖品数
      opts: null,
      startedAt: null,
      uid: localStorage.getItem("UId"),
      username: localStorage.getItem("MiName"),
      currency_name: JSON.parse(localStorage.getItem("InitData")).setting
        .currency_name, //货币符号
      list: [], //抢单记录列表数据
      text: "",
      fileList: [], //手动完成任务  上传的图片列表
      show_success: { bool: false }, //手动抢单成功的弹窗
      show_withDrawal: { bool: false }, //手动完成的 提现弹窗
      taskItem: null, //mytask页面复制过来的变量
      show: false, // 自动抢单的弹窗
      tc_type: "", //显示的弹窗类型
      count: 0,
    };
  },
  created() {
    this.getOrderRecord();
  },
  components: {
    orderSuccess,
    withDrawal,
    zidong,
    openzd,
    autoEnabled,
    autoSuccess,
    autoNo,
    onTrial,
    autoComplete,
  },
  computed: {},
  methods: {
    determine() {
      this.show = false;
      this.tc_type = "";
    },
    //自动完成任务
    autoComplete(id) {
      this.$Model.autoComplete(
        {
          uid: localStorage.getItem("UId"),
          id,
        },
        (res) => {
          if (res.state == 0) {
            this.show = true;
            this.tc_type = "自动完成试用次数已完";
            return;
          } else {
            //自动完成 成功
            this.show = true;
            this.tc_type = "自动完成任务成功";
            this.getOrderRecord();
          }
        }
      );
    },
    invitation() {
      this.show = false;
      this.tc_type = "";
      this.$router.push("/user/promote");
    },
    close() {
      this.show = false;
      this.tc_type = "";
    },
    //开通自动抢单
    openzd() {
      this.show = false;
      this.$router.push("/openzd?type=0");
    },
    submitTask() {
      let { index, id } = this.taskItem;
      if (this.fileList[index]) {
        const examine_demo = this.fileList[index].map((item) => item.url);
        this.$Model.SubmitTask(
          {
            order_id: id,
            examine_demo: examine_demo,
          },
          (data) => {
            if (data.code == 1) {
              this.fileList[index] = [];
              //获取抢单记录
              this.getOrderRecord();
              /*  this.$Dialog.Toast(this.$t("task.msg[1]"), "center"); */
            } else {
              /* this.$Dialog.Toast(this.$t("task.msg[2]"), "center"); */
            }
          }
        );
      } else {
        /*   this.$Dialog.Toast(this.$t("task.msg[2]")); */
      }
    },
    //手动完成 提现弹窗 提现按钮
    withdrawal() {
      this.show_withDrawal.bool = false;
      this.$router.push("/user/wallet");
    },
    toMytask() {
      this.show_success.bool = false;
      this.$router.push("/mytask");
    },
    //手动抢单
    shoudong() {
      this.$Model.manualOrderGrabbing(
        { uid: this.uid, username: this.username },
        (res) => {
          if (res.state == 0) {
            //判断是否是已开通 但是已经达到今日上线
            /*  if (res.msg == "今日抢单已达上限") { */
            this.show = true;
            this.tc_type = "sFail";
            this.start();
            /*    return;
            } */
            return;
          }
          this.start();
          //抢单成功 重新获取抢单记录
          this.getOrderRecord();
          //显示成功抢单弹窗
          this.show_success.bool = true;
        }
      );
    },
    //自动抢单
    zidong() {
      this.$Model.zidong({ uid: this.uid, username: this.username }, (res) => {
        console.log(res.state == 0);
        if (res.state == 0) {
          //判断是否是已开通 但是已经达到今日上线
          if (res.msg == "今日抢单已达上限") {
            this.show = true;
            if (res.is_zd == 0) {
              // 当前账号未开通自动抢单
              this.tc_type = "自动";
              return;
            }

            this.tc_type = "autoNo";
            return;
          } else if (res.msg == "当前无更多订单!") {
            this.$notify({ type: "warning", message: res.msg });
          } else {
            //未开通自动抢单 显示开通 自动抢单弹窗
            this.show = true;
            this.tc_type = "自动";
            this.start();
          }
        } else {
          this.show = true;
          this.tc_type = "autoEnabled";
          this.start();
          setTimeout(() => {
            this.show = true;
            this.tc_type = "autoSuccess";
            this.count = res.msg;
            this.getOrderRecord();
          }, 5000);
        }
      });
    },
    //时间戳转换日期
    gettime(time) {
      const d = new Date(time * 1000);
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const date = d.getDate();
      const hour = d.getHours();
      const minute = d.getMinutes();
      const second = d.getSeconds();
      return (
        year +
        "-" +
        month +
        "-" +
        date +
        "-" +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    // gettime1(time) {
    //   const date = new Date(Number(time) * 1000);
    //   const Y = date.getFullYear();
    //   const M = date.getMonth() + 1;
    //   const D = date.getDay();
    //   const h = date.getHours() ? 0 : date.getHours();
    //   const m = date.getMinutes() === 0 ? "00" : date.getMinutes();
    //   const s = date.getSeconds() ? 0 : date.getSeconds();
    //   return Y + "-" + M + "-" + D + "-" + h + ":" + m + ":" + s;
    // },
    //手动完成 需要上传图片
    afterRead(file, id, index) {
      this.taskItem = {
        id,
        index,
      };
      file.status = "uploading";
      file.message = this.$t("upload[0]");
      this.uploadImgs(file);
    },
    uploadImgs(file) {
      if (file.length) {
        file.forEach((item) => {
          if (!item.file.type.match(/image/)) {
            item.status = "failed";
            item.message = this.$t("upload[1]");
            return;
          }
          console.log(1);
          this.compressImg(item);
        });
      } else {
        if (!file.file.type.match(/image/)) {
          file.status = "failed";
          file.message = this.$t("upload[1]");
          return;
        }
        console.log(2);
        this.compressImg(file);
      }
    },
    compressImg(file) {
      this.$Util.CompressImg(file.file.type, file.content, 750, (image) => {
        let param = new FormData();
        param.append("token", localStorage["Token"]);
        param.append("type", 3);
        param.append("image", image, file.file.name);
        this.$Model.UploadImg(param, (data) => {
          if (data.code == 1) {
            file.message = this.$t("upload[2]");
            file.status = "success";
            file.url = data.url;
            this.submitTask();
            //显示提现弹窗
            this.show_withDrawal.bool = true;
          } else {
            file.status = "failed";
            file.message = this.$t("upload[3]");
          }
        });
      });
    },
    //获取抢单记录
    getOrderRecord() {
      console.log(this.uid);
      this.$Model.getOrderRecord({ uid: this.uid }, (res) => {
        if (res.length > 0) {
          //有抢单记录
          this.list = res;
        }
      });
    },
    start: function (type) {
      this.text = this.$t("TaskPopup[6]");
      setTimeout(() => {
        this.text = "";
      }, 4000);
      if (this.opts) {
        // 增加动画过程中，再次点击开始，立即结束动画，且置为对应中将位置
        this.opts.forEach((opt) => {
          opt.isFinished = true;
          const pos = -opt.finalPos;
          opt.el.style.transform = "translateY(" + pos + "px)";
        });
        return;
      }
      this.opts = this.slots.map((data, i) => {
        const slot = this.$refs.slots[i]; // map(function(){})利用map便利slots的每一个选项组,最终得到return的返回值
        const itemHeight =
          document.getElementsByClassName("slot__item")[0].offsetHeight;
        const choice = Math.floor(Math.random() * data.items.length); // 随机生成一个[0,data.items.length]的整数(floor向下取整)

        const opts = {
          el: slot.querySelector(".slot__wrap"), //指向奖项元素的父级
          finalPos: choice * itemHeight, // itemHeight 为每一个奖品滚动标签的高度
          startOffset: 1000 + Math.random() * 500 + i * 500, // 影响转的圈数
          height: data.items.length * itemHeight,
          duration: 3000 + i * 700, // milliseconds
          isFinished: false,
        };
        return opts;
      });
      console.log(this.opts); //这个时候this.opts已经和opts一模一样了
      next(this.animate); // 开启动画
      /* 手动抢单 */
      if (type == 1) {
        this.shoudong();
      } else {
        this.zidong();
      }
    },
    animate: function (timestamp) {
      if (!this.opts) return;
      // timestamp当前的方法持续的毫秒数
      if (this.startedAt == null) {
        this.startedAt = timestamp; // 动画初始时间
      }
      const timeDiff = timestamp - this.startedAt; //动画持续的时间

      this.opts.forEach((opt) => {
        if (opt.isFinished) {
          return;
        }
        const timeRemaining = Math.max(opt.duration - timeDiff, 0); // 总的持续时间 - 动画持续时间 = 剩下的时间,0表示结束
        const power = 3;
        const offset =
          (Math.pow(timeRemaining, power) / Math.pow(opt.duration, power)) *
          opt.startOffset; // Math.pow(timeRemaining, power)表示: timeRemaining 的3 次幂; // negative, such that slots move from top to bottom
        const pos = -1 * Math.floor((offset + opt.finalPos) % opt.height);
        opt.el.style.transform = "translateY(" + pos + "px)";
        if (timeDiff > opt.duration) {
          console.log("finished", opt, pos, opt.finalPost);
          opt.isFinished = true;
        }
      });
      if (this.opts.every((o) => o.isFinished)) {
        this.opts = null;
        this.startedAt = null;
        console.log("finished");
      } else {
        next(this.animate);
      }
    },
  },
};
</script>

<style scoped>
.GrabOrders {
  height: 100%;
  max-height: max-content;
  background: cornsilk url("../../assets/img/background.png") no-repeat
    center/cover;
  overflow: scroll;
}
.GrabOrders > .title {
  margin-top: 46px;
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}
.GrabOrders > div .zidong {
  padding-top: 10px;
  font-size: 24px;
  color: #fff;
  text-align: center;
  background: url("../../assets/img/automatic.png") no-repeat center/cover;
}
.GrabOrders > div .shoudong {
  padding-top: 10px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  background: url("../../assets/img/click.png") no-repeat center/cover;
}
.GrabOrders > .OrderList {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
}
.GrabOrders > .OrderList > .item {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 343px;
  height: 133px;
  border-radius: 10px;
  background: #f5f0ff;
}
.GrabOrders > .OrderList > .item > .top {
  margin: 10px;
  display: flex;
}
.GrabOrders > .OrderList > .item > .border {
  opacity: 0.39708892;
  width: 342px;
  height: 1px;
  border: solid 0.5px rgba(0, 0, 0, 0.2);
}
.GrabOrders > .OrderList > .item > .top > .text {
  line-height: 18px;
}
.GrabOrders > .OrderList > .item > .top > .text > p:first-child {
  color: #a981ff;
  font-family: "PingFangSC-Regular";
  font-size: 16px;
  font-weight: 400;
}
.GrabOrders > .OrderList > .item > .top > .text > div > span:first-child {
  color: #7765a9;
  font-family: "PingFangSC-Regular";
  font-size: 12px;
  font-weight: 400;
}
.GrabOrders > .OrderList > .item > .top > .text > div > span:last-child {
  color: #a981ff;
  font-family: "PingFangSC-Medium";
  font-size: 14px;
  font-weight: 400;
}
.GrabOrders > .OrderList > .item > .top > .text > p:not(:first-child) {
  color: rgba(119, 101, 169, 0.5);
  font-family: "PingFangSC-Regular";
  font-size: 11px;
  font-weight: 400;
}
.GrabOrders > .OrderList > .item > .bottom {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 5px;
}
.GrabOrders > .OrderList > .item > .bottom > .left span {
  margin-left: 5px;
  display: inline-block;
  text-align: center;
  line-height: 23px;
  width: 77px;
  height: 23px;
  border-radius: 7px;
  border: solid 0.5px #a981ff;
}
.GrabOrders > .OrderList > .item > .bottom .right {
  width: 66px;
  height: 23px;
  border-radius: 7px;
  border: solid 0.5px #00a3ff;
  text-align: center;
  line-height: 23px;
  overflow: hidden;
}
.GrabOrders > .qdjl {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.GrabOrders > .qdjl > div:nth-child(2) {
  text-align: center;
  color: #ffffff;
  font-family: "PingFangSC-Semibold";
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
}
/* 抽奖机的样式 */
.zz {
  padding-bottom: 5px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  color: #fff;
  margin: 0 auto;
  transform: translateY(-20px);
  width: 339px;
  height: 45px;
  border-radius: 12px;
  background: #ff993f;
}
.mach {
  position: relative;
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: max-content;
  height: 260px;
  overflow: hidden;
  border-radius: 11px;
  background: linear-gradient(180deg, #ffae08, #ff7200);
}
.machine {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 339px;
  height: 324px;
  border-radius: 11px;
  background: url("../../assets/img/deng.png") no-repeat center/cover;
}
.slot-machine {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 290px;
  height: 200px;
  background: rgb(93, 57, 164);
  border-radius: 12px;
}
.slot {
  float: left;
  margin: 0.4em;
}
.slot__window {
  width: 85px;
  height: 180px;
  background: #47268a;
  border-radius: 5px;
  overflow: hidden;
}
.slot__item {
  width: 85px;
  height: 85px;
}
.slot__item_content {
  width: 84.95px;
  height: 84.44px;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 15px #fff2d8;
  box-shadow: 0px 9px #5d39a4;
}

/* vant ui 组件内部样式调整 */
::v-deep .van-nav-bar {
  background: transparent;
}
::v-deep .van-icon-arrow-left::before {
  font-size: 24px;
  color: #fff;
}
</style>
