<template>
	<div class="extension">
		<div class="title" v-if="Language == 'cn'">{{ $t('extension.default[0]', { index }) }}</div>
		<div class="title" v-if="Language != 'cn'">{{ $t(`extension.ptb_title[${$route.params.index}]`) }}</div>
		<div class="body">
			<div class="box" v-for="(item, i) in taskList" :key="i">
				<div class="commont" @click="turn(item.task_id, item.is_receive)">
					<div class="one">
						<div class="head">youtube</div>
						<div class="photo"></div>
						<div class="LV">LV{{ item.task_level }}</div>
					</div>
					<div class="two">
						<p>{{ $t('extension.default[1]') }}{{ item.title }}</p>
						<p>{{ $t('extension.default[2]') }}{{ item.requirement }}</p>
					</div>
					<div class="end">
						<div class="coin"></div>
						<div class="btn">{{ $t('extension.default[3]') }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ['index'],
	data() {
		return {
			// index: '',
			taskList: [],
			Language: localStorage['Language'] ? localStorage['Language'] : 'en' //语言
		};
	},
	created() {
		// console.log('taskId',this.index)
		this.getNoviceTaskList(this.UserInfo.userid, this.index);
		// console.log(this.$route);
		// this.index = this.$route.params.index;
		// // console.log(index);
		// if (this.index == 0) {
		// 	this.index = '一';
		// } else if (this.index == 1) {
		// 	this.index = '二';
		// } else if (this.index == 2) {
		// 	this.index = '三';
		// } else if (this.index == 3) {
		// 	this.index = '四';
		// } else if (this.index == 4) {
		// 	this.index = '五';
		// } else if (this.index == 5) {
		// 	this.index = '六';
		// } else if (this.index == 6) {
		// 	this.index = '七';
		// } else if (this.index == 7) {
		// 	this.index = '八';
		// } else if (this.index == 8) {
		// 	this.index = '九';
		// } else if (this.index == 9) {
		// 	this.index = '十';
		// }
		// console.log(this.Language)
	},
	methods: {
		getNoviceTaskList(uid, pac, fn) {
			// 获取新手礼包列表
			let json = {
				uid: uid,
				pac: pac
			};
			// console.log('json', json);
			this.$Model.getNoviceTaskList(json, data => {
				console.log(data);
				this.taskList = data.tasks;
			});
		},
		turn(id, receive) {
			// 判断是否已经领取任务
			if (receive == 1) {
				//领取了直接打开
				this.$router.push('/user/taskInfo/' + id);
			} else {
				//先领取再打开
				this.$Model.ReceiveTask(id, data => {
					console.log(data);
					if (data.code == 1) {
						setTimeout(() => {
							this.$router.push('/user/taskInfo/' + id);
						}, 1000);
					} else {
					}
				});
			}
		}
	}
};
</script>
<style scoped>
.extension {
	height: 100%;
	overflow: auto;
}
.title {
	height: 46px;
	line-height: 46px;
	width: 100%;
	text-align: center;
	color: #fff;
	background: #4e51bf;
	font-size: 17px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 30px;
}
.body {
	padding: 10px;
}
.box {
	margin: 15px 10px 0 10px;
	background-image: linear-gradient(to bottom, #fe93a4, #4f51bf);
	border-radius: 10px;
	padding: 4px;
}
.commont {
	width: 100%;
	height: 100%;
	background-image: linear-gradient(to right, #b4b5fc, #fee8cf);
	border-radius: 5px;
	display: flex;
	justify-content: space-around;
}

.head {
	font-weight: 600;
	font-size: 18px;
	margin: 10px 0 0 5px;
	letter-spacing: 0px;
}
.photo {
	width: 55px;
	height: 55px;
	border-radius: 50px;
	background-color: #fff;
	background-size: 80%;
	background-position-y: 20px;
	background-position-x: 6px;
	margin: 5px 5px 5px 10px;
}
.LV {
	background-repeat: no-repeat;
	background-position-y: 6px;
	background-size: 100%;
	width: 40px;
	height: 25px;
	text-align: center;
	line-height: 32px;
	font-size: 12px;
	font-weight: 600;
	margin-left: 17px;
	margin-top: -5px;
	color: #4e51bf;
}
.two {
	line-height: 25px;
}
.two p:nth-of-type(1) {
	margin-top: 40px;
}

.coin {
	width: 65px;
	height: 65px;
	background-image: url('../../../public/static/images/coin.png');
	background-repeat: no-repeat;
	background-size: 100%;
	margin-top: 10px;
	margin-left: 15px;
}
.btn {
	background: #4e51bf;
	color: #fff;
	text-align: center;
	margin-top: 20px;
	height: 22px;
	width: 75px;
	line-height: 22px;
	border-radius: 9px;
	font-size: 12px;
	margin-bottom: 10px;
}
.end {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
</style>
