<template>
	<div class="PageBox">
		<div class="bgc">
			<div class="topTitle">{{$t('bindcoin[0]')}}</div>
		</div>
		<div class="box">
			<div class="information">
				<p v-if="drawList[active]">{{drawList[active].currencies[0]}}{{$t('bindcoin[1]')}}</p>
				<input v-model="card_no" :placeholder="$t('bindcoin[2]')" />
			</div>
			<!-- <div class="selectBox">
				<div class="select" v-if="drawList[active]">
					<p :class="{'active':active==index}"  v-for="(item,index) in drawList" :key="index" @click="upActive(index)">{{item.file_name}}</p>
				</div>
			</div> -->
			<!-- <div class="informationBox">
				<p class="information-title">{{$t('bindcoin[6]')}}</p>
				<div class="information">
					<p v-if="drawList[active]">{{drawList[active].currencies[0]}}{{$t('bindcoin[7]')}}:</p>
					<input v-model="card_no"/>
				</div>
			</div> -->
			<div class="QRBox">
				<!-- 先隐藏，上传二维码 -->
				<div class="upload" v-if="false">
					<p class="QR-title">{{$t('bindcoin[3]')}}</p>
					<div class="upload_update">
						<!-- <van-uploader upload-icon="photo" max-count="1" v-model="fileList[index]" :after-read="afterRead" multiple /> -->
						<van-uploader v-model="imgList[0]" max-count="1" :after-read="afterRead" :before-delete="deleteImg">
							<img class="btnImg" src="../../../public/static/images/shangChuanErWeiMa.png">
						</van-uploader>
						<p class="title">{{$t('bindcoin[4]')}}</p>
					</div>
				</div>
				<p class="btn" @click="submitInfo()">{{$t('bindcoin[5]')}}</p>
			</div>
		</div>
		<div class="description" :class="{'letter_s':language1}">
			<p class="title">{{$t('bindcoin[6]')}}</p>
			<p class="p">
				1.{{$t('bindcoin[7]')}}
				<a class="toRegister" @click="$router.push('/register')">{{$t('bindcoin[14]')}}</a>
			</p>
			<p class="p">2.{{$t('bindcoin[8]')}}</p>
			<p class="p">3.{{$t('bindcoin[9]')}}</p>
			<p class="p">4.{{$t('bindcoin[10]')}}</p>
			<p class="p">5.{{$t('bindcoin[11]')}}</p>
			<p class="p">6.{{$t('bindcoin[12]')}}</p>
			<p class="p">7.{{$t('bindcoin[13]')}}</p>
		</div>
		<van-dialog v-model="showSuccess" title="" class="thisDialog" :show-confirm-button="false" :closeOnClickOverlay="true">
			<h2>{{$t('bindcoin[15]')}}</h2>
			<div class="mycontent" :class="{'letter_s':language1}">
				{{$t('bindcoin[16]')}}
			</div>
			<img src="../../../public/static/icon2/delete.png" alt="" class="dialogimg" @click="showSuccess=false" />
		</van-dialog>
		<van-loading class="Loading" size="50" vertical v-if="isLoad">{{$t('recharge.default[8]')}}</van-loading>
	</div>
</template>

<script>
	export default {
		name: 'Wallet',
		components: {

		},
		props: ['walletType'],
		data() {
			return {
				listData: '',
				isLoad: true,
				isFinished: false,
				isRefresh: false,
				pageNo: 1,
				tabsState: 1,
				tabsIndex: 0,
				taskTabs: [{
						type: 1,
						text: this.$t('wallet.default[3]')
					},
					// {type: 2, text: '转账记录'},
					{
						type: 3,
						text: this.$t('wallet.default[4]')
					}
				],
				showTurn: false,
				showDraw: false,
				showPicker: false,
				selectBank: '',
				isSubmit: false,
				turnData: {
					username: '',
					turn_money: '',
					drawword: ''
				},
				drawData: {
					draw_type: 'bank',
					user_bank_id: '',
					draw_money: 100,
					// phone: '',
					email: '',
					// ifsc: '',
					drawword: ''
				},
				//上传图片
				imgList: [],
				rechargeList: [], //交易信息
				upiData: {}, //应该是我的信息
				active: 0, //点击的哪个充值平台
				drawList: [], //提现信息
				card_no: '', //提现地址
				bankInfo: {}, //我的绑定信息
				showSuccess:false,//绑定成功提示
				language1:localStorage.getItem('Language')=='cn',
			}
		},
		computed: {

		},
		created() {
			this.$parent.navBarTitle = ''
			this.GetWithdrawType()
		},
		mounted() {

		},
		activated() {

		},
		destroyed() {

		},
		methods: {
			deleteImg() {
				console.log(1)
				this.imgList = []
			},
			//提交绑定
			submitInfo() {
				if (!this.card_no) {
					this.$Dialog.Toast('The withdrawal address cannot be empty');
					return false
				}
				let json = {
					uid: this.UserInfo.userid,
					currency: this.drawList[this.active].currencies[0],
					card_no: this.card_no,
					bank_code: this.drawList[this.active].file_name,
					draw_cfg_id: this.drawList[this.active].id,
				}
				if (this.imgList[0]) {
					if (this.imgList[0][0].url || this.imgList[0][0].length > 3) {
						json.qr_code = this.imgList[0][0].url
					} else if (this.imgList[0].length > 3) {
						json.qr_code = this.imgList[0]
					}
				}
				this.$Model.ChangeBankCardInfo(json, (data) => {
					// this.$Dialog.Toast('success');
					if(data.code==1){
						this.showSuccess=true
					}
				})
			},
			//绑定信息
			GetWithdrawType() {
				this.$Model.GetWithdrawType(data => {
					console.log(data)
					this.isLoad = false
					//字符串转数组
					data.info.forEach(item => {
						item.currencies = item.currencies.split(',')
					})
					// if(data.bank.data.bank_code){
					// 	data.info.forEach((item,index)=>{
					// 		if(item.file_name==data.bank.data.bank_code){
					// 			this.active=index
					// 		}
					// 	})
					// 	//我的绑定信息
					// 	this.bankInfo=data.bank.data
					// 	//银行卡账号
					// 	this.card_no=this.bankInfo.card_no
					// 	let url=[]
					// 	if(data.bank.data.qr_code){
					// 		url[0]={url:data.bank.data.qr_code}
					// 		this.imgList[0]=url
					// 	}
					// }
					//绑定什么平台
					this.drawList = data.info
				})
				this.isLoad = false
			},
			//修改绑定平台active
			upActive(index) {
				this.active = index
			},
			//上传图片回调
			afterRead(file) {
				file.status = 'uploading'
				file.message = this.$t('upload[0]')
				this.uploadImgs(file)
			},
			uploadImgs(file) {
				if (!file.file.type.match(/image/)) {
					file.status = 'failed'
					file.message = this.$t('upload[1]')
					return;
				}
				this.compressImg(file)
			},
			compressImg(file) {
				this.$Util.CompressImg(file.file.type, file.content, 750, (image) => {
					let param = new FormData();
					param.append('token', localStorage['Token']);
					param.append('type', 3);
					param.append('image', image, file.file.name);
					this.$Model.UploadImg(param, (data) => {
						if (data.code == 1) {
							file.message = this.$t('upload[2]')
							file.status = 'success'
							file.url = data.url
							this.imgList[0] = data.url
						} else {
							file.status = 'failed'
							file.message = this.$t('upload[3]')
						}
					});
				})
			},
		}
	}
</script>

<style scoped>
	/* .van-nav-bar--fixed,.Site .van-nav-bar{
	background-color: #0f25fe;
} */
	.PageBox {
		overflow: auto;
	}

	.PageBox .bgc {
		width: 100%;
		background-color: #4E51BF;
		height: 46px;
		position: fixed;
		top: 0;
		z-index: 200;
	}

	.topTitle {
		position: absolute;
		max-width: 60%;
		white-space: nowrap;
		font-size: 18px;
		color: #fff;
		background-color: transparent;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
	}

	.PageBox .box {
		padding: 0 19px;
		font-size: 19px;
		margin-top: 25px;
		color: #555;
	}

	.p .toRegister {
		color: #4E51BF !important;
		padding-left: 20px;
	}

	/*.box .selectBox{
	border-bottom: 1px dashed #c9c9c9;
}	
.box .select{
	max-width: 400px;
	display: flex;
	margin: 40px 0;
	flex-wrap: wrap;
}
.box .select p{
	padding:10px 15px;
	border-radius: 4px;
	text-align: center;
	font-size: 16px;
	border: 1px solid #0f25fe;
	color: #0f25fe;
	margin:0 25px 12px 0;
}
.box .select p:last-child{
	margin-right: 0;
}
p.active{
	background-color: #0f25fe !important;
	color: #fff !important;
}
.informationBox{
	border-bottom: 1px dashed #c9c9c9;
}
.information-title{
	margin:40px 0 34px 0;
}*/
	/* .information-title{
		margin:40px 0 34px 0;
	} */
	.information{
		display: flex;
		font-size: 14px;
		font-family: Alibaba;
		align-items: center;
		max-width:550px;
		background: #EFF0F2;
		border-radius: 10px;
		overflow: hidden;
		padding:0 15px;
		margin-bottom: 25px;
	}
	.information p{
		color:#000;
		display: flex;
		align-items: center;
		width:110px;
		word-wrap:break-word;
	}
	.information input{
		flex:1;
		background: #EFF0F2;
		height: 30px;
		line-height: 40px;
		border: none;
		border-radius: 4px;
		border: none;
		font-size: 13px;
		font-family: Alibaba;
		font-weight: normal;
		color: #626262;
		position: relative;
	}

	.QRBox .QR-title {
		font-size:14px;
		color: #000;
		font-family: Alibaba;
		font-weight: normal;
		
	}

	.upload_update {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		flex: 1;
		position: relative;
	}

	.QRBox .upload {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		width: fit-content;
		margin: auto;
	}

	.QRBox .btn {
		width: 140px;
		padding: 5px 0;
		border-radius: 15px;
		background-color: #4E51BF;
		color: #fff;
		margin: 40px auto 70px auto;
		text-align: center;
	}

	.QRBox .upload .title {
		font-size: 12px;
		transform: scale(0.9);
		color: #fff;
		padding: 4px 10px;
		background-color: #4E51BF;
		border-radius: 9px;
		line-height: 17px;
	}

	.QRBox .upload .van-uploader>>>.btnImg {
		width: 140px;
		height: 140px;
		padding: 45px;
		border-radius: 8px;
		background: #EFF0F2;
		box-sizing: border-box;
	}

	.van-uploader>>>.van-uploader__preview {
		margin: 0px 0 0 0;
	}

	.van-uploader>>>.van-uploader__preview-delete {
		top: 2px;
		right: 2px;
	}

	.QRBox>>>.van-uploader {
		margin: 15px;
		height: 140px;
		box-sizing: border-box;
		border-radius: 8px;
		overflow: hidden;
		position: static !important;
	}

	.QRBox>>>.van-uploader__input-wrapper {
		position: static !important;
	}

	.van-uploader>>>.van-image {
		width: 140px;
		height: 140px;
		box-sizing: border-box;
	}

	.description{
		font-size: 18px;
		color: #555;
		background-color: #fff;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
		padding:40px 33px;
		border-radius: 20% 20% 0 0;
		position: relative;
	}
	.description p{
		color:#000;
		font-size: 13px;
		margin-bottom: 8px;
		line-height: 2.1;
		font-family: Alibaba;
		font-weight: normal;
	}
	.letter_s{
		letter-spacing: 0.04rem;
	}

	.description p:nth-child(2) {
		margin-top: 10px;
	}

	.description .title {
		font-size: 14px;
		position: absolute;
		top:0px;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index:1;
		width:200px;
		padding:5px 0;
		box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.3);
		border-radius: 6px;
		box-sizing: border-box;
		background-color: #fff;
		text-align: center;
	}

	.van-nav-bar__title {
		color: #fff !important;
	}
	/* 弹出框 */
	.PageBox >>> .van-dialog {
	  overflow: initial;
	}
	.thisDialog {
	  height: 250px;
	}
	.thisDialog h2 {
	  text-align: center;
	  margin-top: 50px;
	  font-size: 21px;
	  font-weight: 600;
	  font-family: emoji;
	  letter-spacing: 1px;
	}
	.mycontent {
	  margin: 20px;
	  color: #666666;
	  line-height: 25px;
	  font-size: 15px;
	}
	.dialogimg {
	  width: 35px;
	  height: 35px;
	  position: absolute;
	  top: 280px;
	  left: 140px;
	  z-index: 3000;
	}
	.van-loading {
		position: fixed;
		z-index: 99999;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
</style>
