import Vue from "vue";
import Vuex, { mapState, mapGetters, mapActions } from "vuex";

Vue.use(Vuex);
const ApiUrl = "";
export default new Vuex.Store({
  state: {
    Authorization: "X1VUKlhPdauGqXm7",
    ApiUrl: localStorage["CurrLine"] || ApiUrl,
    InitData: localStorage["InitData"]
      ? JSON.parse(localStorage["InitData"])
      : {
          BanksList: [],
          UserGradeList: [],
          authenticationList: [],
          bannerList: [],
          businessList: [],
          currency: "",
          disclaimerList: [],
          drawNotice: [],
          helpList: [],
          link: [],
          memberList: [],
          noticelist: [],
          serviceList: [],
          setting: {
            up_url: "",
          },
          taskclasslist: [],
          userviplist: [],
        },
    UserInfo: localStorage["UserInfo"]
      ? JSON.parse(localStorage["UserInfo"])
      : "",
    BankCardList: localStorage["BankCardList"]
      ? JSON.parse(localStorage["BankCardList"])
      : [],
    MiUserInfo: localStorage["MiUserInfo"]
      ? JSON.parse(localStorage["MiUserInfo"])
      : "",
    ChatData: localStorage["ChatData"]
      ? JSON.parse(localStorage["ChatData"])
      : "",
    FriendInfo: localStorage["FriendInfo"]
      ? JSON.parse(localStorage["FriendInfo"])
      : "",
    SoFriendInfo: localStorage["SoFriendInfo"]
      ? JSON.parse(localStorage["SoFriendInfo"])
      : "",
    GroupInfo: localStorage["GroupInfo"]
      ? JSON.parse(localStorage["GroupInfo"])
      : "",
    SoGroupInfo: localStorage["SoGroupInfo"]
      ? JSON.parse(localStorage["SoGroupInfo"])
      : "",
    FriendList: localStorage["FriendList"]
      ? JSON.parse(localStorage["FriendList"])
      : "",
    GroupList: localStorage["GroupList"]
      ? JSON.parse(localStorage["GroupList"])
      : "",
    GroupMember: localStorage["GroupMember"]
      ? JSON.parse(localStorage["GroupMember"])
      : "",
    StatisticalData: localStorage["StatisticalData"]
      ? JSON.parse(localStorage["StatisticalData"])
      : "",
    ActivityGetData: localStorage["ActivityGetData"]
      ? JSON.parse(localStorage["ActivityGetData"])
      : "", //活动详情
    ActivityCenterList: localStorage["ActivityCenterList"]
      ? JSON.parse(localStorage["ActivityCenterList"])
      : "", //活动中心
    VipFlowList: localStorage["VipFlowList"]
      ? JSON.parse(localStorage["VipFlowList"])
      : "", //vip页面轮播
    VipRuleList: localStorage["VipRuleList"]
      ? JSON.parse(localStorage["VipRuleList"])
      : "", //vip页面规则
  },
  getters: {
    VipList: (state) => {
      return state.InitData.UserGradeList
        ? state.InitData.UserGradeList.filter((item) => item.grade != 1)
        : "";
    },
  },
  mutations: {
    UpdateApiUrl(state, payload) {
      state.ApiUrl = payload.data;
      localStorage["ApiUrl"] = JSON.stringify(payload.data);
    },
    UpdateInitData(state, payload) {
      state.InitData = payload.data;
      localStorage["InitData"] = JSON.stringify(payload.data);
    },
    UpdateUserInfo(state, payload) {
      state.UserInfo = payload.data;
      localStorage["UserInfo"] = JSON.stringify(payload.data);
    },
    UpdateBankCardList(state, payload) {
      state.BankCardList = payload.data;
      localStorage["BankCardList"] = JSON.stringify(payload.data);
    },
    UpdateMiUserInfo(state, payload) {
      state.MiUserInfo = payload.data;
      localStorage["MiUserInfo"] = JSON.stringify(payload.data);
    },
    UpdateChatData(state, payload) {
      state.ChatData = payload.data;
      localStorage["ChatData"] = JSON.stringify(payload.data);
    },
    UpdateFriendInfo(state, payload) {
      state.FriendInfo = payload.data;
      localStorage["FriendInfo"] = JSON.stringify(payload.data);
    },
    UpdateSoFriendInfo(state, payload) {
      state.SoFriendInfo = payload.data;
      localStorage["SoFriendInfo"] = JSON.stringify(payload.data);
    },
    UpdateGroupInfo(state, payload) {
      state.GroupInfo = payload.data;
      localStorage["GroupInfo"] = JSON.stringify(payload.data);
    },
    UpdateSoGroupInfo(state, payload) {
      state.SoGroupInfo = payload.data;
      localStorage["SoGroupInfo"] = JSON.stringify(payload.data);
    },
    UpdateFriendList(state, payload) {
      state.FriendList = payload.data;
      localStorage["FriendList"] = JSON.stringify(payload.data);
    },
    UpdateGroupList(state, payload) {
      state.GroupList = payload.data;
      localStorage["GroupList"] = JSON.stringify(payload.data);
    },
    UpdateGroupMember(state, payload) {
      state.GroupMember = payload.data;
      localStorage["GroupMember"] = JSON.stringify(payload.data);
    },
    UpdateStatisticalData(state, payload) {
      state.StatisticalData = payload.data;
      localStorage["StatisticalData"] = JSON.stringify(payload.data);
    },
    UpdateActivityGetData(state, payload) {
      state.ActivityGetData = payload.data;
      localStorage["ActivityGetData"] = JSON.stringify(payload.data);
    },
    UpdateActivityCenterList(state, payload) {
      state.ActivityCenterList = payload.data;
      localStorage["ActivityCenterList"] = JSON.stringify(payload.data);
    },
    UpdateVipFlowList(state, payload) {
      state.VipFlowList = payload.data;
      localStorage["VipFlowList"] = JSON.stringify(payload.data);
    },
    UpdateVipRuleList(state, payload) {
      state.VipRuleList = payload.data;
      localStorage["VipRuleList"] = JSON.stringify(payload.data);
    },
  },
  actions: {
    UpdateApiUrl: ({ commit }, data) =>
      commit("UpdateApiUrl", {
        data,
      }),
    UpdateInitData: ({ commit }, data) =>
      commit("UpdateInitData", {
        data,
      }),
    UpdateUserInfo: ({ commit }, data) =>
      commit("UpdateUserInfo", {
        data,
      }),
    UpdateBankCardList: ({ commit }, data) =>
      commit("UpdateBankCardList", {
        data,
      }),
    UpdateMiUserInfo: ({ commit }, data) =>
      commit("UpdateMiUserInfo", {
        data,
      }),
    UpdateChatData: ({ commit }, data) =>
      commit("UpdateChatData", {
        data,
      }),
    UpdateFriendInfo: ({ commit }, data) =>
      commit("UpdateFriendInfo", {
        data,
      }),
    UpdateSoFriendInfo: ({ commit }, data) =>
      commit("UpdateSoFriendInfo", {
        data,
      }),
    UpdateGroupInfo: ({ commit }, data) =>
      commit("UpdateGroupInfo", {
        data,
      }),
    UpdateSoGroupInfo: ({ commit }, data) =>
      commit("UpdateSoGroupInfo", {
        data,
      }),
    UpdateFriendList: ({ commit }, data) =>
      commit("UpdateFriendList", {
        data,
      }),
    UpdateGroupList: ({ commit }, data) =>
      commit("UpdateGroupList", {
        data,
      }),
    UpdateGroupMember: ({ commit }, data) =>
      commit("UpdateGroupMember", {
        data,
      }),
    UpdateStatisticalData: ({ commit }, data) =>
      commit("UpdateStatisticalData", {
        data,
      }),
    UpdateActivityGetData: ({ commit }, data) =>
      commit("UpdateActivityGetData", {
        data,
      }),
    UpdateActivityCenterList: ({ commit }, data) =>
      commit("UpdateActivityCenterList", {
        data,
      }),
    UpdateVipFlowList: ({ commit }, data) =>
      commit("UpdateVipFlowList", {
        data,
      }),
    UpdateVipRuleList: ({ commit }, data) =>
      commit("UpdateVipRuleList", {
        data,
      }),
  },
  modules: {},
});

Vue.mixin({
  computed: {
    ...mapState([
      "ApiUrl",
      "InitData",
      "UserInfo",
      "BankCardList",
      "MiUserInfo",
      "ChatData",
      "FriendInfo",
      "SoFriendInfo",
      "GroupInfo",
      "SoGroupInfo",
      "FriendList",
      "GroupList",
      "GroupMember",
      "StatisticalData",
      "ActivityGetData",
      "ActivityCenterList",
      "VipFlowList",
      "VipRuleList",
    ]),
    ...mapGetters(["VipList"]),
  },
  methods: {
    ...mapActions([
      "UpdateApiUrl",
      "UpdateInitData",
      "UpdateUserInfo",
      "UpdateBankCardList",
      "UpdateMiUserInfo",
      "UpdateChatData",
      "UpdateFriendInfo",
      "UpdateSoFriendInfo",
      "UpdateGroupInfo",
      "UpdateSoGroupInfo",
      "UpdateFriendList",
      "UpdateGroupList",
      "UpdateGroupMember",
      "UpdateStatisticalData",
      "UpdateActivityGetData",
      "UpdateActivityCenterList",
      "UpdateVipFlowList",
      "UpdateVipRuleList",
    ]),
  },
});
