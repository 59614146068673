<template>
  <div class="theAgencyBox">
    <div class="bgc">
      <div class="topTitle">{{ $t("TheAgency.default[0]") }}</div>
       <!-- @click="$router.push('/introduced')" -->
    </div>
    <div class="rightTitle"  @click="$router.push('/introduced')">{{$t("TheAgency.default[6]")}}</div>

    <div class="height"></div>
    <div class="theAgency_nav">
      <!-- nav -->
      <ul class="theAgency_nav_box">
        <li
          class="theAgency_nav_item"
          v-for="(item, index) in srcList"
          :key="index"
          @click="open(index)"
        >
          <div class="theAgency_nav_item_img">
            <img :src="item" />
          </div>
          <div class="theAgency_nav_item_title">
            {{ $t("TheAgency.nav[" + index + "]") }}
          </div>
        </li>
      </ul>
    </div>
    <div class="theAgency_content">
      <!-- content -->
      <div class="theAgency_content_box">
        <div class="theAgency_content_item">
          <div class="theAgency_content_item_img performance1">
            <img src="../../../public/static/images/geRenYeJi.png" />
          </div>
          <div class="theAgency_content_item_title">
            {{ $t("TheAgency.default[1]") }}
          </div>
          <div class="theAgency_content_item_money">
            {{ personal_performData }}
          </div>
        </div>
        <div class="theAgency_content_item">
          <div class="theAgency_content_item_img performance2">
            <img src="../../../public/static/images/tuanDuiYeJi.png" />
          </div>
          <div class="theAgency_content_item_title">
            {{ $t("TheAgency.default[2]") }}
          </div>
          <div class="theAgency_content_item_money">{{ performData_team }}</div>
        </div>
        <div class="theAgency_content_item">
          <div class="theAgency_content_item_img subordinate3">
            <img src="../../../public/static/images/geRenYeJi.png" />
          </div>
          <div class="theAgency_content_item_title">
            {{ $t("TheAgency.default[3]") }}
          </div>
          <div class="theAgency_content_item_money fontBlue">
            {{ teamData_m }}
          </div>
        </div>
        <div class="theAgency_content_item">
          <div class="theAgency_content_item_img subordinate4">
            <img src="../../../public/static/images/tuanDuiYeJi.png" />
          </div>
          <div class="theAgency_content_item_title">
            {{ $t("TheAgency.default[4]") }}
          </div>
          <div class="theAgency_content_item_money fontBlue">
            {{ subordinate }}
          </div>
        </div>
      </div>
      <!-- footer -->
      <div class="theAgency_moneyBox">
        <div class="theAgency_moneyBox_title">
          {{ $t("TheAgency.default[5]") }}
        </div>
        <div class="theAgency_moneyBox_box">
          <div class="theAgency_moneyBox_item">
            <div class="theAgency_moneyBox_item_money">{{ commission }}</div>
            <div class="theAgency_moneyBox_item_title">
              {{ $t("TheAgency.detail[0]") }}
            </div>
          </div>
          <div class="theAgency_moneyBox_item">
            <div class="theAgency_moneyBox_item_money">
              {{ commission_team }}
            </div>
            <div class="theAgency_moneyBox_item_title">
              {{ $t("TheAgency.detail[1]") }}
            </div>
          </div>
          <div class="theAgency_moneyBox_item">
            <div class="theAgency_moneyBox_item_money">
              {{ poorCommission }}
            </div>
            <div class="theAgency_moneyBox_item_title">
              {{ $t("TheAgency.detail[2]") }}
            </div>
          </div>
        </div>
      </div>
      <div class="theAgency_imgBox"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "theAgency",
  components: {},
  data() {
    return {
      srcList: [
        //保存的nav图片
        "/static/images/yaoQingXiaJi.png",
        "/static/images/WoDeTuanDui.png",
        "/static/images/yeJiChaXun.png",
        "/static/images/yongJinJiLu.png",
      ],
      goToPageList: [
        "/user/promote",
        "/user/myTeam",
        "/user/resultsTheQuery",
        "/user/commissionRecord",
      ],
      personal_performData: 0, //本月个人数据
      performData_team: 0, //本月团队数据
      teamData_m: 0, //本月新增下级
      subordinate: 0, //直属下级
      commission: 0, //个人业绩佣金
      commission_team: 0, //团队佣金
      poorCommission: 0, //极差佣金
    };
  },
  created() {
    //标题
    this.$parent.navBarTitle = "";
    //获取团队数据
    this.getTeamData();
    //获取直属下级
    this.get_subordinate();
    //获取本月个人业绩
    this.getResults();
    //获取本月团队数据
    this.getperformData_team();
    //获取个人业绩佣金
    this.getCommissionRecord(1);
    this.getCommissionRecord(2); //获取团队业绩佣金
  },

  methods: {
    //获取本月新增下级
    getTeamData() {
      this.$Model.getTeamData(
        { registerType: 3 /* username: localStorage.getItem("MiName") */ },
        ({ teamData }) => {
          this.teamData_m = teamData.length;
        }
      );
    },
    //获取直属下级
    get_subordinate() {
      this.$Model.getTeamData({}, ({ teamData }) => {
        this.subordinate = teamData.length;
      });
    },
    //获取本月个人业绩
    getResults() {
      this.$Model.getResults(
        {
          type: 1,
          period: 3 /*  startdate: "2021-11-10", enddate: "2021-12-10" */,
        },
        (res) => {
          if (res.performData.length > 0) {
            this.personal_performData = res.performData[0].total_task_amount;
          }
        }
      );
    },
    //获取本月团队业绩
    getperformData_team() {
      this.$Model.getResults(
        {
          type: 2,
          period: 3 /*  startdate: "2021-11-10", enddate: "2021-12-10" */,
        },
        (res) => {
          console.log(res, 1);
          if (!res.performData) {
            return;
          }
          if (Object.keys(res.performData).length > 0) {
            let total_task_amount = 0;
            for (const item in res.performData) {
              total_task_amount += res.performData[item].total_task_amount;
            }
            this.performData_team = total_task_amount;
          }
        }
      );
    },

    //获取个人业绩佣金和团队
    getCommissionRecord(type) {
      this.$Model.getCommissionRecord({ type: type, period: 2 }, (res) => {
        if (res.commisionData.length > 0) {
          if (type == 1) {
            //获取个人业绩佣金
            this.commission = res.commisionData[0].reward_amount;
          } else {
            //获取团队业绩佣金
            this.commission_team = res.commisionData[0].reward_amount;
            //极差佣金
            this.poorCommission = res.commisionData[0].level_reward;
          }
        }
      });
    },
    //跳转
    open(index) {
      this.$router.push(this.goToPageList[index]);
    },
  },
};
</script>

<style scoped>
/* .van-nav-bar--fixed,.Site .van-nav-bar{
	background-color: #0f25fe;
} */
.theAgencyBox {
  overflow: auto;
  height: 100vh;
}
.theAgencyBox .bgc {
  width: 100%;
  background-color: #4e51bf;
  height: 46px;
  position: fixed;
  top: 0;
  z-index: 200;
}

.topTitle {
  position: absolute;
  max-width: 60%;
  white-space: nowrap;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.rightTitle{
  color: #f59a23;
  font-size: 18px;
  position: absolute;
   top: 2%;
  left: 80%;
  z-index: 1111;
}
.height {
  height: 46px;
}
.theAgency_nav {
  width: 100%;
}
.theAgency_nav .theAgency_nav_box {
  padding: 20px 40px 85px;
  background-color: #4e51bf;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.theAgency_nav .theAgency_nav_box .theAgency_nav_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  margin-bottom: 15px;
}
.theAgency_nav .theAgency_nav_box .theAgency_nav_item .theAgency_nav_item_img {
  width: 45px;
  height: 45px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: #3a3ca2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.theAgency_nav .theAgency_nav_item_img img {
  width: 55%;
}
.theAgency_nav .theAgency_nav_item_title {
  margin-top: 5px;
  color: #d0d1ee;
  font-size: 14px;
  text-align: center;
}
.theAgency_content {
  width: 100%;
  padding: 0 20px;
  background-color: #fff;
  position: relative;
  top: -60px;
  border-radius: 50px 0 0 0;
  display: flex;
  flex-direction: column;
}
.theAgency_content .theAgency_content_box {
  display: flex;
  flex-direction: column;
}
.theAgency_content .theAgency_content_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 40px;
  padding: 7px 7px 0;
  background-color: #f5f5f9;
  margin-top: 15px;
  box-sizing: border-box;
  height: 63px;
}
.theAgency_content .performance1 {
  width: 60px;
  height: 60px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-shrink: 0;
}
.theAgency_content .performance2 {
  width: 60px;
  height: 60px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-shrink: 0;
}
.theAgency_content .subordinate3 {
  width: 60px;
  height: 60px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-shrink: 0;
}
.theAgency_content .subordinate4 {
  width: 60px;
  height: 60px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-shrink: 0;
}
.theAgency_content_box
  .theAgency_content_item:nth-child(1)
  .theAgency_content_item_img
  img,
.theAgency_content_box
  .theAgency_content_item:nth-child(3)
  .theAgency_content_item_img
  img {
  width: 30%;
  margin-top: -5px;
}
.theAgency_content_box
  .theAgency_content_item:nth-child(2)
  .theAgency_content_item_img
  img,
.theAgency_content_box
  .theAgency_content_item:nth-child(4)
  .theAgency_content_item_img
  img {
  width: 44%;
  margin-top: -5px;
}
.theAgency_content .theAgency_content_item_title {
  margin-left: 3%;
  flex: 1;
  font-size: 14px;
  color: #333;
  margin-top: -3px;
}
.theAgency_content .theAgency_content_item_money {
  font-size: 17px;
  color: #fe93a5;
  font-weight: bold;
  width: 100px;
  margin-left: 5px;
}
.fontBlue {
  color: #4e51bf !important;
}
.theAgency_moneyBox {
  margin-top: 30px;
}
.theAgency_moneyBox .theAgency_moneyBox_title {
  color: #000;
  font-weight: bold;
  font-size: 17px;
}
.theAgency_moneyBox .theAgency_moneyBox_box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  padding: 20px 10px;
  background-color: #f5f5f9;
  border-radius: 8px;
}
.theAgency_moneyBox .theAgency_moneyBox_item {
  min-width: 250px;
  display: flex;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  /* border: 5px solid rgb(245, 245, 249); */
  border-radius: 10px;
  text-align: center;
  background-color: #fff;
}
.theAgency_moneyBox .theAgency_moneyBox_item_money {
  font-size: 18px;
  color: rgb(78, 81, 191);
  font-weight: bold;
}
.theAgency_moneyBox .theAgency_moneyBox_item_title {
  font-size: 14px;
  color: #333;
  margin-top: 7px;
}
.theAgency_imgBox {
  margin-top: 50px;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 30px;
}
.theAgency_imgBox .theAgency_imgBox_left {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.theAgency_imgBox .theAgency_imgBox_left img {
  height: 80%;
}
.theAgency_imgBox .theAgency_imgBox_right {
  width: 55px;
  height: 55px;
  background-color: #4e51bf;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}
.theAgency_imgBox .theAgency_imgBox_right img {
  height: 60%;
}
</style>
