<template>
  <div class="Grabtask">
    <div class="title">
      <div>{{ $t("görev[16]") }}</div>
    </div>
    <div class="slotMachine">
      <div class="slotMachine-img1">
        <img src="../../../public/static/images/grabtiger.png" alt="" />
      </div>
      <div class="video">
      </div>
      <div class="slotMachine-img2">
        <img :src="img" v-show="upDown" @click="down()" />
        <!-- <img :src="img" v-show="upDown" @click="goToComepleteTask" /> -->
        <!-- <img :src="`../../../public/static/images/garb1_${lang}.png`" alt="" v-show="!upDown"> -->
      </div>
    </div>
    <div class="Grabtask_main">
      <!-- 体验一次按钮 -->
      <!-- <div class="experienceOnce" @click="down('体验')">体验一次</div> -->
      <div class="upgradevip" @click="toWallet()">{{ $t("görev[0]") }}</div>
      <div class="todayOrder">
        <!-- <div class="orderTitle">{{ $t("grabtask[1]") }}</div> -->
        <!-- 点击完成任务 -->
        <!--   <div class="orderList" v-if="isShow_orderList">
          <div class="order">
            <div class="orderLeft">
              <img src="../../../public/static/images/orderTit.png" />
            </div>
            <div class="orderRight">
              <p style="font-size: 16px; color: #fff">
                {{ $t("grabtask[20]") }}
              </p>
              <button @click="completeTheTask()">
                {{ $t("grabtask[21]") }}
              </button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 领取成功的 弹窗 -->
    <van-popup
      v-model="show"
      round
      style="overflow: visible; background: transparent"
    >
      <div class="success">
        <div class="successImg">
          <img src="../../../public/static/images/success.png" />
        </div>
        <div class="successContent">
          <h2>{{ textList[0] }}</h2>
          <p>{{ textList[1] }}</p>
          <!-- , { moeny: moeny }) -->
          <div>
            <!-- <div @click="setShow()">{{ $t("grabtask[6]") }}</div> -->
            <div @click="completeTheTask()">
              {{ $t("inviteSubordinates[13]") }}
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 领取失败的 弹窗 -->
    <van-popup
      v-model="shows"
      style="overflow: visible; background: transparent"
    >
      <div class="fail" v-if="flag">
        <div class="failImg">
          <img src="../../../public/static/images/fail.png" />
        </div>
        <div class="failContent">
          <h2>{{ textList[0] }}</h2>
          <p>{{ textList[1] }}</p>
          <div>
            <div @click="toWallet()">{{ $t("görev[16]") }}</div>
            <div @click="setShows()">{{ $t("görev[9]") }}</div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 体验资格弹窗 -->
    <van-popup class="experiencePopup" v-model="showExperience">
      <div class="delete" @click="closeExperiencePopup">X</div>
      <h2 class="title">恭喜你获得体验资格</h2>
      <div class="content">
        <p class="item">1、可体验一次商城任务过程</p>
        <p class="item">2、体验卡不可提现</p>
        <p class="item">3、体验金获得的佣金可提现</p>
        <p class="item">4、领取体验金后，点击抢单按钮完成任务</p>
        <p class="item">5、每人仅能获得一次体验机会</p>
      </div>
      <div class="goToComepleteTask" @click="goMytask()">
        去完成任务
      </div>
    </van-popup>
    <!-- 领取过体验金弹窗 -->
    <van-overlay :show="isShowAlreadyPopop" class="already">
      <already @close="cl_ex"></already>
    </van-overlay>
  </div>
</template>

<script>
// import { Toast, Dialog } from "vant";
import already from "../../components/tanchuang/alreay.vue";
export default {
  name: "Grabtask",
  components: { already },
  data() {
    return {
      upDown: true, //判断按钮是否按下
      flag: true, //防抖
      show: false, //领取任务成功 弹窗是否显示
      shows: false, //领取任务失败 弹窗是否显示
      textList: [
        // 弹窗显示的内容
      ],
      taskList: [], //任务列表
      // isShow_orderList: false, // 是否显示点击完成任务
      lang: "vn", //默认语言
      img: require("../../../public/static/images/garb2_cn.png"), //抢图片
      moeny: "", //抢任务的佣金
      测试用任务编号: "",
      showExperience: false, //控制体验资格弹窗,
      isShowAlreadyPopop: false, //控制领取过体验金弹窗
    };
  },
  computed: {},
  created() {
    if (localStorage.getItem("Language")) {
      this.lang = localStorage.getItem("Language");
      this.img = require(`../../../public/static/images/garb2_${this.lang}.png`);
    } else {
      this.img = require(`../../../public/static/images/garb2_${this.lang}.png`);
    }
    this.getTodayShopTask((data) => {
      console.log("getTodayShopTask", data);
      if (data.list.length > 0) {
        this.isShow_orderList = true;
        this.taskList = data.list;
        this.mapTaskList();
      }
    });
    console.log(this.img);
  },
  mounted() {},
  methods: {
    // 关闭已经领取过体验经弹窗
    cl_ex() {
      this.isShowAlreadyPopop = false;
    },
    down(type) {
      //体验一次
      // if (type == "体验") {
      //   // 判断 已经领取过体验金
      //   if (
      //     JSON.parse(localStorage.getItem("UserInfo")).experienced == 1 ||
      //     localStorage.getItem("experience") == 1
      //   ) {
      //     this.isShowAlreadyPopop = true;
      //     return;
      //   }
      // }

      if (this.flag) {
        this.flag = false;
        this.img = require(`../../../public/static/images/garb1_${this.lang}.png`);
        let video = document.querySelector(".video>video");
        video.play();

        setTimeout(() => {
          this.$Model.acceptDispatchTask((data) => {
            console.log(data);
            // 正常任务

            switch (
              // code = 0 运行时错误  1 领取成功 -1 当前包未完成则无法接取下一个任务包 -2 当前接取的包无派单配置 -3 没有适合当前任务包接取的任务 -4 你有订单未完成 请先完成订单
              data.code
            ) {
              case 1:
                //体验金 任务
                // if (data.experience === 1) {
                //   //存储已经领取过体验金的状态
                //   localStorage.setItem("experience", 1);
                //   //显示 体验资格弹窗
                //   return this.goToComepleteTask();
                // }
                console.log("领取成功");
                this.textList[0] = this.$t("inviteSubordinates[9]"); //textList[0 || 1]  0 是弹窗显示的标题 1 是内容
                this.textList[1] = this.$t("görev[4]");
                this.show = true; //成功的弹窗
                this.getTodayShopTask((data) => {
                  console.log("getTodayShopTask", data);
                  // 如果有数据 再进行赋值
                  if (data.list.length > 0) {
                    this.isShow_orderList = true;
                    this.taskList = data.list;
                    this.mapTaskList();
                  }
                });
                break;
              case -1:
                console.log("领取失败 当前包未完成则无法接取下一个任务包");
                this.textList[0] = this.$t("görev[7]");
                this.textList[1] = this.$t("görev[18]");
                this.shows = true;
                break;
              case -2:
              case 0:
                console.log("领取失败 当前接取的包无派单配置");
                this.textList[0] = this.$t("görev[7]");
                this.textList[1] = this.$t("görev[7]");
                this.shows = true;
                break;
              case -3:
                console.log("没有适合当前任务包接取的任务");
                this.textList[0] = this.$t("görev[7]");
                this.textList[1] = this.$t("görev[5]");
                this.shows = true;
                break;
              default:
                console.log("你有订单未完成，请先完成订单");
                this.textList[0] = this.$t("görev[7]");
                this.textList[1] = this.$t("görev[19]");
                this.shows = true;
                break;
            }
            this.up();
            /*    if(data.code == 1){
            	this.show=true;
             	this.up();
             	let 最终佣金=(Number(data.DATA.reward_price)*(1-Number(data.DATA.pump))*Number(data.DATA.commission_ratio)).toString()
             	console.log('最终佣金',最终佣金)
             	this.moeny=最终佣金.length<9?最终佣金:最终佣金.substring(0,最终佣金.indexOf(".")+3)
             	console.log('moeny',this.moeny)

             		this.taskList = data;
             		this.mapTaskList();
            	    this.moeny = data[this.taskList.length-1].commission_ratio; */

            // 体验任务
          });
        }, 4000);
      }
    },
    // 跳转页面
    goMytask() {
      this.showExperience = false;
      this.$router.push("/myTask");
    },
    down1() {
      // if (this.flag) {
      //   this.showExperience = false; //控制体验资格弹窗
      //   this.flag = false;
      //   this.img = require(`../../../public/static/images/garb1_${this.lang}.png`);
      //   let video = document.querySelector(".video>video");
      //   video.play();
      //   setTimeout(() => {
      //     this.$Model.acceptDispatchTask((data) => {
      //       console.log(data);
      //       switch (
      //         // code = 0 运行时错误  1 领取成功 -1 当前包未完成则无法接取下一个任务包 -2 当前接取的包无派单配置 -3 没有适合当前任务包接取的任务 -4 你有订单未完成 请先完成订单
      //         data.code
      //       ) {
      //         case 1:
      //           console.log("领取成功");
      //           this.textList[0] = this.$t("inviteSubordinates[9]"); //textList[0 || 1]  0 是弹窗显示的标题 1 是内容
      //           this.textList[1] = this.$t("görev[4]");
      //           this.show = true; //成功的弹窗
      //           this.getTodayShopTask((data) => {
      //             console.log("getTodayShopTask", data);
      //             // 如果有数据 再进行赋值
      //             if (data.list.length > 0) {
      //               this.isShow_orderList = true;
      //               this.taskList = data.list;
      //               this.mapTaskList();
      //             }
      //           });
      //           break;
      //         case -1:
      //           console.log("领取失败 当前包未完成则无法接取下一个任务包");
      //           this.textList[0] = this.$t("görev[7]");
      //           this.textList[1] = this.$t("görev[18]");
      //           this.shows = true;
      //           break;
      //         case -2:
      //         case 0:
      //           console.log("领取失败 当前接取的包无派单配置");
      //           this.textList[0] = this.$t("görev[7]");
      //           this.textList[1] = this.$t("görev[7]");
      //           this.shows = true;
      //           break;
      //         case -3:
      //           console.log("没有适合当前任务包接取的任务");
      //           this.textList[0] = this.$t("görev[7]");
      //           this.textList[1] = this.$t("görev[5]");
      //           this.shows = true;
      //           break;
      //         default:
      //           console.log("你有订单未完成，请先完成订单");
      //           this.textList[0] = this.$t("görev[7]");
      //           this.textList[1] = this.$t("görev[19]");
      //           this.shows = true;
      //           break;
      //       }
      //       this.up();
      //     });
      //   }, 4000);
      // }
    },
    up() {
      this.img = require(`../../../public/static/images/garb2_${this.lang}.png`);
      this.flag = true;
    },
    setShows() {
      this.shows = false;
    },
    setShow() {
      this.show = false;
    },
    //获取今日任务列表
    getTodayShopTask(fn) {
      this.$Model.getTodayShopTask((data) => {
        fn(data);
      });
    },
    //将时间戳转化为y-m-d
    time(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes();
      if (m < 10) {
        m = "0" + m + ":";
      } else {
        m = m + ":";
      }
      var s = date.getSeconds();
      if (s < 10) {
        s = "0" + s;
      }
      return Y + M + D + h + m + s;
    },
    toVIP() {
      // this.$Model.finishShopTask(json, (data) => {});  支付
      //-1 没传ID，-2 钱不够，1成功，-3？
      this.$router.push("/Vip");
    },
    toWallet() {
      this.$router.push("/user/mixRecharge");
    },
    //转化时间格式和价格
    mapTaskList() {
      this.taskList = this.taskList.map((item) => {
        item.add_time = this.time(item.add_time);
        item.end_time = this.time(item.end_time);
        item.reward_price = parseFloat(item.reward_price).toFixed(1);
        return item;
      });
    },
    //点击完成任务 跳转页面
    completeTheTask() {
      this.$router.push("/user/completeTheTask");
    },
    // 体验资格弹窗
    goToComepleteTask() {
      this.showExperience = true;
    },
    // 关闭体验资格弹窗
    closeExperiencePopup() {
      this.showExperience = false;
    },
  },
};
</script>

<style scoped>
.Grabtask {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: url(../../../public/static/images/grabtaskbg.jpg) no-repeat
    top/cover;
}
.title {
  color: white;
  height: 46px;
  line-height: 46px;
  font-size: 17px;
  letter-spacing: 1px;
  position: fixed;
  z-index: 99;
  top: 0;
  width: 100%;
  text-align: center;
}
.title > div {
  width: auto;
  height: 100%;
  margin: 0 auto;
}
.slotMachine {
  width: 100%;
  height: 496px;
  /* padding-top:46px; */
  box-sizing: content-box;
  position: relative;
}
.slotMachine-img1 {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}
.slotMachine-img1 > img {
  width: 100%;
  height: 100%;
  display: block;
}
.slotMachine-img2 {
  width: 100%;
  height: 95px;
  position: absolute;
  top: 300px;
  left: 0;
  z-index: 3;
}
.slotMachine-img2 > img {
  width: 140px;
  height: 100%;
  display: block;
  margin: 0 auto;
}
.video {
  overflow: hidden;
  position: absolute;
  top: 130px;
  width: 100%;
  height: 180px;
  z-index: 1;
}
.video > video {
  width: 100%;
  height: 100%;
}
.Grabtask_main {
  width: 100%;
  height: auto;
  padding: 0px 0 40px;
  box-sizing: content-box;
}
.upgradevip {
  width: 250px;
  height: 40px;
  border-radius: 20px;
  margin: 0 auto 25px;
  background: linear-gradient(to right, rgb(206, 54, 251), rgb(238, 122, 159));
  color: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
}
.todayOrder {
  width: 100%;
  height: auto;
  padding: 0 23px;
}
.orderTitle {
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  text-align: left;
  font-size: 16px;
  color: #fff;
}
.orderList {
  width: 100%;
  height: auto;
}
.order {
  width: 100%;
  height: 115px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  padding: 10px;
  background: rgb(140, 96, 241);
  margin-top: 15px;
}
.orderLeft {
  flex-grow: 1;
  border-right: 1.5px solid rgb(175, 155, 244);
  padding-right: 10px;
}
.orderLeft > img {
  display: block;
  width: 45px;
  height: 45px;
  margin: 22.5px auto;
  border-radius: 50%;
  background: #fff;
  padding: 10px;
  box-sizing: content-box;
}
.orderRight {
  flex-grow: 5;
  padding-left: 10px;
}
.orderRight-top {
  width: 100%;
  height: 18px;
  line-height: 18px;
  display: flex;
  justify-content: space-between;
}
.orderRight-top > div {
  color: #fff;
  font-size: 16px;
}
.ter {
  text-align: right;
}
.orderRight-in {
  margin-top: 13px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
}
.orderRight-in > div > p {
  color: #fff;
  line-height: 18px;
}
/* .orderRight-in>div:first-child{
		margin-top: 10px;
	} */
.orderRight-in > div > img {
  width: 35px;
  height: 35px;
}
.orderRight-footer {
  width: 100%;
  height: 25px;
  display: flex;
  justify-content: space-between;
}
.orderRight-footer > div.submit {
  width: 65px;
  height: 23px;
  border-radius: 15px;
  background: linear-gradient(to right, rgb(206, 54, 251), rgb(238, 122, 159));
  color: #fff;
  line-height: 23px;
  text-align: center;
}
.orderRight-footer > div.link {
  display: flex;
  justify-content: space-between;
  color: rgb(255, 166, 226);
  line-height: 25px;
  text-align: center;
  min-width: 120px;
}
.orderRight-footer > div.link > div > a {
  color: rgb(255, 166, 226);
  line-height: 25px;
  text-align: center;
}
.success {
  /* position: fixed;
		top:30%;
		left:35px; */
  width: 300px;
  height: 258px;
  background: linear-gradient(to bottom, rgb(254, 147, 164), rgb(79, 82, 191));
  border-radius: 20px;
  overflow: hidden;
}
.successImg {
  width: 70px;
  height: 70px;
  position: absolute;
  top: -35px;
  left: 115px;
}
.successImg > img {
  width: 100%;
  height: 100%;
}
.successContent {
  width: 290px;
  height: 248px;
  background: #fff;
  border-radius: 16px;
  margin: 5px;
  overflow: hidden;
  text-align: center;
  padding: 0 20px;
}
.successContent > h2 {
  width: 100%;
  height: 25px;
  line-height: 25px;
  margin-top: 30px;
  font-size: 20px;
  color: rgb(254, 136, 157);
}
.successContent > p {
  width: 100%;
  height: 120px;
  margin-top: 5px;
  line-height: 30px;
  letter-spacing: 1px;
}
.successContent > div {
  width: 100%;
  height: 40px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.successContent > div > div {
  width: 120px;
  height: 100%;
  text-align: center;
  line-height: 40px;
  color: #fff;
  background: rgb(78, 81, 191);
  border-radius: 15px;
}
.fail {
  width: 300px;
  height: 238px;
  background: linear-gradient(to bottom, rgb(79, 82, 191), rgb(254, 147, 164));
  border-radius: 20px;
  overflow: hidden;
}
.failImg {
  width: 70px;
  height: 70px;
  position: absolute;
  top: -35px;
  left: 115px;
}
.failImg > img {
  width: 100%;
  height: 100%;
}
.failContent {
  width: 290px;
  height: 228px;
  background: #fff;
  border-radius: 20px;
  margin: 5px;
  overflow: hidden;
  text-align: center;
  padding: 0 20px;
}
.failContent > h2 {
  padding-top: 15px;
  box-sizing: content-box;
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin-top: 30px;
  font-size: 20px;
  color: rgb(254, 136, 157);
}
.failContent > p {
  width: 100%;
  height: auto;
  min-height: 50px;
  /* margin-top:50px; */
  line-height: 30px;
  letter-spacing: 1px;
}
.failContent > div {
  width: 100%;
  height: 40px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}
.failContent > div > div {
  width: 120px;
  height: 100%;
  text-align: center;
  line-height: 40px;
  color: #fff;
  background: rgb(78, 81, 191);
  border-radius: 15px;
  margin: 0 auto;
}
/* 任务列表样式 */
.orderList {
  width: 100%;
  height: auto;
}
.order {
  width: 100%;
  height: 115px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  padding: 10px;
  background: rgb(140, 96, 241);
  margin-top: 15px;
}
.orderLeft {
  flex-grow: 1;
  border-right: 1.5px solid rgb(175, 155, 244);
  padding-right: 10px;
}
.orderLeft > img {
  display: block;
  width: 45px;
  height: 45px;
  margin: 22.5px auto;
  border-radius: 50%;
  background: #fff;
  padding: 10px;
  box-sizing: content-box;
}
.orderRight {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 5;
  padding-left: 10px;
}
.orderRight > button {
  /* width: 155px; */
  height: 55px;
  border-radius: 5px;
  border: none;
}
/* 体验一次按钮 */
.experienceOnce {
  margin: 8px auto;
  width: 300px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  color: #fff;
  border-radius: 18px;
  background: #f59a23;
}
/* 体验资格弹窗 */
.experiencePopup {
  overflow-y: unset !important;
  margin-top: -15%;
  width: 360px;
  height: 410px;
}
.experiencePopup .delete {
  position: absolute;
  top: -20px;
  right: -5px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 22px;
  color: #333333;
  border: 1px solid #000;
  border-radius: 50%;
  background: #fff;
  z-index: 10000000000;
}
.experiencePopup .title {
  margin: 17px 0 0;
  text-align: center;
  font-size: 28px;
  color: #333333;
  font-weight: 900;
}
.experiencePopup .content {
  margin-top: 60px;
}
.experiencePopup .content p {
  padding: 15px 0px 0px 20px;
  font-size: 18px;
  color: #333333;
  font-weight: 500;
}
.experiencePopup .goToComepleteTask {
  margin: 40px auto 0;
  width: 160px;
  text-align: center;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 18px 30px;
}
/* 领取体检过 */
.already {
  z-index: 99;
}
</style>
