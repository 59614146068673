<template>
  <div class="PageBox">
    <div class="bgc">
    	<div class="topTitle">{{$t('dayReport[0]')}}</div>
    </div>
    <div class="ScrollBox">
      <van-cell
        class="mt17"
        :title="$t('dayReport[1]')"
        :value="$Util.DateFormat('YY-MM-DD', new Date())"
      />
      <van-grid  class="MyEarnings" :column-num="2" :border="false" gutter="1">
        <div class="mygrid" style="width: 50%">
          <van-grid-item>
            <template #icon>
              <div>
                {{ reportData.myTaskFinish }}
              </div>
            </template>

            <template #text>
              <div>{{ $t("dayReport[2]") }}({{ $t("dayReport[6]") }})</div>
            </template>
          </van-grid-item>
        </div>
        <div class="mygrid" style="width: 50%">
          <van-grid-item>
            <template #icon>
              <div>
                {{ reportData.myTaskProfit }}
              </div>
            </template>
            <template #text>
              <div>
                {{ $t("dayReport[3]") }}
                ({{ InitData.currency }})
              </div>
            </template>
          </van-grid-item>
        </div>
        <div class="mygrid" style="width: 50%">
          <van-grid-item>
            <template #icon>
              <div>
                {{ reportData.branchTaskFinish }}
              </div>
            </template>
            <template #text>
              <div>{{ $t("dayReport[4]") }}({{ $t("dayReport[6]") }})</div>
            </template>
          </van-grid-item>
        </div>
        <div class="mygrid" style="width: 50%">
          <van-grid-item>
            <template #icon>
              <div>
                {{ reportData.branchTaskProfit }}
              </div>
            </template>
            <template #text>
              <div>{{ $t("dayReport[5]") }}({{ InitData.currency }})</div>
            </template>
          </van-grid-item>
        </div>
      </van-grid>
      <van-cell
        class="mt17"
        :border="false"
        :title="$t('dayReport[0]')"
        :value="$t('dayReport[7]')"
      />
      <div class="mytable">
        <table>
          <thead>
            <tr class="tabHead">
              <th>
                {{ $t("dayReport[8]") }}
                <div></div>
              </th>
              <th>
                {{ $t("dayReport[9]") }}
                <div></div>
              </th>
              <th>
                {{ $t("dayReport[10]") }}
                <div></div>
              </th>
              <th>
                {{ $t("dayReport[11]") }}
                <div></div>
              </th>
              <th>
                {{ $t("dayReport[12]") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              ref="mytr"
              v-for="(item, index) in reportData.daily"
              :key="index"
            >
              <td>{{ item.count }}</td>
              <td>
                <em>{{ item.task }}</em>
              </td>
              <td>{{ item.branch }}</td>
              <td>
                <em>{{ item.consume }}</em>
              </td>
              <td>{{ item.date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DayReport",
  components: {},
  props: [],
  data() {
    return {
      reportData: {
        myTotalProfit: "0.00",
        myTaskFinish: "0",
        myTaskProfit: "0.00",
        branchTaskFinish: "0",
        branchTaskProfit: "0.00",
        daily: [],
      },
    };
  },
  computed: {},
  watch: {},

  created() {
    this.$parent.navBarTitle = "";
    this.$Model.DailyReport((data) => {
      if (data.code == 1) {
        this.reportData = data.data;
      }
    });
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {},
};
// 图标：icon="cluster"
</script>
<style scoped>
.PageBox .bgc {
	width: 100%;
	background-color: #4E51BF;
	height: 46px;
	position: fixed;
	top: 0;
	z-index: 200;
}

.topTitle {
	position: absolute;
	max-width: 60%;
	white-space: nowrap;
	font-size: 18px;
	color: #fff;
	background-color: transparent;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999;
}
table {
  /*background-color: #151d31;*/
  text-align: center;
  /*margin: 0px 0px 0px 10px;*/
}
table tr th {
  padding: 6px 5px;
  /*background-color: #0e1526;*/
  /*color: #bbb;*/
}
table tr:nth-child(odd) {
  background: #fff;
}
table tr:nth-child(even) {
  background: #f5f6ff;
}
table tr td {
  /*border: 1px #0e1526 solid;*/
  padding: 10px 5px;
  /*color: #fff;*/
}
table tr td:first-child,
table tr td:last-child {
  border-left: 0;
}
table tr td em {
  color: #b9babd;
}
.van-cell .van-cell__left-icon {
  color: #4e51bf;
}
.van-cell .van-cell__title {
  color: #4e51bf;
  font-weight: 600;
  font-size: 18px;
}
.PageBox {
}
.ScrollBox {
  background: white;
}
.mytitle {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translate(-45%, 0);
}
.title {
  font-size: 18px;
  color: #fff;
}

.PageBox >>> .mt17 {
  background: #eff0f2;
}
.PageBox >>> .MyEarnings {
  background: #eff0f2;
  padding:0 10px;
}
.PageBox >>> table {
  width: 90%;
  margin: 0 5% 0 5%;
  padding-bottom: 20px;
  border-spacing: 0;
}

.PageBox >>> .mytable {
  background: #eff0f2;
  width: 100%;
}
.PageBox >>> th {
  background: #f5f6ff;
  font-weight: 500;
  position: relative;
  height: 40px;
}
.PageBox >>> th:nth-of-type(5) {
  border: none;
}
.PageBox >>> .tabHead div {
  height: 12px;
  width: 1px;
  background: #b9babd;
  position: absolute;
  left: 100%;
  top: 15px;
  z-index: 10;
}
.PageBox >>> table tr td {
  color: #b9babd;
  height: 30px;
}
.PageBox >>> .van-grid-item__content {
  width: 100%;
  font-size: 13px;
  padding-left: 28px;
}
.PageBox >>> .van-grid-item__content div {
  width: 100%;
  text-align: left;
}
.PageBox >>> .van-grid-item__content div:nth-of-type(2) {
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.van-cell__value span {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
}
.PageBox >>> .van-grid-item__icon-wrapper div {
  color: #4e51bf;
  position: relative;
  margin-bottom: -3px;
  font-size: 21px;
}
.PageBox >>> .van-grid-item__content::after {
  content: "";
  position: absolute;
  width: 11px;
  height: 11px;
  background-image: linear-gradient(to bottom, #f4869f, #4e51bf);
  border-radius: 20px;
  left: 10px;
  top: 23px;
}
.PageBox >>> .van-grid-item__content::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  background: #fff;
  border-radius: 20px;
  top: 25px;
  left: 11.8px;
  z-index: 999;
}
.Site .van-cell:not(:last-child):after {
  border: none;
}
</style>
