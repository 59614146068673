<template>
  <div class="autoSuccess">
    <div class="success">
      <div class="successImg">
        <img src="../../../public/static/images/success.png" />
      </div>
      <div class="successContent">
        <h2>{{ $t("TaskPopup[31]", { n: count }) }}</h2>
        <div>
          <!-- <div @click="setShow()">{{ $t("grabtask[6]") }}</div> -->
          <div @click="getCommission()">
            {{ $t("TaskPopup[9]") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "autoSuccess",
  data() {
    return {};
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    getCommission() {
      this.$emit("toMytask");
    },
  },
  components: {},
};
</script>

<style scoped>
/* 抢单结果 弹窗样式 */
.success {
  /* position: fixed;
		top:30%;
		left:35px; */
  width: 300px;
  height: 258px;
  background: linear-gradient(to bottom, rgb(254, 147, 164), rgb(79, 82, 191));
  border-radius: 20px;
  overflow: hidden;
}
.successImg {
  width: 70px;
  height: 70px;
  position: absolute;
  top: -35px;
  left: 115px;
}
.successImg > img {
  width: 100%;
  height: 100%;
}
.successContent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 290px;
  height: 248px;
  background: #fff;
  border-radius: 16px;
  margin: 5px;
  overflow: hidden;
  text-align: center;
  padding: 0 20px;
}
.successContent > h2 {
  width: 100%;
  height: 25px;
  line-height: 25px;
  margin-top: 30px;
  font-size: 20px;
  color: rgb(254, 136, 157);
}
.successContent > p {
  width: 100%;
  height: 120px;
  margin-top: 5px;
  line-height: 30px;
  letter-spacing: 1px;
}
.successContent > div {
  width: 100%;
  height: 40px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}
.successContent > div > div {
  width: 120px;
  height: 100%;
  text-align: center;
  line-height: 40px;
  color: #fff;
  background: rgb(78, 81, 191);
  border-radius: 15px;
}
</style>
