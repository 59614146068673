<template>
  <div class="Newcomer">
    <!-- 标题部分 -->
    <div class="title">{{ $t("Newcomer[0]") }}</div>
    <!-- 主体内容部分 -->
    <div class="body">
      <p>YOUTUBE</p>
      <!-- LALKETUL -->
      <div class="head">
        <div class="head_one"></div>
      </div>
      <!-- 数据 -->
      <div class="neck">
        <div class="neck_one">
          <div class="dot"></div>
          <div>{{ $t("Newcomer[1]",{index:222}) }}</div>
        </div>
        <div class="neck_two">
          <div class="dot"></div>
          <div>{{ $t("Newcomer[2]",{index:200}) }}</div>
        </div>
        <div class="neck_three" v-if="$t('Newcomer[3]')">
          <div class="dot"></div>
          <div>{{ $t("Newcomer[3]",{time:72}) }}</div>
        </div>
      </div>
      <!-- 任务描述1 -->
      <div class="task">
        <div>{{ $t("Newcomer[4]") }}</div>
        <p>
          {{ $t("Newcomer[5]") }}
        </p>
        <div class="more_dot">
          <div></div>
          <div>{{ $t("Newcomer[6]",{name:"XXX"}) }}</div>
        </div>
      </div>
      <!-- 任务描述2 -->
      <div class="task2">
        <div class="say">{{ $t("Newcomer[7]") }}</div>
        <div class="btn">
          <div>{{ $t("Newcomer[8]") }}</div>
          <div>{{ $t("Newcomer[9]") }}</div>
          <div>{{ $t("Newcomer[10]") }}</div>
        </div>
      </div>
      <!-- 评论内容 -->
      <div class="com">
        <div class="com_one">{{ $t("Newcomer[11]") }}:</div>
        <p>{{ $t("Newcomer[12]") }}</p>
        <p>{{ $t("Newcomer[13]") }}http://dzb129.com</p>
        <p>{{ $t("Newcomer[14]") }}33333</p>
      </div>
      <!-- 地址复制 -->
      <div class="foor">
        <input
          type="text"
          name=""
          id="inp"
          value="https://huaban.com/pins/2648245003/"
          disabled
        />
        <div>
          <button @click="copy()">{{ $t("Newcomer[16]") }}</button>
          <button>
            <a href="http://dzb129.com">{{ $t("Newcomer[15]") }}</a>
          </button>
        </div>
      </div>
      <!-- 页面跳转切换 -->
      <van-tabs
        class="TaskHall"
        :border="false"
        color="#0000CD"
        v-model="index_select"
        title-inactive-color="#ccc"
        line-width="60"
        style="font-weight: bold"
      >
        <!-- 任务步骤 -->
        <van-tab class="active">
          <template #title>
            <div class="t1">
              <div class="t2">{{ $t("Newcomer[17]") }}</div>
            </div>
          </template>
          <div class="img1">
            <div>{{ $t("Newcomer[19]") }}</div>
            <div>
              <img src="" alt="" />
            </div>
          </div>
          <div class="img2">
            <div>{{ $t("Newcomer[20]") }}</div>
            <div>
              <img src="" alt="" />
            </div>
          </div>
        </van-tab>
        <!-- 审核样例 -->
        <van-tab>
          <template #title>
            <div class="s1">
              <div class="s2">{{ $t("Newcomer[18]") }}</div>
            </div>
          </template>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      infoData: "",
      index_select: 0,
    };
  },
  created() {
    this.getTaskinfo();
    this.promoteUrl = `${this.InitData.setting.reg_url}/#/register/${this.UserInfo.idcode}`;
  },
  methods: {
    getTaskinfo() {
      this.$Model.TaskOrderInfo(this.taskId, (data) => {
        this.isLoad = false;
        if (data.code == 1) {
          this.infoData = data.info;
        }
        // this.$nextTick(() => {
        //   if (data.info.is_fx == 1) {
        //     new QRCode(document.getElementById("QRCode"), {
        //       text: this.promoteUrl,
        //       width: 110,
        //       height: 110,
        //       correctLevel: QRCode.CorrectLevel.H,
        //     });
        //   }
        // });
      });
    },
    copy() {
      const copy = document.getElementById("inp"); //获取文本框
      copy.value = "https://huaban.com/pins/2648245003/";
      //给文本框添加需要复制的内容
      copy.select(); //选择
      document.execCommand("Copy"); //执行浏览器复制命令

      // this.$message({
      //   message: "复制成功",
      //   type: "success",
      // });
    },
  },
};
</script>
<style scoped>
.Newcomer {
  overflow: auto;
  height: 100%;
}
.title {
  background: #4e51bf;
  color: #fff;
  height: 46px;
  text-align: center;
  line-height: 46px;
  font-size: 17px;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 999;
}
.details p:nth-of-type(1) {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}
.body {
  margin-top: 60px;
  font-family: initial;
}
.body p {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 17px;
}
.head {
  width: 100%;
  padding: 10px;
}
.head_one {
  border: #dbdbdbdb 1px solid;
  box-shadow: 0px 0px 10px 0px #dbdbdbdb;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin: auto;
  margin-top: -5px;
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: 50%;
}

.neck {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #dbdbdbdb;
  line-height: 40px;
  display: flex;
  justify-content: space-around;
  margin-top: -8px;
  font-size: 13px;
}
.dot {
  background: #4e51bf;
  width: 9px;
  height: 9px;
  border-radius: 10px;
  margin: auto;
  margin-right: 5px;
}
.neck_one,
.neck_two,
.neck_three {
  display: flex;
}
.more_dot {
  display: flex;
}
.more_dot div:nth-of-type(1) {
  height: 40px;
  width: 40px;
  border: 1px solid #dbdbdbdb;
  box-shadow: 0px 0px 10px 0px #dbdbdbdb;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: 50%;
}
.more_dot div:nth-of-type(2) {
  line-height: 80px;
  margin-left: 10px;
  font-size: 12.5px;
  font-weight: 600 !important;
}
.task div:nth-of-type(1) {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}
.task p {
  font-weight: 500 !important;
  text-align: left !important;
  line-height: 25px;
  font-size: 13px;
  font-weight: 600 !important;
}
.task {
  padding: 0 20px;
}
.task2 {
  padding: 0 20px;
}
.say {
  font-size: 15px;
  font-weight: 600;
  margin-top: 20px;
}
.btn {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.btn div {
  font-size: 12px;
  font-weight: 600;
  background: #b3b6fb;
  width: 85px;
  text-align: center;
  border-radius: 10px;
  height: 25px;
  line-height: 25px;
}
.com {
  padding: 20px;
}
.com_one {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 8px;
}
.com p {
  font-weight: 500 !important;
  text-align: left !important;
  margin-top: 10px;
  line-height: 25px;
  font-size: 12.5px;
  font-weight: 600 !important;
  margin-top: 0;
}
.foor {
  margin: 0 10px 10px 20px;
}
.foor input {
  height: 30px;
  width: 250px;
  border-radius: 10px;
  border: none;
  background: #f5f5f5;
  color: rgb(160, 156, 156);
  text-align: center;
  font-size: 13px;
}
.foor div {
  display: flex;
  flex-direction: column;
  width: 60px;
  margin-top: -70px;
  margin-left: 80%;
}
.foor div button {
  background: #4e51bf;
  color: #fff;
  border: none;
  height: 30px;
  border-radius: 12px;
  font-size: 12px;
}
a {
  color: #fff;
}
.foor div button:nth-of-type(2) {
  margin-top: 10px;
}
/* .details >>> .van-tabs__line {
  height: 0px;
}
.details >>> .van-tab {
  color: #fff;
  font-size: 16px;
}
.details >>> .van-tab__text {
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: flex;
  justify-content: center;
} */
.TaskHall {
  padding: 0px 15px;
}
.TaskHall >>> .van-tabs__wrap {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.TaskHall >>> .van-tabs__nav {
  border-radius: 10px;
  padding: 0 !important;
  box-sizing: border-box;
  height: 30px;
}
.TaskHall >>> .van-tab {
  width: 110px;
  padding: 0 !important;
  line-height: 28px;
  color: #fff !important;
}
.TaskHall >>> .van-tab .van-tab__text {
  width: 100%;
  height: 100%;
  font-size: 13px;
}
.TaskHall >>> .van-tab .van-tab__text div {
  width: 100%;
  height: 100%;
  text-align: center;
}

.TaskHall >>> .van-tab--active {
  border-radius: 9px;
  color: #000 !important;
  font-weight: 600 !important;
}
.TaskHall >>> .van-tabs__line {
  display: none;
}
.TaskHall >>> .van-tab__text {
  text-align: center;
  font-weight: 400;
  font-size: 15px;
}

.img1 {
  border: 1px solid #dbdbdbdb;
  border-radius: 5px;
  padding: 10px;
  box-shadow: #dbdbdbdb 1px 1px 5px 1px;
}
.img1 div:nth-of-type(1) {
  height: auto;
  border-bottom: 1px solid #dbdbdbdb;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 10px;
  
}
.img1 div:nth-of-type(2) {
  width: 100%;
  height: 180px;
  margin-top: 10px;
  background: #f5f5f5;
}
/* .img1 img {
  width: 100%;
  height: 100%;
} */
.img2 {
  border: 1px solid #dbdbdbdb;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 40px;
  margin-top: 20px;
  box-shadow: #dbdbdbdb 1px 1px 5px 1px;
}
.img2 div:nth-of-type(1) {
  border-bottom: 1px solid #dbdbdbdb;
  height: auto;
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 10px;
}
.img2 div:nth-of-type(2) {
  width: 100%;
  height: 180px;
  margin-top: 10px;
  background: #f5f5f5;
}
/* .img2 img {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
} */

.active {
  padding: 5px;
}
.TaskHall >>> .van-tabs__nav {
  background: #4e51bf;
}

.TaskHall >>> .van-tab--active .t1 {
  background-image: linear-gradient(to right, #fe889d, #4e51bf);
  padding: 2px;
  border-radius: 10px;
  line-height: 23px;
  font-weight: 600;
}
.TaskHall >>> .van-tab--active .t1 .t2 {
  background: #fff;
  border-radius: 8px;
}
.TaskHall >>> .van-tab--active .s1 {
  background-image: linear-gradient(to right, #4e51bf, #fe889d);
  padding: 2px;
  border-radius: 10px;
  line-height: 23px;
  font-weight: 600;
}
.TaskHall >>> .van-tab--active .s1 .s2 {
  background: #fff;
  border-radius: 8px;
}
</style>