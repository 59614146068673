<template>
  <div class="Site PageBox">
    <van-nav-bar
      fixed
      :border="false"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="title">
      <div class="head">
        {{ $t("lineList[0]") }}
      </div>
    </div>
    <div class="ScrollBox">
      <div class="box">
        <van-cell
          class="first"
          :title="$t('lineList[1]')"
          :value="currLine | ellipsisVal"
        />

        <div class="box_two">
          <van-radio-group
            v-model="lineIndex"
            @change="selectLine"
            class="second"
          >
            <van-cell
              style="color: #000"
              clickable
              v-for="(item, index) in listData"
              :key="index"
              @click="lineIndex = index"
            >
              <template #title>
                {{ $t("lineList[2]") }} {{ item | ellipsisVal }}
                <i>{{ timeList[index] }} ms</i>
              </template>
              <van-radio #right-icon :name="index" />
            </van-cell>
          </van-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//设置默认接口
import config from "@/config";

const ApiUrl = config.ApiUrl;

import Ping from "web-pingjs";
export default {
  name: "Lines",
  components: {},
  props: {},
  data() {
    return {
      lineIndex: localStorage["LineIndex"]
        ? Number(localStorage["LineIndex"])
        : 0,
      listData: "",
      timeList: [],
      currLine: "",
      isStore: false,
    };
  },
  computed: {},
  filters: {
    ellipsisVal(val) {
      return val
        .replace(/(\S*)\/\//, "")
        .replace(/^(.{3}).*(.{4})$/, "$1***$2");
    },
  },
  watch: {
    "InitData.link"(data) {
      if (!this.isStore) {
        if (data.includes(ApiUrl)) {
          this.listData = data;
        } else {
          this.listData = [ApiUrl].concat(data);
        }
        this.currLine = this.listData[this.lineIndex];
        localStorage["CurrLine"] = this.currLine;
        this.pingLine(this.listData);
      }
    },
  },
  created() {
    if (this.InitData.link) {
      this.isStore = true;
      if (this.InitData.link.includes(ApiUrl)) {
        this.listData = this.InitData.link;
      } else {
        this.listData = [ApiUrl].concat(this.InitData.link);
      }
      this.currLine = this.listData[this.lineIndex];
      localStorage["CurrLine"] = this.currLine;
      this.pingLine(this.listData);
    }
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    pingLine(line) {
      this.timeList = [];
      line.forEach((host) => {
        Ping(host)
          .then((time) => {
            this.timeList.push(time);
          })
          .catch((err) => {
            this.timeList.push("-");
          });
      });
    },
    selectLine(val) {
      this.currLine = this.listData[val];
      localStorage["CurrLine"] = this.currLine;
      localStorage["LineIndex"] = val;
      this.UpdateApiUrl(this.currLine);
    },
  },
};
</script>
<style scoped>
.van-cell__value {
  flex: none;
}
.van-cell__title i {
  color: #4e51bf;
  margin-left: 15px;
}
.van-radio {
  justify-content: flex-end;
}
.van-cell__title {
  color: #000;
}
.title {
  color: #fff;
  border-top: 1px solid #dbdbdb;
}
.head {
  width: 150px;
  height: 40px;
  background: #4e51bf;
  text-align: center;
  line-height: 40px;
  border-radius: 18px;
  font-size: 21px;
  margin: auto;
  margin-top: 20px;
  /* margin-bottom: 30px; */
  font-weight: 600;
}
.PageBox >>> .van-icon {
  color: #000;
}
.ScrollBox {
  padding: 20px;
}
.box {
  padding: 10px 0;
  border: 1px #dbdbdb solid;
  border-radius: 5px;
  box-shadow: 1px 1px 5px 1px #dbdbdb;
}
.first {
  background: #e1e1f9;
  margin-top: 2px;
  font-weight: 600;
  font-size: 17px;
  padding: 0 25px;
  height: 40px;
}

.first span {
  color: #000;
}
.second {
  border: none;
}
.van-cell {
  position: inherit !important;
}
.van-cell:nth-of-type(1) {
  border-bottom: 1px solid #dbdbdb;
}
.box_two {
  padding: 0 10px;
}
.PageBox >>> .van-radio__icon--checked .van-icon {
  background-image: url("../../public/static/icon2/12.png");
  background-color: #fff;
  border: none;
  background-size: 20px;
  background-repeat: no-repeat;
}
.PageBox >>> .van-icon {
  color: rgba(0, 0, 0, 0);
}
.PageBox >>> .van-nav-bar__arrow {
  color: #000;
}
.PageBox>>>.van-cell{
	padding: 11px 16px;
}
</style>