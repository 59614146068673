<template>
  <van-popup
    v-model="show.bool"
    round
    style="overflow: visible; background: transparent"
    @click-overlay="overlay"
  >
    <div class="giftBox">
      <div class="top_title">{{ $t("shopTask[0]") }}</div>

      <div class="img">
        <img
          src="../../assets/img/giftBox.png"
          alt=""
          width="161"
          height="93"
        />
      </div>
      <div class="desc">{{ $t("shopTask[13]") }}</div>
      <div class="button">
        <div @click="getCommission()">{{ $t("TaskPopup[35]") }}</div>
      </div>
    </div>
  </van-popup>
</template>
<script>
/* 手动抢单成功弹窗 */
export default {
  name: "orderGrabSuccess",
  data() {
    return {};
  },
  props: {
    show: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  components: {},
  methods: {
    overlay() {
      this.$set(this.show, "bool", false);
    },
    //手动抢单成功  获取佣金
    getCommission() {
      this.$emit("toMytask");
    },
  },
};
</script>

<style scoped>
/* 抢单结果 弹窗样式 */
.giftBox {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 259px;
  height: 312px;
  border-radius: 20px;
  border: solid 1px rgba(169, 169, 169, 0.4);
  background: #ffffff;
}
.giftBox > .top_title {
  position: absolute;
  top: -6%;
  width: 90%;
  min-height: 38px;
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  background-image: url("../../assets/img/gongxi.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.giftBox > .receiveTask {
  margin-top: 10%;
  color: #0e0e0e;
  font-size: 21px;
  font-weight: 400;
  line-height: 25px;
}
.giftBox > .img {
  margin-top: 15%;
}
.giftBox > .desc {
  padding: 0 10px;
  margin-top: 8%;
  color: #0e0e0e;
  font-family: "PingFangSC-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
.giftBox > .button > div {
  text-align: center;
  width: 170px;
  height: 33px;
  margin-top: 30%;
  color: #fff;
  line-height: 33px;
  font-family: "PingFangSC-Regular";
  font-size: 18px;
  font-weight: 400;
  border-radius: 8px;
  background: #5e59e9;
}
</style>
