<template>
  <div class="resultsTheQuery_content_item">
    <div class="resultsTheQuery_item_title">
      <div class="resultsTheQuery_item_time">
        <span v-if="showDate"> {{ $t("resultsTheQuery.default[5]") }}：{{ item.report_date }}</span
        ><span v-if="showMy" class="my">{{
          $t("resultsTheQuery.default[9]")
        }}</span>
      </div>
      <slot></slot>
    </div>
    <div class="resultsTheQuery_item_detail">
      <div class="resultsTheQuery_detail_item" v-if="item['username']">
        <div class="resultsTheQuery_detail_item_money">
          {{ item.username }}
        </div>
        <div class="resultsTheQuery_detail_item_title">
          username
        </div>
      </div>
      <div class="resultsTheQuery_detail_item">
        <div class="resultsTheQuery_detail_item_money">
          {{ item.total_tasks }}
        </div>
        <div class="resultsTheQuery_detail_item_title">
          {{ $t("resultsTheQuery.default[6]") }}
        </div>
      </div>
      <div class="resultsTheQuery_detail_item">
        <div class="resultsTheQuery_detail_item_money">
          {{ item.total_task_amount }}
        </div>
        <div class="resultsTheQuery_detail_item_title">
          {{ $t("resultsTheQuery.default[7]") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "resultsTheQuery",
  components: {},
  props: {
    item: {
      //每一项
      type: Object,
      default: () => {}, //返回空数组
    },
    showDate:{
      type:Boolean,
      default:true
    },
    showMy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // dateStart:null,//开始日期
      // dateEnd:null,//结束日期
      // //获取全局的laydate，带入到组件中
      // laydate: window.laydate,
      // activeIndex:0,//导航下标
      // show:true,//详情
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style>
.resultsTheQuery_content_item {
  padding: 10px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 10px;
}
.resultsTheQuery_item_time {
  font-size: 14px;
  color: #282828;
  display: flex;
  align-items: center;
  font-family: Adobe 黑体 Std;
}
.resultsTheQuery_item_time .my {
  border-radius: 0px 20px 20px 20px;
  background-color: #ecebfd;
  padding: 3px 8px;
  color: #403dec;
  font-size: 10px;
  margin-left: 3px;
  margin-top: -3px;
  transform: scale(0.8);
}
.resultsTheQuery_item_goDetail {
  border-radius: 20px;
  background-color: #ecebfd;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 4px 10px;
  color: #97979b;
  font-size: 11px;
  transform: scale(0.9);
}
.resultsTheQuery_content .resultsTheQuery_item_goDetail img {
  width: 6px;
  margin-left: 2px;
  margin-top: 3px;
}
.resultsTheQuery_item_detail {
  margin-top: 4px;
  border-radius: 0px 40px 40px 40px;
  background-color: #ecebfd;
  display: flex;
  justify-content: space-around;
  align-items: end;
  padding: 4px 20px;
}
.resultsTheQuery_detail_item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
.resultsTheQuery_detail_item_money {
  font-size: 18px;
  color: #fe93a5;
  font-weight: bold;
}
.resultsTheQuery_item_detail
  .resultsTheQuery_detail_item:first-child
  .resultsTheQuery_detail_item_money {
  font-size: 18px;
  color: #4e51bf;
  font-weight: bold;
}
.resultsTheQuery_detail_item_title {
  font-size: 11px;
  font-family: Adobe 黑体 Std;
  transform: scale(0.9);
}
.resultsTheQuery_item_title {
  display: flex;
  justify-content: space-between;
  /* height: 27px; */
  box-sizing: border-box;
}
</style>
