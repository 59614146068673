<template>
  <div class="userInfo">
    <div class="bgc">
      <div class="topTitle">{{ $t("userInfo1.default[0]") }}</div>
    </div>
    <div class="height"></div>
    <div class="content">
      <div class="UserBg"></div>
      <van-cell class="info" :border="false">
        <template #icon>
          <div class="MyHeader">
            <img
              style="width: 100%; max-height: 100%"
              class="head"
              :src="userInfo.image"
            />
          </div>
        </template>
        <template #title>
          <div class="userName">{{ userInfo.username }}</div>
          <div class="user_vip">
            LV<i>{{ userInfo.level_name }}</i>
          </div>
          <div class="MyHeaderStatus">
            <span class="MyHeaderStatus_radius"></span
            >{{
              userInfo.status == 1
                ? $t("userInfo1.default[1]")
                : $t("userInfo1.default[2]")
            }}
          </div>
        </template>
        <template #label> </template>
        <template #right-icon>
          <van-button class="MyWallet" type="info" @click="goChatPage()">{{
            $t("userInfo1.default[3]")
          }}</van-button>
        </template>
      </van-cell>
      <div class="content_form">
        <div class="content_form_item" v-for="(item, index) in 9">
          <img :src="`./static/icon/userInfo_form0${index + 1}.png`" />
          <span class="item_title"
            >{{ $t("userInfo1.info[" + index + "]") }} :</span
          >
          <span class="item_info" v-show="index != 8">{{
            index == 2 || index == 4 || index == 0
              ? userInfoList[index] + currency_name
              : userInfoList[index]
          }}</span>
          <span
            class="item_info_btn item_info"
            v-show="index == 8"
            @click="goUserTaskRecord()"
            >{{ $t("userInfo1.default[4]") }}</span
          >
        </div>
      </div>
    </div>
    <van-dialog
      class="dialog"
      v-model="showDialog"
      :title="$t('withdrawalCenter[6]')"
      show-cancel-button
      width="300px"
      closeOnClickOverlay
      :confirmButtonText="$t('withdrawalCenter[9]')"
      :cancelButtonText="$t('withdrawalCenter[8]')"
    >
      <div>{{ $t("withdrawalCenter[7]") }}</div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: "userInfo",
  components: {},
  props: ["user_id", "index"],
  data() {
    return {
      withdrawal: {
        withdrawalAddress:
          "https://www.w3cschool.cn/vantlesson/vantlesson-fueq35vn.html", //提现地址
        withdrawalCode: "", //提现二维码
        withdrawalAmount: "", //提现金额
      },
      active: 0, //默认0
      showDialog: false, //提现成功的提示
      userInfo: {}, //用户信息
      userInfoList: ["", "", "", "", "", "", "", "", ""], //用户表单详情
      currency_name: JSON.parse(localStorage.getItem("InitData")).setting
        .currency_name, //货币符号s
    };
  },
  computed: {},
  created() {
    //标题
    this.$parent.navBarTitle = "";
    // this.$Model.RobotUser()

    if (this.InitData) {
      let { memberList } = this.InitData;
      this.userInfo = memberList[this.index];
      this.userInfoList[0] = memberList[this.index].day_money_number;
      this.userInfoList[1] = memberList[this.index].day_task_number;
      this.userInfoList[2] = memberList[this.index].money_number;
      this.userInfoList[3] = memberList[this.index].task_number;
      this.userInfoList[4] = memberList[this.index].money_number;
      this.userInfoList[5] = memberList[this.index].day_money_number;
      this.userInfoList[6] = memberList[this.index].last_ip;
      this.userInfoList[7] = memberList[this.index].isp;
      this.userInfoList[8] = memberList[this.index].isp;
    }
    this.getRobotUser();
    this.getRobotHistory();
    // this.GetWithdrawType()
  },
  mounted() {},
  activated() {},
  destroyed() {},
  methods: {
    // 获取用户详情
    getRobotUser() {
      this.$Model.RobotUser({ user_id: this.user_id }, (data) => {
        console.log(data.data);
        this.userInfo = data.data;
        this.userInfoList[0] = data.data.day_money_number;
        this.userInfoList[1] = data.data.day_task_number;
        this.userInfoList[2] = data.data.money_number;
        this.userInfoList[3] = data.data.task_number;
        this.userInfoList[4] = data.data.money_number;
        this.userInfoList[5] = data.data.day_money_number;
        this.userInfoList[6] = data.data.last_ip;
        this.userInfoList[7] = data.data.isp;
        this.userInfoList[8] = data.data.isp;
      });
    },
    // 获取某用户历史记录
    getRobotHistory() {
      let json = {
        user_id: this.user_id,
        page: 1,
        limit: 20,
      };
      this.$Model.RobotHistory(json, (data) => {
        // 把用户历史记录保存到缓存
        localStorage["robotHistoryList"] = JSON.stringify(data.data.data);
      });
    },
    //提交提现申请
    submit() {
      this.showDialog = true;
    },
    goUserTaskRecord() {
      // 把用户名保存到缓存
      localStorage["robotUserName"] = this.userInfo.username;
      this.$router.push("/user/userTaskRecord/" + this.user_id);
    },
    // 打招呼
    goChatPage() {
      this.$router.push(`/user/chatPage/${this.user_id}`);
    },
  },
};
</script>

<style scoped>
.userInfo {
  overflow: auto;
  height: 100vh;
  background-color: #fff;
  font-family: Alibaba;
  font-weight: normal;
}

.userInfo .bgc {
  width: 100%;
  background-color: #4e51bf;
  height: 46px;
  position: fixed;
  top: 0;
  z-index: 200;
}

.topTitle {
  position: absolute;
  max-width: 60%;
  white-space: nowrap;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.height {
  height: 46px;
}

.content {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.UserBg {
  height: 135px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: #4e51bf;
  border-radius: 0 0 80px 80px;
}
.MyHeader {
  font-size: 19px;
  border-radius: 50%;
  border: 2px #f099b5 solid;
  background: #fff;
  overflow: hidden;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
}
.MyHeaderStatus {
  width: 58px;
  margin-top: 8px;
  margin-left: 13px;
  text-align: center;
  font-size: 14px;
  border-radius: 20px;
  font-size: 12px;
  height: 20px;
  background: linear-gradient(-90deg, #ffe9ce 0%, #b3b5fc 99%);
  color: #4e51bf;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user_vip {
  width: 49px;
  height: 22px;
  background: url(../../../public/static/icon/user_vip01.png) no-repeat;
  background-size: 100% 100%;
  margin-left: 13px;
  color: #4e51bf;
  font-style: italic;
  text-align: center;
  line-height: 23px;
  padding-right: 5px;
  margin: 5px 0 2px 13px;
  font-weight: bold;
}
.MyHeaderStatus .MyHeaderStatus_radius {
  width: 8px;
  height: 8px;
  background: #ff93a4;
  border-radius: 50%;
  display: inline-flex;
  margin-right: 2px;
}
.userName {
  min-width: 135px;
  margin-left: 13px;
  font-size: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.MyWallet {
  /*margin-right: -30px;*/
  background-color: #fff;
  border-radius: 10px;
  margin-top: -18px;
  width: 80px;
  height: 25px;
  font-family: Alibaba;
  line-height: initial;
  margin-right: 15px;
}
.MyWallet >>> .van-button__content .van-button__text {
  font-size: 15px;
  color: #4e51bf;
  font-family: Alibaba;
  white-space: nowrap;
}
.content >>> .van-cell {
  z-index: 10;
  /*background-color: #151d31;*/
  /*margin-top: 10px;*/
  color: #fff;
  position: relative;
  z-index: 3;
  background-color: transparent !important;
  padding: 20px 0;
}

.UserInfo .info .head {
  margin: 0;
  padding: 4px;
  width: 100%;
  height: 100%;
}

.UserInfo .info .van-cell__title {
  font-size: 16px;
}

.UserInfo .info .van-cell__label {
  font-size: 14px;
  /*color: #fff;*/
  background: transparent none repeat scroll 0% 0%;
}

.UserInfo .info .van-cell__value {
  flex: none;
  line-height: 1.3;
  background: transparent none repeat scroll 0% 0%;
}

.UserInfo .info .van-cell__value a {
  text-align: center;
  /*color: #fff;*/
  display: block;
}
.content_form {
  margin-top: 35px;
  width: 100%;
  padding: 0 15px 100px 15px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  font-family: Alibaba;
  font-weight: normal;
  color: #434343;
  margin-bottom: 20px;
  padding-top: 3px;
}
.content_form .content_form_item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 22px 0 2px 0;
}
.content_form .content_form_item img {
  width: 21px;
  margin-right: 8px;
  margin-left: 3px;
}
.content_form .item_title {
  font-size: 15px;
}
.content_form .item_info {
  margin-left: auto;
  margin-right: 10px;
}
.content_form .item_info_btn {
  width: 80px;
  padding: 2px 0;
  text-align: center;
  background-color: #4e51bf;
  color: #fff;
  font-size: 16px;
  border-radius: 10px;
}
</style>
