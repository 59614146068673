<template>
  <div class="autoComplete">
    <!-- 背景图片 -->
    <div class="bg_img"></div>
    <!-- 内容 -->
    <div class="content">{{ $t("görev[34]") }}</div>
    <!-- 按钮 -->
    <div class="btn">
      <div class="determine" @click="determine">
        {{ $t("newInvite[6]") }}
      </div>
    </div>
  </div>
</template>

<script>
/* 自动完成 成功弹窗 */
export default {
  name: "autoComplete",
  data() {
    return {};
  },
  methods: {
    determine() {
      this.$emit("determine");
    },
  },
};
</script>

<style scoped>
.autoComplete {
  position: relative;
  width: 260px;
  height: 270px;
  padding: 0 10px;
  border-radius: 15px;
  background: #fff;
  overflow: hidden;
}
.autoComplete > .bg_img {
  margin-top: 10px;
  height: 100px;
  background: #fff url("../../assets/img/autoComplete_bg.png") center/cover
    no-repeat;
}
.autoComplete > .content {
  padding-top: 20px;
}
.autoComplete > .btn {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
}
.autoComplete > .btn > .determine {
  width: 60%;
  height: 30px;
  border-radius: 10px;
  background-color: rgb(94, 89, 233);
  text-align: center;
  line-height: 30px;
  color: #fff;
}
</style>
