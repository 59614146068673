<template>
  <div class="Site PageBox">
    <van-nav-bar
      fixed
      :border="false"
      :title="$t('common3[1]')"
      left-arrow
      @click-left="$router.go(-1)"
    />
    <div class="ScrollBox" style="margin-right: 15px;width: 95%;padding: 10px">
        <div style="text-align: justify;" v-html="textContent"></div>
    </div>
    <van-popup v-model="showCon" position="bottom" closeable close-on-popstate  style="width: 100%;height: 100%;background-color: #fff">
      <div class="ScrollBox" style="padding: 16px 20px;">
        <h3 style="text-align: center;margin-bottom: 20px">{{infoData.title}}</h3>
        <div class="Content" style="text-align: justify;" v-html="infoData.content"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'Help',
  components: {
  },
  props: [],
  data() {
    return {
      showCon: false,
      infoData:{title:'',content:''},
      textContent:''
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    let siteDesc = this.InitData.siteDesc;
    if (this.InitData.siteDesc){
      this.infoData = this.InitData.siteDesc[0];
      for (var i=0;i<50;i++){
        this.infoData.content = this.infoData.content.replace("nowrap","");
      }
      this.textContent = this.infoData.content;
    }
    // this.infoData.content = "</p><p><img src=\"/upload/image/20220808/1659944902225667.jpg\" title=\"1659944902225667.jpg\" alt=\"images (1).jpg\"/>";
	  // let aa=JSON.parse(this.InitData.helpList[0].content)
	  // this.InitData.helpList.map(item=>JSON.parse(item.content))
	console.log(this.InitData.helpList)
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    openShow(data) {
      this.showCon = true
      this.infoData = data
    }
  }
}
</script>
<style scoped>
  .PageBox {

  }

.Content>>>img{
  max-width: 100%;
}

.ScrollBox{
	padding:0 35px;
}
.ScrollBox>>>.van-cell{
	padding:20px 5px;
	border-bottom: 1px solid #D9D9D9;
}
.ScrollBox>>>.van-cell:last-child{
	border-bottom: 0px solid #D9D9D9;
}
.ScrollBox>>>.van-cell::after{
	display: none;
}
.ScrollBox>>>.van-icon-chat-o::before{
	content: '';
	border-radius: 50%;
	background: #B3B5FC;
	width:8px;
	height: 8px;
}
.ScrollBox>>>.van-icon-chat-o{
	display: flex;
	align-items: center;
	justify-content: center;
}
.ScrollBox>>>.van-cell__title{
	font-size: 16px;
	font-family: ui-serif;
}
.ScrollBox>>>h3{
	font-size: 1.05rem;
	font-family: emoji;
}
.van-cell>>>.van-icon{
	font-size: 18px;
	color:#333;
}
.PageBox>>>.van-popup__close-icon--top-right{
	color:#333;
	top:20px;
	right: 20px;
}
.PageBox>>>.Content{
	margin-top:10px;
}
.PageBox>>>.Content p{
	font-size: 13px;
	line-height: 1.7;
	font-family: 'vant-icon';
	font-weight: 400;
}

</style>
