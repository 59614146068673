<template>
	<div class="memberQuestions">
	<div class="memberQuestionsBox">
		<div class="bgc">
			<div class="topTitle">{{$t('memberQuestions.default[0]')}}</div>
		</div>
		<div class="chatContent">
			<div class="chat" v-for="(item,index) in $t('memberQuestions.dialogue.user')">
				<!-- 甲方发送的聊天信息st -->
				<div class="chat1">
					<div class="chatContent_headPortrait1">
						<div class="sj1"></div>
						<img src="" />
					</div>
					<!-- 甲方聊天的内容 -->
					<div class="chatContentChat1">
						<p>{{$t(`memberQuestions.dialogue.user[${index}]`)}}</p>
					</div>
				</div>
				<!-- 甲方发送的聊天信息en -->
				<!-- 乙方发送的聊天信息st -->
				<div class="chat2">
					<div class="chatContent_headPortrait2">
						<div class="sj2"></div>
						<img src="" />
					</div>
					<!-- 乙方聊天的内容 -->
					<div class="chatContentChat2">
						<p>{{$t(`memberQuestions.dialogue.admin[${index}]`)}}</p>
					</div>
				</div>
			</div>


		</div>
	</div>
	</div>
</template>

<script>
	import resultTheQueryItem from '@/components/resultTheQueryItem.vue'
	export default {
		name: 'memberQuestions',
		components: {
			resultTheQueryItem,
		},
		data() {
			return {
				// message1:[
				// 	{"我想每天都来免费做任务,要如何做呢?"},
				// 	{"押金是什么?"}
				// ]
			}
		},
		computed: {

		},
		watch: {

		},
		created() {
			//标题
			this.$parent.navBarTitle = ''
		},
		mounted() {},
		methods: {}
	}
</script>

<style scoped>
	.memberQuestions{
		height: 100%;
	}
	.memberQuestionsBox {
		display: flex;
		overflow: auto;
		height: 100%;
		background-color: #130f10;
		justify-content: center;
	}

	.memberQuestionsBox .bgc {
		width: 100%;
		background-color: #130f10;
		height: 46px;
		position: fixed;
		top: 0;
		z-index: 200;
	}

	.topTitle {
		position: absolute;
		max-width: 75%;
		white-space: nowrap;
		font-size: 18px;
		font-weight: 600;
		color: #fff;
		background-color: transparent;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.chatContent {
		display: flex;
		width: 90%;
		height: 100%;
		background-color: #fff;
		margin: 70px 0;
		border-radius: 15px;
		border: 3px solid #f9e9aa;
		flex-direction: column;
		height: fit-content;
	}

	.chat {
		display: flex;
		margin-top: 40px;
		flex-direction: column;
		width: 100%;

	}

	.chat1 {
		position: relative;
		display: flex;
		margin-bottom: 15px;
	}

	.chat2 {
		position: relative;
		display: flex;
		flex-direction: row;
		align-self: flex-end;
		margin-bottom: 15px;

	}

	.chatContent_headPortrait1 {
		width: 40px;
		height: 40px;
		background-color: #292929;
		border-radius: 20px;
		margin: 0px 15px 0px 10px;
		border: 2px solid #f9e9aa;
	}

	.chatContent_headPortrait2 {
		order: 2;
		width: 40px;
		height: 40px;
		background-color: #292929;
		border-radius: 20px;
		margin: 0px 15px 0px 10px;
		border: 2px solid #f9e9aa;
	}

	.chatContentChat1 {
		/* width: 170px; */
		width: 190px;
		min-width: 170px;
		max-width: 270px;
		/* height: 50px; */
		border-radius: 5px;
		background-color: #f1dcc1;
	}

	.chatContentChat2 {
		width: 190px;
		min-width: 170px;
		max-width: 270px;
		width: -50%;
		/* height: 50px; */
		border-radius: 5px;
		background-color: #dcdcdc;
	}

	.sj1 {
		position: absolute;
		width: 0;
		height: 0;
		border: 8px solid transparent;
		border-right-color: #f1dcc1;
		top: 20px;
		left: 51px;

	}

	.sj2 {
		position: absolute;
		width: 0;
		height: 0;
		border: 8px solid transparent;
		border-left-color: #dcdcdc;
		top: 20px;
		right: 52px;

	}

	p {
		padding: 5px 6px 5px 9px;
	}
</style>
