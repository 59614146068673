<template>
  <div class="withDraw_Pop">
    <div class="content">
      你已领取过体验金，无法再次领取。若需要继续做任务，请联系在线客服！
    </div>
    <ul>
      <li>
        <img src="../../assets/img/iphone.png" alt="" width="33" height="33" />
        <div class="num">{{ 1234567890 }}</div>
        <a class="open" :href="'https://wa.me/' + whatapp">打开</a>
        <div
          v-clipboard="whatapp"
          v-clipboard:success="clipboardSuccessHandler"
          v-clipboard:error="copyAddressError"
        >
          <img src="../../assets/img/copy.png" alt="" width="21" height="24" />
        </div>
      </li>
      <li>
        <img src="../../assets/img/xfj.png" alt="" width="33" height="33" />
        <div class="num">{{ 4432423234 }}</div>
        <a class="open" href="telegram://">打开</a>
        <div
          v-clipboard="telegram"
          v-clipboard:success="clipboardSuccessHandler"
          v-clipboard:error="copyAddressError"
        >
          <img src="../../assets/img/copy.png" alt="" width="21" height="24" />
        </div>
      </li>
    </ul>
    <div class="confirm" @click="close">确定</div>
  </div>
</template>

<script>
export default {
  name: "withDraw_Pop",
  data() {
    return {};
  },
  props: {
    widthDraw_info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {},
  computed: {
    whatapp() {
      // const array = this.widthDraw_info.whatapp.split(",");
      // return array[Math.round(Math.random() * (array.length - 1))];
    },
    telegram() {
      // const array = this.widthDraw_info.telegram.split(",");
      // return array[Math.round(Math.random() * (array.length - 1))];
    },
  },
  methods: {
    close() {
      //关闭弹窗
      this.$emit("close");
    },
    open(type) {
      console.log(type);
    },
    //复制成功
    clipboardSuccessHandler({ value, event }) {
      this.$toast({
        message: "复制成功",
      });
      console.log(value);
    },
    copyAddressError(e) {
      this.$toast({
        message: "复制失败",
      });
    },
  },
};
</script>

<style scoped>
.withDraw_Pop {
  margin: 0 auto;
  width: 360px;
  height: 440px;
  text-align: center;
  background: #fff;
}
.withDraw_Pop > .content {
  margin-top: 210px;
  padding: 60px 35px 0px;
  color: #ff9d00;
  font-family: "PingFangSC-Regular";
  font-size: 17px;
  line-height: 30px;
  font-weight: 400;
  text-align: left;
}
.withDraw_Pop > ul {
  width: 330px;
  margin: 70px auto 0;
  padding: 15px 50px;
  border: 1px solid #ff9d00;
}
.withDraw_Pop > ul > li {
  display: flex;
  margin-top: 25px;
  align-items: center;
  justify-content: space-around;
}
.withDraw_Pop > ul > li > .open {
  width: 49px;
  height: 25px;
  color: #ff9d00;
  text-align: center;
  line-height: 23px;
  border-radius: 12px;
  border: solid 1px #ff9d00;
}
.withDraw_Pop > ul > li > .num {
  width: 96px;
  overflow: hidden;
}
.withDraw_Pop > img {
  margin-top: 40px;
}

.confirm {
  margin: 30px auto 0;
  width: 160px;
  text-align: center;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 18px 30px;
}
</style>
