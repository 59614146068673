<template>
  <div class="complete shibai">
    <div class="title">
      <span>{{ $t("TaskPopup[58]") }}</span>
    </div>
    <div class="center">
      <img src="../../assets/img/shibai.png" alt="" width="91" height="84" />
      <div class="text">
        <span> {{ $t("TaskPopup[59]") }}。</span>
      </div>
      <p class="shibai">Task progress</p>
      <!-- 进度条 -->
      <div class="progress-bar blue stripes">
        <div style="width: 35%"></div>
      </div>
    </div>
    <!-- 继续完成按钮 -->
    <div class="completeButton">{{ $t("TaskPopup[60]") }}</div>
    <div
      style="
        width: 178px;
        height: 33px;
        border-radius: 8px;
        border: solid 1px #5e59e9;
        text-align: center;
        line-height: 30px;
        font-size: 18px;
        margin-top: 10px;
        color: #5e59e9;
      "
      @click="$router.push('/user/promote')"
    >
      {{ $t("TaskPopup[18]") }}
    </div>
  </div>
</template>

<script>
/* 未完成全部任务 邀请好友得现金弹窗 */
export default {
  name: "incomplete",
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped>
.wrapper > .complete {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 279px;
  height: 341px;
  border-radius: 20px;
  background: #fff url("../../assets/img/amazon_bg.png") no-repeat;
  background-size: contain;
}
.wrapper > .shibai {
  background-image: url("../../assets/img/shibai_bg.png");
}
.wrapper > .shibai {
  width: 279px;
  height: 363px;
}
</style>
