import $ from "jquery";

SharrrePlatform.register("linkin", function (options) {
    var defaultSettings = {  //http://twitter.com/about/resources/tweetbutton
        url: '',  //if you need to personalize url button
        urlCount: false,  //if you want to use personnalize button url on global counter
        count: false,

        popup: {
            width: 900,
            height: 550
        }
    };

    defaultSettings = $.extend(true, {}, defaultSettings, options);
    return {
        settings: defaultSettings,
        trackingAction: {site: 'linkin', action: 'share'},
        url: function (test) {
            // Can't find the correct way to get share count
            return "";
        },
        load: function (self) {
            var sett = this.settings;
            var itself = this;
            $(self.element).find('.buttons').append(
                '<div class="button linkin">' +
                '<a href="https://www.linkedin.com/shareArticle?mini=true&url=' + (sett.url !== '' ? sett.url : self.options.url) + '">' +
                '<img src="https://www.linkinstatic.com/splinkin7.gif" alt="submit to linkin" border="0" />' +
                '</a></div>');
            $(self.element).find('.linkin').on('click', function () {
                itself.popup(self.options);
            });
        },
        tracking: function () {
        },
        popup: function (opt) {
            window.open("https://www.linkedin.com/shareArticle?mini=true&url="
                + encodeURIComponent((this.settings.url !== '' ? this.setting.url : opt.url))
                , "", "toolbar=0, status=0,width=" + this.settings.popup.width + ", height=" + this.settings.popup.height);
        }
    }
});