<template>
  <div class="box">
    <div class="title">{{ $t("inviteSubordinates[0]") }}</div>
    <div class="activityImg">
      <div class="cover_img">
        <img :src="'https://happyjob.online/' + userItem.prop_cover" alt="" />
      </div>
      <div class="inviteTit">
        <p>
          {{ $t("inviteSubordinates[16]")
          }}<span>{{ $t("inviteSubordinates[17]") }}</span
          >{{ $t("inviteSubordinates[18]") }}
        </p>
      </div>
      <div class="invitePeople">
        <div>
          <div @click="invitePeople()">
            <span>{{ $t("inviteSubordinates[1]") }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="receivableAmount">
      <div class="receivableAmount-img">
        <div class="amount">{{ $t("inviteSubordinates[2]") }}</div>
        <div class="moeny">{{ amount }}R$</div>
        <div class="receiveBtn" @click="getUpgradeAmount()">{{ receive }}</div>
      </div>
    </div>
    <div class="rewardList">
      <div>{{ $t("inviteSubordinates[3]") }}</div>
    </div>
    <p class="firends">{{ $t("inviteSubordinates[4]") }}</p>
    <div class="rewardTable" v-if="isempyt">
      <div class="rewardTable-top">
        <div class="rewardTable-top1">
          <p>{{ $t("inviteSubordinates[5]") }}</p>
          <p>/</p>
          <p>{{ $t("inviteSubordinates[6]") }}</p>
        </div>
        <div class="rewardTable-top2">
          <div v-for="(item, index) in userItem">
            {{ item.title }}
          </div>
        </div>
      </div>
      <div class="levelUpgrade">
        <div class="levelUpgradeLeft">
          <div>
            <div>{{ $t("inviteSubordinates[7]") }}</div>
            <div>{{ $t("inviteSubordinates[8]") }}</div>
          </div>
        </div>
        <div class="levelUpgradeRight">
          <div class="levelUpgradeRight-tr" v-for="(item, index) in userItem">
            <div class="level">{{ item.title }}</div>
            <div class="levelMoeny">
              <div v-for="(value, i) in userItem">
                R${{ value.content[index].content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="exposition" v-html="userItem.explain">
      <!-- <p>1、你的好友，升级到对应等级，你可获得对应的奖励金额</p>
			<p>2、获得的奖励系统自动计算到可领金额中 </p>
			<p>3、点击领取，领取你的奖励</p>
			<p>4、你的好友，升级到对应等级，你可获得对应的奖励金额</p> -->
    </div>
    <!-- 成功 -->
    <van-popup
      v-model="show"
      round
      style="overflow: visible; background: transparent"
    >
      <div class="success">
        <div class="successImg">
          <img src="../../../public/static/images/success.png" />
        </div>
        <div class="successContent" v-if="flag">
          <h2>{{ $t("inviteSubordinates[9]") }}</h2>
          <p>
            {{ $t("inviteSubordinates[10]") }}<br />
            {{ $t("inviteSubordinates[11]") }}
          </p>
          <div>
            <div @click="setShow()">{{ $t("inviteSubordinates[12]") }}</div>
            <div @click="toWallet()">{{ $t("inviteSubordinates[13]") }}</div>
          </div>
        </div>
        <div class="successContent" v-else>
          <!-- <h2>已领取</h2> -->
          <h2></h2>
          <p>
            {{ $t("inviteSubordinates[14]") }}{{ $t("inviteSubordinates[15]") }}
          </p>
          <div>
            <div @click="setShow()">{{ $t("inviteSubordinates[12]") }}</div>
            <div @click="toPromote()">{{ $t("inviteSubordinates[1]") }}</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
export default {
  name: "box",
  data() {
    return {
      show: false, //成功
      flag: true, //判断是否以领取
      userItem: [],
      isempyt: "", //判断下级列表是否为空
      userItem_: {},
    };
  },
  created() {
    this.ActivityGet(); //获取内容信息
  },
  computed: {
    receive() {
      if (
        this.userItem_.upgrade_amounts == null ||
        this.userItem_.upgrade_amounts == 0
      ) {
        this.flag = false;
        return "sem quantia";
      } else {
        this.flag = true;
        return "Receber";
      }
    },
    amount() {
      if (
        this.userItem_.upgrade_amounts == null ||
        this.userItem_.upgrade_amounts == 0
      ) {
        let num = 0;
        return num.toFixed(2);
      } else {
        return parseInt(this.userItem_.upgrade_amounts).toFixed(2);
      }
    },
  },
  methods: {
    ActivityGet() {
      let id = this.$route.query.id;
      let lang = localStorage.getItem("Language");
      let token = localStorage.getItem("Token");
      let data = {
        id,
        lang,
        token,
      };
      this.$Model.ActivityGet(data, (res) => {
        this.userItem_ = res.data.item;
        let array = res.data.item.frients_upgrade_conf;
        for (const index in array) {
          array[index].content[index].content
            ? this.userItem.push(array[index])
            : "";
        }
        let num = this.userItem.length;
        if (num > 0) {
          this.isempyt = true;
        } else {
          this.isempyt = false;
        }
      });
    },
    setShow() {
      this.show = false;
      setTimeout(() => {
        this.userItem_.upgrade_amounts = 0;
      }, 200);
    },
    invitePeople() {
      Dialog.confirm({
        message: "邀请好友",
        confirmButtonText: "立即邀请",
        overlay: true,
      })
        .then(() => {
          this.$router.push({ path: "/user/invite/" + 46 + "/" + 44 });
        })
        .catch(() => {
          // on cancel
        });
    },
    getUpgradeAmount() {
      if (this.flag) {
        this.show = true;
        let token = localStorage.getItem("Token");
        let json = {
          token,
        };
        this.$Model.getUpgradeAmount(json, (data) => {
          console.log(data.code);
        });
      } else {
        this.show = true;
      }
    },
    toPromote() {
      this.$router.push("/user/promote");
    },
    toWallet() {
      this.$router.push("/user/wallet");
    },
  },
};
</script>

<style scoped>
.box {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: linear-gradient(
    to bottom,
    rgb(116, 38, 228) 40%,
    rgb(251, 253, 255)
  );
  padding-bottom: 40px;
  box-sizing: content-box;
}
.title {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
.activityImg {
  width: 100%;
  height: 300px;
  position: relative;
}
.cover_img {
  width: 100%;
  height: 100%;
  padding: 0 15px;
}
.cover_img > img {
  width: 100%;
  height: 100%;
  display: block;
}
.inviteTit {
  width: 293px;
  height: 120px;
  /* background: pink; */
  position: absolute;
  top: 105px;
  left: 50px;
  padding: 10px;
}
.inviteTit > p {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  font-style: oblique;
}
.inviteTit > p > span {
  font-size: 25px;
}
.invitePeople {
  width: 250px;
  height: 60px;
  border-radius: 25px;
  background: linear-gradient(to top, rgb(192, 45, 255), rgb(14, 240, 255));
  box-sizing: border-box;
  padding: 4px;
  position: absolute;
  bottom: 10px;
  left: 60px;
}
.invitePeople > div {
  width: 242px;
  height: 52px;
  border-radius: 25px;
  background: rgb(54, 0, 152);
}
.invitePeople > div > div {
  width: 194px;
  height: 56px;
  position: absolute;
  top: 2px;
  left: 28px;
  border-radius: 25px;
  box-sizing: border-box;
  border: 8px solid rgb(35, 125, 229);
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 40px;
}
.receivableAmount {
  width: 100%;
  padding: 25px 0;
  height: 200px;
  box-sizing: border-box;
}
.receivableAmount-img {
  width: 230px;
  height: 100%;
  margin: 0 auto;
}
.amount {
  height: 25px;
  width: 100%;
  text-align: center;
  line-height: 25px;
  font-size: 13px;
  color: #fff;
}
.moeny {
  width: 100%;
  height: 87px;
  text-align: center;
  line-height: 100px;
  font-size: 35px;
  font-weight: 600;
  color: rgb(255, 201, 55);
}
.receiveBtn {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: rgb(255, 57, 91);
}
.rewardList {
  width: 100%;
  height: 70px;
  padding: 15px 0;
}
.rewardList > div {
  width: 165px;
  height: 100%;
  line-height: 40px;
  margin: 0 auto;
  color: #fff;
  border-radius: 20px;
  background: rgb(131, 50, 255);
  text-align: center;
  font-size: 16px;
  padding: 0 10px;
  box-sizing: content-box;
}
.firends {
  width: 100%;
  height: auto;
  line-height: 13px;
  text-align: center;
  color: rgb(255, 195, 179);
  margin: 10px 0;
  line-height: 16px;
}
.rewardTable {
  width: auto;
  max-width: 333px;
  height: auto;
  margin: 20px auto;
  border: 1px solid rgb(78, 81, 191);
  position: relative;
  overflow: scroll;
}
.rewardTable-top {
  height: 66px;
  width: auto;
  display: flex;
}
.rewardTable-top > div.rewardTable-top2 {
  width: calc(100% - 66px);
  height: 100%;
  line-height: 66px;
  text-align: center;
  display: flex;
}
.rewardTable-top > div.rewardTable-top1 {
  width: 66px;
  border-left: 0;
  padding: 5px 0;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
}
.rewardTable-top > .rewardTable-top2 > div {
  flex: 0 0 50px;
  border-left: 1px solid rgb(78, 81, 191);
}
.rewardTable-top > div.rewardTable-top1 > p {
  width: 100%;
  height: 16px;
  line-height: 16px;
}
.rewardTable-top > div.rewardTable-top1 > p:last-child {
  line-height: 13px;
}
.levelUpgrade {
  width: 100%;
  height: auto;
  display: flex;
}
.levelUpgrade > .levelUpgradeLeft {
  width: 33px;
  height: 100% !important;
  text-align: center;
  border-top: 1px solid rgb(78, 81, 191);
}
.levelUpgradeLeft > div {
  width: 33px;
  height: auto;
  /* min-height: 120px; */
  position: absolute;
  top: calc(20% - 16px + 33px);
  left: 0;
  /* text-align: center; */
}
.levelUpgradeLeft > div > div {
  line-height: 16px;
  margin-top: 45px;
  transform: rotate(90deg);
}
.levelUpgradeRight {
  width: calc(100% - 33px);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.levelUpgradeRight-tr {
  width: 100%;
  height: 33px;
  display: flex;
}
.levelUpgradeRight-tr > div.level {
  width: 33px;
  height: 100%;
  line-height: 33px;
  text-align: center;
  border-left: 1px solid rgb(78, 81, 191);
  border-top: 1px solid rgb(78, 81, 191);
  color: rgb(131, 50, 255);
}
.levelUpgradeRight-tr > div.levelMoeny {
  height: 100%;
  width: calc(100% - 33px);
  display: flex;
  text-align: center;
  line-height: 33px;
}
.levelUpgradeRight-tr > div.levelMoeny > div {
  border-left: 1px solid rgb(78, 81, 191);
  border-top: 1px solid rgb(78, 81, 191);
  color: rgb(131, 50, 255);
  flex: 0 0 50px;
}
.exposition {
  width: 100%;
  height: auto;
  padding: 0 30px;
}
.exposition >>> p {
  color: #313131;
  margin-top: 6px;
}
.success {
  /* position: fixed;
		top:30%;
		left:35px; */
  width: 300px;
  height: 238px;
  background: linear-gradient(to bottom, rgb(254, 147, 164), rgb(79, 82, 191));
  border-radius: 20px;
}
.successImg {
  width: 70px;
  height: 70px;
  position: absolute;
  top: -35px;
  left: 115px;
}
.successImg > img {
  width: 100%;
  height: 100%;
}
.successContent {
  width: 290px;
  height: 228px;
  background: #fff;
  border-radius: 20px;
  margin: 5px;
  overflow: hidden;
  text-align: center;
  padding: 0 20px;
}
.successContent > h2 {
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin-top: 30px;
  font-size: 20px;
  color: rgb(254, 136, 157);
}
.successContent > p {
  width: 100%;
  height: auto;
  margin-top: 10px;
  line-height: 30px;
  letter-spacing: 1px;
}
.successContent > div {
  width: 100%;
  height: 40px;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.successContent > div > div {
  width: 120px;
  height: 100%;
  text-align: center;
  line-height: 40px;
  color: #fff;
  background: rgb(78, 81, 191);
  border-radius: 15px;
}
</style>
