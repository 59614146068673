<template>
    <div class="finListBox">
        <div class="bgc">
            <div class="topTitle">{{$t('fin[1]')}}</div>
        </div>
        <div style="margin-top: 55px"></div>
aaa
        <div style="margin-left: 10px;margin-right: 10px" :key="index" v-for="(item,index) in listData">
            <div class="tool">
                <div style="padding-bottom: 15px">
                   <span style="color: red"> {{$t('fin[9]')}}</span>---<span style="color: blue">{{configData[item.configId].name}} +{{Number(configData[item.configId].rate).toFixed(2)}}%</span>
                    <span v-if="item.status==1">（{{$t('fin[17]')}}）</span>
                </div>
                <van-row>
                    <van-col span="19">
                        <div> {{$t('fin[9]')}}{{$t('myprops.Popup[1]')}}:
                            <span style="color: blue">{{Number(item.amount).toFixed(2)}}</span></div>
                        <div>{{$t('fin[9]')}}{{$t('fin[15]')}}:{{item.importChannel==0?$t('fin[29]'):$t('fin[30]')}}</div>
                        <div>{{$t('fin[28]')}}:{{Number(item.actEarning).toFixed(2)}}</div>
                        <div>{{$t('fin[9]')}}{{$t('fin[16]')}}:{{item.crDate}}</div>
                    </van-col>
                    <van-col span="5">
                        <van-button v-if="item.status==1" class="button3" round  @click="outFin(index,item.id)" plain hairline type="info">
                            {{$t('fin[26]')}}
                        </van-button>
                        <van-button v-if="item.status==2" class="button4" disabled round  @click="outFin(index,item.id)" plain hairline type="info">
                            {{$t('fin[27]')}}
                        </van-button>
                    </van-col>
                </van-row>
            </div>
        </div>

    </div>
</template>

<script>
    import config from '@/config'

    const AppDown = config.AppDown
    export default {
        name: 'finlist',
        components: {},
        props: [],
        data() {
            return {
                token:'',
                statisticalData: {},
                uid:'',//用户ID
                configData: {},
                listData:[],
                form:{
                    id:0,
                },
            }
        },
        computed: {

        },
        created() {
            this.token = localStorage.getItem('Token');
            this.GetFinList()
        },
        methods: {
            GetFinList() {
                this.$Model.GetFinConfigData({token:this.token},data => {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        this.configData[item.id] = item
                    }

                    this.$Model.GetFinData({token:this.token},data => {
                        this.listData = data.info
                    })
                })

            },
            outFin(index,id){
                console.log('outFinoutFinoutFin')
                this.$Dialog.Confirm(this.$t('fin[18]'), () => {
                    this.$Model.outFin(this.form,data=>{
                        if(data.code==1){
                            this.listData[index].status = 2
                            this.$Dialog.Confirm(this.$t('fin[20]'), () => {
                                this.$router.push("/user");
                            },this.$t('fin[21]'))
                        }
                    })
                },this.$t('fin[19]'))
                this.form['token'] = this.token;
                this.form['id'] = id
            }
        }
    }
</script>
<style scoped>
    .finListBox {
        overflow: auto;
        height: 100vh;
        background-color: #fff;
        font-family: Alibaba;
        font-weight: normal;
    }

    .finListBox .tool {
        width: 95%;
        border-radius: 12px;
        background-color: #fff;
        box-shadow: 0 2px 10px #dbdbdbdb;
        overflow: hidden;
        margin: 5px auto;
        margin-top: 10px;
        line-height: 20px;
        z-index: 99;
        padding:10px 15px 15px 20px;
    }
    .button3{
        background-color: #7c7cd8;
        color: #ffffff;
        width: 70px;height: 30px
    }

    .button4{
        background-color: #d9d8fb;
        color: #000000;
        margin-left: -5px;
        width: 80px;height: 30px
    }

    .finListBox .bgc {
        width: 100%;
        background-color: #4e51bf;
        height: 46px;
        position: fixed;
        top: 0;
        z-index: 200;
    }

    .topTitle {
        position: absolute;
        max-width: 60%;
        white-space: nowrap;
        font-size: 16px;
        color: #fff;
        background-color: transparent;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
    }

</style>
