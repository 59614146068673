export default {
  mixRecharge: ["Please fill in your email address"],
  language: "Language",
  common: ["Online service", "Unhover","Confirm","Cancel"],
  upload: ["Uploading...", "Wrong format", "Uploaded successfully", "Upload failed"],
  vanPull: ["No more data", "No data"],
  login: {
    text: ["Language","Mlink","Log in"],
    label: ["Logging in...", "Log in immediately"],
    placeholder: ["Please input your email", "Please enter your password"],
    default: ["No account?", "Sign up now","Forgot password?","Forgot Your Password"],
    codes:["account not exist","wrong password","account freezed","fail"],
      text2: ['Mail', 'Verifica', 'Password', 'Invite', 'Phone'],
      text1: ['Log in', 'Welcome to web', "Email or phone", 'Password','Sign in'],
  },
  register: {
    text: [
      "Mlink",
      "Verification code is being sent...",
      "Click to get",
      "Registering...",
      "Sign up now",
    ],
    placeholder: [
      "Please input your email",
      "Please enter verification code",
      "Please enter your password",
      "Please confirm your password",
      "Please enter the invitation code",
      "Different passwords twice",
      "Please enter verification code",
      "Invalid email"
    ],
    label: ["Already have account?{a} {line}", "Back to login"],
    codes:["Account exist","empty code","wrong code","password not the same","wrong recommend","fail"]
  },
  resetpwd:["Forgot password","Please input your email","Please enter verification code","Click to get","Please enter password","Reset Password"],
  footer: ["Front page", "Hall", "Order", "Mine"],
  home: {
      WebMarket:'WebMarket',
      special_attention:'Special attention',
      spot_market:'Spot_market',
    label:["Welcome"],
    broadcast: "Congratulations Member{member} Become{grade}",
    menu: ["Funding Journal", "Tutorial", "Invitation", "Customer Service"],
    noticeTitle: "Latest Announcement",
    msg: "Mission not open",
    video: "No video tutorials yet",
  },
  hall:{
    default:[
        "Trading Floor","Balance","I want to buy","I want to sell","Confirm sell",
      "Order List","Sell Order","Sell to merchant","Pending Order","Buy","Sell"
    ],
    list:[
        'Quantity','Unit Price','Not traded','Sell USDT','Please enter the amount of USDT','Get amount',"Amount",'Buy USDT','Amount spent'
    ],
    kbip:["Revoke","Excess amount","Must be within the amount"]
  },
  msglist:[
    "Message list"
  ],
  sell:{
      placeholder:["Please enter the selling price","Please enter the quantity to sell"],
      label:["balance(USDT)","Name","Bank Account","Current price(USDT)","best price to sell","selling price","sold quantity","best price","all","sure to sell"]
  },
  buy:{
    placeholder:["Please enter a buy price","Please enter the purchase quantity","Minimum amount","maximum amount"],
    label:["balance(USDT)","Name","Bank Account","Current price(USDT)","buy best price","buy price","Buy quantity","best price","all","sure buy","Amount"]
  },
  user: {
    default: [
      "Personal center",
      "Login account",
      "Invitation code",
      "Sign out",
      "Balance",
      "Gold",
      "Wallet",
    ],
    menu: [
      "My assets",
      "Team list",
      "Account binding",
      "Account details",
      "Platform Introduction",
      "Sign out",
    ],
  },
 bindAccount: {
    default: ["Bind account","Submit"],
    fields: ["Bank Name","Account Name", "Bank Account", "Cellphone number","name","Types","USDT Address"],
    placeholder: ["Please enter bank name","Please enter account name","Please enter Bank Account","Please enter phone number","Please enter bank name",
      "Please select USDT type","Please enter USDT address"],
     fields2: ["Account", "Phone", "Card No","Bank Name","Type","Address"],
     placeholder2: ["Please enter account","Please enter phone","Please enter card no",
         "Please enter bank name",
         "Please choose USDT type","Please enter USDT address"],
  },
  wallet: {
    default: [
      "My assets",
      "Recharge",
      "Withdraw",
      "Recharge record",
      "Withdrawals record",
      "Recharged amount",
      "Amount sold",
      "Earned",
      "Account balance",
      "balance",
      "Recharge amount(USDT)",
      "Amount sold(USDT)",
      "Total revenue",
        "Upgrade business",
      "To upgrade the merchant, please contact customer service",
        "Confirm the settled business",
        "Merchant Certification",
      "You need pay","Payment voucher","Merchant deposit","Authenticate now","Nnformation","You need pay","Whether the balance is insufficient to recharge"
    ],
    label: [
      "Withdrawal method",
      "Withdrawal Amount",
      "Fund password",
      "Submit",
      "Phone number",
      "Mail",
      "IFSC",
      "Sure",
    ],
    placeholder: [
      "Choose a withdrawal method",
      "Please enter the withdrawal amount",
      "Please enter the fund password",
      "Please select a withdrawal method",
      "Please enter the recipient's mobile number",
      "Please enter recipient email",
      "Please enter payee IFSC",
    ],
    msg: [
      "You have not set up a fund password, please set it first",
      "You have not bound your PIX account, please bind it first",
      "Sure you want to withdraw?",
    ],
  },
  recharge: {
    default: [
      "Wallet recharge",
      "Wallet pre-charge",
      "Recharge method",
        "Recharge",
        "Confirm",
    ],
    label: [
      "Category",
      "Address",
      "Recharge amount",
      "Enter amount",
      "Upload certificate",
        "Copy successfully",
        "Precharge Exchange"
    ],
    placeholder: [
      "Types",
      "Address",
    ],
    info: [
      "Recharge amount",
      "Order number",
      "Beneficiary Bank",
      "Receiving account",
      "Payee",
      "Copy",
    ]
  },
  task: {
    tabs: ["All","In progress", "Expired", "Completed","Wait pay","Wait confirm"],
    default: ["Order List",],
    msg: ["Submit review", "Submitted, please wait for review", "Failed to submit, please resubmit"],
  },
  userInfo: {
    default: [
      "Personal information",
      "Avatar",
      "Account",
      "Mail",
      "Credit",
      "Details",
      "Change Password",
      "Fund password",
      "Click on settings",
      "Modify avatar",
      "Modify login password",
      "Modify the fund password",
      "Submit",
      "Empty the cache",
      "View information",
      "Nick name",
      "Referral code"
    ],
    label: [
      "Original login password",
      "New login password",
      "Confirm password",
      "Original fund password",
      "New fund password",
      "Confirm password",
      "Save"
    ],
    placeholder: [
      "Please enter the original login password",
      "Please enter a new login password",
      "Please confirm the login password",
      "Please enter the original fund password",
      "Please enter new fund password",
      "Please confirm the fund password",
    ],
  },
  fundRecord: {
    default: ["Expense record", "Recharge record", "Asset log", "Charge", "Receive", "Branch"],
    tabs: ["Income", "Expenditure", "Recharge"],
  },
 dialog: [
    "Hint",
    "Confirm",
    "Submitting...",
    "Copy successfully",
    "IOS system version is not supported",
    "Registering...",
    "Loading...",
     "Loading...",
  ],
  serviceCenter: [
    "Customer Service",
    "Hi,I am a dedicated customer service~",
    "Glad to serve you",
    "Self service",
    "Online service",
    "Recharge customer service",
    "Line customer service",
  ],
 userTaskRecord: [
    "My Order",
    "Sell order",
    "Buy order",
    "Current state",
    "Get commission",
    "Completed",
  ],
  withdrawlist: [
    "Withdrawals record",
  ],
  teamReport: {
    default: [
      "Team list",
    ],
  },
  common2:['Beginner\'s Tutorial',"Contact Customer Service","Terms of Service, see details","Customer service 1","Customer service 2"],
  common3:['Success',"Platform Introduction"],
  invite:['Share promotion','My invitation code',"Copy the invitation code to invite more friends to join","copy"],
  common4:['Real name authentication',"Submitted successfully","matters needing attention",
      "Content of matters","premium received","Tax amount","Financial certificate"
      ,"Please finish card binding","Please finish ID auth","Please finish business auth"],
  common5:["Sold","Minutes","before","Save QR code","Open","The account is frozen and cannot be traded for the time being","APP"],
  common6:["RechargeOrder","OrderNumber","Amount","Time","Status"],
  hall2:["min sell amount is","","exceed daily sell count"],
  register2:["Mail","Phone","Please enter phone number"],
  withdraw2:["Withdraw","SellerAccount","Balance","Need pay","Pay","There are still tasks that have not been completed. Cash withdrawal is not allowed for the time being","Withdrawal count exceed"],
  mytask2:["MerchantAccount","price","amount","balance","pay amount","match time","OrderDetail","number can't be 0","balance not enough","price can't 0","wrong scope"],
  taskOrder2:["You still have an open order and can't be cancelled","balance not enough","Confirm","fail","You still need to complete the order before you can withdraw cash:"],
  busAuth2:['MerchantUp','I agree',"up confirm","up Merchant",
      "If you need to modify the bank card information, please contact customer service",
  "The current account needs to be upgraded to a merchant account"],
    recharge2:['amount required','screenshots required'],
    buy3:["count down","hour","type",'WelfareOrder','GroupOrder','count limit','count limit','specify Userid','input user id'],
    hall3:["day","hour","minute","second","GroupOrder","account",'overtime'],
    sell3:['sell price can not bigger than best price'],
    busAuth3:["Member","Merchant","CrownMerchant"],
    recharge3:["Cal"],
    home3:["Miner","Guess","Blind box","Financial","Coming soon"],
    home4:["Quick trade","Quick buy USDT","C2C trade","TransferUSDT",'Online',"24H Amount(USDT)"],
    common7:["Contact service if have any questions","Contact service","Ask Help","Setting","All","Invite","MemberCount","Month New","Reg time","下属人数"],
    hall4:['Type','Member',"Your account","trade success","complete successfly","TotalAmount","Buy","Sell"],
    task3:["All","Not pay","Paid"],
    my:["Are sure to logout?"],
    bindAccount2:["Confirm that the information is filled in correctly, otherwise the normal transaction will be affected",
        "Bind now","Customer Service"],
    recharge4:["For the safety of your funds, after the transfer is successful, please submit the screenshot of successful transfer for review!"],
    resetpwd2:['Mail','Phone','Note: the password is composed of 6 ~ 16 characters and is not case sensitive'],
    home2:["Special Attension","Money market"],
    login1:["Next","Login now","Looks like you dont't have an account. Let's create a new account:","Agree and continue"],
    login2:["More login ways","Continue with ","Facebook","Google","Apple"],
    service2:["Trading","Other"],
    buy2:["Order type","Buy successfull","USDT was bought successfully"],
    sell4:['You confirm this sale',"Verify","Successful sale","You successfully sell","Wait for the buyer to pay","Complete"],
    hall5:["Member"],
    busauth:['Identify information','Please input your real name',
        'Please input your ID number','upload photo',
        'please upload your ID photo',"Please upload bank detail"],
    recharge5:["Please upload a photo","Recharge successfully",
        "USDT was successfully recharged to the account","Verify"],
    note:["Note","tipsssss","Telephone Number","Please input your telephone number","Leave a message","Please leave your message here",
        "Upload pictures","Please upload picture","Submit successfully","We will contact you as soon as possible","Close"],
    notelist:[
        "Note List"
    ],
    search:['Search','no data found',"Please input keyword to find"],
    searchs:{
        orders:"Order",recharge:"Recharge",withdraw:"Withdraw"
    },
    team2:["Invatation details","Invite friends"],
    task4:["My Task"],
    lang3:["English","Korean","Taiwanese"],
    login3:["Account or password error, please re-enter"],
    shiming:['Tax Audit'],
};
