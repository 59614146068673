export default {
  mixRecharge: ["メールアドレスを入力してください"],
  language: "言語",
  common: ["オンラインサポート", "フロートをキャンセル", "確認", "キャンセル"],
  upload: ["アップロード中...", "形式が正しくありません", "アップロード成功", "アップロード失敗"],
  vanPull: ["データがもうありません", "データがありません"],
	  login: {
		text: ["言語", "KKR", "ログイン"],
		label: ["ログイン中...", "すぐにログイン"],
		placeholder: ["メールアドレス/携帯電話番号を入力してください", "ログインパスワードを入力してください"],
		default: ["アカウントがありませんか？", "今すぐ登録", "パスワードをお忘れですか？", "ログインパスワードをお忘れですか？"],
		codes: ["アカウントが存在しません", "パスワードが正しくありません", "アカウントが凍結されました", "失敗"]
		},
  register: {
    text: [
        "KKR",
        "認証コード送信中...",
        "取得する",
        "登録中...",
        "今すぐ登録",
    ],
    placeholder: [
        "メールアドレスを入力してください",
        "認証コードを入力してください",
        "ログインパスワードを入力してください",
        "パスワードを確認してください",
        "招待コードを入力してください",
        "パスワードが一致しません",
        "認証コードを入力してください",
        "無効なメールアドレス"
    ],
    label: ["既にアカウントをお持ちですか？{a} {line}", "ログインに戻る"],
    codes:["アカウントは既に存在します","認証コードがありません","認証コードが正しくありません","パスワードが一致しません","推薦人が存在しません","失敗"]
},
resetpwd:["パスワードをお忘れですか","メールアドレスを入力してください","認証コードを入力してください","取得する","パスワードを入力してください","パスワードをリセットする"],
footer: ["ホーム", "ロビー", "注文", "私の"],


  home: {
    label:["ようこそ"],
    broadcast: "会員{member}さん、{grade}になりました。おめでとうございます。",
    menu: ["資金記録", "初心者ガイド", "招待登録", "カスタマーサポート"],
    noticeTitle: "最新お知らせ",
    msg: "ミッションはまだ利用できません",
    video: "ビデオチュートリアルは現在利用できません",
},
hall:{
    default:[
        "取引ロビー","残高","購入","売却","売却を確定する",
        "注文リスト","売却注文","売却先業者","オーダー中","購入","売却先に進む"
    ],
    list:[
        'USDTの購入数量','USDT価格','残り','売却するUSDT','USDT数量を入力してください','受け取る金額','最低購入限度額','USDTの購入','支払金額','最大購入限度額'
    ],
    kbip:["取り消し","金額超過","金額範囲内でなければならない"]
},
msglist:[
    "メッセージリスト"
],
sell:{
    placeholder:["売却価格を入力してください","売却数量を入力してください"],
    label:["残高(USDT)","名前","銀行口座","現在の価格(USDT)","最適売値","売却価格","売却USDT数量","最適価格","全部","売却を確定する"]
},
buy:{
    placeholder:["購入価格を入力してください","購入数量を入力してください","最小限度額","最大限度額"],
    label:["残高(USDT)","名前","銀行口座","現在の価格(USDT)","最適購入価格","購入価格","購入数量","最適価格","全部","購入を確定する","限度額"]
},
user: {
    default: [
        "個人情報",
        "ログインアカウント",
        "招待コード",
        "ログアウト",
        "残高",
        "金貨",
        "ウォレット",
    ],
    menu: [
        "私の資産",
        "チームリスト",
        "アカウントバインディング",
        "アカウント履歴",
        "プラットフォーム紹介",
        "ログアウト",
    ],
},

  bindAccount: {
    default: ["アカウントをバインド", "提出する"],
    fields: ["お名前", "電話番号", "アカウント", "銀行", "タイプ", "USDTアドレス", "銀行コード"],
    fields2: ["アカウント名", "電話番号", "アカウント", "銀行名", "タイプ", "アドレス"],
    placeholder: ["口座名義を入力してください", "携帯電話番号を入力してください", "銀行口座番号を入力してください", "開設銀行名を入力してください",
        "USDTの種類を選択してください", "USDTアドレスを入力してください"],
    placeholder2: ["アカウント名を入力してください", "電話番号を入力してください", "アカウントを入力してください", "銀行名を入力してください",
        "USDTの種類を選択してください", "USDTアドレスを入力してください"],
},
wallet: {
    default: [
        "マイアセット",
        "チャージ",
        "引き出し",
        "チャージ履歴",
        "引き出し履歴",
        "チャージ済み金額",
        "売却済み金額",
        "受け取った利益",
        "アカウント残高",
        "残高",
        "チャージ金額(USDT)",
        "売却金額",
        "トータル収益",
        "ビジネスパートナーに昇格",
        "ビジネスパートナーに昇格するには、カスタマーサポートに連絡してください",
        "ビジネス認証",
        "お支払いが必要です", "支払い証明", "ビジネス保証金", "今すぐ認証", "情報", "お支払いが必要です", "残高が不足しています。チャージしますか？"
    ],
    label: [
        "引き出し方法",
        "引き出し金額",
        "資金パスワード",
        "提出する",
        "携帯電話番号",
        "メールアドレス",
        "IFSC",
        "確定",
    ],
    placeholder: [
        "引き出し方法を選択してください",
        "引き出し金額を入力してください",
        "資金パスワードを入力してください",
        "引き出し方法を選択してください",
        "受取人の携帯電話番号を入力してください",
        "受取人のメールアドレスを入力してください",
        "受取人のIFSCを入力してください",
    ],
    msg: [
        "資金パスワードが設定されていません。まず設定してください。",
        "PIXアカウントがバインドされていません。まずバインドしてください。",
        "引き出しますか？",
    ],
},
recharge: {
    default: [
        "ウォレットチャージ",
        "ウォレットプリチャージ",
        "チャージ方法",
        "チャージ",
        "確認",
    ],
    label: [
        "タイプ",
        "アドレス",
        "チャージ金額",
        "金額を入力",
        "アップロード証明",
        "コピー成功",
        "プリチャージ取引所"
    ],
    placeholder: [
        "タイプを選択してください",
        "アドレスを入力してください",
    ],
    info: [
        "チャージ金額",
        "注文番号",
        "受取銀行",
        "受取アカウント",
        "受取人",
        "コピー",
    ]
},

  task: {
    tabs: ["すべて","進行中", "無効", "完了","業者の支払い待ち","確認待ち"],
    default: ["注文リスト",],
    msg: ["審査提出", "提出済み、審査待ちです", "提出失敗、再提出してください"],
},
userInfo: {
    default: [
        "個人情報",
        "プロフィール画像",
        "アカウント",
        "メール",
        "アリペイ",
        "詳細情報",
        "パスワード変更",
        "資金パスワード",
        "設定する",
        "プロフィール画像変更",
        "ログインパスワード変更",
        "資金パスワード変更",
        "提出",
        "キャッシュクリア",
        "情報を確認",
        "ニックネーム",
        "招待コード"
    ],
    label: [
        "旧ログインパスワード",
        "新規ログインパスワード",
        "パスワード確認",
        "旧資金パスワード",
        "新規資金パスワード",
        "パスワード確認",
        "保存"
    ],
    placeholder: [
        "旧ログインパスワードを入力してください",
        "新規ログインパスワードを入力してください",
        "ログインパスワードを確認してください",
        "旧資金パスワードを入力してください",
        "新規資金パスワードを入力してください",
        "資金パスワードを確認してください",
    ],
},
fundRecord: {
    default: ["支出履歴", "チャージ履歴", "アセットログ", "チャージ", "受取", "支払"],
    tabs: ["収入", "支出", "チャージ"],
},
dialog: [
    "注意",
    "確認",
    "提出中...",
    "コピー成功",
    "iOSのバージョンが低いためサポートされていません",
    "登録中...",
    "データを読み込んでいます...",
],
serviceCenter: [
    "カスタマーセンター",
    "こんにちは、専属カスタマーサービスです。",
    "ご利用いただきありがとうございます。",
    "セルフサービス",
    "オンラインサポート",
    "チャージサポート",
    "ラインサポート",
],
userTaskRecord: [
    "マイオーダー",
    "売却オーダー",
    "購入オーダー",
    "現在のステータス",
    "手数料を受け取る",
    "完了",
],
withdrawlist: [
    "出金履歴",
],

  teamReport: {
    default: [
        "チームリスト",
		],
	},
	common2:['初心者ガイド',"カスタマーサポート","利用規約を確認する","カスタマーサービス1","カスタマーサービス2"],
	common3:['成功',"プラットフォーム紹介"],
	invite:['シェアプロモーション','招待コード',"招待コードをコピーして、より多くの友人を招待します","コピー"],
	common4:['本人確認',"提出成功","注意事項","内容","支払金額","税額",
		"財務証明","アカウントバインドを先に行ってください","本人確認を先に行ってください"],
	common5:["売却","分","前","QRコードを保存","開く","アカウントが凍結されています。一時的に取引できません","アプリのダウンロード"],
	common6:["チャージ注文","注文番号","金額","時間","ステータス"],

	hall2:["最低販売金額は","","販売回数が1日の制限を超えました"],
	register2:["メールで登録","携帯電話で登録","携帯電話番号を入力してください"],
	withdraw2:["引き出し","販売者アカウント","口座残高","支払い金額","即時支払い","未完了のタスクがあり、一時的に引き出しができません","毎日の引き出し回数を超えました","備考説明"],
	mytask2:["ビジネスアカウント","価格","金額","アカウント残高","支払い金額","時間","注文の詳細","数量は0にできません","残高が不足しています","価格は0にできません","範囲エラー"],
	taskOrder2:["未処理の注文がありますので、キャンセルできません","残高が不足しています","確認","失敗","引き出しにはまだ完了していない注文が必要です:"],
	busAuth2:['ビジネスアップグレード','同意する',"アップグレードを確認","ビジネスアップグレード","銀行カード情報を変更する場合はカスタマーサポートにお問い合わせください","現在のアカウントはビジネスアカウントにアップグレードする必要があります"],
	recharge2:['金額は0にできません','スクリーンショットをアップロードしてください'],
	buy3:["カウントダウン","時間","タイプ",'福利注文','共同注文','制限人数','指定しない場合は制限されません','指定ユーザーID','指定IDを入力してください'],
	hall3:["日","時間","分","秒","共同注文","アカウント",'期限切れ'],
	sell3:['販売価格は最適価格を超えることはできません'],
	busAuth3:["会員","ビジネス","クラウンビジネス"],
	recharge3:["計算"],
	home3:["マイニング","ゲーム予想","宝箱","投資","お楽しみに"],
	home4:["高速取引","USDT一括購入","C2C取引","USDT売買",'オンラインユーザー',"24時間取引総額(USDT)"],
	common7:["問題が解決されない場合は、オンラインカスタマーサポートにお問い合わせください","カスタマーサポートに連絡する","問い合わせをクリックする","設定","すべて","友達を招待する","チームメンバー","今月追加","登録日","下属の数"],
	hall4:['あなたの身分','会員',"あなたのアカウント情報","取引成功","あなたはすでに成功しました","総額","購入","販売"],
	task3:["すべて","未払い","支払済み"],

	my:["アカウントをログアウトしてもよろしいですか？"],
	bindAccount2:["情報が正しく入力されていることを確認してください。それ以外の場合、正常な取引に影響する可能性があります","すぐにバインド","カスタマーサポートに連絡"],
	recharge4:["あなたの資金の安全のため、送金が完了したら送金成功のスクリーンショットを提出してください。審査のためです！"],
	resetpwd2:['メール','携帯電話','注意：パスワードは6〜16文字で構成され、大文字と小文字を区別しません'],
	home2:["スペシャル・アテンション","現物市場"],
	login1:["次へ","今すぐログイン","お持ちのアカウントがまだないようです。新しいアカウントを作成しましょう：","同意して続行"],
	login2:["他のログイン方法","引き続き ","Facebook","Google","Apple"],
	service2:["取引","その他"], buy2:["注文タイプ","購入成功","USDTの購入が成功しました"],
	sell4:["この販売を確認しますか","確認","販売成功","販売に成功しました","バイヤーの支払い待ち","完了"],
	hall5:["会員"],
	busauth:["身分情報認証","あなたの実際の名前を入力してください","あなたの身分証明書番号を入力してください","写真をアップロードしてください","あなたの身分証明書の写真をアップロードしてください","あなたの送金明細をアップロードしてください"],
	recharge5:["写真をアップロードしてください","充電が成功しました","USDTがアカウントに正常に充電されました","審査"],
	note:["注意事項","ヒント","電話番号","あなたの電話番号を入力してください","メッセージ","ここにメッセージを残してください","写真をアップロードしてください","画像をアップロードしてください","成功した","すぐにご連絡します","閉じる"],
	notelist:[ "メッセージリスト" ],
	search:["検索","データが見つかりません","キーワードを入力して検索してください"],
	searchs:{ orders:"注文",recharge:"充電",withdraw:"引き出し" },
	team2:["チームの詳細","友達を招待する"],
	task4:["私のタスク"],
	lang3:["英語","韓国語","繁体字中国語"],
	login3:["アカウントまたはパスワードが間違っています。もう一度入力してください"],
	shiming:['税務審査'],

};
