<template>
  <div class="details">
    <!-- 标题部分 -->
    <div class="title">{{ $t("details.word[0]") }}</div>
    <!-- 主体内容部分 -->
    <div class="body">
      <p>{{ $t("details.word[1]",{name:'xxx'}) }}</p>
      <!-- LALKETUL商场 -->
      <div class="head">
        <div class="head_one"></div>
        <div class="head_two">+R$ 0.98</div>
      </div>
      <!-- 数据 -->
      <div class="neck">
        <div class="neck_one">
          <div class="dot"></div>
          <div>{{ $t("details.word[2]",{index:300}) }}</div>
        </div>
        <div class="neck_two">
          <div class="dot"></div>
          <div>{{ $t("details.word[3]",{index:200}) }}</div>
        </div>
        <div class="neck_three">
          <div class="dot" v-show="$t('details.word[4]')"></div>
          <div>{{ $t("details.word[4]") }}</div>
        </div>
      </div>
      <!-- 任务描述1 -->
      <div class="task">
        <div>{{ $t("details.word[5]") }}:</div>
        <p>{{ $t("details.word[6]") }}</p>
        <div class="more_dot">
          <div></div>
          <div>{{ $t("details.word[7]",{name:'多点点'}) }}</div>
        </div>
      </div>
      <!-- 任务描述2 -->
      <div class="task2">
        <div class="say">{{ $t("details.word[8]") }}:</div>
        <div class="btn">
          <div>{{ $t("details.word[9]") }}</div>
          <div>{{ $t("details.word[10]") }}</div>
          <div>{{ $t("details.word[11]") }}</div>
        </div>
      </div>
      <!-- 评论内容 -->
      <div class="com">
        <div class="com_one">{{ $t("details.word[12]") }}:</div>
        <p>{{ $t("details.word[18]") }}</p>
      </div>
      <!-- 地址复制 -->
      <div class="foor">
        <input type="text" name="" id="" />
        <div>
          <button>{{ $t("details.word[13]") }}</button>
          <button>
            <a href="">{{ $t("details.word[14]") }}</a>
          </button>
        </div>
      </div>
      <!-- 任务步骤 -->
      <van-tabs
        class="TaskHall"
        :border="false"
        color="#0000CD"
        v-model="index_select"
        title-inactive-color="#ccc"
        line-width="60"
        style="font-weight: bold"
      >
        <!-- 任务步骤 -->
        <van-tab
          class="active"
          :title="$t('details.word[15]')"
          style="background: #fff; font-weight: bold"
        >
          <div class="img1">
            <div>{{ $t("details.word[17]",{index:1}) }}到商场下单</div>
            <div>
              <img src="" alt="" />
            </div>
          </div>
          <div class="img2">
            <div>{{ $t("details.word[17]",{index:2}) }}支付money</div>
            <div>
              <img src="" alt="" />
            </div>
          </div>
        </van-tab>
        <!-- 审核样例 -->
        <van-tab
          :title="$t('details.word[16]')"
          style="background: #fff; font-weight: bold"
        >
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      infoData: "",
      index_select: 0,
    };
  },
  created() {
    this.getTaskinfo();
    this.promoteUrl = `${this.InitData.setting.reg_url}/#/register/${this.UserInfo.idcode}`;
  },
  methods: {
    getTaskinfo() {
      this.$Model.TaskOrderInfo(this.taskId, (data) => {
        this.isLoad = false;
        if (data.code == 1) {
          this.infoData = data.info;
        }
        // this.$nextTick(() => {
        //   if (data.info.is_fx == 1) {
        //     new QRCode(document.getElementById("QRCode"), {
        //       text: this.promoteUrl,
        //       width: 110,
        //       height: 110,
        //       correctLevel: QRCode.CorrectLevel.H,
        //     });
        //   }
        // });
      });
    },
  },
};
</script>
<style scoped>
.details {
  overflow: auto;
  height: 100%;
}
.title {
  background: #4e51bf;
  color: #fff;
  height: 46px;
  text-align: center;
  line-height: 46px;
  font-size: 17px;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 999;
}
.details p:nth-of-type(1) {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}
.body {
  margin-top: 60px;
}
.head {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.head_one {
  border: #dbdbdbdb 1px solid;
  box-shadow: 0px 0px 10px 0px #dbdbdbdb;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin: auto;
  transform: translate(80%);
  margin-top: 10px;
}
.head_two {
  color: #ffa7b5;
  height: 60px;
  line-height: 120px;
  margin-right: 20px;
  font-size: 13px;
  font-weight: 600;
}
.neck {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #dbdbdbdb;
  line-height: 40px;
  display: flex;
  justify-content: space-around;
}
.dot {
  background: #4e51bf;
  width: 9px;
  height: 9px;
  border-radius: 20px;
  margin: auto;
  margin-right: 5px;
}
.neck_one,
.neck_two,
.neck_three {
  display: flex;
}
.neck_one{
	overflow: hidden;
}
.neck_one div:nth-child(2){
	flex: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.neck_two div:nth-child(2){
	white-space: nowrap;
}
.more_dot {
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #dbdbdbdb;
}
.more_dot div:nth-of-type(1) {
  height: 40px;
  width: 40px;
  border: 1px solid #dbdbdbdb;
  box-shadow: 0px 0px 10px 0px #dbdbdbdb;
  border-radius: 30px;
}
.more_dot div:nth-of-type(2) {
  line-height: 80px;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 600;
}
.task div:nth-of-type(1) {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}
.task p {
  font-weight: 500 !important;
  text-align: left !important;
  line-height: 25px;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.task {
  padding: 0 20px;
}
.task2 {
  padding: 0 20px;
}
.say {
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
}
.btn {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.btn div {
  font-weight: 500;
  font-size: 12px;
  font-weight: 600;
  background: #b3b6fb;
  width: 85px;
  text-align: center;
  border-radius: 10px;
  height: 24px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.com {
  padding: 20px;
}
.com_one {
  font-weight: 600;
  font-size: 16px;
}
.com p {
  font-weight: 500 !important;
  text-align: left !important;
  line-height: 25px;
  margin-top: 6px;
  line-height: 25px;
  font-size: 12.5px !important;
  font-weight: 600 !important;
}
.foor {
  padding: 20px;
}
.foor input {
  height: 30px;
  width: 250px;
  border-radius: 10px;
  border: none;
  background: #f5f5f5;
}
.foor div {
  display: flex;
  flex-direction: column;
  width: 60px;
  margin-top: -70px;
  margin-left: 80%;
}
.foor div button {
  background: #4e51bf;
  color: #fff;
  border: none;
  height: 30px;
  border-radius: 12px;
  font-size: 13px;
}
a {
  color: #fff;
}
.foor div button:nth-of-type(2) {
  margin-top: 10px;
}
/* .details >>> .van-tabs__line {
  height: 0px;
}
.details >>> .van-tab {
  color: #fff;
  font-size: 16px;
}
.details >>> .van-tab__text {
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: flex;
  justify-content: center;
} */
.TaskHall {
  padding: 0px 15px;
}
.TaskHall >>> .van-tabs__wrap {
  display: flex;
  justify-content: center;
  height: 60px;
}
.TaskHall >>> .van-tabs__nav {
  border: 2px solid #4e51bf !important;
  border-bottom: 2px solid #4e51bf !important;
  border-radius: 12px;
  padding: 0 !important;
  box-sizing: border-box;
  background-color: #4e51bf;
  height: 30px;
}
.TaskHall >>> .van-tab {
  width: 110px;
  box-sizing: border-box;
  padding: 0 !important;
  line-height: 28px;
  color: #fff !important;
}
.TaskHall >>> .van-tab .van-tab__text {
  width: 100%;
  height: 100%;
  font-size: 12px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TaskHall >>> .van-tab .van-tab__text div {
  width: 100%;
  height: 100%;
  text-align: center;
}
.TaskHall >>> .van-tab--active {
  background-color: #fff;
  border-radius: 10px;
  color: #000 !important;
  font-weight: 500 !important;
}
.TaskHall >>> .van-tabs__line {
  display: none;
}

.img1 {
  border: 1px solid #dbdbdbdb;
  border-radius: 5px;
  padding: 10px;
  margin: 0 20px 0 20px;
  /* box-shadow: #dbdbdbdb 1px 1px 8px 0px; */
}
.img1 div:nth-of-type(1) {
  height: 25px;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #dbdbdbdb;
}
.img1 div:nth-of-type(2) {
  width: 100%;
  height: 180px;
  margin-top: 10px;
  background: #f5f5f5;
}
/* .img1 img {
  width: 100%;
  height: 100%;
} */
.img2 {
  border: 1px solid #dbdbdbdb;
  border-radius: 5px;
  padding: 10px;
  margin: 20px 20px 40px 20px;
  margin-top: 20px;
  /* box-shadow: #dbdbdbdb 1px 1px 5px 1px; */
}
.img2 div:nth-of-type(1) {
  height: 25px;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid #dbdbdbdb;
}
.img2 div:nth-of-type(2) {
  width: 100%;
  height: 180px;
  margin-top: 10px;
  background: #f5f5f5;
}
/* .img2 img {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
} */
</style>