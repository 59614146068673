<template>
  <div class="resultsTheQueryBox">
    <div class="bgc">
      <div class="topTitle">{{ $t("resultsTheQuery.default[0]") }}</div>
    </div>
    <div class="height"></div>
    <div class="resultsTheQuery_nav">
      <ul class="resultsTheQuery_nav_active">
        <li
          class="resultsTheQuery_nav_active_item"
          v-for="(item, index) in $t('resultsTheQuery.nav')"
          :class="{ active: type - 1 == index }"
          @click="switchType(index + 1)"
        >
          {{ item }}
        </li>
      </ul>
      <div class="resultsTheQuery_nav_box">
        <div class="resultsTheQuery_nav_title">
          {{ $t("resultsTheQuery.default[1]") }}
        </div>
        <div class="resultsTheQuery_nav_content">
          <div class="resultsTheQuery_nav_timeBox">
            <div class="resultsTheQuery_nav_time start">
              <input
                type="text"
                readonly
                v-model="startDate"
                @click="
                  (showDate = true),
                    (currentDate = new Date(startDate)),
                    (pickType = 1)
                "
              />
              <img src="../../../public/static/images/riQiPan.png" />
            </div>
            <span>-</span>
            <div class="resultsTheQuery_nav_time end">
              <input
                type="text"
                readonly
                v-model="endDate"
                @click="
                  (showDate = true),
                    (currentDate = new Date(endDate)),
                    (pickType = 2)
                "
              />
              <img src="../../../public/static/images/riQiPan.png" />
            </div>
          </div>
          <div class="resultsTheQuery_nav_search" @click="getPerformance()">
            {{ $t("resultsTheQuery.default[4]") }}
          </div>
        </div>
      </div>
    </div>
    <div class="resultsTheQuery_content" v-if="this.type < 3">
      <resultTheQueryItem
        v-for="(item, index) in performanceList"
        :item="item"
        :key="index"
      >
        <div
          v-if="type > 1"
          @click="openDetail(item)"
          class="resultsTheQuery_item_goDetail"
        >
          {{ $t("resultsTheQuery.default[8]")
          }}<img src="../../../public/static/images/detailJianTou.png" />
        </div>
      </resultTheQueryItem>
    </div>
    <div
      class="resultsTheQuery_content"
      v-if="this.type == 3"
      style="margin-top: 10px"
    >
      <div v-for="(items, reportdate) in performanceList">
        <div class="resultsTheQuery_item_title">
          <div class="resultsTheQuery_item_time">
            {{ $t("resultsTheQuery.default[5]") }}：<span>{{
              reportdate
            }}</span>
          </div>
          <slot></slot>
        </div>
        <resultTheQueryItem
          :showDate="showDetailDate"
          v-for="(item, index) in items"
          :item="item"
          :showMy="showDetailDate"
          :key="index"
        >
          <div @click="openDetail(item)" class="resultsTheQuery_item_goDetail">
            {{ $t("resultsTheQuery.default[8]")
            }}<img src="../../../public/static/images/detailJianTou.png" />
          </div>
        </resultTheQueryItem>
      </div>
    </div>
    <!-- 详情 -->
    <van-popup v-model="show">
      <div class="popup_box">
        <div class="popup_box_title">
          {{ $t("resultsTheQuery.default[7]")
          }}<img
            src="../../../public/static/images/guanBi.png"
            @click="show = !show"
          />
        </div>
        <div class="popup_box_content">
          <resultTheQueryItem
            :showMy="true"
            :item="item"
            v-for="(item, index) in detaillist"
            :key="index"
          ></resultTheQueryItem>
        </div>
      </div>
    </van-popup>
    <!-- 日期 -->
    <van-popup
      class="popup_date"
      v-model="showDate"
      position="bottom"
      close-on-popstate
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        :title="$t('resultsTheQuery.date[0]')"
        :min-date="minDate"
        :confirm-button-text="$t('resultsTheQuery.date[1]')"
        :cancel-button-text="$t('resultsTheQuery.date[2]')"
        @confirm="confirmDate"
        @cancel="showDate = false"
      />
    </van-popup>
  </div>
</template>

<script>
import resultTheQueryItem from "@/components/resultTheQueryItem.vue";
export default {
  name: "resultsTheQuery",
  components: {
    resultTheQueryItem,
  },
  data() {
    return {
      show: false, //详情
      type: 1,
      showDetailDate: false,
      performanceList: [], //查询的用户数据
      detaillist: [],
      //
      showDate: false, //显示日期
      startDate: this.$Util.DateFormat("YY-MM-DD", new Date()),
      endDate: this.$Util.DateFormat("YY-MM-DD", new Date()),
      currentDate: "",
      minDate: new Date(2020, 0, 1),
    };
  },
  computed: {},
  watch: {},
  created() {
    //标题
    this.$parent.navBarTitle = "";
    //默认获取当月数据
    this.getPerformance();
  },
  mounted() {},
  methods: {
    //选择日期
    confirmDate(date) {
      if (this.pickType == 1) {
        if (this.endDate) {
          //判断起始时间不能小于结束时间
          let end = parseInt(this.endDate.split("-").join("")),
            start = parseInt(
              this.$Util.DateFormat("YY-MM-DD", date).split("-").join("")
            );
          if (start > end)
            return this.$toast(this.$t("resultsTheQuery.msg[1]"));
        }
        this.startDate = this.$Util.DateFormat("YY-MM-DD", date);
      }
      if (this.pickType == 2) {
        if (this.startDate) {
          //判断起始时间不能小于结束时间
          let start = parseInt(this.startDate.split("-").join("")),
            end = parseInt(
              this.$Util.DateFormat("YY-MM-DD", date).split("-").join("")
            );
          if (start > end)
            return this.$toast(this.$t("resultsTheQuery.msg[1]"));
        }
        this.endDate = this.$Util.DateFormat("YY-MM-DD", date);
      }
      this.showDate = false;
    },
    switchType(type) {
      this.type = type;
      this.getPerformance();
    },
    //打开详情弹窗
    openDetail(item) {
      console.log(item);
      let param = {
        type: this.type,
        period: 1,
        report_date: item["report_date"],
      };
      if (this.type == 3) {
        param["childuid"] = item["uid"];
      }
      this.$Model.getResults(param, (data) => {
        this.detaillist = data.performData;
        console.log(this.detaillist);
        this.show = true;
      });
    },
    //获取查询信息
    getPerformance(chooseDate) {
      this.performanceList = [];

      let param = {
        type: this.type,
        period: 1,
      };
      if (chooseDate == 1) {
        if (!this.startDate || !this.endDate) {
          this.$toast(this.$t("resultsTheQuery.msg[0]"));
          return false;
        }
        param["startdate"] = this.startDate; //开始时间
        param["enddate"] = this.endDate;
      }
      this.$Model.getResults(param, (data) => {
        this.performanceList = data.performData;
        var tt = this.performanceList.length;
        console.log(this.performanceList);
      });
    },
  },
};
</script>

<style scoped>
.resultsTheQueryBox {
  overflow: auto;
  height: 100vh;
  background-color: #f5f5f5;
}

.resultsTheQueryBox .bgc {
  width: 100%;
  background-color: #4e51bf;
  height: 46px;
  position: fixed;
  top: 0;
  z-index: 200;
}

.topTitle {
  position: absolute;
  max-width: 60%;
  white-space: nowrap;
  font-size: 18px;
  color: #fff;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.height {
  height: 46px;
}

.resultsTheQuery_nav {
  padding: 10px 15px 70px;
  background-color: #4e51bf;
  border-radius: 0px 0px 65px 65px;
}

.resultsTheQuery_nav .resultsTheQuery_nav_active {
  padding: 5px 5px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
  max-width: 450px;
}

.resultsTheQuery_nav .resultsTheQuery_nav_active_item {
  color: #333;
  font-size: 15px;
  /* font-weight: 600; */
  padding: 8.5px 40px;
  border-radius: 4px;
  font-family: Adobe 黑体 Std;
}

.active {
  color: #4e51bf !important;
  background: linear-gradient(180deg, #b1adff, #f3e1d6);
}

.resultsTheQuery_nav .resultsTheQuery_nav_box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.resultsTheQuery_nav .resultsTheQuery_nav_title {
  color: #e2e2f4;
  font-size: 13px;
  /* font-weight: 600; */
  margin-right: 8px;
}

/* 日期 */
.resultsTheQuery_nav .resultsTheQuery_nav_timeBox {
  display: flex;
  align-items: center;
}

.resultsTheQuery_nav .resultsTheQuery_nav_timeBox span {
  margin: 0 7px;
  color: #fff;
}

.resultsTheQuery_nav .resultsTheQuery_nav_time {
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.resultsTheQuery_nav .resultsTheQuery_nav_time input {
  height: 29px;
  border-radius: 6px;
  color: #e2e2f4;
  background-color: #4143ac;
  border: 1px solid #7c7cd8;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  padding: 4px 30px 4px 4px;
  text-align: center;
  box-sizing: border-box;
}

input::-webkit-input-placeholder {
  color: #fff;
  font-size: 14px;
}

.resultsTheQuery_nav .resultsTheQuery_nav_time img {
  position: absolute;
  top: 50%;
  transform: translate(-15%, -40%);
  right: 0px;
  width: 20px;
  margin-left: 9%;
}

.resultsTheQuery_nav .resultsTheQuery_nav_content {
  display: flex;
  flex-wrap: wrap;
}

/* .resultsTheQuery_nav .resultsTheQuery_nav_timeBox>>> */
.resultsTheQuery_nav .resultsTheQuery_nav_search {
  padding: 4px 17.5px;
  border: 1px solid #afaeff;
  border-radius: 4px;
  color: #403dec;
  font-size: 13px;
  background: linear-gradient(180deg, #b1adff, #f3e1d6);
  flex-shrink: 0;
  margin-left: 10px;
  box-sizing: border-box;
  line-height: 18px;
}

.resultsTheQuery_content {
  padding: 0 15px;
  margin-top: -55px;
}

.van-overlay {
  z-index: 10000 !important;
}

.van-popup {
  width: 90%;
  border-radius: 10px;
  z-index: 10001 !important;
}

.popup_box {
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
}

.popup_box_title {
  font-size: 15px;
  padding: 15px 0;
  /* font-weight: 525; */
  display: flex;
  justify-content: center;
  position: relative;
  border-bottom: 0.5px solid #eeeeee;
  flex-shrink: 0;
  font-family: Adobe 黑体 Std;
}

.popup_box_title img {
  position: absolute;
  right: 13px;
  top: 10px;
  width: 22px;
}

.popup_box_content {
  height: 100%;
  overflow: auto;
}

.ipt {
  font-size: 13px;
}
.popup_date {
  width: 100%;
  border-radius: 10px 10px 0 0;
}
</style>
