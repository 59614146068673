<template>
	<div class="share_box">
	<div class="share">
		<div class="bgc" :class="[show?'':'bgColor']">
			<div class="topTitle">{{$t('share.dollar[0]')}}</div>
		</div>
		<div class="subtitle" ref="count">
			<img :class="[Language=='cn'?'subtitle_cn':'']" :src="`/static/images/theFirsetToShareTitle_${Language}.png`" />
		</div>
		<div class="subject">
			<div :class="isCN ? 'head' : 'headEn'">
				<!-- <div class="head"> -->
				<!-- <div class="subHead">{{ $t("share.dollar[1]") }}</div> -->
			</div>
			<div :class="isCN ? 'subBody' : 'subBodyEn'">
				<div :class="isCN ? 'subHead' : 'subHeadEn'">
					{{ $t("share.dollar[1]") }}
				</div>
				<div :class="isCN ? 'one' : 'oneEn'">
					<div :class="isCN ? 'two' : 'twoEn'" style="overflow: hidden">
						<p class="pCn" v-for="(item,index) in $t('share.words')">{{item}}</p>
					</div>
				</div>
			</div>
			<div @click="jump()" :class="isCN ? 'subTitle' : 'subTitleEn'">
				{{ $t("share.dollar[2]") }}
			</div>
		</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				show: true,
				isCN: localStorage.getItem("Language") == "cn",
				Language:localStorage['Language'],//当前语言
			};
		},
		props:["id"],
		created() {
			this.pageScroll();
			this.$parent.navBarTitle = "";
			this.getActivityGet(this.id)
		},
		destroyed() {
			// 在组件生命周期结束的时候要销毁监听事件，否则其他页面也会使用这个监听事件
			window.removeEventListener("scroll", this.handleScroll, true);
		},
		methods: {
			//活动详情
			getActivityGet(id) {
				console.log(this.id)
				let json = {
					id
				}
				this.$Model.ActivityGet(json, (data) => {
					console.log(data)
					// this.signList = data.data.sign_config
				})
			},
			jump() {
				// 提现
				this.$router.push("/user/withdrawalCenter");
			},
			pageScroll() {
				// 监听滚动事件，用handleScroll这个方法进行相应的处理
				window.addEventListener("scroll", this.handleScroll, true);
			},
			handleScroll() {
				let top = this.$refs.count.getBoundingClientRect().top;
				// 监听到页面滚动后的处理事件
				if (top > -36) {
					this.show = true;
				} else {
					this.show = false;
				}
			},
		},
	};
</script>
<style scoped>
	.share_box{
		overflow: auto;
		height: 100%;
	}
	.share {
		background: url("../../../public/static/images/thfristbg.jpg");
		background-size: 100%;
		width: 100%;
		padding-bottom: 70px;
	}
	.share .bgc {
		width: 100%;
		background-color: transparent;
		height: 46px;
		position: fixed;
		top: 0;
		z-index: 200;
	}
	.bgColor{
		background-color: #4e51bf !important;
	}
	.topTitle {
		position: absolute;
		max-width: 60%;
		white-space: nowrap;
		font-size: 18px;
		color: #fff;
		background-color: transparent;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	.height {
		height: 46px;
	}
	.subtitle {
		height: 220px;
		width: 100%;
		padding-top: 70px;
		text-align: center;
	}

	.subtitle img {
		width: 260px;
	}
	.subtitle .subtitle_cn{
		width: 210px;
		margin-top: 20px;
	}

	.title {
		font-size: 16px;
		padding-top: 10px;
		text-align: center;
		color: white;
		width: 100%;
		position: fixed;
	}

	.title2 {
		font-size: 16px;
		padding-top: 10px;
		text-align: center;
		color: white;
		width: 100%;
		position: fixed;
		background: #4e51bf;
		height: 46px;
	}

	.showImg {
		width: 90%;
		height: 120px;
		margin: 10px 5% 0 5%;
		border-radius: 15px;
	}

	.head {
		width: 100%;
		position: absolute;
		top: 260px;
		z-index: 99;
	}

	.subHead {
		text-align: center;
		width: 180px;
		height: 40px;
		line-height: 40px;
		border-radius: 8px;
		background-image: linear-gradient(to bottom, #f9c571, #f19a73);
		color: #ff395b;
		margin: 40px auto 0 auto;
		font-size: 15px;
		font-weight: 600;
		margin: auto;
		position: relative;
	}

	.subBody {
		padding: 20px;
		/* position: absolute; */
		top: 260px;
		/* position: relative; */
	}

	.subBody .one {
		background-image: linear-gradient(to bottom, #ff93a4, #4e51bf);
		padding: 1px;
		border-radius: 14px;
		width: 100%;
		height: 100%;
		margin-top: -20px;
	}

	.subBody .two {
		background: #fff;
		border-radius: 15px;
		width: 100%;
		height: 100%;
		padding: 40px 0px 20px 10px;
	}

	.pCn {
		font-size: 12px;
		line-height: 25px;
		font-weight: 600;
	}

	.subTitle {
		background-image: linear-gradient(to right, #fde5a8, #ff94a4);
		height: 40px;
		color: #ff395b;
		font-weight: 600;
		line-height: 40px;
		border-radius: 15px;
		margin: 0px auto 0 auto;
		font-size: 16px;
		border: 1px;
		padding: 0px 10px;
		/* width: max-content; */
		width: 160px;
		text-align: center;
	}

	.share>>>.van-swipe__track {
		width: 100%;
		height: 120px;
	}

	.showImg {
		overflow: hidden;
		position: absolute;
	}

	.share>>>.van-swipe__indicator--active {
		background: #4e51bf;
		border-radius: 10px !important;
	}

	.share>>>.van-swipe__indicator {
		border-radius: 10px !important;
	}

	/* 英文样式 */
	.subtitleEn {
		height: 195px;
		width: 100%;
		padding-top: 50px;
	}

	.subtitleEn p {
		text-align: center;
		color: #fff156;
		font-weight: 600;
		font-family: Alibaba;
		font-size: 24px !important;
		text-shadow: -2px -1px 0px #fa36b6;
		line-height: 1.4;
		width: 70%;
		margin: 0px auto;
	}

	.headEn {
		width: 100%;
		position: absolute;
		top: 248px;
		z-index: 99;
	}

	.subHeadEn {
		text-align: center;
		width: 188px;
		height: 40px;
		line-height: 40px;
		border-radius: 6px;
		background-image: linear-gradient(to bottom, #f9c571, #f19a73);
		color: #ff395b;
		font-size: 21px;
		font-weight: 600;
		position: absolute;
		left: 50%;
		transform: translate(-50%, -50%);
		/* letter-spacing: 1px; */
	}

	.subBodyEn {
		padding: 46px 20px 0px;
		position: relative;
	}

	.subBodyEn .oneEn {
		background-image: linear-gradient(to bottom, #ff93a4, #4e51bf);
		padding: 1px;
		border-radius: 14px;
		width: 100%;
		height: 100%;
	}

	.subBodyEn .twoEn {
		background: #fff;
		border-radius: 15px;
		width: 100%;
		height: 100%;
		padding: 40px 0px 20px 10px;
	}

	.pEn {
		font-size: 12px;
		line-height: 25px;
		font-weight: 600;
		word-wrap: break-word;
	}

	.subTitleEn {
		background-image: linear-gradient(to right, #fde5a8, #ff94a4);
		height: 40px;
		color: #ff395b;
		font-weight: 600;
		line-height: 40px;
		border-radius: 15px;
		margin: 21px auto 0px auto;
		font-size: 16px;
		border: 1px;
		padding: 0px 10px;
		/* width: max-content; */
		width: 188px;
		text-align: center;
	}
</style>
