<template>
  <div class="PageBox">
	  <div class="bgc">
	  	<div class="topTitle">{{$t('postingDetails.navBar.title')}}</div>
	  </div>
    <div class="ScrollBox">
      <van-form label-width="70">
		<!-- 任务分类开始 -->
        <van-field 
          class="m0" 
          :label="$t('postingDetails.field[0]')" 
          :border="false"
        >
		 <van-dropdown-menu slot="input">
		   <van-dropdown-item v-model="postData.task_class" :options="taskType" @change="changeDropdown" />
		 </van-dropdown-menu>
        </van-field>
	   <van-field
	     :border="false" 
	     v-model.trim="postData.title" 
	     :label="$t('postingDetails.field[1]')" 
	   />
		<van-field
		  :border="false" 
		  v-model.trim="postData.content" 
		  rows="1" 
		  autosize 
		  :label="$t('postingDetails.field[2]')" 
		  type="textarea" 
		/>
		<van-field
		  :border="false" 
		  :label="$t('postingDetails.field[3]')" 
		>
        </van-field>
		<van-field
		  :border="false" 
		  :label="$t('postingDetails.field[4]')"
		  
		/>
		<van-field
		  :border="false" 
		  v-model="postData.total_price" 
		  :label="$t('postingDetails.field[5]')" 
		  readonly
		/>
		<van-field
		  class="reserved"
		  :border="false" 
		  v-model.trim="postData.person_time" 
		  type="digit"
		  :label="$t('postingDetails.field[6]')"
		>
		 <template #right-icon>
			 <div @click="goTaskdetails()" class="goDetails">{{$t('postingDetails.default[0]')}}</div>
		 </template>
		</van-field>
		<van-field
		  :border="false" 
		  :label="$t('postingDetails.field[7]')"
		/>
       <van-field 
          :label="$t('postingDetails.field[8]')" 
          :border="false"
        >
		 </van-field>
		<van-field
		  :border="false" 
		  :label="$t('postingDetails.field[9]')"
		/>
	 <van-field
	   :border="false" 
	   :label="$t('postingDetails.field[10]')"
	 />
        <van-field 
          :border="false" 
          :label="$t('postingDetails.field[11]')"
        >
          <template #input>
            <van-checkbox-group v-model="conditionArr">
              <van-checkbox :name="index" v-for="(item,index) in InitData.authenticationList" :key="index">{{item}}</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field 
          :label="$t('postingDetails.field[12]')"
        >
        </van-field>
        <van-field 
          :border="false"
          :label="$t('postingDetails.field[13]')" 
        />
      </van-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'postingDetails',
  components: {
  },
  props: ['taskId'],
  data() {
    return {
      taskType: [],
      taskTips: '',
      levelOptions: [],
      showCalendar: false,
      fileList: [],
      conditionArr: [],
      showSteps: false,
      stepData: [
        {
          img: [],
          describe: ''
        }
      ],
      postData: {
        task_class: 0,
        title: '',
        content: '',
        reward_price: 1,
        total_number: '',
        person_time: 1,
        total_price: 0,
        link_info: '',
        task_level: 1,
        end_time: '',
        finish_condition: '',
        requirement: '',
        examine_demo: '',
        task_step: [],
      },
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    // this.$parent.navBarTitle = this.$t('postingDetails.navBar.title')
	this.$parent.navBarTitle = ''
    this.taskType = this.InitData.taskclasslist.filter(obj=>obj.is_f==1).flatMap(item => [{text: item.group_name,value: item.group_id}])
    this.levelOptions = this.InitData.UserGradeList0.flatMap(item => [{text: item.name,value: item.grade}])
	console.log(this.taskId)
    if(this.taskId){
      this.$Model.GetTaskinfo(this.taskId,data=>{
        if(data.code==1){
          this.postData = {
            id: this.taskId,
            task_class: data.info.task_class,
            title: data.info.title,
            content: data.info.content,
            reward_price: Number(data.info.reward_price),
            total_number: Number(data.info.total_number),
            person_time: Number(data.info.person_time),
            total_price: Number(data.info.total_price)+Number(data.info.total_price)*this.UserInfo.pump,
            link_info: data.info.link_info,
            task_level: data.info.task_level,
            end_time: data.info.end_time,
            finish_condition: data.info.finish_condition||'',
            requirement: data.info.requirement||'',
            examine_demo: data.info.examine_demo||'',
            task_step: data.info.task_step||[]
          }
          this.taskTips = this.InitData.taskclasslist.find(item=>item.group_id==data.info.task_class).group_info
          this.conditionArr = data.info.finish_condition||[]
          this.fileList = data.info.examine_demo?data.info.examine_demo.flatMap(item=>[{url: this.ApiUrl+item}]):[]
          this.stepData = data.info.task_step?data.info.task_step.flatMap(item=>[{img:[{url: this.ApiUrl+item.img}],describe:item.describe}]):[]
        }
		console.log(data.info)
      })
    }else{
      this.taskTips = this.InitData.taskclasslist.filter(obj=>obj.is_f==1)[0].group_info
      this.postData.task_class = this.$route.query.type?Number(this.$route.query.type):this.InitData.taskclasslist.filter(obj=>obj.is_f==1)[0].group_id
      this.postData.task_level = this.InitData.UserGradeList[0].grade
    }
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {
    
  },
  methods: {
	goTaskdetails(){
		this.$router.push("/user/taskdetails")
	},
    changeDropdown(val) {
      this.taskTips = this.InitData.taskclasslist.find(item=>item.group_id==val).group_info
    },
    onConfirm(date) {
      this.showCalendar = false
      this.postData.end_time = this.$Util.DateFormat('YY-MM-DD',date)
    },
    afterRead(file) {
      file.status = 'uploading'
      file.message = this.$t('upload[0]')
      this.uploadImgs(file)
    },
    compressImg(file) {
      this.$Util.CompressImg(file.file.type,file.content,750,(image)=>{
        let param = new FormData();
        param.append('token',localStorage['Token']);
        param.append('type',3);
        param.append('image',image,file.file.name);
        this.$Model.UploadImg(param,(data)=>{
          if(data.code==1){
            file.message = this.$t('upload[2]')
            file.status = 'success'
            file.url = data.url
          }else{
            file.status = 'failed'
            file.message = this.$t('upload[3]')
          }
        });
      })
    },
    uploadImgs(file){
      if(file.length){
        file.forEach(item=>{
          if (!item.file.type.match(/image/)){
            item.status = 'failed'
            item.message = this.$t('upload[1]')
            return;
          }
          this.compressImg(item)
        })
      }else{
        if (!file.file.type.match(/image/)){
          file.status = 'failed'
          file.message = this.$t('upload[1]')
          return;
        }
        this.compressImg(file)
      }
    },
    minusSteps() {
      if(this.stepData.length==1){
        this.$Dialog.Toast(this.$t('postingDetails.tips[2]'));
      }
      if(this.stepData.length>1){
        this.stepData.pop()
      }
    },
    pushSteps() {
      if(this.stepData.length==10){
        this.$Dialog.Toast(this.$t('postingDetails.tips[3]'));
      }
      if(this.stepData.length<10){
        this.stepData.push({
          img: [],
          describe: ''
        })
      }
      
    },
    completeSteps() {
      let isOk = true
      this.stepData.forEach(item=>{
        if(!item.img.length){
          this.$Dialog.Toast(this.$t('postingDetails.field[13].img'));
          isOk = false
        }
      })
      if(isOk){
        this.showSteps = false
      }
    },
    totalPrice() {
      this.postData.total_price = this.postData.reward_price*this.postData.total_number+this.postData.reward_price*this.postData.total_number*this.UserInfo.pump
    },
    onSubmit() {
      const emptyIndex = this.stepData.findIndex(item=>!item.img.length)+1
      if(!emptyIndex){
        this.postData.task_step = []
        this.stepData.forEach(item=>{
          this.postData.task_step.push({img: item.img[0].url.replace(this.ApiUrl,''),describe: item.describe})
        })
      }else{
        this.postData.task_step = []
      }
      if(!this.postData.title){
        this.$Dialog.Toast(this.$t('postingDetails.field[1].error'));
        return
      }
      if(!this.postData.reward_price){
        this.$Dialog.Toast(this.$t('postingDetails.field[3].error'));
        return
      }
      if(!this.postData.total_number){
        this.$Dialog.Toast(this.$t('postingDetails.field[4].error'));
        return
      }
      if(!this.postData.person_time){
        this.$Dialog.Toast(this.$t('postingDetails.field[5].error'));
        return
      }
      if(!this.postData.total_price){
        this.$Dialog.Toast(this.$t('postingDetails.field[6].error'));
        return
      }
      if(!this.postData.link_info){
        this.$Dialog.Toast(this.$t('postingDetails.field[7].error'));
        return
      }
      if(!this.postData.end_time){
        this.$Dialog.Toast(this.$t('postingDetails.field[9].error'));
        return
      }
      if(!this.postData.task_step.length){
        this.$Dialog.Toast(this.$t('postingDetails.field[13].error'));
        return
      }
      if(this.conditionArr.length){
        this.postData.finish_condition = this.conditionArr
      }else{
        this.postData.finish_condition = ''
      }
      if(this.fileList.length){
        this.postData.examine_demo = this.fileList.map(item=>item.url.replace(this.ApiUrl,''))
      }else{
        this.postData.examine_demo = ''
      }
      this.$Model.postingDetails(this.postData,data=>{
        if(data.code==1){
          if(this.taskId){
            this.postData = {
              task_class: this.InitData.taskclasslist.filter(obj=>obj.is_f==1)[0].group_id,
              title: '',
              content: '',
              reward_price: 1,
              total_number: '',
              person_time: 1,
              total_price: 0,
              link_info: '',
              task_level: 1,
              end_time: '',
              finish_condition: '',
              examine_demo: '',
              task_step: [],
            }
            this.conditionArr = []
            this.fileList = []
            this.stepData = [
              {
                img: [],
                describe: ''
              }
            ]
            $('.ScrollBox')[0].scrollTop = 0
          }else{
            this.$router.go(-1)
          }
        }
      })
    },
  }
}
</script>
<style scoped>
	.PageBox .bgc {
		width: 100%;
		background-color: #4E51BF;
		height: 46px;
		position: fixed;
		top: 0;
		z-index: 200;
	}
	
	.topTitle {
		position: absolute;
		max-width: 60%;
		white-space: nowrap;
		font-size: 18px;
		color: #fff;
		background-color: transparent;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
	}
.van-form{
  padding: 0px 20px 200px;
}
.van-form .van-field{
  background-color: #f4f5fe;
  border-radius: 10px;
  margin-top: 17px;
  padding: 2px 5px 2px 10px;
}
.van-form .van-field:nth-child(1){
  background-color: #f4f5fe;
  border-radius: 10px;
  margin-top: 20px;
  padding: 4px 5px 4px 10px;
}
.van-form>>>.van-field__label{
  color: #000;
  font-size: 14px;
  /* font-weight: 400; */
  font-family: Adobe 黑体 Std;
  margin: 0px;
}
.van-form>>>.van-field__control--custom{
  min-height: auto;
}
.van-dropdown-menu{
  width: 100%;
}
.van-dropdown-menu>>>.van-dropdown-menu__bar{
  background: transparent;
  box-shadow: none;
  height: auto;
}
.van-dropdown-menu>>>.van-dropdown-menu__title{
  font-size: 14px;
  width: 100%;
  padding: 0px 13px 0px 0px;
  margin-right:0px;
  color: #bbb;
  text-align: right;
}
.van-dropdown-menu>>>.van-dropdown-menu__title  {
	color: #000000;
}
.van-dropdown-menu>>>.van-dropdown-menu__title::after{
  border-color: transparent transparent #888 #888;
  right: 0;
}
.van-checkbox-group{
  display: flex;
  white-space: nowrap;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  width: 100%;
  flex-wrap:wrap;
}
.van-checkbox-group>>>.van-checkbox{
	margin-left: auto;
}
.van-checkbox-group>>>.van-icon{
  display: none;
}
.van-checkbox-group>>>.van-checkbox__label{
  margin: 0px 0;
  border: 1px #bbb solid;
  padding: 1px 5px;
  border-radius: 12px;
  font-size: 3px;
  color: #000;
  background: #FFFFFF;
  transform: scale(0.8);
}
.ScrollBox>>>.van-cell::after{
	display: none;
}  
.van-checkbox-group>>>.van-checkbox__icon--checked~.van-checkbox__label{
  color: #606069;
  background-color: #b3b6fb;
  /* border-color: #4087f1; */
}
.Example>>>.van-uploader__wrapper{
  width: 105%;
}
.Example>>>.van-uploader__upload{
  background-color: #fff;
  margin: 4px 8px 4px 0;
}
.Example>>>.van-uploader__preview{
  margin: 4px 8px 4px 0;
}
.van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image{
  width: 60px;
  height: 60px;
  background-color: #d2d2d2;
}

.Steps{
  padding: 10px;
}
.Steps .tag{
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  background-color: #4087f1;
  color: #fff;
  border-radius: 100%;
  margin-right: 10px;
}
.ScrollBox>>>.van-field__control{
	display: flex;
	justify-content: flex-end;
	padding: 0px 15px 0px 0px;
	text-align: end;
	
}
.ScrollBox>>>.van-uploader__upload-icon {
	color: #FFFFFF;
}
.Steps>>>.van-uploader__upload,.Steps>>>.van-uploader__preview{
  margin: 0;
}
.Steps .van-cell__title,.Steps .van-cell__value{
  flex: none;
}
.Steps .van-cell__value{
  margin-left: 10px;
  flex: auto;
}
.Steps .van-cell__value textarea{
  width: 100%;
  min-height: 60px;
  border: 1px #ddd solid;
  display: block;
  padding: 0 5px;
  font-size: 12px;
}
.Tips{
  font-size: 12px;
  margin-top: 10px;
  color: #999;
}
.Tips>>>a{
  color: #4e51bf;
}
.reserved .goDetails{
	padding:0px 5px;
	border-radius: 8px;
	border: 1px solid #d5d6de;
	background-color: #fff;
	margin-right: 5px;
	font-size: 12px;
	transform: scale(0.8);
	color: #6a6a6a;
	border-bottom: none;
}
.van-cell>>>.van-cell__title{
	width: 85px  !important;
}
.van-cell>>>.van-cell__title span{
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	word-wrap: initial;
}



</style>