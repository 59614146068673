<template>
  <div class="IndexBox">
    <div class="ScrollBox">
      <img :src="`${ApiUrl}/static/images/profit-${$i18n.locale}.jpg`" width="100%">
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  name: 'Profit',
  components: {

  },
  props: [],
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {
    
  },
  methods: {

  }
}
</script>
<style scoped>

</style>